const handlePhone = (key, value) =>
  (key === 'phone' || key === 'home_phone') && value?.startsWith('+1')
    ? `(${value.slice(2, 5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`
    : value;

export const parseAttributes = (attributes) =>
  attributes &&
  Object.fromEntries(
    Object.entries(attributes).map(([key, { value, type }]) => [
      key,
      type === 'json' ? JSON.parse(value) : handlePhone(key, value),
    ])
  );

import { forwardRef, useEffect, useRef } from 'react';
import { Stack, useTheme } from '@mui/material';
import * as d3 from 'd3';

import uid from 'utils/uid';
import {
  getTooltip,
  tooltipMouseMoveHandler,
  tooltipMouseLeaveEvent,
} from './tooltip';

const TreemapChart = forwardRef(({ id, data, height = '300px' }, ref) => {
  const _ref = useRef(null);
  const localRef = ref ?? _ref;
  const theme = useTheme();

  useEffect(() => {
    if (!localRef?.current?.offsetHeight) return;

    const resizeObserver = new ResizeObserver(() => {
      const height = localRef.current.offsetHeight - 5;
      const width = localRef.current.offsetWidth;

      d3.select(`#${id}`).selectAll('svg').remove();

      // Compute the layout.
      const root = d3
        .treemap()
        .tile(d3.treemapBinary)
        .size([width, height])
        .padding(1)
        .round(true)(
        d3
          .hierarchy(data)
          .sum((d) => d.value)
          .sort((a, b) => b.value - a.value)
      );

      const svg = d3
        .select(localRef.current)
        .append('svg')
        .attr('viewBox', [0, 0, width, height]);

      const tooltip = getTooltip(id, theme);

      // Add a cell for each leaf of the hierarchy.
      const leaf = svg
        .selectAll('g')
        .data(root.leaves())
        .join('g')
        .attr('transform', (d) => `translate(${d.x0},${d.y0})`)
        .on('mousemove', tooltipMouseMoveHandler(data, tooltip, theme))
        .on('mouseleave', tooltipMouseLeaveEvent(tooltip));

      // Append a color rectangle.
      leaf
        .append('rect')
        .attr('id', (d) => (d.leafUid = uid('leaf')).id)
        .attr('fill', (d) => {
          while (d.depth > 1) d = d.parent;
          return d.data.color;
        })
        .attr('width', (d) => d.x1 - d.x0)
        .attr('height', (d) => d.y1 - d.y0);

      // Append a clipPath to ensure text does not overflow.
      leaf
        .append('clipPath')
        .attr('id', (d) => (d.clipUid = uid('clip')).id)
        .append('use')
        .attr('xlink:href', (d) => d.leafUid.href);

      // Append multiline text. The last line shows the value and has a specific formatting.
      leaf
        .append('text')
        .attr('clip-path', (d) => d.clipUid)
        .selectAll('tspan')
        .data((d) => [d.data.name, `${d.data.value}%`])
        .join('tspan')
        .attr('text-anchor', () => 'middle')
        .attr('x', (_, __, node) => {
          const { x0, x1 } = d3.select(node[0].parentNode.parentNode).datum();
          return (x1 - x0) / 2;
        })
        .attr('y', (_, i, node) => {
          const { y0, y1 } = d3.select(node[0].parentNode.parentNode).datum();
          const spaceBetweenText = i * 20;
          const yOffset = -5;
          return spaceBetweenText + yOffset + (y1 - y0) / 2;
        })
        .attr(
          'style',
          (_, i) =>
            `font-size: ${
              i === 1 ? 18 : 16
            }px; font-weight: 800; font-family: sans-serif; fill: white`
        )
        .text((d) => d);
    });

    resizeObserver.observe(localRef.current);

    return () => resizeObserver.disconnect();
  }, [data, localRef, id, theme, theme.palette]);

  return (
    <Stack
      ref={localRef}
      id={id}
      justifyContent='flex-end'
      height={height}
      sx={{ overflow: 'hidden' }}
    />
  );
});

export default TreemapChart;

import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const NHFModal = ({
  title,
  isConfirmLoading,
  isConfirmDisabled,
  confirmLabel = 'Confirm',
  open,
  setOpen,
  handleSubmit,
  children,
  useFormioButtons,
  handleModalClose,
}) => {
  const theme = useTheme();

  const handleClose = () => {
    handleModalClose?.();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {title}
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {!useFormioButtons && (
        <DialogActions>
          <Button
            onClick={handleClose}
            variant='secondary'
            sx={{
              border: `1px solid ${theme.palette.gray[20]}`,
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={isConfirmDisabled}>
            {confirmLabel}{' '}
            {isConfirmLoading && <CircularProgress sx={{ ml: 1 }} size={16} />}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default NHFModal;

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme, Box, Grid, Typography } from '@mui/material';

const ColoredBox = ({ title, backgroundColor, link, boxImage, ...props }) => {
  const theme = useTheme();
  return (
    <Link to={link} style={{ color: theme.palette.gray[100] }}>
      <Box bgcolor={backgroundColor} {...props}>
        <Grid
          container
          sx={{
            paddingTop: { xs: 6, md: 0 },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: '20%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingX: 10,
              paddingTop: 10,
              paddingBottom: 3,
            }}
          >
            <Typography variant='h3' fontSize={24} fontWeight={700} mb={1.5}>
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: '80%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'bottom',
              paddingTop: { xs: 3, md: 0 },
            }}
          >
            <img src={boxImage} width='85%' alt='Splash Background' />
          </Grid>
        </Grid>
      </Box>
    </Link>
  );
};

ColoredBox.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default ColoredBox;

import { useParams } from 'react-router-dom';

import BaselineSurvey from 'components/Surveys/BaselineSurvey';

const Survey = () => {
  const { id = false } = useParams();
  return <BaselineSurvey id={id}></BaselineSurvey>;
};

export default Survey;

const en = {
  //  unsavedRowsError: 'Please save all rows before proceeding.',
  //  invalidRowsError: 'Please correct invalid rows before proceeding.',
  //  invalidRowError: 'Invalid row. Please correct it or delete.',
  //  alertMessageWithLabel: '{{label}}: {{message}}',
  //  alertMessage: '{{message}}',
  //  complete: 'Submission Complete',
  //  error: 'Please fix the following errors before submitting.',
  //  errorListHotkey: 'Press Ctrl + Alt + X to go back to the error list.',
  //  errorsListNavigationMessage: 'Click to navigate to the field with following error.',
  //  submitError: 'Please check the form and correct all errors before submitting.',
  required: 'This field is required',
  // unique: 'This field must be unique',
  // array: 'This field must be an array',
  // array_nonempty: 'This field must be a non-empty array', // eslint-disable-line camelcase
  // nonarray: 'This field must not be an array',
  // select: 'This field contains an invalid selection',
  // pattern: 'This field does not match the pattern {{pattern}}',
  // minLength: 'This field must have at least {{length}} characters.',
  maxLength: 'This field must have no more than {{length}} characters.',
  // minWords: 'This field must have at least {{length}} words.',
  // maxWords: 'This field must have no more than {{length}} words.',
  min: 'This field cannot be less than {{min}}.',
  max: 'This field cannot be greater than {{max}}.',
  // maxDate: 'This field should not contain date after {{- maxDate}}',
  // minDate: 'This field should not contain date before {{- minDate}}',
  // maxYear: 'This field should not contain year greater than {{maxYear}}',
  // minYear: 'This field should not contain year less than {{minYear}}',
  invalid_email: 'Please enter a valid email address',
  // invalid_url: 'This field must be a valid url.', // eslint-disable-line camelcase
  // invalid_regex: 'This field does not match the pattern {{regex}}.', // eslint-disable-line camelcase
  // invalid_date: 'This field is not a valid date.', // eslint-disable-line camelcase
  // invalid_day: 'This field is not a valid day.', // eslint-disable-line camelcase
  mask: '{{ field }} does not match the criteria.',
  // valueIsNotAvailable: 'This field is an invalid value.',
  //  stripe: '{{stripe}}',
  //  month: 'Month',
  //  day: 'Day',
  //  year: 'Year',
  //  january: 'January',
  //  february: 'February',
  //  march: 'March',
  //  april: 'April',
  //  may: 'May',
  //  june: 'June',
  //  july: 'July',
  //  august: 'August',
  //  september: 'September',
  //  october: 'October',
  //  november: 'November',
  //  december: 'December',
  //  next: 'Next',
  //  previous: 'Previous',
  //  cancel: 'Cancel',
  //  submit: 'Submit Form',
  //  confirmCancel: 'Are you sure you want to cancel?',
  //  saveDraftInstanceError: 'Cannot save draft because there is no formio instance.',
  //  saveDraftAuthError: 'Cannot save draft unless a user is authenticated.',
  //  restoreDraftInstanceError: 'Cannot restore draft because there is no formio instance.',
  //  time: 'Invalid time',
  //  cancelButtonAriaLabel: 'Cancel button. Click to reset the form',
  //  previousButtonAriaLabel:'Previous button. Click to go back to the previous tab',
  //  nextButtonAriaLabel:'Next button. Click to go to the next tab',
  //  submitButtonAriaLabel:'Submit Form button. Click to submit the form',
};

export default en;

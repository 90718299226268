import { useState } from 'react';
import { Box, Stack } from '@mui/material';

import Section from 'components/DataStory/Section';
import FormIO from 'components/FormIO';
import employmentDifficulties from 'forms/employmentDifficulties';
import PeopleChart from './peopleChart';
const percentages = {
  hemophiliaA: {
    haveYouEverHadAnyDifficultyFindingEmploymentBecauseOfYourBleedingDisorder: 28,
    haveYouEverHadAnyDifficultyMaintainingEmploymentBecauseOfYourBleedingDisorder: 32,
    haveYouEverDeclinedEmploymentOpportunitiesBecauseOfYourBleedingDisorder: 38,
  },
  hemophiliaB: {
    haveYouEverHadAnyDifficultyFindingEmploymentBecauseOfYourBleedingDisorder: 32,
    haveYouEverHadAnyDifficultyMaintainingEmploymentBecauseOfYourBleedingDisorder: 35,
    haveYouEverDeclinedEmploymentOpportunitiesBecauseOfYourBleedingDisorder: 51,
  },
  vonWillebrandDisease: {
    haveYouEverHadAnyDifficultyFindingEmploymentBecauseOfYourBleedingDisorder: 14,
    haveYouEverHadAnyDifficultyMaintainingEmploymentBecauseOfYourBleedingDisorder: 26,
    haveYouEverDeclinedEmploymentOpportunitiesBecauseOfYourBleedingDisorder: 15,
  },

  ultraRareBleedingDisorders: {
    haveYouEverHadAnyDifficultyFindingEmploymentBecauseOfYourBleedingDisorder: 27,
    haveYouEverHadAnyDifficultyMaintainingEmploymentBecauseOfYourBleedingDisorder: 28,
    haveYouEverDeclinedEmploymentOpportunitiesBecauseOfYourBleedingDisorder: 30,
  },
};
const EmploymentDifficulties = () => {
  const [chartPercentages, setChartPercentages] = useState(40);
  const handleChange = ({ bleedingDisorder, haveYou }) =>
    setChartPercentages(percentages[bleedingDisorder][haveYou]);

  return (
    <Section
      title='Employment difficulties '
      description='Let’s see if other CVR participants experience employment difficulties:'
    >
      <Stack direction='row' spacing='50px' alignItems='stretch'>
        <Box sx={{ flex: '1 1 0px' }}>
          <FormIO
            form={employmentDifficulties}
            template='enrollment'
            onChange={handleChange}
          />
        </Box>
        <PeopleChart percentage={chartPercentages} />
      </Stack>
    </Section>
  );
};

export default EmploymentDifficulties;

const colorMapping = (theme) => ({
  veryMuch: theme.palette.nbdfBlue[100],
  quiteABit: theme.palette.honeyFlower[100],
  somewhat: theme.palette.tamarillo[100],
  aLittleBit: theme.palette.orient[100],
  notAtAll: theme.palette.salmon[100],
});

const labelMapping = () => ({
  somewhat: 'Somewhat',
  notAtAll: 'Not at all',
  quiteABit: 'Quite a bit',
  veryMuch: 'Very much',
  aLittleBit: 'A little bit',
});

const orderMapping = () => ({
  veryMuch: 0,
  quiteABit: 1,
  somewhat: 2,
  aLittleBit: 3,
  notAtAll: 4,
});

export const parseData = (theme, data, { bleedingDisorder, howMuch } = {}) => ({
  children: Object.entries(data[bleedingDisorder]?.[howMuch] ?? {})
    ?.map(([key, value]) => ({
      color: colorMapping(theme)[key],
      name: labelMapping()[key],
      value,
    }))
    ?.sort?.(({ order: lhs }, { order: rhs }) => lhs - rhs),
});

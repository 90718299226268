const FaqContent = [
  {
    id: '1',
    title: 'What is CVR?',
    content:
      'Community Voices in Research (CVR) is a partnership between the bleeding disorder community and NBDF! CVR is a community-powered registry that documents the experiences of persons living with inheritable bleeding disorders and their non-affected relatives over the years. It offers researchers a way to better understand what it is like to live with a bleeding disorder directly from people most affected by them.',
  },
  {
    id: '2',
    title: 'What is the goal of CVR?',
    content:
      'CVR provides an important piece currently missing in research: the perspective of people directly affected by inheritable bleeding disorders. This lived experience complements and improves other research efforts which focus on clinical information. CVR’s ultimate goal is to improve the community’s quality of life by helping identify research questions important and relevant to them.',
  },
  {
    id: '3',
    title: 'Who can sign up?',
    content:
      'CVR is open to US citizens living in the US who are over the age of 18 and are either affected by inheritable bleeding disorders or have an immediate family member who does (parents, spouses, children, siblings, and grandparents).',
  },
  {
    id: '4',
    title: 'What is the benefit of joining CVR?',
    content:
      'There are many benefits to joining CVR! When you join CVR you will feel proud and satisfied knowing that you are contributing to science with the hope that future generations will be better off that those that came before them.<br> More tangibly, you will also have access to a Personalized Data Dashboard where you will be able to see your survey responses overtime and compare them with the deidentified and aggregate information of others. Through your My Health Summary, you will be able to gather relevant information into a single report which you can take with you to your next clinic visit or even the emergency room (ER). CVR will provide you with trustworthy curated educational materials tailored to your diagnoses and will connect you to additional research opportunities – some might even compensate you for your time. Lastly, you will also have access to any publication and findings that result from the information collected in CVR presented in an easy-to-understand manner.',
  },
  {
    id: '5',
    title: 'How does CVR benefit the community?',
    content:
      'By adding your voice and experiences, you can help shape the future of research! CVR will help researchers understand what it means to live with an inheritable bleeding disorder directly from individuals most affected by them. CVR gathers information not collected anywhere else – such as, among other things, symptom interference, the ability to go to work/school, participate in physical activity, access to care, the use of medicinal cannabis, pain, anxiety, depression, and social support. This information is important and will allow researchers understand issues facing the community; some of which may be unknown at this time. CVR will transform anecdotes into evidence. CVR’s goal is to improve quality of life (QOL) by helping identify relevant research questions important to the community. However, information collected from CVR will also help develop community resources and programs. That is, the bleeding disorders community will benefit from improved educational programs, community advocacy, and much more!',
  },
  {
    id: '6',
    title: 'How is CVR’s information gathered?',
    content:
      'Information is gathered through electronic surveys. After enrolling, participants are invited to complete a baseline survey which takes 10-12 minutes from beginning to end – you don’t have to do it all in one sitting. Every year after that, participants will receive an invitation to take the annual survey which takes about 10 minutes. Annual surveys will ask for the same information over time (longitudinally) to be able to identify patterns, trends, and changes. Additional research surveys focused on specific areas of interest will be sent periodically. Participation is always optional.',
  },
  {
    id: '7',
    title: 'Who will have access to my data?',
    content:
      'No one at NBDF will have access to individual responses. NBDF staff will only have access to grouped, de-identified information. Access to identified information will be highly controlled and limited to essential personnel trained to protect participant’s privacy and is based solely on need to know, job function, and role. This access is required to ensure proper functioning of the infrastructure.',
  },
  {
    id: '8',
    title: 'How will my data be protected?',
    content:
      'Among the security tools that CVR uses are: software maintenance, continual security monitoring and anti-viral updates; multiple levels of restricted access to certain data (such as names and contact information), user access (for example, a password is needed to enter CVR), application access (meaning only staff with specific roles can access certain features or audit trails), and hosting services (including firewall and secure socket layers).While we are using many tools and practices to ensure the security of information saved in CVR, we cannot guarantee the security of the information on your computer or mobile device. We also cannot guarantee the security of data while it is being sent to the CVR database from your computer or mobile device. To decrease this risk, we recommend you send information to CVR via a secure Wi-Fi or cellular connection.',
  },
  {
    id: '9',
    title: 'Does CVR cost anything?',
    content: 'CVR does not cost you anything.',
  },
  {
    id: '10',
    title: 'Can I withdraw from CVR?',
    content:
      'Yes, you can withdraw from CVR at any time by logging into CVR and click on My Account, Access Participant Agreement, and click on, “I DO NOT AGREE”. Then send an email to msantaella@hemophilia.org requesting that you do not want to participate in CVR. You can state that you do not want your data to be used anymore for research, resulting in a deletion of all your data.',
  },
];

export default FaqContent;

const MuiAvatar = {
  styleOverrides: {
    root: {
      backgroundColor: '#F9F9F9',
      color: '#DCDDDE',
    },
  },
};

export default MuiAvatar;

import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';

const commonFontProps = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  lineHeight: '140%',
};

const fontWeightBold = 700;
const fontWeightMedium = 600;
const fontWeightRegular = 500;
const fontWeightLight = 400;

const fontWeights = [
  fontWeightBold,
  fontWeightMedium,
  fontWeightRegular,
  fontWeightLight,
];

const fontSizes = [10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 30];

const fonts = fontWeights.reduce(
  (acc, fontWeight) => ({
    ...acc,
    ...fontSizes.reduce(
      (acc, fontSize) => ({
        ...acc,
        [`w${fontWeight}h${fontSize}`]: {
          ...commonFontProps,
          fontSize: `${fontSize}px`,
          fontWeight,
        },
      }),
      {}
    ),
  }),
  {}
);

const typography = {
  ...commonFontProps,
  ...fonts,
  fontWeightBold,
  fontWeightMedium,
  fontWeightRegular,
  fontWeightLight,
};

export default typography;

import { useCallback, useState } from 'react';

import ThankYou from 'components/Surveys/ThankYou';
import ConfirmSubmitModal from 'components/Surveys/ConfirmSubmitModal';
import Baseline from './Baseline';
import Enrollment from './Enrollment';

const bypassEnrollment = false;

const hardcodedEnrollmentValues = {
  R8: 'male',
  R17: 'yes',
  R18: 'yesIHaveBeenFormallyDiagnosedWithABleedingDisorderIncludingBeingACarrierByAHealthCareProvider',
  R19: {
    hemophiliaA: true,
    hemophiliaB: true,
    vonWillebrandDisease: true,
    plateletDisorder: false,
    factorIDeficiency: false,
    factorIiDeficiency: false,
    factorVDeficiency: false,
    factorViiDeficiency: false,
    factorXDeficiency: false,
    factorXiDeficiency: false,
    factorXiiiDeficiency: false,
    pai1Deficiency: false,
    a2AntiPlasminDeficiency: false,
    ehlersDanlosSyndrome: false,
    connectiveTissueDisorderOtherThanEhlersDanlos: false,
    bleedingOfUnknownCause: false,
    other: false,
  },
  R20: 'type1',
};

// const defaultValues = {
//   PN1: 1,
//   PN2_PN3: {
//     PN2: '1',
//     PN3: '1',
//   },
//   PMed1: 'No',
//   PMed2: 'No',
//   MH1: {
//     Therapy: false,
//     Group: false,
//     Medication: false,
//     noneOfTheAbove: false,
//     other: false,
//   },
//   MH2: '',
//   MH3_MH6: {},
//   MH7_MH21: {},
//   SS1_SS8: {},
//   SS9: '',
//   C1: '',
//   HTC1: '',
//   AP1: {
//     National: false,
//     Chapter: false,
//     Oth_org: false,
//     other: false,
//     No: false,
//   },
//   AP6: {
//     Distance: false,
//     Transportation: false,
//     No_interest: false,
//     No_time: false,
//     Schedule: false,
//     No_value: false,
//     Language: false,
//     Child_grown: false,
//     Not_heard: false,
//     other: false,
//   },
//   PA1: {
//     Aquatics: false,
//     Archery: false,
//     Baseball: false,
//     Basketball: false,
//     Bicycling: false,
//     BMX: false,
//     B_sculpting: false,
//     Workout: false,
//     Bounce: false,
//     Bowling: false,
//     Boxing: false,
//     Canoeing: false,
//     Kickboxing: false,
//     Cheerleading: false,
//     Cir_train: false,
//     Comp_diving: false,
//     'X-country_ski': false,
//     Crossfit: false,
//     Dance: false,
//     Skiing: false,
//     Elliptical: false,
//     Fishing: false,
//     Flag_ftball: false,
//     Fris_golf: false,
//     Golf: false,
//     Gymnastics: false,
//     HI_fx_training: false,
//     Hiking: false,
//     Hockey: false,
//     Horse: false,
//     Ind_cycle: false,
//     Ind_rock: false,
//     Jetski: false,
//     J_rope: false,
//     Kayaking: false,
//     Lacrosse: false,
//     ATV: false,
//     Mount_bike: false,
//     Pilates: false,
//     P_lifting: false,
//     Racquetball: false,
//     Rec_diving: false,
//     Rec_frisbee: false,
//     Res_train: false,
//     Rafting: false,
//     Rodeo: false,
//     Rowing: false,
//     Row_machine: false,
//     Rugby: false,
//     Run: false,
//     Scooters: false,
//     Scuba: false,
//     Skate: false,
//     Ski_mach: false,
//     Snorkeling: false,
//     Snowboarding: false,
//     Snowmobiling: false,
//     Soccer: false,
//     Softball: false,
//     Station_bike: false,
//     Stepper: false,
//     Strength_train: false,
//     Surfing: false,
//     Swimming: false,
//     Tackle_ftbl: false,
//     Tai_Chi: false,
//     T_ball: false,
//     Tennis: false,
//     Track: false,
//     MMA: false,
//     Trampoline: false,
//     Treadmill: false,
//     Ult_frisbee: false,
//     Volleyball: false,
//     Walking: false,
//     W_polo: false,
//     Lifting: false,
//     Wrestling: false,
//     Yoga: false,
//     Zumba: false,
//   },
//   PA2: '',
//   PA3: {
//     Nothing: false,
//     No_partner: false,
//     Joint_pain: false,
//     Gen_pain: false,
//     ROM_restrict: false,
//     Weight: false,
//     Time: false,
//     Bleeds: false,
//     Sweat: false,
//     'Don’t': false,
//     other: false,
//   },
//   PA4_no: false,
//   PA7_no: false,
//   PA10_no: false,
//   PA13_PA14_DK: false,
//   BC1: '',
//   OC1: '',
//   OC3: '',
// };

const BaselineSurveyTest = () => {
  const [injectedValues, setInjectedValues] = useState(
    bypassEnrollment && hardcodedEnrollmentValues
  );
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [end, setEnd] = useState(false);

  const handleEnrollmentSubmit = ({
    sex_at_birth: R8,
    bd_hist_symptoms: R17,
    bleeding_disorder: R18,
    bleeding_disorder_diag: R19,
    vwd_type: R20,
  }) =>
    setInjectedValues(() => {
      const values = { R8, R17, R18, R19, R20 };
      console.log('injectedValues', values);
      return values;
    });

  const handleSubmit = useCallback((...args) => setConfirmModalOpen(args), []);

  const handleSave = useCallback((...args) => console.log(args), []);

  if (end) return <ThankYou surveyName='Baseline Survey' />;

  if (injectedValues || bypassEnrollment)
    return (
      <>
        <Baseline
          injectedValues={injectedValues}
          onSubmit={handleSubmit}
          onSave={handleSave}
          // defaultValues={defaultValues}
        />
        <ConfirmSubmitModal
          onSubmit={() => {
            console.log(confirmModalOpen);
            setConfirmModalOpen(false);
            setEnd(true);
          }}
          open={confirmModalOpen}
          setOpen={setConfirmModalOpen}
        />
      </>
    );

  return (
    <Enrollment
      onSubmit={handleEnrollmentSubmit}
      onSave={(...args) => console.log(args)}
    />
  );
};

export default BaselineSurveyTest;

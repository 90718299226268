const formSA = {
  title: 'Form SA',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'SA',
  type: 'panel',
  label: 'Form SA',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Sexual Activity</strong>',
      label: 'Content',
      refreshOnChange: false,
      key: 'SA0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'Do you have any limitations with sexual activity due to your bleeding disorder?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'SA1',
      type: 'radio',
      input: true,
    },
  ],
};

export default formSA;

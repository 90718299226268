import { Box, Link, Typography } from '@mui/material';

const ResendCode = ({ handleResendCode }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Typography variant='subtitle2' fontWeight='normal'>
        Did not receive the code? Click{' '}
        <Link onClick={handleResendCode}>
          <b>here</b>
        </Link>{' '}
        to resend
      </Typography>
    </Box>
  );
};

export default ResendCode;

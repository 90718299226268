import { useEffect, useState } from 'react';

import { useReactToPrint } from 'react-to-print';

const usePrint = (ref) => {
  const [printing, setPrinting] = useState(false);
  const print = () => setPrinting(true);

  const reactToPrint = useReactToPrint({ content: () => ref.current });
  useEffect(() => {
    const handlePrint = async () => {
      if (printing) {
        reactToPrint();
        setPrinting(false);
      }
    };
    handlePrint();
  }, [printing, reactToPrint]);
  return { print, printing };
};

export default usePrint;

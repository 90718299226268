import { Card, Stack, Typography, useTheme } from '@mui/material';

const ContentBox = ({ title, children }) => {
  const theme = useTheme();
  return (
    <Card variant='outlined'>
      <Stack m='2.5rem'>
        <Typography
          color='#231F20'
          sx={{
            fontSize: '1.5rem',
            fontWeight: theme.typography.fontWeightMedium,
            marginBottom: 3,
          }}
        >
          {title}
        </Typography>
        {children}
      </Stack>
    </Card>
  );
};

export default ContentBox;

const interferenceFromPainData = {
  hemophiliaA: {
    yourDayToDayActivities: {
      somewhat: 23,
      notAtAll: 29,
      quiteABit: 15,
      veryMuch: 4,
      aLittleBit: 29,
    },
    workAroundTheHome: {
      somewhat: 22,
      notAtAll: 31,
      quiteABit: 13,
      veryMuch: 5,
      aLittleBit: 29,
    },
    yourAbilityToParticipateInSocialActivities: {
      somewhat: 22,
      notAtAll: 38,
      quiteABit: 11,
      veryMuch: 5,
      aLittleBit: 24,
    },
    yourHouseholdChores: {
      somewhat: 24,
      notAtAll: 31,
      quiteABit: 15,
      veryMuch: 4,
      aLittleBit: 25,
    },
  },
  hemophiliaB: {
    yourDayToDayActivities: {
      somewhat: 41,
      notAtAll: 20,
      quiteABit: 20,
      veryMuch: 3,
      aLittleBit: 16,
    },
    workAroundTheHome: {
      somewhat: 30,
      notAtAll: 24,
      quiteABit: 25,
      veryMuch: 3,
      aLittleBit: 18,
    },
    yourAbilityToParticipateInSocialActivities: {
      somewhat: 32,
      notAtAll: 26,
      quiteABit: 15,
      veryMuch: 4,
      aLittleBit: 22,
    },
    yourHouseholdChores: {
      somewhat: 31,
      notAtAll: 22,
      quiteABit: 23,
      veryMuch: 5,
      aLittleBit: 19,
    },
  },
  vonWillebrandDisease: {
    yourDayToDayActivities: {
      somewhat: 22,
      notAtAll: 34,
      quiteABit: 14,
      veryMuch: 8,
      aLittleBit: 22,
    },
    workAroundTheHome: {
      somewhat: 21,
      notAtAll: 29,
      quiteABit: 15,
      veryMuch: 9,
      aLittleBit: 26,
    },
    yourAbilityToParticipateInSocialActivities: {
      somewhat: 22,
      notAtAll: 40,
      quiteABit: 14,
      veryMuch: 8,
      aLittleBit: 16,
    },
    yourHouseholdChores: {
      somewhat: 23,
      notAtAll: 32,
      quiteABit: 8,
      veryMuch: 11,
      aLittleBit: 26,
    },
  },
  ultraRareBleedingDisorders: {
    yourDayToDayActivities: {
      somewhat: 32,
      notAtAll: 22,
      quiteABit: 8,
      veryMuch: 7,
      aLittleBit: 31,
    },
    workAroundTheHome: {
      somewhat: 32,
      notAtAll: 23,
      quiteABit: 8,
      veryMuch: 8,
      aLittleBit: 27,
    },
    yourAbilityToParticipateInSocialActivities: {
      somewhat: 19,
      notAtAll: 24,
      quiteABit: 23,
      veryMuch: 10,
      aLittleBit: 24,
    },
    yourHouseholdChores: {
      somewhat: 24,
      notAtAll: 25,
      quiteABit: 14,
      veryMuch: 13,
      aLittleBit: 24,
    },
  },
};

export default interferenceFromPainData;

import { useEffect, useMemo } from 'react';

import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';

import Container from 'components/Enrollment/Container';
import FormIO from 'components/FormIO2';
import enrollment from 'forms/enrollment';
import usePersonAttributes from 'hooks/usePersonAttributes';
import { openSnackbar } from 'store/slices/application';
import {
  EnrollmentCompletionState,
  SnackbarError,
  SnackbarSuccess,
} from 'utils/enums';
import { formatPhoneNumber } from 'utils/string';

import { parseAttributes } from './utils';

const disableNext = false;

if (disableNext)
  console.error(
    '"disableNext" flag is for development purpose. It should always be false',
  );

const components = enrollment.components?.[0]?.components;
const inputs = components?.filter?.(({ input }) => input);
const keys = inputs.map(({ key }) => key);

const fillResult = (data) =>
  Object.fromEntries(keys.map((key) => [key, key in data ? data[key] : null]));

const EnrollmentStep = ({ onNext }) => {
  const dispatch = useDispatch();
  const { attributes, isLoading, upsertAttributes } = usePersonAttributes();

  const completed =
    attributes?.enrollment_completion_state?.value ===
    EnrollmentCompletionState.Completed;

  useEffect(() => {
    if (completed) !disableNext && onNext();
  }, [completed, onNext]);

  const defaultValues = useMemo(
    () => parseAttributes(attributes),
    [attributes],
  );

  const handleSubmit = ({ phone, ...rawData }) => {
    const data = phone
      ? { ...rawData, phone: formatPhoneNumber(phone) }
      : rawData;
    upsertAttributes({
      ...fillResult(data),
      enrollment_completion_state: EnrollmentCompletionState.Completed,
    }).catch(() => dispatch(openSnackbar(SnackbarError.TryAgain())));
  };

  const handleSave = ({ phone, ...rawData }) => {
    const data = phone
      ? { ...rawData, phone: formatPhoneNumber(phone) }
      : rawData;
    upsertAttributes({
      ...fillResult(data),
      enrollment_completion_state: EnrollmentCompletionState.Ongoing,
    })
      .then(() => dispatch(openSnackbar(SnackbarSuccess.FormSaved())))
      .catch(() => dispatch(openSnackbar(SnackbarError.TryAgain())));
  };

  if (isLoading || (completed && !disableNext)) return <CircularProgress />;

  return (
    <Container maxWidth='600px'>
      <FormIO
        defaultValues={defaultValues}
        form={enrollment}
        onSubmit={handleSubmit}
        template='enrollment'
        onSave={handleSave}
      />
    </Container>
  );
};

export default EnrollmentStep;

const createAccount = {
  display: 'wizard',
  navBar: {
    submitLabel: 'continue',
  },
  components: [
    {
      title: 'Page 1',
      key: 'page1',
      type: 'panel',
      input: false,
      tableView: true,
      components: [
        {
          label: 'First Name',
          placeholder: 'Enter Name',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
            maxLength: 100,
            custom:
              'valid = input.match(/^[a-zA-Z]{1,100}([a-zA-Z\'\\-\\s]{1,98}[a-zA-Z])?$/u) || input.length === 0 ? true : "You can\'t use special characters in the beginning or the end of your name, the limit of characters is 100."',
          },
          key: 'given_name',
          type: 'textfield',
          input: true,
          autocomplete: 'given-name',
          spellcheck: false,
        },
        {
          label: 'Middle Name (Skip if no middle name)',
          placeholder: 'Enter Name',
          applyMaskOn: 'change',
          tableView: true,
          key: 'middle_name',
          type: 'textfield',
          validate: {
            maxLength: 100,
            custom:
              'valid = input.match(/^[a-zA-Z]{1,100}([a-zA-Z\'\\-\\s]{1,98}[a-zA-Z])?$/u) || input.length === 0 ? true : "You can\'t use special characters in the beginning or the end of your name, the limit of characters is 100."',
          },
          input: true,
          autocomplete: 'additional-name',
          spellcheck: false,
        },
        {
          label: 'Last Name',
          placeholder: 'Enter Name',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
            maxLength: 100,
            custom:
              'valid = input.match(/^[a-zA-Z]{1,100}([a-zA-Z\'\\-\\s]{1,98}[a-zA-Z])?$/u) || input.length === 0 ? true : "You can\'t use special characters in the beginning or the end of your name, the limit of characters is 100."',
          },
          key: 'family_name',
          type: 'textfield',
          input: true,
          autocomplete: 'family-name',
          spellcheck: false,
        },
        {
          label: 'Primary Phone Number',
          placeholder: 'Enter Phone',
          inputMask: '(999) 999-9999',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
          },
          key: 'phone',
          type: 'textfield',
          input: true,
          autocomplete: 'tel-national',
          spellcheck: false,
        },
        {
          label: 'Set Password',
          placeholder: 'Enter Password',
          applyMaskOn: 'change',
          tableView: false,
          key: 'password',
          type: 'password',
          validate: {
            required: true,
            maxLength: 255,
            custom:
              "valid = input.match(/^(?=\\S*[a-z])(?=\\S*[A-Z])(?=\\S*\\d)(?=\\S*[\\!\\@\\#\\$\\%\\^\\&\\*âêîôû])[A-Za-z\\d\\!\\@\\#\\$\\%\\^\\&\\*âêîôû]{8,}$/g) || input.length === 0 ? true : 'At least a length of 8 characters, one uppercase, one lowercase, one digit and one special character(e.g. !@#$%^&*) is required.'",
          },
          input: true,
          protected: true,
          autocomplete: 'new-password',
          spellcheck: false,
        },
        {
          label: 'Confirm Password',
          placeholder: 'Enter Password',
          applyMaskOn: 'change',
          tableView: false,
          validate: {
            required: true,
            maxLength: 255,
            custom:
              "valid = (input === data.password) ? true : 'Password does not match'",
          },
          key: 'confirmPassword',
          type: 'password',
          input: true,
          protected: true,
          autocomplete: 'off',
          spellcheck: false,
        },
      ],
    },
  ],
};

export default createAccount;

import { Box, Stack, Typography, CircularProgress } from '@mui/material';

import Container from 'components/Enrollment/Container';
import { ReactComponent as QrCodeIcon } from 'images/AccountSecurity/qr-code.svg';
import { ReactComponent as CellphoneIcon } from 'images/AccountSecurity/cellphone.svg';
import { ReactComponent as ForwardIcon } from 'images/AccountSecurity/forward.svg';
import { ReactComponent as EmailIcon } from 'images/AccountSecurity/envelope.svg';
import ActionCard from './ActionCard';

const AccountSecurityStep = ({
  onSkip,
  onMethodSelected,
  loaders,
  pageLoading,
}) => (
  <Container maxWidth='600px'>
    <Stack spacing='20px' py='30px' px={{ xs: '20px', sm: '30px' }}>
      {pageLoading ? (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant='w700h16' color='gray.80'>
            Two factor authentication is a good way of increasing account
            security, by adding a second step to the normal login flow.
          </Typography>
          <Typography variant='w700h16' color='gray.80'>
            Choose your preferred method:
          </Typography>
          <ActionCard
            Icon={QrCodeIcon}
            title='Authenticator app'
            description='Use an app to generate time-sensitive authentication codes on your phone.'
            onClick={() => onMethodSelected('authenticatorApp')}
            loading={loaders.authenticatorApp}
          />
          <ActionCard
            Icon={CellphoneIcon}
            title='Text Message'
            description='Receive time-sensitive authentication codes messaged to your phone'
            onClick={() => onMethodSelected('sms')}
            loading={loaders.sms}
          />
          <ActionCard
            Icon={EmailIcon}
            title='Email'
            description='Receive time-sensitive authentication codes messaged to your email inbox'
            onClick={() => onMethodSelected('email')}
            loading={loaders.email}
          />
          <ActionCard
            Icon={ForwardIcon}
            title='Skip for now'
            description='You can find 2FA options in your My Account Security Settings'
            onClick={onSkip}
            loading={loaders.skip}
          />
        </>
      )}
    </Stack>
  </Container>
);

export default AccountSecurityStep;

const formPQ = {
  title: 'Form PQ',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'PQ',
  customConditional:
    'const allow = [2, "2", 3, "3", 4, "4", 5, "5"]; show = allow.includes(data?.PI1_PI2?.PI1) || allow.includes(data?.PI1_PI2?.PI2);',
  type: 'panel',
  label: 'Form PQ',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Pain Quality</strong><br/>The following questions ask you about the quality of your pain (what it feels like) during the past 7 days.',
      label: 'Content',
      refreshOnChange: false,
      key: 'PQ0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'How often in the past 7 days…',
      tableView: false,
      questions: [
        {
          label: 'Did your pain feel like pins and needles?',
          value: 'PQ1',
          tooltip: '',
        },
        {
          label: 'Did your pain feel tingly?',
          value: 'PQ2',
          tooltip: '',
        },
        {
          label: 'Did your pain feel stinging?',
          value: 'PQ3',
          tooltip: '',
        },
        {
          label:
            'Did your pain feel electrical (burning, pinpricks, or sudden shocks of electricity)?',
          value: 'PQ4',
          tooltip: '',
        },
        {
          label: 'Did your pain feel numb?',
          value: 'PQ5',
          tooltip: '',
        },
      ],
      values: [
        {
          label: 'Not at all',
          value: '1',
          tooltip: '',
        },
        {
          label: 'A little bit',
          value: '2',
          tooltip: '',
        },
        {
          label: 'Somewhat',
          value: '3',
          tooltip: '',
        },
        {
          label: 'Quite a bit',
          value: '4',
          tooltip: '',
        },
        {
          label: 'Very much',
          value: '5',
          tooltip: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'PQ1_PQ5',
      type: 'survey',
      input: true,
    },
  ],
};

export default formPQ;

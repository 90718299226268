import formIntro from './formIntro';
import formA from './formA';
import formFBD from './formFBD';
import formBH from './formBH';
import formMC from './formMC';
import formRM from './formRM';
import formP from './formP';
import formG from './formG';
import formL from './formL';
import formI from './formI';
import formBP from './formBP';
import formM from './formM';
import formAD from './formAD';
import formHW from './formHW';
import formDX from './formDX';
import formS from './formS';
import formETOH from './formETOH';
import formSA from './formSA';
import formHCP from './formHCP';
import formPN from './formPN';
import formPI from './formPI';
import formPQ from './formPQ';
import formPM from './formPM';
import formPF from './formPF';
import formPSEQ from './formPSEQ';
import formPMed from './formPMed';
import formMH from './formMH';
import formSS from './formSS';
import formC from './formC';
import formHTC from './formHTC';
import formAP from './formAP';
import formBC from './formBC';
import formOC from './formOC';
import formPA from './formPA';
import generalHealth from './generalHealth';

const baselineSurvey = {
  display: 'wizard',
  navBar: {
    previousLabel: 'previous',
    saveLabel: 'save and continue later',
    startLabel: 'continue',
    nextLabel: 'next',
    submitLabel: 'submit',
    cancelLabel: 'cancel',
    closeLabel: 'close',
    hasIntroPage: true,
  },
  components: [
    formIntro,
    formA,
    formFBD,
    formBH,
    formMC,
    formRM,
    formP,
    formG,
    formL,
    formI,
    formBP,
    formM,
    ...generalHealth,
    formAD,
    formHW,
    formDX,
    formS,
    formETOH,
    formSA,
    formHCP,
    formPN,
    formPI,
    formPQ,
    formPM,
    formPF,
    formPSEQ,
    formPMed,
    formMH,
    formSS,
    formC,
    formHTC,
    formAP,
    formPA,
    formBC,
    formOC,
  ],
};

export default baselineSurvey;

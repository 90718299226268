const form = `
{% if (component.slider) { %}
  {% const inputId = (instance.id ? instance.id : '') + '-' + (component.key ? component.key : '') %}
  {% const inputValueId = inputId + 'Value' %}
  <div class="range-slider">
    {% if (component.sliderProps.minLabel) { %}
      <span class="label max-label">{{ component.sliderProps.maxLabel }}</span>
    {% } %}
    <div class="range-slider-core">
      <div class="range-scale range-secondary-scale"></div>
      <div class="range-scale range-primary-scale"></div>
      <div class="range-scale-values">
      {% for (let i = 0; i <= 100; i += 10) { %}
        <span>{{ 100 - i }}</span>
      {% } %}
      </div>
      <input
        type="range"
        ref="{{ input.ref ? input.ref : 'input' }}"
        value="{{ input.attr.value }}"
        id="{{ inputId }}"
        min="{{ component.sliderProps.min }}"
        max="{{ component.sliderProps.max }}"
        step="{{ component.sliderProps.step }}"
        {% if (disabled) { %}
          disabled="disabled"
        {% } %}
      >
      <output id="{{ inputValueId }}">{{ input.attr.value }}</output>
    </div>
    {% if (component.sliderProps.minLabel) { %}
      <span class="label min-label">{{ component.sliderProps.minLabel }}</span>
    {% } %}
  </div>
{% } else { %}
  {% if (prefix || suffix) { %}
    <div class="input-group">
  {% } %}
    {% if (prefix) { %}
      <div class="input-group-prepend" ref="prefix">
        <span class="input-group-text">
          {% if (prefix instanceof HTMLElement) { %}
            {% const tPrefixOuterHTML = t(prefix.outerHTML, { _userInput: true }) %}
            {{ tPrefixOuterHTML ? tPrefixOuterHTML : '' }}
          {% } else { %}
            {% const tPrefix = t(prefix, { _userInput: true }) %}
            {{ tPrefix ? tPrefix : '' }}
          {% } %}
        </span>
      </div>
    {% } %}
    {% if (!component.editor && !component.wysiwyg) { %}
      {% if (input.type === 'input' && input.attr.type === 'password') { %}
        <div class="password-wrapper">
      {% } %}
      <{{ input.type }}
        ref="{{ input.ref ? input.ref : 'input' }}"
        {% for (var attr in input.attr) { %}
          {% if (attr) %}
          {{ attr }}="{{ input.attr[attr] ? input.attr[attr] : '' }}"
        {% } %}
        id="{{ instance.id ? instance.id : '' }}-{{ component.key ? component.key : '' }}"
        {% const aria = (instance.id ? instance.id : '') + '-' + (component.key ? component.key : '') %}
        aria-labelledby="l-{{ aria }}{{ component.description ? (' d-' + aria) : '' }}"
        aria-required="{{ input.ref === 'input' || !input.ref ? component.validate.required : (component.fields && component.fields[input.ref] && component.fields[input.ref].required) || false }}"
      >
      {% if (input.type === 'input' && input.attr.type === 'password') { %}
        <i class="show-password-eye"></i>
      {% } %}
        {{ input.content ? input.content : '' }}
      </{{ input.type }}>
      {% if (input.type === 'input' && input.attr.type === 'password') { %}
        </div>
      {% } %}
    {% } %}

    {% if (component.editor || component.wysiwyg) { %}
      <div ref="input"></div>
    {% } %}

    {% if (component.type === 'datetime') { %}
      <span
        aria-live="assertive"
        id="{{ instance.id ? instance.id : '' }}-liveRegion"
        class="sr-only"
        ref="liveRegion"
      >
      </span>
    {% } %}

    {% if (suffix) { %}
      <div class="input-group-append" ref="suffix">
        <span class="input-group-text">
          {% if (suffix instanceof HTMLElement) {%}
            {% const tSuffixOuterHTML = t(suffix.outerHTML, { _userInput: true }) %}
            {{ tSuffixOuterHTML ? tSuffixOuterHTML : '' }}
          {% } else { %}
            {% const tSuffix = t(suffix, { _userInput: true }) %}
            {{ tSuffix ? tSuffix : '' }}
          {% } %}
        </span>
      </div>
    {% } %}


  {% if (prefix || suffix) { %}
    </div>
  {% } %}


  {% if (component.showCharCount || component.showWordCount) { %}
    <div class="form-text {{ component.description ? 'pull-right' : 'text-right' }}">
      {% if (component.showCharCount) { %}
        <span class="text-muted" ref="charcount" aria-live="polite"></span>
      {% } %}
      {% if (component.showWordCount) { %}
        <span class="text-muted" ref="wordcount" aria-live="polite"></span>
      {% } %}
    </div>
  {% } %}
{% } %}
`;

export default form;

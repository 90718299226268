import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import { resetAll } from 'store/slices/reset';

const initialState = {
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  expiresIn: 0,
  userInfo: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession: (state, action) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken ?? state.refreshToken;
      state.expiresIn = action.payload.expiresIn;
      state.userInfo = jwt_decode(action.payload.idToken);
    },
  },
  extraReducers: (builder) =>
    builder.addCase(resetAll.type, () => initialState),
});

export const selectAccessToken = () => (state) => state.auth.accessToken;
export const selectSession = () => (state) => state.auth;
export const selectIsAuthenticated = () => (state) =>
  state.auth.isAuthenticated;

export const { setSession } = authSlice.actions;

export default authSlice;

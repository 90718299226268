const formDX = {
  title: 'Form DX',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'DX',
  type: 'panel',
  label: 'Form DX',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Other Conditions</strong><br/>Other medical conditions may pose challenges to living with a bleeding disorder. The next few questions will ask about your past medical history.',
      label: 'Content',
      refreshOnChange: false,
      key: 'DX0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'Do you have a history of any of the following conditions or problems? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        HI: false,
        Migraines: false,
        Sz_dis: false,
        Throm_Str: false,
        Hemor_Str: false,
        noneOfTheAbove: false,
      },
      values: [
        {
          label: 'Head injury',
          value: 'HI',
          shortcut: '',
        },
        {
          label: 'Migraines',
          value: 'Migraines',
          shortcut: '',
        },
        {
          label: 'Seizure disorder',
          value: 'Sz_dis',
          shortcut: '',
        },
        {
          label: 'Stroke (caused by a brain clot)',
          value: 'Throm_Str',
          shortcut: '',
        },
        {
          label: 'Stroke (caused by a brain hemorrhage)',
          value: 'Hemor_Str',
          shortcut: '',
        },
        {
          label: 'None of the above',
          value: 'noneOfTheAbove',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX1',
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label:
        'Do you have a history of any of the following conditions or problems? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        HI: false,
        Migraines: false,
        Sz_dis: false,
        Throm_Str: false,
        Hemor_Str: false,
        noneOfTheAbove: false,
      },
      values: [
        {
          label: 'Accidental drug overdose',
          value: 'OD',
          shortcut: '',
        },
        {
          label: 'Alcoholism',
          value: 'Alcoholism',
          shortcut: '',
        },
        {
          label: 'Chronic pain',
          value: 'Chr_pain',
          shortcut: '',
        },
        {
          label: 'Substance Use Disorder (drug addiction)',
          value: 'SUD',
          shortcut: '',
        },
        {
          label: 'Suicide attempt',
          value: 'Suicide',
          shortcut: '',
        },
        {
          label: 'None of the above',
          value: 'noneOfTheAbove',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX2',
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label:
        'Do you have a history of any of the following conditions or problems? Choose all that apply. ',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        HI: false,
        Migraines: false,
        Sz_dis: false,
        Throm_Str: false,
        Hemor_Str: false,
        noneOfTheAbove: false,
      },
      values: [
        {
          label: 'Anxiety Disorder',
          value: 'Anxiety',
          shortcut: '',
        },
        {
          label:
            'Attention Deficit Disorder (ADD) or Attention Deficit Hyperactivity Disorder (ADHD)',
          value: 'ADD',
          shortcut: '',
        },
        {
          label: 'Autism',
          value: 'Autism',
          shortcut: '',
        },
        {
          label: 'Bipolar Disorder (also known as manic depression)',
          value: 'Bipolar',
          shortcut: '',
        },
        {
          label: 'Depression',
          value: 'Depression',
          shortcut: '',
        },
        {
          label: 'Post-Traumatic Stress Disorder (PTSD)',
          value: 'PTSD',
          shortcut: '',
        },
        {
          label: 'Schizophrenia',
          value: 'Schizophrenia',
          shortcut: '',
        },
        {
          label: 'None of the above',
          value: 'noneOfTheAbove',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX3',
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label:
        'Do you have a history of any of the following conditions or problems? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        HI: false,
        Migraines: false,
        Sz_dis: false,
        Throm_Str: false,
        Hemor_Str: false,
        noneOfTheAbove: false,
      },
      values: [
        {
          label: 'Asthma',
          value: 'Asthma',
          shortcut: '',
        },
        {
          label: 'Chronic Bronchitis',
          value: 'Bronchitis',
          shortcut: '',
        },
        {
          label: 'Chronic Obstructive Pulmonary Disease (COPD)',
          value: 'COPD',
          shortcut: '',
        },
        {
          label: 'Emphysema',
          value: 'Emphysema',
          shortcut: '',
        },
        {
          label: 'Obstructive Sleep Apnea',
          value: 'OSA',
          shortcut: '',
        },
        {
          label: 'Reactive Airway Disease',
          value: 'RAD',
          shortcut: '',
        },
        {
          label: 'None of the above',
          value: 'noneOfTheAbove',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX4',
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label:
        'Do you have a history of any of the following conditions or problems? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        HI: false,
        Migraines: false,
        Sz_dis: false,
        Throm_Str: false,
        Hemor_Str: false,
        noneOfTheAbove: false,
      },
      values: [
        {
          label:
            'Arrhythmia or abnormal heartbeat (such as fast or slow heart rate, atrial fibrillation or "afib")',
          value: 'Arrhythmia',
          shortcut: '',
        },
        {
          label: 'Chest pain/angina',
          value: 'Angina',
          shortcut: '',
        },
        {
          label: 'Congestive heart failure or enlarged heart (CHF)',
          value: 'CHF',
          shortcut: '',
        },
        {
          label: 'Heart attack',
          value: 'MI',
          shortcut: '',
        },
        {
          label: 'None of the above',
          value: 'noneOfTheAbove',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX5',
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label:
        'Do you have a history of any of the following conditions or problems? Choose all that apply. ',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        HI: false,
        Migraines: false,
        Sz_dis: false,
        Throm_Str: false,
        Hemor_Str: false,
        noneOfTheAbove: false,
      },
      values: [
        {
          label: 'Hepatitis A',
          value: 'Hep_A',
          shortcut: '',
        },
        {
          label: 'Hepatitis B',
          value: 'Hep_B',
          shortcut: '',
        },
        {
          label: 'Hepatitis C',
          value: 'Hep_C',
          shortcut: '',
        },
        {
          label: 'Cirrhosis of the liver (liver scarring)',
          value: 'Cirrhosis',
          shortcut: '',
        },
        {
          label: 'Non-alcoholic fatty liver disease',
          value: 'NAFLD',
          shortcut: '',
        },
        {
          label: 'Non-alcoholic steatohepatitis',
          value: 'NASH',
          shortcut: '',
        },
        {
          label: 'Liver cancer',
          value: 'Liver_CA',
          shortcut: '',
        },
        {
          label: 'None of the above',
          value: 'noneOfTheAbove',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX6',
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'What is your current Hepatitis C virus (HCV) status?',
      widget: 'choicesjs',
      placeholder: 'Select HCV status',
      tableView: true,
      data: {
        values: [
          {
            label: 'My HCV went away on its own (cleared without treatment)',
            value: 'Spont_clear',
          },
          {
            label: 'My HCV was treated and cured',
            value: 'Cured',
          },
          {
            label: 'I was treated but it didn’t work',
            value: 'Tx_ineffective',
          },
          {
            label: 'I never got treated',
            value: 'No_tx',
          },
          {
            label: 'I don’t know',
            value: 'DK',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'DX7',
      customConditional: "show = data['DX6']?.Hep_C;",
      type: 'select',
      input: true,
    },
    {
      label:
        'Do you have a history of any of the following conditions or problems? Choose all that apply. ',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        HI: false,
        Migraines: false,
        Sz_dis: false,
        Throm_Str: false,
        Hemor_Str: false,
        noneOfTheAbove: false,
      },
      values: [
        {
          value: 'Anemia',
          label: 'Anemia (low red blood cell count)',
        },
        {
          value: 'Cancer',
          label: 'Cancer (other than liver)',
        },
        {
          value: 'Diabetes',
          label: 'Diabetes (high blood sugar)',
        },
        {
          value: 'HBP',
          label: 'High blood pressure (greater than 130/80)',
        },
        {
          value: 'Hyperlipidemia',
          label: 'High cholesterol',
        },
        {
          value: 'HIV/AIDS',
          label: 'HIV/AIDS',
        },
        {
          value: 'Hypoglycemia',
          label: 'Hypoglycemia (low blood sugar)',
        },
        {
          value: 'Skin',
          label: 'Skin disorders (examples: eczema, psoriasis)',
        },
        {
          value: 'Thyroid',
          label: 'Thyroid problems',
        },
        {
          value: 'noneOfTheAbove',
          label: 'None of the above',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX8',
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label:
        'Do you have a history of any of the following conditions or problems? Choose all that apply. ',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        HI: false,
        Migraines: false,
        Sz_dis: false,
        Throm_Str: false,
        Hemor_Str: false,
        noneOfTheAbove: false,
      },
      values: [
        {
          value: 'Hemarthropathy',
          label: 'Hemarthropathy (joint disease caused by joint bleeding)',
        },
        {
          value: 'Osteoarthritis',
          label:
            'Osteoarthritis (joint damage caused by wear and tear on the joints)',
        },
        {
          value: 'Osteoporosis',
          label: 'Osteoporosis (weak or brittle bones)',
        },
        {
          value: 'RA',
          label: 'Rheumatoid Arthritis (Inflammatory arthritis)',
        },
        {
          value: 'noneOfTheAbove',
          label: 'None of the above',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX9',
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label:
        'Do you have joint limitations - limitations in the way the joints move or work?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX10',
      customConditional:
        'show = data.DX9 && Object.values({ ...data.DX9, noneOfTheAbove: false }).find(v => v);',
      type: 'radio',
      input: true,
    },
    {
      label: 'Which joint(s)? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          value: 'L_ankle',
          label: 'Left ankle',
          shortcut: '',
        },
        {
          value: 'R_ankle',
          label: 'Right ankle',
          shortcut: '',
        },
        {
          value: 'L_knee',
          label: 'Left knee',
          shortcut: '',
        },
        {
          value: 'R_knee',
          label: 'Right knee',
          shortcut: '',
        },
        {
          value: 'L_hip',
          label: 'Left hip',
          shortcut: '',
        },
        {
          value: 'R_hip',
          label: 'Right hip',
          shortcut: '',
        },
        {
          value: 'L_shoul',
          label: 'Left shoulder',
          shortcut: '',
        },
        {
          value: 'R_shoul',
          label: 'Right shoulder',
          shortcut: '',
        },
        {
          value: 'L_elbow',
          label: 'Left elbow',
          shortcut: '',
        },
        {
          value: 'R_elbow',
          label: 'Right elbow',
          shortcut: '',
        },
        {
          value: 'L_wrist',
          label: 'Left wrist',
          shortcut: '',
        },
        {
          value: 'R_wrist',
          label: 'Right wrist',
          shortcut: '',
        },
        {
          value: 'noneOfTheAbove',
          label: 'None of the above',
          shortcut: '',
        },
        {
          value: 'other',
          label: 'Other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX11',
      conditional: {
        show: true,
        when: 'DX10',
        eq: 'Yes',
      },
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        L_ankle: false,
        R_ankle: false,
        L_knee: false,
        R_knee: false,
        L_hip: false,
        R_hip: false,
        L_shoul: false,
        R_shoul: false,
        L_elbow: false,
        R_elbow: false,
        L_wrist: false,
        R_wrist: false,
        noneOfTheAbove: false,
        other: false,
      },
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'DX11_other',
      customConditional: 'show = !!data.DX11?.other',
      type: 'textfield',
      input: true,
    },
    {
      label: 'Have you undergone joint replacement surgery?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX12',
      type: 'radio',
      input: true,
    },
    {
      label: 'Which joint(s) was (were) replaced? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        L_ankle: false,
        R_ankle: false,
        L_knee: false,
        R_knee: false,
        L_hip: false,
        R_hip: false,
        L_shoul: false,
        R_shoul: false,
        L_elbow: false,
        R_elbow: false,
        L_wrist: false,
        R_wrist: false,
        noneOfTheAbove: false,
        other: false,
      },
      values: [
        {
          value: 'L_ankle',
          label: 'Left ankle',
          shortcut: '',
        },
        {
          value: 'R_ankle',
          label: 'Right ankle',
          shortcut: '',
        },
        {
          value: 'L_knee',
          label: 'Left knee',
          shortcut: '',
        },
        {
          value: 'R_knee',
          label: 'Right knee',
          shortcut: '',
        },
        {
          value: 'L_hip',
          label: 'Left hip',
          shortcut: '',
        },
        {
          value: 'R_hip',
          label: 'Right hip',
          shortcut: '',
        },
        {
          value: 'L_shoul',
          label: 'Left shoulder',
          shortcut: '',
        },
        {
          value: 'R_shoul',
          label: 'Right shoulder',
          shortcut: '',
        },
        {
          value: 'L_elbow',
          label: 'Left elbow',
          shortcut: '',
        },
        {
          value: 'R_elbow',
          label: 'Right elbow',
          shortcut: '',
        },
        {
          value: 'L_wrist',
          label: 'Left wrist',
          shortcut: '',
        },
        {
          value: 'R_wrist',
          label: 'Right wrist',
          shortcut: '',
        },
        {
          value: 'noneOfTheAbove',
          label: 'None of the above',
          shortcut: '',
        },
        {
          value: 'other',
          label: 'Other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX13',
      conditional: {
        show: true,
        when: 'DX12',
        eq: 'Yes',
      },
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'DX13_other',
      customConditional: 'show = !!data.DX13?.other',
      type: 'textfield',
      input: true,
    },
    {
      label: 'Have you undergone a revision of a joint replacement?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      conditional: {
        show: true,
        when: 'DX12',
        eq: 'Yes',
      },
      key: 'DX14',
      type: 'radio',
      input: true,
    },
    {
      label:
        'For which joint(s) did you undergo revision surgery? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'wergwerg',
          value: 'wergwerg',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX15',
      conditional: {
        show: true,
        when: 'DX14',
        eq: 'Yes',
      },
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
        {
          name: 'DX13 changed',
          trigger: {
            type: 'javascript',
            javascript: 'result = data.DX13',
          },
          actions: [
            {
              name: 'Filter',
              type: 'mergeComponentSchema',
              schemaDefinition:
                "const values = utils\n  .getComponent(form.components, 'DX13').values\n  .filter(({ value }) => data.DX13?.[value])\n  .map(entrie => entrie.value === 'other' ? { ...entrie, label: data.DX13_other } : entrie);\nschema = { values }",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Have any of your joints been fused?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX16',
      type: 'radio',
      input: true,
    },
    {
      label: 'Which joint(s) was (were) fused? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        L_ankle: false,
        R_ankle: false,
        L_knee: false,
        R_knee: false,
        L_hip: false,
        R_hip: false,
        L_shoul: false,
        R_shoul: false,
        L_elbow: false,
        R_elbow: false,
        L_wrist: false,
        R_wrist: false,
        noneOfTheAbove: false,
        other: false,
      },
      values: [
        {
          value: 'L_ankle',
          label: 'Left ankle',
          shortcut: '',
        },
        {
          value: 'R_ankle',
          label: 'Right ankle',
          shortcut: '',
        },
        {
          value: 'L_knee',
          label: 'Left knee',
          shortcut: '',
        },
        {
          value: 'R_knee',
          label: 'Right knee',
          shortcut: '',
        },
        {
          value: 'L_hip',
          label: 'Left hip',
          shortcut: '',
        },
        {
          value: 'R_hip',
          label: 'Right hip',
          shortcut: '',
        },
        {
          value: 'L_shoul',
          label: 'Left shoulder',
          shortcut: '',
        },
        {
          value: 'R_shoul',
          label: 'Right shoulder',
          shortcut: '',
        },
        {
          value: 'L_elbow',
          label: 'Left elbow',
          shortcut: '',
        },
        {
          value: 'R_elbow',
          label: 'Right elbow',
          shortcut: '',
        },
        {
          value: 'L_wrist',
          label: 'Left wrist',
          shortcut: '',
        },
        {
          value: 'R_wrist',
          label: 'Right wrist',
          shortcut: '',
        },
        {
          value: 'noneOfTheAbove',
          label: 'None of the above',
          shortcut: '',
        },
        {
          value: 'other',
          label: 'Other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      conditional: {
        show: true,
        when: 'DX16',
        eq: 'Yes',
      },
      key: 'DX17',
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'DX17_other',
      customConditional: 'show = !!data.DX17?.other',
      type: 'textfield',
      input: true,
    },
    {
      label: 'Have you ever had a synovectomy?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX18',
      conditional: {
        show: true,
        when: 'DX10',
        eq: 'Yes',
      },
      type: 'radio',
      input: true,
    },
    {
      label: 'Which joint(s) had the synovectomy? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        L_ankle: false,
        R_ankle: false,
        L_knee: false,
        R_knee: false,
        L_hip: false,
        R_hip: false,
        L_shoul: false,
        R_shoul: false,
        L_elbow: false,
        R_elbow: false,
        L_wrist: false,
        R_wrist: false,
        noneOfTheAbove: false,
        other: false,
      },
      values: [
        {
          value: 'L_ankle',
          label: 'Left ankle',
          shortcut: '',
        },
        {
          value: 'R_ankle',
          label: 'Right ankle',
          shortcut: '',
        },
        {
          value: 'L_knee',
          label: 'Left knee',
          shortcut: '',
        },
        {
          value: 'R_knee',
          label: 'Right knee',
          shortcut: '',
        },
        {
          value: 'L_hip',
          label: 'Left hip',
          shortcut: '',
        },
        {
          value: 'R_hip',
          label: 'Right hip',
          shortcut: '',
        },
        {
          value: 'L_shoul',
          label: 'Left shoulder',
          shortcut: '',
        },
        {
          value: 'R_shoul',
          label: 'Right shoulder',
          shortcut: '',
        },
        {
          value: 'L_elbow',
          label: 'Left elbow',
          shortcut: '',
        },
        {
          value: 'R_elbow',
          label: 'Right elbow',
          shortcut: '',
        },
        {
          value: 'L_wrist',
          label: 'Left wrist',
          shortcut: '',
        },
        {
          value: 'R_wrist',
          label: 'Right wrist',
          shortcut: '',
        },
        {
          value: 'noneOfTheAbove',
          label: 'None of the above',
          shortcut: '',
        },
        {
          value: 'other',
          label: 'Other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX19',
      conditional: {
        show: true,
        when: 'DX18',
        eq: 'Yes',
      },
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'DX19_other',
      customConditional: 'show = !!data?.DX19?.other',
      type: 'textfield',
      input: true,
    },
    {
      label:
        'Do you have a history of any of the following conditions or problems? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          value: 'Gastritis',
          label: 'Chronic gastritis',
          shortcut: '',
        },
        {
          value: 'Crohns',
          label: "Crohn's disease or ulcerative colitis",
          shortcut: '',
        },
        {
          value: 'Gingivitis',
          label: 'Gingivitis or swollen gums',
          shortcut: '',
        },
        {
          value: 'IBS',
          label:
            'Irritable Bowel Syndrome (IBS-either diarrhea or constipation)',
          shortcut: '',
        },
        {
          value: 'Ulcers',
          label: 'Ulcers (stomach or intestinal)',
          shortcut: '',
        },
        {
          value: 'noneOfTheAbove',
          label: 'None of the above',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX20',
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        Gastritis: false,
        Crohns: false,
        Gingivitis: false,
        IBS: false,
        Ulcers: false,
        noneOfTheAbove: false,
      },
    },
    {
      label:
        'Do you have a history of any of the following conditions or problems? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          value: 'Hematuria',
          label:
            'Blood in urine (not associated with infection or kidney stones)',
          shortcut: '',
        },
        {
          value: 'UTIs',
          label: 'Frequent Urinary Tract Infections (UTIs)',
          shortcut: '',
        },
        {
          value: 'K_colic',
          label: 'Kidney colic (unexplained kidney pain)',
          shortcut: '',
        },
        {
          value: 'K_stones',
          label: 'Kidney stones',
          shortcut: '',
        },
        {
          value: 'Renal_dis',
          label: 'Kidney/renal disease',
          shortcut: '',
        },
        {
          value: 'noneOfTheAbove',
          label: 'None of the above',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX21',
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        Hematuria: false,
        UTIs: false,
        K_colic: false,
        K_stones: false,
        Renal_dis: false,
        noneOfTheAbove: false,
      },
    },
    {
      label:
        'Do you have a history of any of the following conditions or problems? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        Hematuria: false,
        UTIs: false,
        K_colic: false,
        K_stones: false,
        Renal_dis: false,
        noneOfTheAbove: false,
      },
      values: [
        {
          value: 'Hypermenorrhagia',
          label: 'Abnormally heavy or prolonged periods (hyper-menorrhagia)',
          shortcut: '',
        },
        {
          value: 'Endometriosis',
          label: 'Endometriosis',
          shortcut: '',
        },
        {
          value: 'Fibroids',
          label: 'Fibroids in uterus',
          shortcut: '',
        },
        {
          value: 'Dysmenorrhea',
          label: 'Painful periods (dysmenorrhea)',
          shortcut: '',
        },
        {
          value: 'PCOS',
          label: 'Polycystic Ovary Syndrome (PCOS)',
          shortcut: '',
        },
        {
          value: 'PMS',
          label: 'Premenstrual syndrome (PMS)',
          shortcut: '',
        },
        {
          value: 'noneOfTheAbove',
          label: 'None of the above',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX22',
      customConditional: "show = form.injectedValues.R8 !== 'male'",
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label:
        'Do you have a history of any other conditions or problems not listed?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'DX23',
      type: 'radio',
      input: true,
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'DX23_other',
      conditional: {
        show: true,
        when: 'DX23',
        eq: 'Yes',
      },
      type: 'textfield',
      input: true,
    },
  ],
};

export default formDX;

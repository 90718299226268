import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Session from 'store-management';

import usePersonAttributes from 'hooks/usePersonAttributes';
import { ErrorStatus, SnackbarError, SnackbarSuccess } from 'utils/enums';
import { openSnackbar } from 'store/slices/application';
import { useAuth } from 'context/AuthContext';
import {
  useAssociateMFAMutation,
  useAuth0OAuthTokenMutation,
  useChallenMFATokenMutation,
  useDeleteMFAChannelMutation,
  useGetMFAAuthenticatorsQuery,
} from 'store/api/auth0/endpoints';
import {
  AUTH0_CLIENT_ID,
  AUTH0_CLIENT_SECRET,
  MFA_GRANT_TYPE,
} from 'utils/constants';
import {
  useCurrentUserQuery,
  useUpdateAuth0MetadataMutation,
} from 'store/api/nhf/endpoints/users';
import { setSession } from 'store/slices/auth';
import { handleError } from 'utils/errorHandler';
import useCountdown from 'hooks/useCountdown';
import { formatPhoneNumber } from 'utils/string';

const session = new Session();

const useMFA = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { accessToken, login } = useAuth();
  const { timeLeft, setTimeLeft } = useCountdown({ timer: 30 });
  const { timeLeft: accountLockedTimer, setTimeLeft: setAccountLockedTimer } =
    useCountdown({
      timer: 0,
    });
  const [associateMFA] = useAssociateMFAMutation();
  const [deleteMFAChannel] = useDeleteMFAChannelMutation();
  const [updateAuth0Metadata, { isLoading: isUpdatedAuth0MetaLoading }] =
    useUpdateAuth0MetadataMutation();
  const [auth0OAuthToken, { isLoading: isAuth0OAuthTokenLoading }] =
    useAuth0OAuthTokenMutation();
  const [challenMFAToken] = useChallenMFATokenMutation();

  const mfaToken = session.get('mfa_token') ?? accessToken;
  const isVerificationRoute = [
    '/verify-2fa',
    '/homepage/my-account',
    '/account-security',
  ].includes(location.pathname);

  const {
    authenticatorId,
    authenticators,
    allAuthenticators = [],
    status: authenticatorsStatus,
  } = useGetMFAAuthenticatorsQuery(
    { token: mfaToken },
    {
      skip: !mfaToken || !isVerificationRoute,
      selectFromResult: ({ data, status }) => ({
        authenticatorId: data?.[0]?.id,
        authenticators: data?.filter((authenticator) => authenticator.active),
        allAuthenticators: data,
        status,
      }),
    }
  );

  const isAuthenticatorApp = authenticatorId?.includes('otp');

  const [mfaLoaders, setMfaLoaders] = useState({
    skip: false,
    sms: false,
    authenticatorApp: false,
    email: false,
    confirm: isAuth0OAuthTokenLoading,
    verify: false,
  });
  const [code, setCode] = useState('');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openEnableMFAModal, setOpenEnableMFAModal] = useState(false);
  const [mfaChannel, setMfaChannel] = useState('');
  const [mfaCode, setMFACode] = useState('');
  const [accountLocked, setAccountLocked] = useState(false);
  const challengingUser = useRef(true);

  const {
    attributes,
    isLoading: isPersonAttributesLoading,
    upsertAttributes,
  } = usePersonAttributes();

  const userEmail = attributes?.email?.value;

  const { data: user, isLoading: isUserLoading } = useCurrentUserQuery(
    {},
    { skip: !userEmail }
  );

  const updateMFAStatus = async (status) => {
    const mfaStatus = status === 'no' ? 'MFASkipped' : 'MFASetup';
    try {
      await upsertAttributes({ mfa_enrolled: status });
      if (!Boolean(mfaChannel)) {
        dispatch(openSnackbar(SnackbarSuccess[mfaStatus]()));
      }
    } catch (error) {
      dispatch(openSnackbar(SnackbarError.TryAgain()));
    } finally {
      setMfaLoaders((prev) => ({ ...prev, skip: false }));
    }
  };

  const handleSkipMFA = async ({ redirect = true }) => {
    if (redirect === 'back') {
      return navigate('/account-security');
    }
    // skip
    setMfaLoaders((prev) => ({ ...prev, skip: true }));
    const userMetadata = {
      mfa_enabled: 'false',
    };
    await updateAuth0Metadata({
      userId: user?.id,
      userMetadata,
    });
    await updateMFAStatus('no');
    setMfaLoaders((prev) => ({ ...prev, skip: false }));
    setOpenConfirmModal(false);
    session.flush();
    if (redirect) {
      navigate('/homepage');
    }
  };

  const getSelectedMFABody = (method) => {
    switch (method) {
      case 'email':
        return {
          email: userEmail,
          oob_channels: ['email'],
          authenticator_types: ['oob'],
        };
      case 'sms':
        return {
          phone_number: formatPhoneNumber(attributes.phone.value),
          oob_channels: ['sms'],
          authenticator_types: ['oob'],
        };
      case 'authenticatorApp':
        return {
          authenticator_types: ['otp'],
        };
      default:
        return {};
    }
  };

  const handleMFAMethodSelected = async (method, redirect = true) => {
    const body = getSelectedMFABody(method);

    setMfaLoaders((prev) => ({ ...prev, [method]: true }));
    const { data, error } = await associateMFA({ body, token: accessToken });
    setMfaLoaders((prev) => ({ ...prev, [method]: false }));

    if (error) {
      return handleError(error);
    }
    session.put(
      'mfa_code',
      method !== 'authenticatorApp' ? data.oobCode : data.barcodeUri
    );
    setMFACode(method !== 'authenticatorApp' ? data.oobCode : data.barcodeUri);

    if (redirect) {
      // set auth
      navigate('/account-security/confirm', {
        state: { oobCode: data.oobCode, authenticationType: method },
      });
    }
  };

  const handleConfirmMFA = async ({ method, code, redirect = true }) => {
    setMfaLoaders((prev) => ({ ...prev, confirm: true }));
    const isAuthenticatorApp = method === 'authenticatorApp';
    const body = {
      grant_type: `${MFA_GRANT_TYPE}/mfa-${isAuthenticatorApp ? 'otp' : 'oob'}`,
      mfa_token: accessToken,
      ...(!isAuthenticatorApp ? { oob_code: session.get('mfa_code') } : {}),
      [isAuthenticatorApp ? 'otp' : 'binding_code']: code,
      client_id: AUTH0_CLIENT_ID,
      client_secret: AUTH0_CLIENT_SECRET,
    };

    // confirm mfa code
    const { data: auth0OAuthTokenData, error: auth0OAuthTokenError } =
      await auth0OAuthToken({ body });
    if (auth0OAuthTokenData) {
      // update MFA status
      await updateMFAStatus('yes');
      // relogin again
      if (redirect) {
        await login({
          email: userEmail,
          password: session.pull('temporary_password'),
        });
      }
      const userMetadata = {
        mfa_enabled: 'true',
      };
      // update metadata
      await updateAuth0Metadata({
        userId: user.id,
        userMetadata,
      });
      dispatch(openSnackbar(SnackbarSuccess.MFASetup()));
      if (redirect) {
        navigate('/enrollment');
      }
    } else if (auth0OAuthTokenError) {
      setMfaLoaders((prev) => ({ ...prev, confirm: false }));
      handleError(auth0OAuthTokenError);
      throw auth0OAuthTokenError;
    }

    setMfaLoaders((prev) => ({ ...prev, confirm: false }));
  };

  const handleSubmit2FAVerification = async ({ code }) => {
    setMfaLoaders((prev) => ({ ...prev, confirm: true }));
    const body = {
      grant_type: `${MFA_GRANT_TYPE}/mfa-${isAuthenticatorApp ? 'otp' : 'oob'}`,
      mfa_token: mfaToken,
      ...(!isAuthenticatorApp ? { oob_code: session.get('mfa_code') } : {}),
      [isAuthenticatorApp ? 'otp' : 'binding_code']: code,
      client_id: AUTH0_CLIENT_ID,
      client_secret: AUTH0_CLIENT_SECRET,
    };
    const { error, data } = await auth0OAuthToken({ body });
    if (data) {
      dispatch(setSession(data));
      navigate('/enrollment');
      session.flush();
    } else if (error) {
      const { accountLocked } = handleError(error);
      if (accountLocked) {
        setAccountLockedTimer(60);
      }
    }
    setMfaLoaders((prev) => ({ ...prev, confirm: false }));
  };

  const handleResendCode = () => {
    setTimeLeft(30);
    if (isVerificationRoute) {
      return handleChallenge2FA();
    }
    handleMFAMethodSelected(location?.state?.authenticationType);
  };

  const handleResendCodeWhenReEnabling = () => {
    handleMFAMethodSelected(mfaChannel, false);
  };

  const handleDeleteMFAChannels = async (method, deleteInActive = false) => {
    const authenticatorsToBeDeleted = allAuthenticators.filter(
      (authenticator) =>
        deleteInActive
          ? !authenticator.active
          : !authenticator.id.includes(
              method === 'authenticatorApp' ? 'totp' : method
            )
    );

    await Promise.all(
      authenticatorsToBeDeleted.map(async (authenticator) => {
        deleteMFAChannel({ authenticatorId: authenticator.id });
      })
    );
  };

  const handleConfirmMFAWhenReEnabling = async ({ method, code }) => {
    try {
      await handleConfirmMFA({
        method,
        code,
        redirect: false,
      });

      await handleDeleteMFAChannels(method);

      setOpenEnableMFAModal(false);
      session.forget('mfa_code');
    } catch (error) {}
    setMfaLoaders((prev) => ({ ...prev, confirm: false }));
  };

  const handleChallenge2FA = async () => {
    setMfaLoaders((prev) => ({ ...prev, verify: true }));

    const body = {
      challenge_type: `${authenticatorId.includes('otp') ? 'otp' : 'oob'}`,
      authenticator_id: authenticatorId,
      mfa_token: mfaToken,
      client_id: AUTH0_CLIENT_ID,
      client_secret: AUTH0_CLIENT_SECRET,
    };

    // challenge MFA
    const { error, data } = await challenMFAToken({ body });
    if (data) {
      session.put('mfa_code', data.oobCode);
    } else if (error) {
      const { accountLocked } = handleError(error);
      if (accountLocked) {
        setAccountLockedTimer(60);
      }
    }
    setMfaLoaders((prev) => ({ ...prev, verify: false }));
  };

  const handleOptOut = async () => {
    await Promise.all(
      allAuthenticators.map(async (authenticator) => {
        deleteMFAChannel({ authenticatorId: authenticator.id });
      })
    );
    handleSkipMFA({ redirect: false });
  };

  const handleToggleMFA = async (event, channel) => {
    setMfaLoaders((prev) => ({ ...prev, confirm: false }));
    setCode('');
    if (!event.target.checked) {
      return setOpenConfirmModal(true);
    }
    handleMFAMethodSelected(channel, false);
    setMfaChannel(channel);
    setOpenEnableMFAModal(true);
  };

  useEffect(() => {
    if (
      authenticatorId &&
      challengingUser.current &&
      location.pathname === '/verify-2fa'
    ) {
      challengingUser.current = false;
      handleChallenge2FA();
    }
    if (authenticatorsStatus === ErrorStatus.Rejected) {
      navigate('/login');
    }
  }, [authenticatorId, challengingUser.current, authenticatorsStatus]);

  useEffect(() => {
    setAccountLocked(accountLockedTimer > 0);
  }, [accountLockedTimer]);

  const handleModalClose = async () => {
    // remove inactive authenticators
    await handleDeleteMFAChannels('', true);
  };

  const isLoading = isPersonAttributesLoading || isUserLoading;
  const isConfirmPageLoading =
    isPersonAttributesLoading || isUpdatedAuth0MetaLoading;

  return {
    isLoading,
    isConfirmPageLoading,
    mfaLoaders,
    code,
    accountLocked,
    timeLeft,
    authenticatorId,
    openConfirmModal,
    openEnableMFAModal,
    authenticators,
    authenticatorsStatus,
    mfaChannel,
    mfaCode: session.get('mfa_code') ?? mfaCode,
    setCode,
    setOpenConfirmModal,
    handleModalClose,
    setOpenEnableMFAModal,
    handleSkipMFA,
    handleMFAMethodSelected,
    handleConfirmMFAWhenReEnabling,
    handleConfirmMFA,
    handleChallenge2FA,
    handleSubmit2FAVerification,
    handleToggleMFA,
    handleResendCode,
    handleResendCodeWhenReEnabling,
    handleOptOut,
  };
};

export default useMFA;

import { Grid, Stack } from '@mui/material';

import Section from 'components/Dashboard/Section';
import ChartPlaceholder from './ChartPlaceholder';
import DashboardUnderConstruction from './DashboardUnderConstruction';

const RegistryDashboard = ({ disabled = false, ...rest }) => (
  <Section
    disabled={disabled}
    title='registry dashboard'
    to='registry-dashboard'
    {...rest}
  >
    <Stack>
      {disabled ? (
        <Stack>
          <DashboardUnderConstruction />
        </Stack>
      ) : (
        <Grid container spacing='16px' columns={{ xs: 1, md: 2 }}>
          <Grid item xs={1}>
            <ChartPlaceholder />
          </Grid>
          <Grid item xs={1}>
            <ChartPlaceholder />
          </Grid>
        </Grid>
      )}
    </Stack>
  </Section>
);

export default RegistryDashboard;

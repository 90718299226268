import apiBaseQuery from 'store/api/baseQuery';
import { AUTH0_API_URL } from 'utils/constants';

const jsonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const baseQuery =
  () =>
  ({ apiVersion, headers, needAuthentication = true, ...args }, ...params) => {
    const headersForJson = {
      ...jsonHeaders,
      ...headers,
    };
    return apiBaseQuery(
      {
        baseURL: AUTH0_API_URL,
        headers: headersForJson,
        needAuthentication,
        ...args,
      },
      ...params
    );
  };

export default baseQuery;


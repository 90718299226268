import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import { ReactComponent as ChevronRight } from 'images/chevron-right.svg';
import Card from '../Card';

const EducationalResources = (props) => (
  <Card
    {...props}
    title='my educational resources'
    description={
      'A curated collection of educational content aiming to shed light on various aspects of bleeding disorders.'
    }
  >
    <Button
      variant='text'
      component={Link}
      endIcon={<ChevronRight />}
      sx={{ width: 'fit-content' }}
      to='/homepage/my-educational-resources'
    >
      View All
    </Button>
  </Card>
);

export default EducationalResources;

import { Typography, useTheme } from '@mui/material';

import ContentBox from 'components/MyAccount/ContentBox';

const AboutRegistry = () => {
  const theme = useTheme();
  return (
    <ContentBox title='About the registry'>
      <Typography
        variant='p'
        sx={{
          fontSize: '0.85rem',
          fontWeight: theme.typography.fontWeightLight,
          lineHeight: '150%',
        }}
      >
        CVR is the National Bleeding Disorders Foundation’s (BDF)
        community-powered registry (also known as a database) for the bleeding
        disorders community. CVR gathers information from people with inherited
        bleeding disorders and their immediate family members longitudinally
        –this means that it will gather information over the years.
        <br />
        <br />
        Based on this information, NBDF develops educational programs, focuses
        advocacy efforts tailored to the community’s needs, and identifies
        research priorities important to the community, enabling research funds
        to be directed accordingly. In addition, CVR also provides you (the
        participant), a personalized dashboard so that, among other things, you
        can see your personal data, follow trends over time and compare yourself
        with other participants anonymously. This novel approach will help
        everyone be an active participant in their healthcare.
      </Typography>
    </ContentBox>
  );
};

export default AboutRegistry;

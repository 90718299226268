import {
  AUTH0_CLIENT_ID,
  AUTH0_CLIENT_SECRET,
  AUTH0_API_URL,
} from 'utils/constants';
import axios from 'axios';

const useAuthenticationApi = () => {
  const revokeToken = async (refreshToken) => {
    const options = {
      method: 'POST',
      url: AUTH0_API_URL + '/oauth/revoke',
      headers: { 'content-type': 'application/json' },
      data: {
        client_id: AUTH0_CLIENT_ID,
        client_secret: AUTH0_CLIENT_SECRET,
        token: refreshToken,
      },
    };

    try {
      const response = await axios.request(options);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return revokeToken;
};

export default useAuthenticationApi;

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';

import Container from 'components/Enrollment/Container';
import { getAuthenticatorDetails } from 'utils/mfa';
import { getNthStringOccurance } from 'utils/string';
import ResendCode from 'components/ResendCode';

const ConfirmMFA = ({
  authenticationType,
  mfaCode,
  mfaLoaders,
  newMFASetup = true,
  pageLoading,
  code,
  setCode,
  handleSubmit,
  handleSkipMFA,
  handleResendCode,
}) => {
  const { mfaTitle, placeholder, description } =
    getAuthenticatorDetails(authenticationType);
  const isAuthenticatorApp = authenticationType === 'authenticatorApp';

  const barcodeUri =
    Boolean(mfaCode) &&
    !pageLoading &&
    `${mfaCode?.substring(
      0,
      getNthStringOccurance(mfaCode, '/', 3)
    )}/NHF${mfaCode?.substring(getNthStringOccurance(mfaCode, '?', 1))}`;
  return (
    <Container maxWidth='600px'>
      {pageLoading ? (
        <Stack spacing='20px' py='30px' px={{ xs: '20px', sm: '30px' }}>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </Stack>
      ) : (
        <>
          {newMFASetup && (
            <>
              <Stack spacing='20px' py='30px' px={{ xs: '20px', sm: '30px' }}>
                <Typography variant='h6'>{mfaTitle}</Typography>
              </Stack>
              <Divider />
            </>
          )}
          <Stack spacing='20px' py='30px' px={{ xs: '20px', sm: '30px' }}>
            <Grid container>
              <Grid item xs={12} md={isAuthenticatorApp ? 8 : 12}>
                <Typography
                  variant='subtitle2'
                  fontWeight='normal'
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Grid>
              {isAuthenticatorApp && (
                <Grid item xs={12} md={4} display='flex' justifyContent='right'>
                  <Typography variant='subtitle2' fontWeight='normal'>
                    <QRCodeSVG value={barcodeUri} />
                  </Typography>
                </Grid>
              )}
            </Grid>
            <TextField
              placeholder={placeholder}
              onChange={(e) => setCode(e.target.value)}
            />
            {!isAuthenticatorApp && Boolean(handleResendCode) && (
              <ResendCode handleResendCode={handleResendCode} />
            )}
          </Stack>
          {newMFASetup && <Divider />}
          <Stack
            direction='row'
            spacing='20px'
            py={newMFASetup ? '30px' : ''}
            px={{ xs: '20px', sm: '30px' }}
            justifyContent='right'
          >
            {Boolean(handleSkipMFA) && (
              <Button
                disabled={mfaLoaders.skip}
                variant='outlined'
                onClick={() => handleSkipMFA({redirect: 'back'})}
              >
                Cancel {mfaLoaders.skip && <CircularProgress size={16} />}
              </Button>
            )}
            {Boolean(handleSubmit) && (
              <Button
                disabled={mfaLoaders.confirm || !Boolean(code)}
                onClick={() =>
                  handleSubmit({ code, method: authenticationType })
                }
              >
                Confirm {mfaLoaders.confirm && <CircularProgress size={16} />}
              </Button>
            )}
          </Stack>
        </>
      )}
    </Container>
  );
};

export default ConfirmMFA;

import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

const baseQuery = async (
  {
    bypassCaseConverter = false,
    needAuthentication = false,
    headers,
    body: data,
    ...rest
  },
  { getState }
) => {
  const client = bypassCaseConverter
    ? axios.create()
    : applyCaseMiddleware(axios.create());

  const token = needAuthentication && getState().auth.accessToken;

  const authorizedHeaders = {
    ...(!!token && { Authorization: `Bearer ${token}` }),
    ...headers,
  };

  try {
    const result = await client.request({
      ...rest,
      data,
      headers: authorizedHeaders,
    });
    return { data: result.data };
  } catch (axiosError) {
    const error = {
      status: axiosError.response?.status,
      data: axiosError.response?.data || axiosError.message,
    };
    return { error };
  }
};

export default baseQuery;

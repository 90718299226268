const formMH = {
  title: 'Form MH',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'MH',
  type: 'panel',
  label: 'Form MH',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Mental Health</strong><br/>Mental health is very important. This section will ask how you are feeling. Remember that this information is confidential, so please answer these questions sincerely.<br/><br/>If you have any concerns or would like to discuss your answers with someone who can help, contact your health care provider for guidance today.',
      label: 'Content',
      refreshOnChange: false,
      key: 'MH0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'Are you currently receiving any of the following treatments for mental or emotional health? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'Counseling or therapy',
          value: 'Therapy',
          shortcut: '',
        },
        {
          label: 'Support group',
          value: 'Group',
          shortcut: '',
        },
        {
          label: 'Medication',
          value: 'Medication',
          shortcut: '',
        },
        {
          label: 'None of the above',
          value: 'noneOfTheAbove',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.noneOfTheAbove;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      validate: {
        required: true,
      },
      key: 'MH1',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other...',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      key: 'MH1_other',
      customConditional: 'show = data.MH1?.other;',
      validate: {
        required: true,
        maxLength: 250,
      },
      type: 'textfield',
      input: true,
    },
    {
      label:
        "Stress is when someone feels tense, nervous, anxious, or can't sleep at night because their mind is troubled. How stressed are you?",
      tableView: false,
      optionsLabelPosition: 'bottom',
      inline: true,
      values: [
        {
          label: 'Not at all',
          value: '0',
          tooltip: '',
        },
        {
          label: 'A little bit',
          value: '1',
          tooltip: '',
        },
        {
          label: 'Somewhat',
          value: '2',
          tooltip: '',
        },
        {
          label: 'Quite a bit',
          value: '3',
          tooltip: '',
        },
        {
          label: 'Very much',
          value: '4',
          tooltip: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'MH2',
      type: 'radio',
      input: true,
    },
    {
      label:
        'Consider how well the following statements describe your behavior and actions',
      tableView: false,
      questions: [
        {
          label: 'I look for creative ways to alter difficult situations.',
          value: 'MH3',
          tooltip: '',
        },
        {
          label:
            'Regardless of what happens to me, I believe I can control my reaction to it.',
          value: 'MH4',
          tooltip: '',
        },
        {
          label:
            'I believe I can grow in positive ways by dealing with difficult situations.',
          value: 'MH5',
          tooltip: '',
        },
        {
          label:
            'I actively look for ways to replace the losses I encounter in my life.',
          value: 'MH6',
          tooltip: '',
        },
      ],
      values: [
        {
          label: 'Does not describe me at all',
          value: '1',
          tooltip: '',
        },
        {
          label: 'Does not describe me',
          value: '2',
          tooltip: '',
        },
        {
          label: 'Neutral',
          value: '3',
          tooltip: '',
        },
        {
          label: 'Describes me',
          value: '4',
          tooltip: '',
        },
        {
          label: 'Describes me very well',
          value: '5',
          tooltip: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'MH3_MH6',
      type: 'survey',
      input: true,
    },
    {
      label: 'Please rate the following statements. In the past 7 days I...',
      tableView: false,
      questions: [
        {
          label: 'Felt worthless',
          value: 'MH7',
          tooltip: '',
        },
        {
          label: 'Felt that I had nothing to look forward to',
          value: 'MH8',
          tooltip: '',
        },
        {
          label: 'Felt helpless',
          value: 'MH9',
          tooltip: '',
        },
        {
          label: 'Felt sad',
          value: 'MH10',
          tooltip: '',
        },
        {
          label: 'Felt like a failure',
          value: 'MH11',
          tooltip: '',
        },
        {
          label: 'Felt depressed',
          value: 'MH12',
          tooltip: '',
        },
        {
          label: 'Felt unhappy',
          value: 'MH13',
          tooltip: '',
        },
        {
          label: 'Felt hopeless',
          value: 'MH14',
          tooltip: '',
        },
        {
          label: 'Felt fearful',
          value: 'MH15',
          tooltip: '',
        },
        {
          label: 'Felt anxious',
          value: 'MH16',
          tooltip: '',
        },
        {
          label: 'Felt worried',
          value: 'MH17',
          tooltip: '',
        },
        {
          label: 'Found it hard to focus on anything other than my anxiety',
          value: 'MH18',
          tooltip: '',
        },
        {
          label: 'Felt nervous',
          value: 'MH19',
          tooltip: '',
        },
        {
          label: 'Felt uneasy',
          value: 'MH20',
          tooltip: '',
        },
        {
          label: 'Felt tense',
          value: 'MH21',
          tooltip: '',
        },
      ],
      values: [
        {
          label: 'Never',
          value: '1',
          tooltip: '',
        },
        {
          label: 'Rarely',
          value: '2',
          tooltip: '',
        },
        {
          label: 'Sometimes',
          value: '3',
          tooltip: '',
        },
        {
          label: 'Often',
          value: '4',
          tooltip: '',
        },
        {
          label: 'Always',
          value: '5',
          tooltip: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'MH7_MH21',
      type: 'survey',
      input: true,
    },
  ],
};

export default formMH;

import getEnvVariables from 'config';

export const {
  API_URL,
  AUDIENCE,
  AUTH0_DOMAIN,
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_CLIENT_SECRET,
  EMAIL_FROM,
  AUTH0_API_URL,
  AUTH0_API_CLIENT_ID,
  AUTH0_API_CLIENT_SECRET,
  AUTH0_API_AUDIENCE,
  MFA_GRANT_TYPE,
  RECAPTCHA_PUBLIC_KEY,
} = getEnvVariables();

export const PROJECT = 'nhf';

const formA = {
  title: 'Form A',
  label: 'Form A',
  type: 'panel',
  key: 'A',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Income, education, insurance, access to care</strong></br/>In this section we ask about your income, education, conditions about life and work, finances, insurance, and access to health care because these are important non-medical factors that influence your health and wellness. They are often called "social determinants of health" and we know very little about how they impact persons with bleeding disorders.',
      label: 'Content',
      refreshOnChange: false,
      key: 'A0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'How many family members, <u>including yourself</u>, do you currently live with?',
      placeholder: 'Please enter the number',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      calculateValue: 'if (value) value = value < 0 ? 0 : Math.trunc(value)',
      validate: {
        required: true,
        min: 1,
        max: 999,
      },
      key: 'A1',
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label:
        'How many family members in your household are <u>under age 18</u>?',
      placeholder: 'Please enter the number',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      calculateValue: 'if (value) value = value < 0 ? 0 : Math.trunc(value)',
      validate: {
        required: true,
        custom:
          // eslint-disable-next-line no-template-curly-in-string
          'valid = (input < data.A1) ? true : `This field cannot be greater than ${data.A1 - 1}`',
        min: 0,
        max: 999,
      },
      key: 'A2',
      customConditional: 'show = data.A1 > 1',
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label:
        'How many family members in your household, <u>including yourself</u>, are over age 18?',
      placeholder: 'Family members over age 18',
      applyMaskOn: 'change',
      mask: false,
      disabled: true,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      calculateValue: 'value = data.A1 - (data.A2 ?? 0);',
      validate: {
        required: true,
      },
      key: 'A3',
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'What is your current marital status?',
      widget: 'choicesjs',
      placeholder: 'Marital status...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Never been married',
            value: 'Never_mar',
          },
          {
            label: 'In a long-term relationship',
            value: 'LTR',
          },
          {
            label: 'Married',
            value: 'Married',
          },
          {
            label: 'Separated or divorced',
            value: 'Sep_Div',
          },
          {
            label: 'Widowed',
            value: 'Widowed',
          },
          {
            label: 'I choose not to answer',
            value: 'NAK',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'A4',
      type: 'select',
      input: true,
    },
    {
      html: '<em>A "Head of household" is someone who is <strong>ALL</strong> of the following:<ul><li>Legally single.</li><li>Pays more than half of the household expenses.</li><li>Has a dependent child or relative living in the household for at least half the year OR for whom the head of household pays more than half of their living expenses.</li></ul></em>',
      label: 'Content',
      refreshOnChange: false,
      key: 'content2',
      type: 'content',
      customConditional: "show = data.A4 !== 'Married'",
      input: false,
      tableView: false,
    },
    {
      label: 'Based on this definition, are you a “head of household”?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A5',
      customConditional: "show = data.A4 !== 'Married'",
      type: 'radio',
      input: true,
    },
    {
      label:
        'Based on this definition, is your “head of household” older than age 65?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A6',
      customConditional: "show = data.A5 === 'No' && data.A4 !== 'Married'",
      type: 'radio',
      input: true,
    },
    {
      label: 'What is your housing situation today?',
      widget: 'choicesjs',
      placeholder: 'Housing situation...',
      tableView: true,
      data: {
        values: [
          {
            label: 'I have housing',
            value: 'Housing',
          },
          {
            label:
              'I do not have housing (I am staying with others, in a hotel, in a shelter, living outside on the street, on a beach, in a car, or in a park)',
            value: 'No_Housing',
          },
          {
            label: 'I choose not to answer',
            value: 'NAK',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'A7',
      type: 'select',
      input: true,
    },
    {
      label: 'Are you worried about losing your housing?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
        {
          label: 'I choose not to answer',
          value: 'NAK',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A8',
      customConditional: "show = data.A7 !== 'No_Housing'",
      type: 'radio',
      input: true,
    },
    {
      label: 'What is the highest level of school that you have finished?',
      widget: 'choicesjs',
      placeholder: 'Highest level of school...',
      tableView: true,
      data: {
        values: [
          {
            label: 'I did not finish high school',
            value: '1',
          },
          {
            label: 'High school diploma or GED certificate',
            value: '2',
          },
          {
            label: 'Some college but no degree',
            value: '3',
          },
          {
            label: 'Trade school certificate',
            value: '4',
          },
          {
            label: 'Associate’s degree',
            value: '5',
          },
          {
            label: 'Bachelor’s Degree',
            value: '6',
          },
          {
            label: 'Master’s Degree',
            value: '7',
          },
          {
            label: 'Doctorate or professional degree (PhD, JD, MD, etc.)',
            value: '8',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'A9',
      type: 'select',
      input: true,
    },
    {
      label: 'Have you served in the armed forces of the United States?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A10',
      type: 'radio',
      input: true,
    },
    {
      label:
        "At any point in the past 2 years, has seasonal or migrant farm work been your or your family's main source of income?",
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
        {
          label: 'I choose not to answer',
          value: 'NAK',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A11',
      type: 'radio',
      input: true,
    },
    {
      label: 'What is your current work situation? Choose only one response.',
      widget: 'choicesjs',
      placeholder: 'Current work situation...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Full time work',
            value: 'FT',
          },
          {
            label: 'Part time work',
            value: 'PT',
          },
          {
            label: 'Seasonally unemployed',
            value: 'Seasonal',
          },
          {
            label: 'Unemployed and disabled',
            value: 'Disabled',
          },
          {
            label: 'Unemployed and able to work',
            value: 'Unemployed',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'A12',
      type: 'select',
      input: true,
    },
    {
      label:
        'You stated you are currently not employed, please tell us the <u>main</u> (primary) reason. Choose only one response.',
      widget: 'choicesjs',
      placeholder: 'Main reason...',
      tableView: true,
      data: {
        values: [
          {
            label: 'I am a student',
            value: 'Student',
          },
          {
            label: 'I am retired',
            value: 'Retired',
          },
          {
            label: 'I am an unpaid caregiver or homemaker',
            value: 'Caregiver',
          },
          {
            label: 'I am looking for a better job or changing careers',
            value: 'Changing',
          },
          {
            label: 'There are not many jobs in my field right now',
            value: 'Low_demand',
          },
          {
            label: 'I am in the process of moving to another location',
            value: 'Moving',
          },
          {
            label: 'I lost my job due to automation or technology',
            value: 'Displaced_by_tech',
          },
          {
            label: 'I was fired or laid off',
            value: 'Fired',
          },
          {
            label: 'My bleeding disorder makes it hard for me to keep a job',
            value: 'BD',
          },
          {
            label: 'Other',
            value: 'other',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'A13',
      conditional: {
        show: true,
        when: 'A12',
        eq: 'Unemployed',
      },
      type: 'select',
      input: true,
    },
    {
      label: 'Other main reason',
      placeholder: 'Other main reason...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'A13_other',
      conditional: {
        show: true,
        when: 'A13',
        eq: 'other',
      },
      type: 'textfield',
      input: true,
    },
    {
      label: 'How many hours do you work in an average work week?',
      placeholder: 'Please enter the number...',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        required: true,
        min: 0,
        max: 999,
      },
      key: 'A14',
      customConditional:
        "show = data.A12 === 'FT' || data.A12 === 'PT' || data.A12 === 'Seasonal'",
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label:
        'Do you work more jobs today than in previous years to make ends meet?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A15',
      customConditional:
        "show = data.A12 !== 'Disabled' && data.A12 !== 'Unemployed' && data.A12 !== ''",
      type: 'radio',
      input: true,
    },
    {
      label:
        'Have you experienced any of the following because of your bleeding disorder? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label:
            'I chose a specific career path or specific employment opportunity',
          value: 'Specific_path',
          shortcut: '',
        },
        {
          label: 'I did not accept an employment opportunity',
          value: 'Declined_emp',
          shortcut: '',
        },
        {
          label: 'I had difficulty finding employment',
          value: 'Diff_find_emp',
          shortcut: '',
        },
        {
          label: 'I had difficulty maintaining employment',
          value: 'Diff_maintain_emp',
          shortcut: '',
        },
        {
          label: 'None of the above',
          value: 'noneOfTheAbove',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A16',
      logic: [
        {
          name: 'None of the above is true',
          trigger: {
            type: 'javascript',
            javascript: 'result = data?.A16?.noneOfTheAbove',
          },
          actions: [
            {
              name: 'Clear all',
              type: 'value',
              value:
                "value = Object.keys(data?.A16).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        Specific_path: false,
        Declined_emp: false,
        Diff_find_emp: false,
        Diff_maintain_emp: false,
        noneOfTheAbove: false,
      },
    },
    {
      label:
        'In the past year, what was the total combined income for you and the family members you live with? Total household income includes income from everyone living in your home and also includes unemployment income, child support, social security, disability income, government assistance income, etc.',
      placeholder: 'Please enter total combined income...',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      currency: 'USD',
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      suffix: 'USD',
      validate: {
        required: true,
        min: 0,
        max: 999999,
      },
      key: 'A17',
      logic: [
        {
          name: 'Disable component',
          trigger: {
            type: 'javascript',
            javascript: 'result = data.A17_iChooseNotToAnswer;',
          },
          actions: [
            {
              name: 'Disable',
              type: 'property',
              property: {
                label: 'Disabled',
                value: 'disabled',
                type: 'boolean',
              },
              state: true,
            },
            {
              name: 'Clear',
              type: 'value',
              value: 'value = ""',
            },
            {
              name: 'Not required',
              type: 'property',
              property: {
                label: 'Required',
                value: 'validate.required',
                type: 'boolean',
              },
              state: false,
            },
          ],
        },
      ],
      type: 'currency',
      input: true,
      delimiter: true,
    },
    {
      label: 'I choose not to answer',
      tableView: false,
      defaultValue: false,
      key: 'A17_iChooseNotToAnswer',
      type: 'checkbox',
      input: true,
    },
    {
      label:
        'How many people, including yourself, are supported by this household income?',
      placeholder: 'Please enter number',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      calculateValue: 'if (value) value = value < 0 ? 0 : Math.trunc(value)',
      truncateMultipleSpaces: false,
      validate: {
        required: true,
        custom:
          // eslint-disable-next-line no-template-curly-in-string
          'valid = (input <= data.A1) ? true : `This field cannot be greater than ${data.A1}`',
        min: 1,
        max: 999,
      },
      key: 'A18',
      logic: [
        {
          name: 'Disable component',
          trigger: {
            type: 'javascript',
            javascript: 'result = data.A18_iChooseNotToAnswer;',
          },
          actions: [
            {
              name: 'Disable',
              type: 'property',
              property: {
                label: 'Disabled',
                value: 'disabled',
                type: 'boolean',
              },
              state: true,
            },
            {
              name: 'Clear',
              type: 'value',
              value: 'value = ""',
            },
            {
              name: 'Not required',
              type: 'property',
              property: {
                label: 'Required',
                value: 'validate.required',
                type: 'boolean',
              },
              state: false,
            },
          ],
        },
      ],
      type: 'number',
      input: true,
    },
    {
      label: 'I choose not to answer',
      tableView: false,
      defaultValue: false,
      key: 'A18_iChooseNotToAnswer',
      type: 'checkbox',
      input: true,
    },
    {
      label:
        'In the past year, have <u>you or any family members you live with</u> been unable to get any of the following when it was really needed? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'Food',
          value: 'Food',
          shortcut: '',
        },
        {
          label: 'Utilities',
          value: 'Utilities',
          shortcut: '',
        },
        {
          label:
            'Health care (medical, dental, mental health, vision, substance abuse treatment)',
          value: 'Health_care',
          shortcut: '',
        },
        {
          label: 'Medicine',
          value: 'Medicine',
          shortcut: '',
        },
        {
          label: 'Medical supplies other than medicine',
          value: 'Supplies',
          shortcut: '',
        },
        {
          label: 'Menstrual products (sanitary pads, tampons, etc.)',
          value: 'Menstrual',
          shortcut: '',
        },
        {
          label: 'Phone',
          value: 'Phone',
          shortcut: '',
        },
        {
          label: 'Clothing',
          value: 'Clothing',
          shortcut: '',
        },
        {
          label: 'Child care',
          value: 'Childcare',
          shortcut: '',
        },
        {
          label: 'Transportation',
          value: 'Transportation',
          shortcut: '',
        },
        {
          label: 'Legal help',
          value: 'Legal',
          shortcut: '',
        },
        {
          label: 'Help for a domestic violence situation',
          value: 'Dom_violence',
          shortcut: '',
        },
        {
          label: 'None of the above',
          value: 'noneOfTheAbove',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A19',
      logic: [
        {
          name: 'None of the above is true',
          trigger: {
            type: 'javascript',
            javascript: 'result = data?.A19?.noneOfTheAbove',
          },
          actions: [
            {
              name: 'Clear all',
              type: 'value',
              value:
                "value = Object.keys(data?.A19).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        Food: false,
        Utilities: false,
        Health_care: false,
        Medicine: false,
        Supplies: false,
        Menstrual: false,
        Phone: false,
        Clothing: false,
        Childcare: false,
        Transportation: false,
        Legal: false,
        Dom_violence: false,
        noneOfTheAbove: false,
        other: false,
      },
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'A19_other',
      customConditional: 'show = !!data?.A19?.other',
      type: 'textfield',
      input: true,
    },
    {
      label:
        'How often has your healthcare provider helped you (example: made a referral, facilitated an appointment, provided information about where to go or who to contact, etc.) with any of the above issues?',
      placeholder: 'How often...',
      tableView: true,
      values: [
        {
          label: 'Never',
          value: '0',
        },
        {
          label: 'Rarely',
          value: '1',
        },
        {
          label: 'Sometimes',
          value: '2',
        },
        {
          label: 'Often',
          value: '3',
        },
        {
          label: 'Always',
          value: '4',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A21',
      customConditional:
        "show = !!Object.entries(data?.A19).find(([key, value]) => !!value && key !== 'noneOfTheAbove')",
      type: 'radio',
      optionsLabelPosition: 'bottom',
      inline: true,
      input: true,
    },
    {
      label:
        'How often do you or any of your family members have <strong>difficulty paying for menstrual products</strong> every month?',
      tableView: true,
      values: [
        {
          label: 'Never',
          value: '0',
        },
        {
          label: 'Rarely',
          value: '1',
        },
        {
          label: 'Sometimes',
          value: '2',
        },
        {
          label: 'Often',
          value: '3',
        },
        {
          label: 'Always',
          value: '4',
        },
        {
          label: 'I choose not to answer',
          value: 'NAK',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A20',
      customConditional: 'show = !!data?.A19?.Menstrual',
      type: 'radio',
      optionsLabelPosition: 'bottom',
      inline: true,
      input: true,
    },
    {
      label: 'Survey',
      description:
        '<sup>†</sup>A payday loan or advance is a short-term loan in which you write a check with a future date to a lender for all or some of your paycheck and you receive cash from that lender minus a fee.',
      hideLabel: true,
      tableView: false,
      questions: [
        {
          label:
            'How often do you have difficulty paying for your medications?',
          value: 'A22',
          tooltip: '',
        },
        {
          label:
            'How often do you have difficulty with transportation to your clinic visits?',
          value: 'A23',
          tooltip: '',
        },
        {
          label:
            'How often do you have difficulty getting time off from work to manage your health care?',
          value: 'A24',
          tooltip: '',
        },
        {
          label:
            'How often do you have difficulty meeting your monthly house expenses?',
          value: 'A25',
          tooltip: '',
        },
        {
          label:
            'How often do you use credit cards to pay for monthly necessities you could not otherwise afford?',
          value: 'A26',
          tooltip: '',
        },
        {
          label:
            'How often have you used payday loans or payday advances<sup>†</sup> <u>in the past year?</u>',
          value: 'A27',
          tooltip: '',
        },
      ],
      values: [
        {
          label: 'Never',
          value: '0',
          tooltip: '',
        },
        {
          label: 'Rarely',
          value: '1',
          tooltip: '',
        },
        {
          label: 'Sometimes',
          value: '2',
          tooltip: '',
        },
        {
          label: 'Often',
          value: '3',
          tooltip: '',
        },
        {
          label: 'Always',
          value: '4',
          tooltip: '',
        },
        {
          label: 'I choose not to answer',
          value: 'NAK',
          tooltip: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A22_A27',
      type: 'survey',
      input: true,
    },
    {
      label:
        'Has lack of (not having) transportation kept you from any of the following? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        Appts: false,
        Medical: false,
        Work: false,
        Shopping: false,
        noneOfTheAbove: false,
        other: false,
      },
      values: [
        {
          label: 'Meetings or non-medical appointments',
          value: 'Appts',
          shortcut: '',
        },
        {
          label: 'Medical appointments',
          value: 'Medical',
          shortcut: '',
        },
        {
          label: 'Work',
          value: 'Work',
          shortcut: '',
        },
        {
          label: 'Getting things that you need',
          value: 'Shopping',
          shortcut: '',
        },
        {
          label: 'None of the above',
          value: 'noneOfTheAbove',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A28',
      logic: [
        {
          name: 'None of the above selected',
          trigger: {
            type: 'javascript',
            javascript: 'result = data?.A28?.noneOfTheAbove',
          },
          actions: [
            {
              name: 'Clear all',
              type: 'value',
              value:
                "value = Object.keys(data?.A28).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'A28_other',
      customConditional: 'show = !!data.A28?.other;',
      type: 'textfield',
      input: true,
    },
    {
      label: 'What is your primary health insurance? Choose only one response.',
      widget: 'choicesjs',
      placeholder: 'Primary health insurance...',
      tableView: true,
      data: {
        values: [
          {
            label:
              'Insurance through a current or former employer, union, or family member',
            value: 'Ins_Emp',
          },
          {
            label:
              'Insurance purchased through the Marketplace or from an insurance company',
            value: 'Ins_Purc',
          },
          {
            label:
              'Medicare, for people 65 and older or people with certain disabilities',
            value: 'Medicare',
          },
          {
            label:
              'Medicaid or any kind of government-assistance special needs plan (CHIP, GHPP, or CSHS)',
            value: 'Medicaid',
          },
          {
            label: 'Military (Tricare), Veterans Affairs (VA)',
            value: 'Military',
          },
          {
            label: 'Indian Health Service',
            value: 'IHS',
          },
          {
            label: "I don't have health insurance",
            value: 'No_ins',
          },
          {
            label: 'Other type of health insurance or health coverage plan',
            value: 'other',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'A29',
      type: 'select',
      input: true,
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'A29_other',
      conditional: {
        show: true,
        when: 'A29',
        eq: 'other',
      },
      type: 'textfield',
      input: true,
    },
    {
      label:
        'Have you been asked by your insurance to "fail" a specific medication (for example, having to use one medication and demonstrating that it does not work) before the insurance allows you to use another one?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'no',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'A30',
      type: 'radio',
      input: true,
    },
  ],
};

export default formA;

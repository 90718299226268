import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import { ReactComponent as MinusIcon } from 'images/minus.svg';
import { ReactComponent as PlusIcon } from 'images/plus.svg';
import {
  AccordionTitle,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './style';

const NHFAccordion = ({ data, setAllViewed, hasDangerousElements }) => {
  const [expanded, setExpanded] = useState('panel0');
  const initialState = data.map((el, index) => ({
    id: `panel${index}`,
    isViewed: index === 0,
  }));

  const [viewedAccordion, setViewedAccordion] = useState(initialState);
  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      const newState = viewedAccordion.map(({ id, isViewed }) => ({
        id,
        isViewed: id === panel || isViewed,
      }));

      setViewedAccordion(newState);
    }
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (setAllViewed && viewedAccordion.every((value) => value.isViewed)) {
      setAllViewed(true);
    }
  }, [viewedAccordion, setAllViewed]);

  return (
    <div>
      {data.map((item, index) => (
        <StyledAccordion
          expanded={expanded === `panel${index}`}
          key={item.id}
          onChange={handleChange(`panel${index}`)}
        >
          <StyledAccordionSummary
            expandIcon={
              expanded === `panel${index}` ? (
                <MinusIcon color='#5B294F' />
              ) : (
                <PlusIcon color='#5B294F' />
              )
            }
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
            style={{
              borderBottom:
                expanded === `panel${index}` ? '1px solid #EBEBEB' : '',
            }}
          >
            <AccordionTitle>{item.title}</AccordionTitle>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {hasDangerousElements ? (
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            ) : (
              <Typography>{item.content}</Typography>
            )}
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  );
};

export default NHFAccordion;

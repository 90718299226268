import { Stack, Typography } from '@mui/material';

const Text = ({ title, count, total }) => (
  <Stack direction='row' spacing='10px' alignItems='center'>
    <Typography variant='w700h16' color='gray.100'>
      {`${count} / ${total}`}
    </Typography>
    <Typography
      variant={title === 'Baseline Survey Completed' ? 'w600h12' : 'w600h14'}
      color='gray.80'
    >
      {title}
    </Typography>
  </Stack>
);

export default Text;

import { Grid, Link } from '@mui/material';

import { LinkCard } from 'components/Cards';
import { GridPageLayout } from 'components/Layouts';
import { myImpactWithCVR } from 'mocks/myImpactWithCVR';

const MyImpactWithCVR = () => {
  return (
    <GridPageLayout
      title='My Impact Through CVR'
      description={
        <span>
          Your unique lived experience and contributions to CVR are helping
          researchers develop new research questions and discover new
          information to improve the lives of those within the bleeding
          disorders community. This page outlines summaries of some of the
          publications that have been made as a direct result of the community’s
          participation in CVR.
          <br />
          <br />
          If you would like to learn more about other NBDF research publications
          please click{' '}
          <Link
            target='_blank'
            rel='noopener'
            href='https://www.hemophilia.org/research/research-projects/foundation-research'
            variant='w700h14'
          >
            <u>here</u>
          </Link>
        </span>
      }
    >
      {myImpactWithCVR.map(({ id, ...data }) => (
        <Grid key={id} item xs={12} xl={6}>
          <LinkCard {...data} />
        </Grid>
      ))}
    </GridPageLayout>
  );
};

export default MyImpactWithCVR;

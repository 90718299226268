const formC = {
  title: 'Form C',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'C',
  type: 'panel',
  label: 'Form C',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Cannabis</strong><br/>Cannabis for medical use is now legal in most states. The next few questions will ask if and how it is being used in the bleeding disorders community, and what effects (if any) are associated with its use.<br/><br/>Please remember that this and all information is reported in a grouped and de-identified manner.',
      label: 'Content',
      refreshOnChange: false,
      key: 'C0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'Have you ever used cannabis for symptom management?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
        {
          label: 'I choose not to answer',
          value: 'NAK',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'C1',
      type: 'radio',
      input: true,
    },
    {
      label: 'What symptom(s) do you use cannabis for? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'Pain',
          value: 'Pain',
          shortcut: '',
        },
        {
          label: 'Anxiety',
          value: 'Anxiety',
          shortcut: '',
        },
        {
          label: 'Depression',
          value: 'Depression',
          shortcut: '',
        },
        {
          label: 'Seizures',
          value: 'Seizures',
          shortcut: '',
        },
        {
          label: 'Sleep',
          value: 'Sleep',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'C2',
      conditional: {
        show: true,
        when: 'C1',
        eq: 'Yes',
      },
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'C2_other',
      customConditional: 'show = data.C2?.other;',
      type: 'textfield',
      input: true,
    },
    {
      label: 'On a scale of 0-10, how effective do you feel that cannabis is?',
      tableView: false,
      values: [
        {
          label: 'not at all effective<br/>0',
          value: '0',
          shortcut: '',
          tooltip: '',
        },
        {
          label: '1',
          value: '1',
          shortcut: '',
          tooltip: '',
        },
        {
          label: '2',
          value: '2',
          shortcut: '',
          tooltip: '',
        },
        {
          label: '3',
          value: '3',
          shortcut: '',
          tooltip: '',
        },
        {
          label: '4',
          value: '4',
          shortcut: '',
          tooltip: '',
        },
        {
          label: '5',
          value: '5',
          shortcut: '',
          tooltip: '',
        },
        {
          label: '6',
          value: '6',
          shortcut: '',
          tooltip: '',
        },
        {
          label: '7',
          value: '7',
          shortcut: '',
          tooltip: '',
        },
        {
          label: '8',
          value: '8',
          shortcut: '',
          tooltip: '',
        },
        {
          label: '9',
          value: '9',
          shortcut: '',
          tooltip: '',
        },
        {
          label: 'totally resolved my symptom<br/>10',
          value: '10',
          shortcut: '',
          tooltip: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'C3',
      customConditional:
        'show = data.C2 && Object.values(data.C2).some(value => value);',
      logic: [
        {
          name: 'C2 selected',
          trigger: {
            type: 'javascript',
            javascript: 'result = { ...data.C2, other: data.C2_other};',
          },
          actions: [
            {
              name: 'Add questions',
              type: 'mergeComponentSchema',
              schemaDefinition:
                "const C2comp = utils.getComponent(form.components, 'C2');\nconst questions = C2comp?.values?.filter?.(({ value }) => result?.[value]).map(entry => entry.value === 'other' ? { label: result.other, value: entry.value } : entry);\nschema = { questions };",
            },
          ],
        },
      ],
      type: 'survey',
      input: true,
    },
  ],
};

export default formC;

import { Box, styled } from '@mui/material';

const backgroundBarHeight = '6px';
const foregroundBarHeight = '8px';
const iconSize = '18px';

export const Container = styled(Box)({
  width: '100%',
  height: iconSize,
  position: 'relative',
});

export const BackgroundBar = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: `calc(${iconSize} / 2 - ${backgroundBarHeight} / 2)`,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.gray[10],
  height: backgroundBarHeight,
  borderRadius: `calc(${backgroundBarHeight} / 2)`,
}));

export const ForegroundBar = styled(Box, {
  shouldForwardProp: (prop) => !['color', 'current', 'total'].includes(prop),
})(({ color, current, total }) => ({
  position: 'absolute',
  top: `calc(${iconSize} / 2 - ${foregroundBarHeight} / 2)`,
  left: 0,
  width: `${(current * 100) / total}%`,
  backgroundColor: color,
  height: foregroundBarHeight,
  borderRadius: `calc(${foregroundBarHeight} / 2)`,
}));

export const CompletedIconWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['color', 'current', 'total'].includes(prop),
})(({ theme, color, current, total }) => ({
  ...(current !== total && { visibility: 'hidden' }),

  position: 'absolute',
  right: 0,
  width: iconSize,
  height: iconSize,
  borderRadius: '100%',
  backgroundColor: color,
  boxShadow: '0px 1.25px 3.75px rgba(255, 255, 255, 0.15)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.white,
}));

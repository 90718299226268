import { Box } from '@mui/material';

import FormIO from 'components/FormIO';
import Container from 'components/Surveys/Container';

const defaultValues = {};
const survey = {
  display: 'wizard',
  navBar: {
    previousLabel: 'previous',
    saveLabel: 'save and continue later',
    nextLabel: 'next',
    submitLabel: 'submit',
    cancelLabel: 'cancel',
    closeLabel: 'close',
  },
  components: [
    {
      title: 'Form A',
      breadcrumbClickable: true,
      buttonSettings: {
        previous: true,
        cancel: true,
        next: true,
      },
      navigateOnEnter: false,
      saveOnEnter: false,
      scrollToTop: false,
      collapsible: false,
      key: 'formA',
      type: 'panel',
      label: 'Form A',
      input: false,
      tableView: false,
      components: [
        {
          label: 'Radio',
          optionsLabelPosition: 'right',
          inline: false,
          tableView: false,
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'radioA',
          type: 'radio',
          input: true,
        },
        {
          label:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in lorem at elit bibendum fermentum sit amet eget eros. Mauris risus risus, posuere eget rhoncus et, pulvinar quis sapien. Nulla pellentesque dapibus venenatis. Maecenas cursus augue nec pulvinar elementum. Suspendisse euismod placerat ante, ac finibus nisl imperdiet id. Etiam elit eros, cursus sit amet tincidunt sit amet, dapibus lobortis odio. Donec sollicitudin porttitor accumsan. Proin nulla metus, bibendum vehicula feugiat vel, scelerisque ac est. Nunc vitae lectus egestas, ornare dolor feugiat, luctus turpis. Ut vitae laoreet metus.  Donec a enim ut elit imperdiet congue sed ut elit. Donec suscipit sed lacus sit amet semper. Curabitur pretium fermentum elementum. Sed sit amet lacinia libero, vitae aliquam nisl. Cras placerat, turpis ut lobortis aliquet, nisi nunc tristique metus, eu lobortis metus purus sit amet magna. Sed neque sem, tincidunt in velit non, aliquet congue orci. Vivamus sagittis, risus ac feugiat scelerisque, neque nisl porttitor dui, a luctus augue augue sit amet nisi.  Vivamus pretium enim turpis, nec suscipit mi mollis ut. Maecenas a nulla quis elit tempus dapibus. Vivamus vestibulum nulla at tincidunt tempor. Nullam venenatis semper diam vitae semper. Quisque quis augue nec purus dignissim facilisis non sit amet justo. Integer vehicula leo ac sollicitudin placerat. Ut vulputate interdum ornare. Etiam vulputate elementum turpis, ut facilisis tellus ultricies eget. Phasellus pretium vel nunc sed tristique. Aliquam ut felis sed nulla molestie laoreet in ac mauris. Sed mattis ex eget justo euismod fermentum. Nullam dolor enim, interdum non nunc auctor, rutrum interdum metus. Pellentesque luctus leo non libero iaculis, vitae ultrices ipsum dictum. Sed aliquam mi eget finibus tempor. Nullam sit amet sem nisl.',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
          },
          key: 'textFieldA',
          type: 'textfield',
          input: true,
        },
        {
          label:
            'Ut malesuada laoreet enim, vel facilisis mauris viverra in. Nulla et aliquet erat. Maecenas pharetra interdum tortor ut facilisis. Duis elit justo, ultricies hendrerit lorem at, vulputate lobortis tortor. Maecenas feugiat, elit et gravida porttitor, erat libero interdum orci, a rhoncus orci ipsum non lacus. Ut finibus rhoncus ex sit amet aliquet. Nam eu blandit elit.  Aliquam erat volutpat. Nam egestas a erat id vehicula. Suspendisse ut velit risus. Phasellus pulvinar velit eu leo suscipit, id pellentesque tortor fermentum. Aliquam eget scelerisque nisl. Nunc ultricies turpis ac ipsum fermentum suscipit. Suspendisse hendrerit pharetra urna eget finibus. Donec accumsan sapien ac lobortis feugiat.<br />Vestibulum nec posuere nisi, quis vulputate lectus. Etiam elementum sodales nisi, vel porttitor tellus congue vitae. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur rutrum commodo nibh at efficitur. Donec non mauris quam. Cras vel nisl pellentesque, laoreet velit non, posuere diam. Phasellus ut laoreet lectus. Proin lobortis odio quis turpis tempor, vel elementum felis varius. Phasellus fermentum aliquet consectetur. Proin fermentum sapien vitae commodo luctus. In porttitor ex tempor nunc venenatis mattis. In eleifend semper nisl ut gravida. Praesent ac consequat ligula, egestas vulputate ante. Sed ut nisl luctus, placerat nunc vulputate, commodo magna.  Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vestibulum tellus magna, porta at viverra sed, ultrices sit amet ex. Vivamus placerat neque tortor, et sollicitudin purus pretium sit amet. Sed non erat ac justo elementum rutrum. Morbi maximus massa diam, ut tincidunt felis rhoncus a. Duis tincidunt tempus interdum. <br/>Donec eleifend lobortis diam a ornare. Suspendisse non magna at velit laoreet scelerisque et sed odio.',
          optionsLabelPosition: 'right',
          tableView: false,
          defaultValue: {
            optionA: false,
            optionB: false,
            optionC: false,
            optionD: false,
            optionE: false,
            optionF: false,
            optionG: false,
            optionH: false,
            optionI: false,
          },
          values: [
            {
              label: 'option a',
              value: 'optionA',
              shortcut: '',
            },
            {
              label: 'option b',
              value: 'optionB',
              shortcut: '',
            },
            {
              label: 'option c',
              value: 'optionC',
              shortcut: '',
            },
            {
              label: 'option d',
              value: 'optionD',
              shortcut: '',
            },
            {
              label: 'option e',
              value: 'optionE',
              shortcut: '',
            },
            {
              label: 'option f',
              value: 'optionF',
              shortcut: '',
            },
            {
              label: 'option g',
              value: 'optionG',
              shortcut: '',
            },
            {
              label: 'option h',
              value: 'optionH',
              shortcut: '',
            },
            {
              label: 'option i',
              value: 'optionI',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'selectBoxesA',
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
      ],
    },
    {
      title: 'Form B',
      breadcrumbClickable: true,
      buttonSettings: {
        previous: true,
        cancel: true,
        next: true,
      },
      navigateOnEnter: false,
      saveOnEnter: false,
      scrollToTop: false,
      collapsible: false,
      key: 'formB',
      type: 'panel',
      label: 'Form B',
      input: false,
      tableView: false,
      components: [
        {
          label: 'Radio',
          optionsLabelPosition: 'right',
          inline: false,
          tableView: false,
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'radioB',
          type: 'radio',
          input: true,
        },
        {
          label:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in lorem at elit bibendum fermentum sit amet eget eros. Mauris risus risus, posuere eget rhoncus et, pulvinar quis sapien. Nulla pellentesque dapibus venenatis. Maecenas cursus augue nec pulvinar elementum. Suspendisse euismod placerat ante, ac finibus nisl imperdiet id. Etiam elit eros, cursus sit amet tincidunt sit amet, dapibus lobortis odio. Donec sollicitudin porttitor accumsan. Proin nulla metus, bibendum vehicula feugiat vel, scelerisque ac est. Nunc vitae lectus egestas, ornare dolor feugiat, luctus turpis. Ut vitae laoreet metus.  Donec a enim ut elit imperdiet congue sed ut elit. Donec suscipit sed lacus sit amet semper. Curabitur pretium fermentum elementum. Sed sit amet lacinia libero, vitae aliquam nisl. Cras placerat, turpis ut lobortis aliquet, nisi nunc tristique metus, eu lobortis metus purus sit amet magna. Sed neque sem, tincidunt in velit non, aliquet congue orci. Vivamus sagittis, risus ac feugiat scelerisque, neque nisl porttitor dui, a luctus augue augue sit amet nisi.  Vivamus pretium enim turpis, nec suscipit mi mollis ut. Maecenas a nulla quis elit tempus dapibus. Vivamus vestibulum nulla at tincidunt tempor. Nullam venenatis semper diam vitae semper. Quisque quis augue nec purus dignissim facilisis non sit amet justo. Integer vehicula leo ac sollicitudin placerat. Ut vulputate interdum ornare. Etiam vulputate elementum turpis, ut facilisis tellus ultricies eget. Phasellus pretium vel nunc sed tristique. Aliquam ut felis sed nulla molestie laoreet in ac mauris. Sed mattis ex eget justo euismod fermentum. Nullam dolor enim, interdum non nunc auctor, rutrum interdum metus. Pellentesque luctus leo non libero iaculis, vitae ultrices ipsum dictum. Sed aliquam mi eget finibus tempor. Nullam sit amet sem nisl.',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
          },
          key: 'textFieldB',
          type: 'textfield',
          input: true,
        },
        {
          label:
            'Ut malesuada laoreet enim, vel facilisis mauris viverra in. Nulla et aliquet erat. Maecenas pharetra interdum tortor ut facilisis. Duis elit justo, ultricies hendrerit lorem at, vulputate lobortis tortor. Maecenas feugiat, elit et gravida porttitor, erat libero interdum orci, a rhoncus orci ipsum non lacus. Ut finibus rhoncus ex sit amet aliquet. Nam eu blandit elit.  Aliquam erat volutpat. Nam egestas a erat id vehicula. Suspendisse ut velit risus. Phasellus pulvinar velit eu leo suscipit, id pellentesque tortor fermentum. Aliquam eget scelerisque nisl. Nunc ultricies turpis ac ipsum fermentum suscipit. Suspendisse hendrerit pharetra urna eget finibus. Donec accumsan sapien ac lobortis feugiat.<br />Vestibulum nec posuere nisi, quis vulputate lectus. Etiam elementum sodales nisi, vel porttitor tellus congue vitae. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur rutrum commodo nibh at efficitur. Donec non mauris quam. Cras vel nisl pellentesque, laoreet velit non, posuere diam. Phasellus ut laoreet lectus. Proin lobortis odio quis turpis tempor, vel elementum felis varius. Phasellus fermentum aliquet consectetur. Proin fermentum sapien vitae commodo luctus. In porttitor ex tempor nunc venenatis mattis. In eleifend semper nisl ut gravida. Praesent ac consequat ligula, egestas vulputate ante. Sed ut nisl luctus, placerat nunc vulputate, commodo magna.  Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vestibulum tellus magna, porta at viverra sed, ultrices sit amet ex. Vivamus placerat neque tortor, et sollicitudin purus pretium sit amet. Sed non erat ac justo elementum rutrum. Morbi maximus massa diam, ut tincidunt felis rhoncus a. Duis tincidunt tempus interdum. <br/>Donec eleifend lobortis diam a ornare. Suspendisse non magna at velit laoreet scelerisque et sed odio.',
          optionsLabelPosition: 'right',
          tableView: false,
          defaultValue: {
            optionA: false,
            optionB: false,
            optionC: false,
            optionD: false,
            optionE: false,
            optionF: false,
            optionG: false,
            optionH: false,
            optionI: false,
          },
          values: [
            {
              label: 'option a',
              value: 'optionA',
              shortcut: '',
            },
            {
              label: 'option b',
              value: 'optionB',
              shortcut: '',
            },
            {
              label: 'option c',
              value: 'optionC',
              shortcut: '',
            },
            {
              label: 'option d',
              value: 'optionD',
              shortcut: '',
            },
            {
              label: 'option e',
              value: 'optionE',
              shortcut: '',
            },
            {
              label: 'option f',
              value: 'optionF',
              shortcut: '',
            },
            {
              label: 'option g',
              value: 'optionG',
              shortcut: '',
            },
            {
              label: 'option h',
              value: 'optionH',
              shortcut: '',
            },
            {
              label: 'option i',
              value: 'optionI',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'selectBoxesB',
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
      ],
    },
  ],
};

const FormioTest2 = () => {
  const handleSave = (...args) => console.log(args);
  const handleSubmit = (...args) => console.log(args);

  return (
    <Box m={{ xs: '0', sm: '50px' }}>
      <Container maxWidth='800px' m='50px'>
        <FormIO
          form={survey}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          onSave={handleSave}
          template='enrollment'
          unlockSubmit={true}
        />
      </Container>
    </Box>
  );
};

export default FormioTest2;

import { Skeleton } from '@mui/material';
import { format } from 'date-fns';

import Item from 'components/MyHealthSummary/Content/Items/Item';
import usePersonAttributes from 'hooks/usePersonAttributes';

const DateOfBirth = (props) => {
  const { isLoading, attributes } = usePersonAttributes();
  const dob =
    attributes?.date_of_birth?.value &&
    format(new Date(attributes.date_of_birth.value), 'MM-dd-yyyy');
  return (
    <Item
      label='Date of Birth'
      content={isLoading ? <Skeleton width='75px' /> : dob}
      {...props}
    />
  );
};

export default DateOfBirth;

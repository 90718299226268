const MuiContainer = {
  defaultProps: {
    maxWidth: false,
    variant: 'outlined',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        padding: 0,
      },
    }),
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: ({ theme }) => ({
        borderRadius: '8px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
      }),
    },
    {
      props: { variant: 'transparent' },
      style: ({ theme }) => ({
        borderRadius: '0',
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'none',
      }),
    },
  ],
};

export default MuiContainer;

import { Divider, Stack, Switch, Typography } from '@mui/material';

import ConfirmMFA from 'components/Enrollment/AccountSecurityStep/ConfirmMFA';
import ContentBox from 'components/MyAccount/ContentBox';
import NHFModal from 'components/NHFModal';
import useMFA from 'hooks/useMFA';

const AccountSecurity = ({ isMFAEnabled }) => {
  const {
    openConfirmModal,
    mfaLoaders,
    authenticators = [],
    openEnableMFAModal,
    code,
    mfaChannel,
    mfaCode,
    handleResendCodeWhenReEnabling,
    setCode,
    handleConfirmMFAWhenReEnabling,
    setOpenEnableMFAModal,
    setOpenConfirmModal,
    handleToggleMFA,
    handleOptOut,
    handleModalClose,
  } = useMFA();

  const authenticatorChannels = isMFAEnabled
    ? authenticators.map(
        (authenticator) =>
          authenticator.oobChannel || authenticator.authenticatorType
      )
    : [];

  return (
    <ContentBox title='Security'>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='p'>
          2 Factor Authentication Communication Preferences
        </Typography>
        {/* <Switch
          checked={isMFAEnabled}
          onClick={(event) =>
            isMFAEnabled
              ? setOpenConfirmModal(true)
              : handleToggleMFA(event, 'email')
          }
        /> */}
      </Stack>
      <Divider sx={{ my: '15px' }} />
      <Stack spacing={2.5}>
        {/* <Typography variant='subtitle2'>
          2 Factor Authentication Communication Preferences
        </Typography> */}
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='p'>
            Receive verification codes via email message
          </Typography>
          <Switch
            checked={authenticatorChannels[0] === 'email'}
            onChange={(event) => handleToggleMFA(event, 'email')}
          />
        </Stack>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='p'>
            Receive verification codes via text message
          </Typography>
          <Switch
            checked={authenticatorChannels[0] === 'sms'}
            onChange={(event) => handleToggleMFA(event, 'sms')}
          />
        </Stack>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='p'>
            Receive verification codes via Authenticator App
          </Typography>
          <Switch
            checked={authenticatorChannels[0] === 'otp'}
            onChange={(event) => handleToggleMFA(event, 'authenticatorApp')}
          />
        </Stack>
      </Stack>
      <NHFModal
        handleSubmit={() => handleOptOut()}
        setOpen={setOpenConfirmModal}
        title='Opt Out'
        open={openConfirmModal}
        isConfirmLoading={mfaLoaders.skip}
        isConfirmDisabled={mfaLoaders.skip}
      >
        Are you sure you want to opt out of MFA?
      </NHFModal>
      <NHFModal
        handleSubmit={() =>
          handleConfirmMFAWhenReEnabling({
            method: mfaChannel,
            code,
          })
        }
        setOpen={setOpenEnableMFAModal}
        title='Enable MFA'
        open={openEnableMFAModal}
        isConfirmLoading={mfaLoaders.confirm}
        isConfirmDisabled={!Boolean(code) || mfaLoaders.confirm}
        handleModalClose={handleModalClose}
      >
        <ConfirmMFA
          authenticationType={mfaChannel}
          mfaLoaders={mfaLoaders}
          code={code}
          pageLoading={
            mfaLoaders.email || mfaLoaders.authenticatorApp || mfaLoaders.sms
          }
          mfaCode={mfaCode}
          newMFASetup={false}
          setCode={setCode}
          handleResendCode={handleResendCodeWhenReEnabling}
        />
      </NHFModal>
    </ContentBox>
  );
};

export default AccountSecurity;

import { Box, styled } from '@mui/material';

export const Container = styled(Box, {
  shouldForwardProp: (prop) => !['completed'].includes(prop),
})(({ theme, completed }) => ({
  display: 'flex',
  backgroundColor: completed ? theme.palette.white : theme.palette.gray[10],
  height: '32px',
  width: 'fit-content',
  borderRadius: '4px',
  padding: '0 10px 0 8px',
}));

const formHW = {
  title: 'Form HW',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'HW',
  type: 'panel',
  label: 'Form HW',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Height and Weight</strong>',
      label: 'Content',
      refreshOnChange: false,
      key: 'HW0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'What is your current weight?',
      placeholder: 'Please enter weight in pounds',
      applyMaskOn: 'change',
      suffix: 'pounds',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      calculateValue: 'if (value) value = Math.trunc(value)',
      validate: {
        required: true,
        min: 1,
        max: 999,
      },
      key: 'HW1',
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'What is your current height?',
      placeholder: 'Feet...',
      suffix: 'feet',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      calculateValue: 'if (value) value = Math.trunc(value)',
      validate: {
        required: true,
        min: 1,
        max: 999,
      },
      key: 'HW2',
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'What is your current height?',
      placeholder: 'Inches...',
      suffix: 'inches',
      applyMaskOn: 'change',
      hideLabel: true,
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      calculateValue: 'if (value) value = Math.trunc(value)',
      validate: {
        required: true,
        min: 0,
        max: 11,
      },
      key: 'HW3',
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
  ],
};

export default formHW;

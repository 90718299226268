import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import FormIO from 'components/FormIO';
import NHFModal from 'components/NHFModal';
import { openSnackbar } from 'store/slices/application';
import { parseAttributes } from 'components/Enrollment/EnrollmentStep/utils';
import personalInformation from 'forms/personalInformation';
import { SnackbarError } from 'utils/enums';
import usePersonAttributes from 'hooks/usePersonAttributes';
import { formatPhoneNumber } from 'utils/string';

const PersonalInformationModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const handleSubmit = (data) => {
    data.phone = formatPhoneNumber(data.phone);
    upsertAttributes({
      ...defaultValues,
      ...data,
    }).catch(() => dispatch(openSnackbar(SnackbarError.TryAgain())));
    setOpen(false);
  };

  const { attributes, upsertAttributes } = usePersonAttributes();
  const defaultValues = useMemo(
    () => parseAttributes(attributes),
    [attributes]
  );

  return (
    <NHFModal
      title='Personal Information'
      open={open}
      setOpen={setOpen}
      useFormioButtons={true}
    >
      <FormIO
        defaultValues={defaultValues}
        form={personalInformation}
        onSubmit={handleSubmit}
        onSave={() => {
          setOpen(false);
        }}
        template='enrollment'
      />
    </NHFModal>
  );
};

export default PersonalInformationModal;

import PropTypes from 'prop-types';
import { Grid, Stack } from '@mui/material';

import TitleWithSubtitle from 'components/TitleWithSubtitle';

const IconWithText = ({ icon, title, subtitle, ...props }) => (
  <Grid
    item
    {...props}
    xs={12}
    md={3}
    sx={{ marginX: { xs: 8.5, md: 0 }, marginBottom: { xs: 8, md: 0 } }}
  >
    <Stack justifyContent='center' alignItems='center' mb={3}>
      <img src={icon} alt='Icon' />
    </Stack>
    <TitleWithSubtitle
      title={title}
      subtitle={subtitle}
      titleSize={18}
      subTitleSize={14}
    />
  </Grid>
);

IconWithText.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default IconWithText;

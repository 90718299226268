import { useState, useMemo } from 'react';
import { format } from 'date-fns';
import { Button, Divider, Link, Stack, Typography } from '@mui/material';

import { parseAttributes } from 'components/Enrollment/EnrollmentStep/utils';
import ContentBox from 'components/MyAccount/ContentBox';
import ConfirmWithdrawConsentModal from 'components/NHFModal/ConfirmWithdrawConsentModal';
import ParticipationAgreementModal from 'components/NHFModal/ParticipationAgreementModal';
import WithdrawConsentModal from 'components/NHFModal/WithdrawConsentModal';
import usePersonAttributes from 'hooks/usePersonAttributes';

const Consent = () => {
  const [openParticipationAgreementModal, setOpenParticipationAgreementModal] =
    useState(false);
  const [openWithdrawConsentModal, setOpenWithdrawConsentModal] =
    useState(false);
  const [openConfirmWithdrawConsentModal, setOpenConfirmWithdrawConsentModal] =
    useState(false);
  const handleSetOpen = () => {
    setOpenParticipationAgreementModal(true);
  };
  const { attributes, isLoading } = usePersonAttributes();
  const displayDate = useMemo(() => {
    if (isLoading) {
      return '12 jan 2023';
    }
    const values = parseAttributes(attributes);
    const date = new Date(values.consent_date);
    return format(date, 'dd MMM yyyy');
  }, [attributes, isLoading]);
  return (
    <>
      <ContentBox title='Agreement'>
        <Stack spacing={2.5}>
          <Typography
            variant='p'
            color='gray.60'
            sx={{
              fontSize: '0.75rem',
              fontWeight: (theme) => theme.typography.fontWeightLight,
            }}
          >
            Accepted on {displayDate}
          </Typography>
          <Typography
            variant='p'
            color='gray.80'
            sx={{
              fontSize: '1rem',
              fontWeight: (theme) => theme.typography.fontWeightMedium,
            }}
          >
            NBDF’s Community Voices in Research (CVR) Participant Agreement
          </Typography>
          <Button fullWidth variant='outlined' onClick={handleSetOpen}>
            Access Participant Agreement
          </Button>
          <Divider />
          <Typography
            variant='p'
            color='#444'
            sx={{
              fontSize: '0.85rem',
              fontWeight: (theme) => theme.typography.fontWeightLight,
              lineHeight: '150%',
            }}
          >
            You can withdraw your agreement at any time by clicking on "Access
            Participant Agreement" and clicking on "I DO NOT AGREE".
            <br />
            <br />
            You can withdraw by choosing not to participate in registry
            activities any more. This means that the foundation will no longer
            contact you to complete surveys. All of the valuable information you
            have provided so far will remain in the Registry and contribute to
            our research.
            <br />
            <br />
            You can also withdraw from the Registry completely. If you choose to
            do this all of the information you have provided about yourself and
            all surveys that you have completed will be deleted and would no
            longer be available to researchers. Withdrawing in this way means
            that your information will not contribute to future research but it
            would not be possible to remove data from research that has already
            taken place.
            <br />
            <br />
            Please send an email to{' '}
            <Link to='mailto:msantaella@hemophilia.org'>
              msantaella@hemophilia.org
            </Link>{' '}
            requesting that you do not want to participate in CVR. You can state
            that you do not want your data to be used anymore for research,
            resulting in a deletion of all your data.
          </Typography>
        </Stack>
      </ContentBox>
      <ParticipationAgreementModal
        displayDate={displayDate}
        open={openParticipationAgreementModal}
        setOpen={setOpenParticipationAgreementModal}
        setOpenWithdrawConsentModal={setOpenWithdrawConsentModal}
      />
      <WithdrawConsentModal
        displayDate={displayDate}
        open={openWithdrawConsentModal}
        setOpen={setOpenWithdrawConsentModal}
        setOpenConfirmWithdrawConsentModal={setOpenConfirmWithdrawConsentModal}
      />
      <ConfirmWithdrawConsentModal
        open={openConfirmWithdrawConsentModal}
        setOpen={setOpenConfirmWithdrawConsentModal}
        setOpenConfirmWithdrawConsentModal={setOpenConfirmWithdrawConsentModal}
      ></ConfirmWithdrawConsentModal>
    </>
  );
};

export default Consent;

import Section from 'components/DataStory/Section';
import PieChart from 'components/DataStory/BleedingDisorders/PieChart';
const data = {
  hemophiliaA: 50,
  hemophiliaB: 20,
  vonWillebrandDisease: 15,
  ultraRare: 15,
};
const BleedingDisorders = ({ attributes, isLoading }) => (
  <Section
    title='Bleeding Disorders'
    description="Let's see how I compare with the rest of the CVR population:"
  >
    <PieChart
      data={data}
      width={600}
      height={600}
      person={attributes}
      isPersonLoading={isLoading}
    />
  </Section>
);

export default BleedingDisorders;

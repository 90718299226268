import { Link } from 'react-router-dom';
import { Button, Link as MUILink, Stack, Typography } from '@mui/material';

import Container from 'components/Enrollment/Container';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg';
import { ReactComponent as ThankYouIcon } from 'images/thank-you.svg';
import { Layout } from './styles';

const ThankYouForYourInterest = () => (
  <Stack py={{ xs: 0, sm: '50px' }}>
    <Container maxWidth='600px'>
      <Layout>
        <Stack
          spacing='10px'
          alignItems={{ xs: 'stretch', sm: 'center' }}
          flex={1}
        >
          <Stack spacing='10px' alignItems='center' flex={1}>
            <ThankYouIcon />
            <Typography variant='w600h24' color='gray.80' align='center'>
              Thank you for your interest
            </Typography>
            <Typography variant='w400h14' color='gray.80' align='left'>
              <p>At this point, you are not eligible to participate in CVR.</p>
              <p>
                For more information, please visit the National Bleeding
                Disorders Foundation website at{' '}
                <MUILink
                  target='_blank'
                  rel='noopener'
                  href='https://www.hemophilia.org/'
                  variant='w400h14'
                >
                  https://www.hemophilia.org/
                </MUILink>
              </p>
            </Typography>
          </Stack>
          <Button endIcon={<ArrowRightIcon />} component={Link} to='/'>
            Go to Homepage
          </Button>
        </Stack>
      </Layout>
    </Container>
  </Stack>
);

export default ThankYouForYourInterest;

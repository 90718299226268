const test = {
  API_URL: 'https://nhf-cvr-study-test.ihp.iqvia.com/api',
  AUDIENCE: 'https://ihp-nhf-cvr-test.us.auth0.com/api/v2/',
  AUTH0_DOMAIN: 'ihp-nhf-cvr-test.us.auth0.com',
  AUTH0_AUDIENCE: 'https://ihp-nhf-cvr-test.us.auth0.com/mfa/',
  AUTH0_CLIENT_ID: 'wLMqITGsburzofH3gvCIl7JTTHvh90Zt',
  AUTH0_API_URL: 'https://ihp-nhf-cvr-test.us.auth0.com',
  AUTH0_API_CLIENT_ID: 'rEusq6Mym10e8GenvIhbMMP345YEdr99',
  AUTH0_API_CLIENT_SECRET: 'UlHmcpKkGepTmuK3soJWHQjc6pM-AMp8xKz7ACOUbJGXOhmeFhHM3LvWxY4BtoYQ',
  AUTH0_API_AUDIENCE: 'https://nhf-cvr-study-test.ihp.iqvia.com/api/',
  EMAIL_FROM: 'noreply@nhf-cvr-test.ihp.iqvia.com',
  MFA_GRANT_TYPE: 'http://auth0.com/oauth/grant-type',
  RECAPTCHA_PUBLIC_KEY: '6Le-F7kpAAAAAKIIMg3WDVxuWemmhdFaD3Gl1pHk',
  AZURE_STORAGE_BASE_URL: '',
  AZURE_STORAGE_BLOB_TOKEN: '',
  AZURE_STORAGE_BLOB_SAS_URL: '',
};

export default test;

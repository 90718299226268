const createAccount = {
  display: 'wizard',
  navBar: {
    submitLabel: 'continue',
  },
  components: [
    {
      title: 'Page 1',
      key: 'page1',
      type: 'panel',
      input: false,
      tableView: true,
      components: [
        {
          label: 'Password',
          placeholder: 'Enter Password',
          applyMaskOn: 'change',
          tableView: false,
          validate: {
            required: true,
            maxLength: 255,
            custom:
              "valid = input.match(/^(?=\\S*[a-z])(?=\\S*[A-Z])(?=\\S*\\d)(?=\\S*[\\!\\@\\#\\$\\%\\^\\&\\*âêîôû])[A-Za-z\\d\\!\\@\\#\\$\\%\\^\\&\\*âêîôû]{8,}$/g) ? true : 'At least a length of 8 characters, one uppercase, one lowercase, one digit and one special character(e.g. !@#$%^&*) is required.'",
          },
          key: 'password',
          type: 'password',
          input: true,
          protected: true,
        },
        {
          label: 'Confirm Password',
          placeholder: 'Enter Password',
          applyMaskOn: 'change',
          tableView: false,
          validate: {
            required: true,
            custom:
              "valid = (input === data.password) ? true : 'Password does not match'",
          },
          key: 'confirmPassword',
          type: 'password',
          input: true,
          protected: true,
        },
      ],
    },
  ],
};

export default createAccount;

const form = `
  <div
    id="{{ id ?? '' }}"
    class="{{ (classes ?? '').includes('formio-component-form') ? ('formio-enrollment ' + classes) : (classes ?? '') }}"
    {% if (key) { %}
      data-key="{{ key }}"
    {% } %}
    ref="component"
  >
    {% if (visible && children != null) { %}
      {{ children }}
    {% } %}
    <div ref="messageContainer" class="formio-errors invalid-feedback"></div>
  </div>
`;

export default form;

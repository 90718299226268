const formI = {
  title: 'Form I',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'I',
  customConditional:
    "if (form.injectedValues) show = form.injectedValues.R19?.hemophiliaA || form.injectedValues.R19?.hemophiliaB || (form.injectedValues.R19?.vonWillebrandDisease && form.injectedValues.R20 === 'type3');",
  type: 'panel',
  label: 'Form I',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Inhibitors</strong>',
      label: 'Content',
      refreshOnChange: false,
      key: 'I0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'Do you know what an inhibitor is? ',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'I1',
      type: 'radio',
      input: true,
    },
    {
      label: 'In the past 12 months, have you been tested for an inhibitor?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
        {
          label: 'I’m not sure',
          value: 'NS',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'I2',
      type: 'radio',
      input: true,
    },
    {
      label: 'Have you ever been diagnosed with an inhibitor?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
        {
          label: 'I’m not sure',
          value: 'NS',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'I3',
      type: 'radio',
      input: true,
    },
    {
      label: 'What is your current inhibitor status? Choose only one response.',
      widget: 'choicesjs',
      placeholder: 'Select inhibitor status',
      tableView: true,
      data: {
        values: [
          {
            label:
              'Active, tried to tolerize (to get rid of the inhibitor) but failed',
            value: 'Active-F',
          },
          {
            label:
              'Active, currently receiving immune tolerance (scheduled doses of factor to get rid of the inhibitor)',
            value: 'Active-Tx',
          },
          {
            label:
              'Active, never tried to tolerize (never received scheduled doses of factor)',
            value: 'Active-No Tx',
          },
          {
            label:
              'Negative, tolerized successfully (received scheduled doses of factor for a long time and got rid of the inhibitor)',
            value: 'Neg-Tol',
          },
          {
            label:
              'Negative, the inhibitor went away on its own without any treatment (also known as transient inhibitors)',
            value: 'Neg-Transient',
          },
          {
            label: 'I’m not sure',
            value: 'NS',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'I4',
      conditional: {
        show: false,
        when: 'I3',
        eq: 'No',
      },
      type: 'select',
      input: true,
    },
    {
      label:
        'Aside from factor, which medications have you or did you receive to decrease your body’s immune response? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'Cellcept',
          value: 'Cellcept',
          shortcut: '',
        },
        {
          label: 'Prednisone',
          value: 'Prednisone',
          shortcut: '',
        },
        {
          label: 'Rituxan',
          value: 'Rituxan',
          shortcut: '',
        },
        {
          label: 'None of the above',
          value: 'noneOfTheAbove',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: "result = data['I5']?.noneOfTheAbove;",
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(value).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {});",
            },
          ],
        },
      ],
      key: 'I5',
      customConditional:
        'const option = ["Active-F", "Active-Tx", "Neg-Tol"];\nshow = data.I3 === \'Yes\'&& option.includes(data.I4);',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        Cellcept: false,
        Prednisone: false,
        Rituxan: false,
        noneOfTheAbove: false,
        other: false,
      },
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'I5_other',
      customConditional: 'show = data.I5?.other',
      type: 'textfield',
      input: true,
    },
    {
      label: 'I6',
      tableView: false,
      key: 'I6',
      conditional: {
        show: true,
        when: 'I3',
        eq: 'Yes',
      },
      type: 'container',
      input: true,
      components: [
        {
          label:
            'Please select any medication(s) you are currently taking or prescribed to treat/prevent bleeding in the presence of inhibitors. Choose all that apply.',
          optionsLabelPosition: 'right',
          tableView: true,
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            Alpha9: false,
            Alprolix: false,
            Benefix: false,
            Idelvion: false,
            Ixinity: false,
            Mononine: false,
            Rebinyn: false,
            Rixubis: false,
          },
          values: [
            {
              label: 'FEIBA',
              value: 'FEIBA',
              shortcut: '',
            },
            {
              label: 'Hemlibra (emicizumab)',
              value: 'Hemlibra',
              shortcut: '',
            },
            {
              label: 'NovoSeven RT',
              value: 'Novo7_RT',
              shortcut: '',
            },
            {
              label: 'SevenFACT',
              value: 'SevenFACT',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          validate: {
            required: true,
          },
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      label:
        'True or False: Experts recommend that all people with hemophilia or VWD type 3 who infuse with factor should be tested for inhibitors at least once a year.',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'True',
          value: 'True',
          shortcut: '',
        },
        {
          label: 'False',
          value: 'False',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'I7',
      type: 'radio',
      input: true,
    },
  ],
};

export default formI;

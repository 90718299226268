import { Box, Container, Grid } from '@mui/material';

import {
  BleedingDisorderCareProvider,
  Condition,
  DateOfBirth,
  Diagnoses,
  Medications,
  Name,
} from './Items';

const Content = ({ print }) => (
  <Container variant={print ? 'transparent' : 'outlined'}>
    <Box p={print ? 0 : '40px'}>
      <Grid
        container
        rowSpacing={{ xs: '10px', sm: '30px' }}
        columnSpacing='16px'
      >
        <Name print={print} />
        <DateOfBirth print={print} />
        <Diagnoses print={print} />
        <BleedingDisorderCareProvider print={print} />
        <Medications print={print} />
        <Condition print={print} />
      </Grid>
    </Box>
  </Container>
);

export default Content;

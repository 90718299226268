const formPI = {
  title: 'Form PI',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'PI',
  customConditional:
    'const allow = [2, "2", 3, "3", 4, "4", 5, "5"]; show = allow.includes(data?.PN1) || allow.includes(data?.PN2_PN3?.PN2) || allow.includes(data?.PN2_PN3?.PN3);',
  type: 'panel',
  label: 'Form PI',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Pain Intensity</strong><br/>The following questions ask you about your pain intensity (NOT related to menstrual pain) in the past 7 days.',
      label: 'Content',
      customConditional: "show = form?.injectedValues?.R8 !== 'male'",
      refreshOnChange: false,
      key: 'PI0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      html: '<strong>Pain Intensity</strong><br/>The following questions ask you about your pain intensity in the past 7 days.',
      label: 'Content',
      customConditional: "show = form?.injectedValues?.R8 === 'male'",
      refreshOnChange: false,
      key: 'PI0_2',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'PI1_PI2',
      hideLabel: true,
      tableView: false,
      questions: [
        {
          label: 'In the past 7 days, how intense was your pain at its worst?',
          value: 'PI1',
          tooltip: '',
        },
        {
          label: 'In the past 7 days, how intense was your average pain?',
          value: 'PI2',
          tooltip: '',
        },
      ],
      values: [
        {
          label: 'Had no pain',
          value: '1',
          tooltip: '',
        },
        {
          label: 'Mild',
          value: '2',
          tooltip: '',
        },
        {
          label: 'Moderate',
          value: '3',
          tooltip: '',
        },
        {
          label: 'Severe',
          value: '4',
          tooltip: '',
        },
        {
          label: 'Very severe',
          value: '5',
          tooltip: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'PI1_PI2',
      type: 'survey',
      input: true,
    },
  ],
};

export default formPI;

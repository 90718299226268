import { useMemo } from 'react';

import { Skeleton } from '@mui/material';

import Item from 'components/MyHealthSummary/Content/Items/Item';
import useGetBaseline from 'hooks/useGetBaseline';

import { parseConditions } from './utils';

const skeletonList = [
  { id: 1, description: <Skeleton width='210px' /> },
  { id: 2, description: <Skeleton width='260px' /> },
  { id: 3, description: <Skeleton width='180px' /> },
];

const Condition = (props) => {
  const { data, isLoading } = useGetBaseline();
  const conditionList = useMemo(() => parseConditions(data), [data]);

  return (
    <Item
      label='Condition'
      content={
        isLoading
          ? skeletonList
          : conditionList.length === 0
            ? '--'
            : conditionList
      }
      {...props}
    />
  );
};

export default Condition;

import { createApi } from '@reduxjs/toolkit/query/react';
import auth0APIQuery from './baseQuery';

const auth0Api = createApi({
  reducerPath: 'auth0Api',
  baseQuery: auth0APIQuery(),
  endpoints: () => ({}),
  tagTypes: ['MFAAuthenticators'],
});

export default auth0Api;

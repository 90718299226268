import { Box, styled } from '@mui/material';

const Layout = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  margin: '0 20px 0px 20px',
  padding: '0 0 20px 0',
  [theme.breakpoints.up('sm')]: {
    borderRadius: '8px',
    margin: '20px 30px 30px 30px',
    padding: '25px 60px',
    backgroundColor: theme.palette.background.default,
  },
}));

export default Layout;

export const myEducationalResources = [
  {
    id: 1,
    title: 'Steps for Living',
    description:
      'Steps for Living is a go-to online resource for bleeding disorders education presented by life stages - its resources and tools are divided by age groups.',
    url: 'https://stepsforliving.hemophilia.org/',
    spanish: true,
  },
  {
    id: 2,
    title: 'Clotting Cascade',
    description:
      'This video series is full of information about how the body works to stop bleeding and how different treatments work for bleeding disorders.',
    url: 'https://www.hemophilia.org/educational-programs/education/clotting-cascade',
    spanish: false,
  },
  {
    id: 3,
    title: 'MASAC for You',
    description:
      'These resources are designed to take the important medical information from the Medical and Scientific Advisory Council (MASAC) Guidelines and explain it in simple language. They are available in both English and Spanish.',
    url: 'https://www.hemophilia.org/bleeding-disorders-a-z/treatment/masac-for-you',
    spanish: true,
  },
  {
    id: 4,
    title: 'Current Treatments',
    description:
      'Learn about the many different types of therapies for bleeding disorders, and some of the new ones in development.',
    url: 'https://www.hemophilia.org/bleeding-disorders-a-z/treatment/current-treatments',
    spanish: false,
  },
  {
    id: 5,
    title: 'Future Treatments',
    description:
      'Learn about the several new medicines, or therapies, currently being explored for the treatment of bleeding disorders – most of them are still under investigation.',
    url: 'https://www.hemophilia.org/bleeding-disorders-a-z/treatment/future-therapies',
    spanish: false,
  },
  {
    id: 6,
    title: 'HANDI',
    description:
      'Fulfills information requests, provides quality educational publications, and makes referrals to additional sources of assistance. HANDI processes thousands of requests from anyone interested in learning about any topic related to inheritable bleeding disorders.',
    url: 'https://www.hemophilia.org/community-resources/request-information/handi-nbdfs-information-resource-center',
    spanish: false,
  },
  {
    id: 7,
    title: 'NBDF publications',
    description:
      'Access the many NBDF publications, brochures and tools developed in various topics for immediate download or, if available in print, for mail order.',
    url: 'https://www.hemophilia.org/community-resources/request-information/read-our-publications',
    spanish: false,
  },
  {
    id: 8,
    title: 'Wednesday Webinar Series',
    description:
      'Is a free education series open to anyone interested in learning more about inheritable bleeding disorders. Browse through past recordings or sign up to attend future webinars focused on the latest in research, breakthrough developments, and more.',
    url: 'https://www.hemophilia.org/community-resources/request-information/wednesday-webinar-series',
    spanish: false,
  },
];

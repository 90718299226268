import { Box, Grid } from '@mui/material';

import { LinkCard } from 'components/Cards';
import { GridPageLayout } from 'components/Layouts';
import { ReactComponent as SpanishBadge } from 'images/spanish-badge.svg';
import { myEducationalResources } from 'mocks/myEducationalResources';

const MyEducationalResources = () => {
  return (
    <GridPageLayout
      title='My Educational Resources'
      description={
        <span>
          Welcome to our guide on bleeding disorders. Through easy-to-understand
          articles and links, we'll explore different aspects of these
          conditions. Whether you're someone with a bleeding disorder or a
          family member, we're here to help you understand more about it. Learn
          about what causes bleeding disorders, their symptoms, how they're
          diagnosed, and how they can be managed. We'll also talk about the
          latest research and treatments.
          <br />
          <br />
          Our goal is to provide information that's easy to understand and
          reliable. Feel free to explore and learn. Let's come together to
          support each other and raise awareness about bleeding disorders.
          <br />
          <br />
          Resources available in Spanish. Recursos disponibles en Español.
          <Box
            ml='15px'
            sx={{ display: 'inline-flex', verticalAlign: 'middle' }}
          >
            <SpanishBadge />
          </Box>
        </span>
      }
    >
      {myEducationalResources.map(({ id, spanish, ...data }) => (
        <Grid key={id} item xs={12} xl={6}>
          <LinkCard chipText={spanish && 'DISPONIBLE EN ESPAÑOL'} {...data} />
        </Grid>
      ))}
    </GridPageLayout>
  );
};

export default MyEducationalResources;

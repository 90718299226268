const impactOfPainData = {
  hemophiliaA: {
    couldNotGetOutOfBed: {
      often: 8,
      sometimes: 19,
      always: 0,
      rarely: 18,
      never: 55,
    },
    itWasHardToFinishWhatYouWereDoing: {
      often: 15,
      sometimes: 30,
      always: 1,
      rarely: 17,
      never: 37,
    },
    couldNotDoAnythingForAWholeDay: {
      often: 12,
      sometimes: 24,
      always: 1,
      rarely: 16,
      never: 47,
    },
    hadToStopWhatYouWereDoing: {
      often: 15,
      sometimes: 27,
      always: 2,
      rarely: 19,
      never: 37,
    },
  },
  hemophiliaB: {
    couldNotGetOutOfBed: {
      often: 10,
      sometimes: 36,
      always: 2,
      rarely: 16,
      never: 35,
    },
    itWasHardToFinishWhatYouWereDoing: {
      often: 19,
      sometimes: 45,
      always: 2,
      rarely: 16,
      never: 18,
    },
    couldNotDoAnythingForAWholeDay: {
      often: 23,
      sometimes: 29,
      always: 1,
      rarely: 18,
      never: 29,
    },
    hadToStopWhatYouWereDoing: {
      often: 29,
      sometimes: 35,
      always: 1,
      rarely: 15,
      never: 20,
    },
  },
  vonWillebrandDisease: {
    couldNotGetOutOfBed: {
      often: 8,
      sometimes: 18,
      always: 1,
      rarely: 19,
      never: 53,
    },
    itWasHardToFinishWhatYouWereDoing: {
      often: 18,
      sometimes: 31,
      always: 4,
      rarely: 15,
      never: 32,
    },
    couldNotDoAnythingForAWholeDay: {
      often: 11,
      sometimes: 23,
      always: 1,
      rarely: 25,
      never: 40,
    },
    hadToStopWhatYouWereDoing: {
      often: 11,
      sometimes: 38,
      always: 4,
      rarely: 18,
      never: 29,
    },
  },
  ultraRareBleedingDisorders: {
    couldNotGetOutOfBed: {
      often: 5,
      sometimes: 15,
      always: 3,
      rarely: 36,
      never: 41,
    },
    itWasHardToFinishWhatYouWereDoing: {
      often: 21,
      sometimes: 31,
      always: 2,
      rarely: 23,
      never: 23,
    },
    couldNotDoAnythingForAWholeDay: {
      often: 8,
      sometimes: 18,
      always: 2,
      rarely: 36,
      never: 36,
    },
    hadToStopWhatYouWereDoing: {
      often: 16,
      sometimes: 28,
      always: 7,
      rarely: 24,
      never: 25,
    },
  },
};

export default impactOfPainData;

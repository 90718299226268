import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Chip,
} from '@mui/material';

import { ReactComponent as ChevronRight } from 'images/chevron-right.svg';

const Section = ({
  title,
  to,
  children,
  disabled,
  last = false,
  isCommingSoon = false,
  ...rest
}) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Container variant={sm ? 'outlined' : 'transparent'} {...rest}>
      <Stack spacing='16px' p={{ xs: '0', sm: '20px' }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            variant='w700h18'
            color='gray.100'
            noWrap
            sx={{ textTransform: 'uppercase' }}
          >
            {title}
          </Typography>
          {isCommingSoon && <Chip label='Coming soon' variant='outlined' />}
          {!disabled && to && (
            <Button
              variant='text'
              component={Link}
              to={to}
              endIcon={<ChevronRight />}
            >
              View all
            </Button>
          )}
        </Stack>
        {children}
        {!sm && !last && (
          <Box py='4px'>
            <Divider />
          </Box>
        )}
      </Stack>
    </Container>
  );
};

export default Section;

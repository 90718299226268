import { forwardRef } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import { RECAPTCHA_PUBLIC_KEY } from 'utils/constants';

const Captcha = forwardRef((props, ref) => (
  <ReCAPTCHA ref={ref} sitekey={RECAPTCHA_PUBLIC_KEY} {...props} />
));

export default Captcha;

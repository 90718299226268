const MuiButton = {
  defaultProps: {
    disableElevation: true,
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      borderRadius: '4px',
      whiteSpace: 'nowrap',
      minWidth: 'auto',
    },
  },
  variants: [
    {
      props: { size: 'small' },
      style: ({ theme }) => ({
        ...theme.typography.w500h12,
        height: '32px',
      }),
    },
    {
      props: { size: 'medium' },
      style: ({ theme }) => ({
        ...theme.typography.w700h14,
        height: '46px',
        textTransform: 'uppercase',
      }),
    },
    {
      props: { size: 'large' },
      style: ({ theme }) => ({
        ...theme.typography.w700h14,
        height: '50px',
        textTransform: 'uppercase',
      }),
    },
    {
      props: { variant: 'contained' },
      style: ({ theme }) => ({
        color: `${theme.palette.white} !important`,
      }),
    },
    {
      props: { variant: 'outlined' },
      style: ({ theme }) => ({
        color: theme.palette.gray[80],
        borderColor: theme.palette.gray[20],
        background: theme.palette.background.paper,
      }),
    },
    {
      props: { variant: 'text' },
      style: ({ theme }) => ({
        color: theme.palette.primary.main,
        textTransform: 'none',
        height: 'unset',
        padding: 0,
        ':hover': {
          background: 'none',
        },
      }),
    },
  ],
};

export default MuiButton;

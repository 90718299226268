const formG = {
  title: 'Form G',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'G',
  type: 'panel',
  label: 'Form G',
  customConditional:
    'if (form.injectedValues) show = form.injectedValues?.R19?.hemophiliaA || form.injectedValues?.R19?.hemophiliaB;',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Gene Therapy</strong></br/>Gene therapy is a new way to treat genetic diseases like hemophilia. It gives people with hemophilia working copies of the factor VIII (8) or factor IX (9) genes to fix the abnormal propensity to bleed. There are different methods to do gene therapy, like gene transfer and gene editing.',
      label: 'Content',
      refreshOnChange: false,
      key: 'G0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'Have you undergone gene therapy to treat your bleeding disorder?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'G1',
      type: 'radio',
      input: true,
    },
    {
      label: 'What was the estimated date of the infusion?',
      format: 'MM/yyyy',
      tableView: false,
      datePicker: {
        disableWeekends: false,
        disableWeekdays: false,
      },
      enableTime: false,
      validate: {
        required: true,
      },
      enableMinDateInput: false,
      enableMaxDateInput: false,
      key: 'G2',
      conditional: {
        show: true,
        when: 'G1',
        eq: 'Yes',
      },
      type: 'datetime',
      input: true,
      widget: {
        type: 'calendar',
        displayInTimezone: 'viewer',
        locale: 'en',
        useLocaleSettings: false,
        allowInput: true,
        mode: 'single',
        enableTime: false,
        noCalendar: false,
        format: 'MM/yyyy',
        hourIncrement: 1,
        minuteIncrement: 1,
        time_24hr: false,
        minDate: null,
        disableWeekends: false,
        disableWeekdays: false,
        maxDate: null,
      },
    },
    {
      label: 'What product did you receive?',
      widget: 'choicesjs',
      tableView: true,
      data: {
        values: [
          {
            label: 'Entranacogene dezaparvovec-drib (Hemgenix)',
            value: 'Hemgenix',
          },
          {
            label: 'Valoctocogene Roxaparvovec (AAV5-hfviii-SQ) (Roctavian)',
            value: 'Roctavian',
          },
          {
            label: 'Other',
            value: 'other',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'G3',
      conditional: {
        show: true,
        when: 'G1',
        eq: 'Yes',
      },
      type: 'select',
      input: true,
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'G3_other',
      conditional: {
        show: true,
        when: 'G3',
        eq: 'other',
      },
      type: 'textfield',
      input: true,
    },
    {
      label:
        'Would you consider gene therapy as a treatment option for your bleeding disorder?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
        {
          label: 'I don’t know',
          value: 'DK',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'G4',
      conditional: {
        show: true,
        when: 'G1',
        eq: 'No',
      },
      type: 'radio',
      input: true,
    },
    {
      label:
        'Why would you not consider gene therapy as a treatment option? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          value: 'Not_heard',
          label: 'I have never heard of it',
        },
        {
          value: 'DK_much',
          label: "I don't know much about it",
        },
        {
          value: 'Religious',
          label: 'For religious reasons',
        },
        {
          value: 'Ethical',
          label: 'For ethical reasons',
        },
        {
          value: 'Not_offered',
          label: 'It has not been offered to me as a treatment option',
        },
        {
          value: 'Not_eligible',
          label: 'My provider told me that I am not eligible',
        },
        {
          value: 'Clinic_not_prep',
          label: 'My clinic does not do the infusions',
        },
        {
          value: 'Fam_against',
          label: 'My family or friends have advised me against it',
        },
        {
          value: 'Happy_cur_tx',
          label: 'I am happy with my current treatment',
        },
        {
          value: 'Response',
          label: 'I am concerned that I will not get a good response',
        },
        {
          value: 'Side_eff',
          label: 'I am concerned about the potential side effects',
        },
        {
          value: 'Wait_see',
          label: 'I want to wait and see what happens with others who get it',
        },
        {
          value: 'Wait_oth',
          label:
            'I want to wait for other gene therapy options which could be better',
        },
        {
          value: 'Early',
          label:
            'It is too early to know all the risks and long-term effects of gene therapy',
        },
        {
          value: 'Mild_BD',
          label: "My bleeding is mild and therefore it's not worth doing it",
        },
        {
          value: 'Expense',
          label: 'It is too expensive',
        },
        {
          value: 'Insurance',
          label: 'My insurance does not cover it',
        },
        {
          value: 'other',
          label: 'Other',
        },
      ],
      validate: {
        required: true,
      },
      key: 'G5',
      customConditional: 'show = data?.G4 === "No" || data?.G4 === "DK";',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'G5_other',
      customConditional: 'show = !!data.G5?.other;',
      type: 'textfield',
      input: true,
    },
  ],
};

export default formG;

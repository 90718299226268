const formPM = {
  title: 'Form PM',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'PM',
  customConditional:
    'const allow = [2, "2", 3, "3", 4, "4", 5, "5"]; show = allow.includes(data?.PI1_PI2?.PI1) || allow.includes(data?.PI1_PI2?.PI2);',
  type: 'panel',
  label: 'Form PM',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Pain Impact</strong><br/>The following questions will ask you about how pain has impacted your activities during the past 7 days.',
      label: 'Content',
      refreshOnChange: false,
      key: 'PM0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'In the past 7 days how often did you have...',
      tableView: false,
      questions: [
        {
          label: 'Pain so bad that you could not do anything for a whole day?',
          value: 'PM1',
          tooltip: '',
        },
        {
          label: 'Pain so bad that you could not get out of bed?',
          value: 'PM2',
          tooltip: '',
        },
        {
          label: 'Very severe pain?',
          value: 'PM3',
          tooltip: '',
        },
        {
          label: 'Pain so bad that you had to stop what you were doing?',
          value: 'PM4',
          tooltip: '',
        },
        {
          label: 'Pain so bad that it was hard to finish what you were doing?',
          value: 'PM5',
          tooltip: '',
        },
      ],
      values: [
        {
          label: 'Never',
          value: '1',
          tooltip: '',
        },
        {
          label: 'Rarely',
          value: '2',
          tooltip: '',
        },
        {
          label: 'Sometimes',
          value: '3',
          tooltip: '',
        },
        {
          label: 'Often',
          value: '4',
          tooltip: '',
        },
        {
          label: 'Always',
          value: '5',
          tooltip: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'PM1_PM5',
      type: 'survey',
      input: true,
    },
  ],
};

export default formPM;

import { useState } from 'react';

import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { useAuth } from 'context/AuthContext';
import usePersonAttributes from 'hooks/usePersonAttributes';
import { ReactComponent as CheckIcon } from 'images/successful.svg';
import { useUpdatePersonMutation } from 'store/api/nhf/endpoints/people';
import { openSnackbar } from 'store/slices/application';
import { PersonStatus, PersonStatusReason, SnackbarError } from 'utils/enums';

const Layout = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));
const ConfirmWithdrawConsentModal = ({ open, setOpen }) => {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const [consent_optout_message, setConsentOptOutMessage] = useState('');
  const rejectStatus = {
    status: PersonStatus.Withdrawn,
    statusReason: PersonStatusReason.DeclinedConsent,
  };
  const [updatePerson] = useUpdatePersonMutation();
  const { personId, upsertAttributes } = usePersonAttributes();
  const useUpdateStatus =
    ({ attributes, onError, onSuccess }) =>
    async () => {
      try {
        await upsertAttributes({
          consent_date: new Date(),
          consent_optout_message,
        });
        const { error } = await updatePerson({
          personId: personId.toString(),
          attributes,
        });
        if (error) return onError?.();
      } catch {
        return onError?.();
      }
      onSuccess?.();
    };
  const onError = () => dispatch(openSnackbar(SnackbarError.TryAgain()));
  const handleReject = useUpdateStatus({
    attributes: rejectStatus,
    onError,
    onSuccess: logout,
  });
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
      PaperProps={{
        sx: {
          width: '100%',
          minHeight: 550,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        Withdraw Consent
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Layout>
          <Stack
            spacing='10px'
            alignItems={{ xs: 'stretch', sm: 'center' }}
            flex={1}
          >
            <CheckIcon />
            <Typography variant='w600h24' color='gray.80' align='center'>
              You've have been withdrawn
            </Typography>
            <Typography variant='w400h14' color='gray.80' align='center'>
              <p>Please let us now why you withdrew from this study</p>
            </Typography>
            <TextField
              placeholder='Type here...'
              variant='outlined'
              style={{ width: '550px', height: '60px' }}
              rows={5}
              multiline
              onChange={(e) => {
                setConsentOptOutMessage(e.target.value);
              }}
            />
          </Stack>
        </Layout>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleReject}>Save and Logout</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmWithdrawConsentModal;

import { forwardRef } from 'react';

import { Grid, Stack } from '@mui/material';

import BreadCrumb from './BreadCrumb';
import Header from './Header';

const GridPageLayout = forwardRef(
  (
    { title, description, buttons, showBreadCrumb = true, print, children },
    ref,
  ) => (
    <Stack
      ref={ref}
      alignItems='center'
      py={{ xs: '20px', sm: '46px' }}
      px={{ xs: '20px', sm: '20px', md: '40px', lg: '40px', xl: '80px' }}
    >
      <Grid
        container
        rowSpacing={{ xs: '10px', sm: '30px' }}
        columnSpacing='16px'
        maxWidth={{ xs: '800px', lg: '1312px' }}
      >
        {!print && showBreadCrumb && (
          <Grid item xs={12}>
            <BreadCrumb path={title} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Header
            title={title}
            description={description}
            buttons={!print && buttons}
          />
        </Grid>
        {children}
      </Grid>
    </Stack>
  ),
);

export default GridPageLayout;

var count = 0;

function uid(name) {
  return new Id('O-' + (name == null ? '' : name + '-') + ++count);
}

function Id(id) {
  this.id = id;
  this.href = new URL(`#${id}`, window.location.href) + '';
}

Id.prototype.toString = function () {
  return 'url(' + this.href + ')';
};

export default uid;

import { Divider, Stack, Typography } from '@mui/material';

import Section from 'components/Dashboard/Section';
const Card = (props) => {
  return (
    <Section title={props.title} {...props}>
      <Stack pt='10px'>
        <Stack spacing='5px' height='115px'>
          <Divider />
          <Stack spacing='5px' flex={1} justifyContent='center'>
            <Typography variant='w600h16' color='gray.100'>
              {props.subtitle}
            </Typography>
            <Typography variant='w400h14' color='gray.80'>
              {props.description}
            </Typography>
          </Stack>
          {props.children}
        </Stack>
      </Stack>
    </Section>
  );
};

export default Card;

const black = '#000000';

// ??
const camelot = {
  10: '#F3E9EE',
  100: '#872651',
};

// ??
const chestnutRose = {
  10: '#FBEDED',
  100: '#D24f4e',
};

// grape
const honeyFlower = {
  10: '#EFE9F0',
  100: '#5f2167',
};

// navy blue
const nbdfBlue = {
  10: '#E9E7EF',
  100: '#250e62',
};

// ??
const orient = {
  10: '#E6EFF3',
  100: '#005f86',
};

// Turquoise
const pacificBlue = {
  10: '#E6F7F8',
  100: '#00b0b9',
};

// Melon
const salmon = {
  10: '#FFF4F0',
  100: '#FF8D6D',
};

// cardinal
const tamarillo = {
  10: '#F6E8EA',
  100: '#A6192E',
};

const gray = {
  0: '#F9F9F9', // 1
  10: '#EBEBEB', // 3
  20: '#D6D6D6',
  40: '#ADADAD',
  60: '#848484', // 50
  80: '#5B5B5B', // 70
  100: '#323232', // 8 and 90
};

const mustard = {
  100: '#EEA536',
};

const turquoise = {
  100: '#00ACBB',
  10: '#E5F7F8',
};

const white = '#FFFFFF';

const palette = {
  primary: {
    main: nbdfBlue[100],
  },
  background: {
    paper: white,
    default: gray[0],
  },
  black,
  camelot,
  chestnutRose,
  gray,
  honeyFlower,
  nbdfBlue,
  orient,
  pacificBlue,
  salmon,
  tamarillo,
  white,
  mustard,
  turquoise,
};

export default palette;

import { Button, Stack } from '@mui/material';

const ButtonBar = ({
  onAgree,
  onReject,
  firstButtonText,
  secondButtonText,
  secondButtonIcon,
}) => (
  <Stack
    direction={{ xs: 'column-reverse', sm: 'row' }}
    justifyContent='space-between'
    spacing='10px'
    mt='30px'
    mb='40px'
    mx={{ xs: '20px', sm: '50px' }}
  >
    <Button variant='outlined' onClick={onReject}>
      {firstButtonText}
    </Button>
    <Button onClick={onAgree} endIcon={secondButtonIcon}>
      {secondButtonText}
    </Button>
  </Stack>
);

export default ButtonBar;

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import LoginProcessLayout from 'components/LoginProcessLayout';
import { useAuth } from 'context/AuthContext';
import { ReactComponent as EnvelopeIcon } from 'images/envelope.svg';
import { openSnackbar } from 'store/slices/application';
import {
  useCurrentUserQuery,
  useResendVerificationEmailMutation,
} from 'store/api/nhf/endpoints/users';
import useCountdown from 'hooks/useCountdown';

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const { userInfo, isLoading: isAuthLoading } = useAuth();
  const { timeLeft, setTimeLeft } = useCountdown({ timer: 30 });
  const { data: user, isLoading: isUserLoading } = useCurrentUserQuery(
    {},
    { skip: !userInfo }
  );
  const [resendVerificationEmail] = useResendVerificationEmailMutation();
  const isLoading = isAuthLoading || isUserLoading;

  const handleResendVerificationEmail = async () => {
    setTimeLeft(30);
    const resendEmail = await resendVerificationEmail({
      userId: user.id,
    });

    if (!!resendEmail.error) {
      return dispatch(
        openSnackbar({
          type: 'error',
          message: 'Could not resend verification. Please try again later',
        })
      );
    }
    return dispatch(
      openSnackbar({
        type: 'success',
        message: `Verification email successfully resent to: ${userInfo.email}`,
      })
    );
  };

  if (isLoading) return <CircularProgress />;

  return (
    <LoginProcessLayout title='Please verify your email address!'>
      <Stack p={{ xs: '10px 20px 20px 20px', sm: '10px 30px 30px 30px' }}>
        <Stack alignItems='center' justifyContent='center' py='20px'>
          <EnvelopeIcon />
        </Stack>
        <Typography variant='w400h14' color='gray.100'>
          <p>
            We sent an email to <b>{userInfo?.email}</b>
          </p>
          <p>
            Once you receive it, click on the link provided to complete your
            registration. If you don’t see it, you may need to{' '}
            <b>check your spam</b> folder.
          </p>
          <p>Once verified, you will be able to continue.</p>
          <p>It may take up to 5 minutes to receive the email.</p>
        </Typography>
        <Divider />

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'stretch', sm: 'center' }}
          justifyContent='space-between'
          spacing='20px'
          py='20px'
        >
          <Typography variant='w400h14' color='gray.100' align='center'>
            Still can’t find the email?{' '}
            {timeLeft > 0 &&
              `You can retry in 00:${timeLeft.toString().padStart(2, '0')}`}
          </Typography>
          <Typography
            variant='w400h14'
            color='gray.100'
            align='center'
          ></Typography>
          {timeLeft <= 0 && (
            <Button onClick={handleResendVerificationEmail} variant='outlined'>
              Resend Email
            </Button>
          )}
        </Stack>

        <Typography variant='w400h14' color='gray.100' align='center'>
          Need help?{' '}
          <Link to='/contact'>
            <Typography variant='w600h14' color='gray.100'>
              Contact Us
            </Typography>
          </Link>
        </Typography>
      </Stack>
    </LoginProcessLayout>
  );
};

export default VerifyEmail;

import NHFModal from 'components/NHFModal';

import changePassword from 'forms/changePassword';
import FormIO from 'components/FormIO';
import { openSnackbar } from 'store/slices/application';
import { SnackbarError, SnackbarSuccess } from 'utils/enums';
import { useAuth } from 'context/AuthContext';
import { useSetNewPasswordMutation } from 'store/api/auth0/endpoints';
import useManagementApi from 'hooks/useManagement';
import { useDispatch } from 'react-redux';
import {
  useChangeUserPasswordMutation,
  useCurrentUserQuery,
} from 'store/api/nhf/endpoints/users';
import { Box, CircularProgress } from '@mui/material';

const ChangePasswordModal = ({ open, setOpen }) => {
  const { data: user, isLoading: isUserLoading } = useCurrentUserQuery();
  const [changeUserPassword, { isLoading: isChangingPassword }] =
    useChangeUserPasswordMutation();
  const dispatch = useDispatch();

  const onSubmit = async ({ currentPassword: old_password, password }) => {
    console.log(user);
    const { error } = await changeUserPassword({
      userId: user?.id,
      body: {
        old_password,
        password,
        password_confirmation: password,
      },
    });

    if (error) {
      return dispatch(
        openSnackbar({
          ...SnackbarError.WrongCurrentPassword(),
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      );
    }

    dispatch(
      openSnackbar({
        ...SnackbarSuccess.PasswordChanged(),
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    );
    setOpen(false);
  };

  const isConfirmLoading = isUserLoading;

  return (
    <NHFModal
      title='Change Password'
      open={open}
      setOpen={setOpen}
      useFormioButtons={true}
    >
      {isConfirmLoading ? (
        <Box display='flex' justifyContent='center'>
          <CircularProgress sx={{ ml: 1 }} size={16} />
        </Box>
      ) : (
        <FormIO
          form={changePassword}
          onSubmit={onSubmit}
          unlockSubmit={true}
          onSave={() => {
            setOpen(false);
          }}
          template='enrollment'
        />
      )}
    </NHFModal>
  );
};

export default ChangePasswordModal;

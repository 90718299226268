import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItem, useMediaQuery, useTheme } from '@mui/material';

import { useAuth } from 'context/AuthContext';
import LogoFull from 'images/logo/CVR-logo-full.png';
import LogoMini from 'images/logo/CVR-logo-mini.png';
import { Container } from './styles';
import User from './User';

const Header = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const theme = useTheme();
  const location = useLocation();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { isAuthenticated } = useAuth();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <Container>
      {sm ? (
        <img src={LogoMini} alt='CVR Logo' width='37px' />
      ) : (
        <img src={LogoFull} alt='CVR Logo' width='370px' />
      )}
      {location.pathname === '/verify-2fa' && (
        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
      )}
      {isAuthenticated && <User />}
    </Container>
  );
};

export default Header;

import { Link } from 'react-router-dom';
import { Button, Grid, Stack } from '@mui/material';

import TitleWithSubtitle from 'components/TitleWithSubtitle';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg';
import getStartedBackground from 'images/SplashPage/backgrounds/get-started-background-image.png';

const GetStarted = () => (
  <Grid
    container
    sx={{
      height: '535px',
      backgroundImage: `url(${getStartedBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      marginY: { xs: 0, md: 10 },
      direction: 'column-reverse',
    }}
  >
    <Grid item xs={12} md={4} order={{ xs: 2, md: 1 }} />
    <Grid item xs={12} md={8} order={{ xs: 1, md: 2 }}>
      <Stack
        justifyContent='center'
        alignItems='center'
        minHeight='100%'
        spacing='30px'
      >
        <TitleWithSubtitle
          title='Get started'
          subtitle='​Answer a few questions to see if you are eligible to join!'
        />
        <Button component={Link} endIcon={<ArrowRightIcon />} to='/join'>
          Join
        </Button>
      </Stack>
    </Grid>
  </Grid>
);

export default GetStarted;

import fileIcon from 'images/SplashPage/icons/file.svg';
import healthIcon from 'images/SplashPage/icons/health.svg';
import idIcon from 'images/SplashPage/icons/id.svg';
import laptopIcon from 'images/SplashPage/icons/laptop.svg';

const SplashIcons = {
  FileIcon: fileIcon,
  HealthIcon: healthIcon,
  IdIcon: idIcon,
  LaptopIcon: laptopIcon,
};

export default SplashIcons;

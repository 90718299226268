import { Box } from '@mui/material';

import FormIO from 'components/FormIO2';
import Container from 'components/Surveys/Container';
import enrollment from 'forms/enrollment';

const defaultValues = {};

const FormioTest4 = () => {
  const handleSave = (...args) => console.log(args);
  const handleSubmit = (...args) => console.log(args);

  return (
    <Box m={{ xs: '0', sm: '50px' }}>
      <Container maxWidth='800px' m='50px'>
        <FormIO
          form={enrollment}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          onSave={handleSave}
          template='enrollment'
          unlockSubmit={true}
        />
      </Container>
    </Box>
  );
};

export default FormioTest4;

const formIntro = {
  title: 'Form Intro',
  label: 'Form Intro',
  type: 'panel',
  key: 'Intro',
  input: false,
  tableView: false,
  components: [
    {
      html: `<div style="font: var(--typography-w600h24); color: var(--palette-gray-100)">
      Baseline survey
    </div>
    <br />
    <div
      style="
        background-color: var(--palette-gray-10);
        padding: 15px 30px 15px 30px;
        border-radius: 5px;
        gap: 40px;
        display: flex;
      "
    >
      <div style="flex: 1; display: flex; flex-direction: column; gap: 3px">
        <div style="font: var(--typography-w400h12); color: var(--palette-gray-60)">
          Task type
        </div>
        <div style="display: flex; gap: 8px">
          <div
            style="font: var(--typography-w600h16); color: var(--palette-gray-100)"
          >
            Survey
          </div>
        </div>
      </div>
      <div style="flex: 1; display: flex; flex-direction: column; gap: 3px">
        <div style="font: var(--typography-w400h12); color: var(--palette-gray-60)">
          Estimated time
        </div>
        <div style="display: flex; gap: 8px">
          <div
            style="font: var(--typography-w600h16); color: var(--palette-gray-100)"
          >
            20 to 30 minutes
          </div>
        </div>
      </div>
    </div>
    <br />
    <div style="font: var(--typography-w400h14); color: var(--palette-gray-60)">
      <ul>
        <li>
          This survey length depends on question and answers that are specific to
          each individual.
        </li>
        <li>
          You can do it all at once or start, stop, and resume at a later time. If
          exiting the survey at any point, make sure you press the “SAVE AND
          CONTINUE LATER” button before closing.
        </li>
        <li>
          Please note that if you leave and return to complete the survey, you will
          resume from the start, but your information will be saved. Simply advance
          until you see the next page that needs to be completed.
        </li>
        <li>
          Please take your time to read the questions carefully before answering
          them.
        </li>
        <li>There is no timer or progression status.</li>
        <li>
          The information you provide will be confidential and only reported in a
          de-identified, aggregate (grouped) manner.
        </li>
        <li>
          Please use the survey navigation buttons, NEXT and PREVIOUS to advance or
          go to the previous page, respectively.
        </li>
        <li>Do NOT use the web browser Forward and Backward arrows.</li>
        <li>Thank you for being the most important part of CVR!</li>
      </ul>
    </div>
    `,
      label: 'Content',
      refreshOnChange: false,
      key: 'Intro0',
      type: 'content',
      input: false,
      tableView: false,
    },
  ],
};

export default formIntro;

const joinCVR = {
  display: 'wizard',
  navBar: {
    submitLabel: 'continue',
  },
  components: [
    {
      title: 'Page 1',
      navigateOnEnter: false,
      saveOnEnter: false,
      scrollToTop: false,
      collapsible: false,
      key: 'page1',
      type: 'panel',
      label: 'Page 1',
      input: false,
      tableView: true,
      components: [
        {
          label: 'Email Address',
          placeholder: 'Enter email',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
          },
          kickbox: {
            enabled: true,
          },
          key: 'email',
          type: 'email',
          input: true,
          autocomplete: 'email',
          spellcheck: false,
        },
        {
          label: 'Password',
          placeholder: 'Enter Password',
          applyMaskOn: 'change',
          tableView: false,
          validate: {
            required: true,
          },
          key: 'password',
          type: 'password',
          input: true,
          protected: true,
          autocomplete: 'current-password',
          spellcheck: false,
        },
      ],
    },
  ],
};

export default joinCVR;

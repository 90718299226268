import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { useAuth } from 'context/AuthContext';
import useCheckUserExists from 'hooks/useCheckUserExists';
import { useCurrentPersonQuery } from 'store/api/nhf/endpoints/people';
import { useGetPersonAttributesQuery } from 'store/api/nhf/endpoints/personAttributes';
import { PersonStatus } from 'utils/enums';

const PrivateRoute = () => {
  const { userInfo, isAuthenticated, isAuthLoading } = useAuth();
  const { userExists, isLoading: isCheckUserExistsLoading } =
    useCheckUserExists();
  const isEmailVerified = userInfo?.email_verified;

  const { isEnrollmentCompleted, personId, isPersonLoading } =
    useCurrentPersonQuery(
      {},
      {
        skip: !userExists || !isAuthenticated,
        selectFromResult: ({ data, isFetching }) => ({
          isPersonLoading: isFetching,
          isEnrollmentCompleted:
            data?.attributes?.status === PersonStatus.Onboarded ||
            data?.attributes?.status === PersonStatus.Enrolled,
          personId: data?.id,
        }),
      }
    );

  const { MFASetupRequired, isMFALoading } = useGetPersonAttributesQuery(
    { personId },
    {
      skip: !personId || !userExists || !isAuthenticated,
      selectFromResult: ({ data, isFetching }) => ({
        MFASetupRequired: !Boolean(data?.mfa_enrolled?.value),
        isMFALoading: isFetching,
      }),
    }
  );

  const isLoading =
    isAuthLoading ||
    isCheckUserExistsLoading ||
    isPersonLoading ||
    isMFALoading;

  const { pathname } = useLocation();
  const isLoginPath = pathname === '/login';
  const isJoinPath = pathname === '/join';
  const isVerifyEmailPath = pathname === '/verify-email';
  const isEnrollmentPath = pathname === '/enrollment';
  const isAccountSecurityPath = pathname.includes('/account-security');

  if (isLoading && !isJoinPath) {
    return <CircularProgress />;
  }

  if (!userExists) {
    if (!isLoginPath && !isJoinPath) {
      return <Navigate to='/login' replace />;
    }
  } else if (!isEmailVerified) {
    if (!isVerifyEmailPath) {
      return <Navigate to='/verify-email' replace />;
    }
  } else if (MFASetupRequired) {
    if (!isAccountSecurityPath) {
      return <Navigate to='/account-security' />;
    }
  } else if (!isEnrollmentCompleted) {
    if (!isEnrollmentPath) {
      return <Navigate to='/enrollment' />;
    }
  } else if (!MFASetupRequired) {
    if (isAccountSecurityPath) {
      return <Navigate to='/homepage' />;
    }
  }

  if (
    (userExists && (isLoginPath || isJoinPath)) ||
    (isEmailVerified && isVerifyEmailPath) ||
    (isEnrollmentCompleted && isEnrollmentPath)
  ) {
    return <Navigate to='/homepage ' replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;

export const TestPages = () => {
  if (window?.__ENV__ !== 'local' && window?.__ENV__ !== 'dev') {
    return <Navigate to='/login' replace />;
  }

  return <Outlet />;
};

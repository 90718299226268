import {
  Card,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const DashboardUnderConstruction = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Card
      variant={sm ? '' : 'outlined'}
      color={sm ? theme.palette.gray[0] : null}
      sx={{
        width: '100%',
      }}
    >
      <Stack
        alignItems='center'
        justifyContent='center'
        height='100%'
        spacing='20px'
        m='45px'
      >
        <Stack alignItems='center' justifyContent='center' spacing='20px'>
          <Typography variant='w700h30' color='primary' align='center'>
            Welcome to your Personalized Dashboard!
          </Typography>
          <Typography variant='w400h16' color='tamarillo.100'>
            <ul>
              <li>
                <Typography variant='w700h18' align='center'>
                  <i>New:</i>
                </Typography>
                <Typography variant='w600h16' color='gray.80' align='center'>
                  {' '}
                  Scroll down to MY TASKS and complete the Baseline Survey (if
                  eligible) – once completed you will be entered into a raffle
                  to{' '}
                  <i>
                    win an all-expenses paid trip to NBDF's 2024 Bleeding
                    Disorder Conference in Atlanta September
                  </i>{' '}
                  12 - 14!
                </Typography>
              </li>
              <li>
                <Typography variant='w600h16' color='gray.80' align='center'>
                  Learn how your data is making an impact in the lives of many
                  affected by bleeding disorders.
                </Typography>
              </li>
              <li>
                <Typography variant='w600h16' color='gray.80' align='center'>
                  Discover additional research opportunities tailored to you.
                </Typography>
              </li>
              <li>
                <Typography variant='w600h16' color='gray.80' align='center'>
                  Find curated educational resources to help you be an active
                  member of your healthcare team.
                </Typography>
              </li>
              <li>
                <Typography variant='w600h16' color='gray.80'>
                  In the future, you will be able to:
                  <ul>
                    <li>take the Family Baseline Survey (if eligible)</li>
                    <li>
                      compare your answers over time and with others like you!
                    </li>
                  </ul>
                </Typography>
              </li>
            </ul>
          </Typography>
          <Typography variant='w700h16' color='gray.80' align='center'>
            Stay tuned as we work hard to improve CVR.
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
export default DashboardUnderConstruction;

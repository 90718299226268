import { Stack, Step, StepLabel, Stepper as MUIStepper } from '@mui/material';

const Stepper = ({ step, steps }) => (
  <Stack px='20px' width='100%' alignItems='center'>
    <MUIStepper
      activeStep={step}
      alternativeLabel
      px='20px'
      sx={{ maxWidth: '660px' }}
    >
      {steps.map(({ label }) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </MUIStepper>
  </Stack>
);

export default Stepper;

import { Grid } from '@mui/material';

import IconWithText from 'pages/SplashPage/components/IconWithText';
import ListIcons from 'mocks/listIcons';

const IconsList = () => {
  return (
    <Grid container columnSpacing={5} sx={{ paddingX: { xs: 0, md: 22.5 } }}>
      {ListIcons?.map((item) => {
        return (
          <IconWithText
            key={item.id}
            icon={item.icon}
            title={item.title}
            subtitle={item.subtitle}
          />
        );
      })}
    </Grid>
  );
};

export default IconsList;

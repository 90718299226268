const myInvitations = {
  display: 'wizard',
  navBar: {
    submitLabel: 'Invite',
    saveLabel: 'Cancel',
  },
  components: [
    {
      title: 'Page 1',
      key: 'page1',
      type: 'panel',
      input: false,
      tableView: true,
      components: [
        {
          label: 'First name',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
            maxLength: 100,
            custom:
              'valid = input.match(/^[a-zA-Z]{1,100}([a-zA-Z\'\\-\\s]{1,98}[a-zA-Z])?$/u) || input.length === 0 ? true : "You can\'t use special characters in the beginning or the end of your name, the limit of characters is 100."',
          },
          key: 'first_name',
          type: 'textfield',
          input: true,
        },
        {
          label: 'Last name',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
            maxLength: 100,
            custom:
              'valid = input.match(/^[a-zA-Z]{1,100}([a-zA-Z\'\\-\\s]{1,98}[a-zA-Z])?$/u) || input.length === 0 ? true : "You can\'t use special characters in the beginning or the end of your name, the limit of characters is 100."',
          },
          key: 'last_name',
          type: 'textfield',
          input: true,
        },
        {
          label: 'Email address',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
            maxLength: 250,
          },
          kickbox: {
            enabled: true,
          },
          key: 'email',
          type: 'email',
          input: true,
        },
      ],
    },
  ],
};

export default myInvitations;

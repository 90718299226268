import parsePhoneNumber from 'libphonenumber-js';

export const getNthStringOccurance = (stringValue, subString, index) => {
  return stringValue.split(subString, index).join(subString).length;
};

export const formatPhoneNumber = (phone, format = 'number', country = 'US') => {
  if (!phone) {
    return null;
  }

  return parsePhoneNumber(phone, country)[format];
};

import { useState } from 'react';
import { Box, Stack } from '@mui/material';

import Section from 'components/DataStory/Section';
import FormIO from 'components/FormIO';
import chapterParticipation from 'forms/chapterParticipation';
import ChapterParticipationChart from './ChapterParticipationChart';

const percentages = {
  hemophiliaA: {
    Educational: 87,
    Social: 65,
    Fundraising: 55,
    Advocacy: 47,
    Camp: 32,
  },
  hemophiliaB: {
    Educational: 81,
    Social: 58,
    Fundraising: 45,
    Advocacy: 29,
    Camp: 14,
  },
  vonWillebrandDisease: {
    Educational: 94,
    Social: 72,
    Fundraising: 58,
    Advocacy: 68,
    Camp: 32,
  },
  ultraRareBleedingDisorders: {
    Educational: 95,
    Social: 74,
    Fundraising: 55,
    Advocacy: 55,
    Camp: 32,
  },
};
function sortObjectByValues(obj) {
  const sortedKeys = Object.keys(obj).sort(
    (keyA, keyB) => obj[keyB] - obj[keyA]
  );
  const sortedObject = {};
  sortedKeys.forEach((key) => {
    sortedObject[key] = obj[key];
  });
  return sortedObject;
}
const texts = ['Educational', 'Social', 'Fundraising', 'Advocacy', 'Camp'];

const ChapterParticipation = () => {
  const [chartPercentages, setChartPercentages] = useState({});
  const handleChange = ({ bleedingDisorder }) =>
    setChartPercentages(sortObjectByValues(percentages[bleedingDisorder]));

  return (
    <Section
      title='Chapter participation'
      description='Let’s see what types of NBDF Chapter programs others participate in:'
    >
      <Stack spacing='35px' alignItems='stretch'>
        <Box maxWidth='400px'>
          <FormIO
            form={chapterParticipation}
            template='enrollment'
            onChange={handleChange}
          />
        </Box>
        <ChapterParticipationChart
          percentages={chartPercentages}
          texts={texts}
        />
      </Stack>
    </Section>
  );
};

export default ChapterParticipation;

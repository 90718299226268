import { useCallback, useRef } from 'react';

import { Form } from '@formio/react';
import { Box } from '@mui/material';
import { cloneDeep, unset } from 'lodash';

import {
  connectSliderToOutput,
  fixCheckboxSelectionBug,
  fixRadioSelectionBug,
} from 'formio/framework/templates/utils';
import i18n from 'formio/i18n';
import useMutationObserver from 'hooks/useMutationObserver';
import useScrollTools from 'hooks/useScrollTools';

const traverseFormioComponentTree = (root, visitor) => {
  visitor?.(root);
  root?.components?.forEach?.((component) => {
    traverseFormioComponentTree(component, visitor);
  });
};

const clearData = (data, root) => {
  const cleanData = cloneDeep(data);
  traverseFormioComponentTree(
    root,
    (node) =>
      node.component.input && !node.visible && unset(cleanData, node.path),
  );
  return cleanData;
};

const FormIO = ({
  form,
  onSubmit,
  onCancel,
  onSave,
  onChange,
  onNextPage,
  onFocus,
  defaultValues,
  template,
  disableNextOnError = false,
  readOnly = false,
  unlockSubmit = false,
  ...rest
}) => {
  const rootRef = useRef(null);
  const formRef = useRef(null);
  const formData = useRef(null);
  const submittingRef = useRef(false);
  const pageChangedRef = useRef(null);

  const { scrollToTop, restoreScrollPosition } = useScrollTools();

  const handleSave = useCallback(
    () => onSave(clearData(formData.current, formRef.current)),
    [onSave],
  );

  const setupExtraActions = useCallback(
    ({ onCancel, onSave }) => {
      if (onCancel) {
        const cancelBtn =
          rootRef?.current?.getElementsByClassName?.('btn-wizard-cancel')?.[0];
        cancelBtn?.addEventListener?.('click', onCancel);
      }
      if (onSave) {
        const saveBtn =
          rootRef?.current?.getElementsByClassName?.('btn-wizard-save')?.[0];
        saveBtn?.addEventListener('click', onSave);
      }

      const nextBtn =
        rootRef?.current?.getElementsByClassName?.('btn-wizard-forward')?.[0];

      const errorQuestion =
        rootRef?.current?.getElementsByClassName?.('form-text error')?.[0]
          ?.parentElement?.parentElement;

      if (disableNextOnError) {
        if (!!errorQuestion && !nextBtn?.hasAttribute('disabled'))
          nextBtn?.setAttribute('disabled', '');
        if (!errorQuestion && nextBtn?.hasAttribute('disabled'))
          nextBtn?.removeAttribute('disabled');
      } else {
        nextBtn?.addEventListener?.('click', (event) => {
          if (!pageChangedRef.current) {
            const errorQuestion =
              rootRef?.current?.getElementsByClassName?.('form-text error')?.[0]
                ?.parentElement?.parentElement;
            errorQuestion?.scrollIntoView();
          }
          pageChangedRef.current = false;
          event.stopImmediatePropagation();
        });
      }

      Array.from(
        rootRef?.current?.getElementsByClassName?.('password-wrapper') ?? [],
      ).forEach((node) => {
        const showButton =
          node?.getElementsByClassName?.('show-password-eye')?.[0];
        const passwordField = node?.getElementsByTagName?.('input')?.[0];

        const showAction = () => {
          if (!showButton?.classList?.contains?.('pressed')) {
            passwordField?.setAttribute?.('type', 'text');
            showButton?.classList?.add('pressed');
          }
        };

        const hideAction = () => {
          if (showButton?.classList?.contains?.('pressed')) {
            passwordField?.setAttribute?.('type', 'password');
            showButton?.classList?.remove('pressed');
          }
        };

        showButton?.addEventListener?.('mousedown', showAction);
        showButton?.addEventListener?.('mouseup', hideAction);
        showButton?.addEventListener?.('mouseout', hideAction);
      });

      Array.from(
        rootRef?.current?.getElementsByClassName?.('password-wrapper') ?? [],
      ).forEach((node) => {
        const showButton =
          node?.getElementsByClassName?.('show-password-eye')?.[0];
        const passwordField = node?.getElementsByTagName?.('input')?.[0];

        const showAction = () => {
          if (!showButton?.classList?.contains?.('pressed')) {
            passwordField?.setAttribute?.('type', 'text');
            showButton?.classList?.add('pressed');
          }
        };

        const hideAction = () => {
          if (showButton?.classList?.contains?.('pressed')) {
            passwordField?.setAttribute?.('type', 'password');
            showButton?.classList?.remove('pressed');
          }
        };

        showButton?.addEventListener?.('mousedown', showAction);
        showButton?.addEventListener?.('mouseup', hideAction);
        showButton?.addEventListener?.('mouseout', hideAction);
      });
    },
    [disableNextOnError],
  );

  const setup = useCallback(
    () => setupExtraActions({ onCancel, onSave: handleSave }),
    [setupExtraActions, onCancel, handleSave],
  );
  useMutationObserver(rootRef, setup);

  const handleSubmit = ({ data }) => {
    if (!submittingRef.current || unlockSubmit) {
      submittingRef.current = true;
      onSubmit?.(clearData(data, formRef.current));
    }
  };

  const handleNavPage = (data) => {
    fixRadioSelectionBug(data, rootRef);
    fixCheckboxSelectionBug(data, rootRef);
    connectSliderToOutput(rootRef);
    scrollToTop();
    handleFocus?.();
    pageChangedRef.current = true;
  };

  const handleNavPagePrev = ({ submission: { data } }) => handleNavPage(data);

  const handleNavPageNext = ({ submission: { data } }) => {
    handleNavPage(data);
    onNextPage?.(data);
  };

  const handleChange = ({ data }) => {
    fixRadioSelectionBug(data, rootRef);
    fixCheckboxSelectionBug(data, rootRef);
    connectSliderToOutput(rootRef);
    rootRef && restoreScrollPosition();
    onChange?.(data);
    formData.current = data;
  };

  const handleFormReady = (form) => {
    setupExtraActions({ onCancel, onSave: handleSave });
    fixRadioSelectionBug(defaultValues, rootRef);
    fixCheckboxSelectionBug(defaultValues, rootRef);
    connectSliderToOutput(rootRef);
    formRef.current = form;
  };

  const handleFocus = () => formData.current && onFocus?.(formData.current);

  return (
    <Box ref={rootRef} {...rest}>
      <Form
        submission={defaultValues && { data: defaultValues }}
        form={form}
        options={{
          template,
          readOnly,
          i18n,
          alwaysDirty: disableNextOnError,
          submitOnEnter: true,
        }}
        formReady={handleFormReady}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onFocus={handleFocus}
        onNextPage={handleNavPageNext}
        onPrevPage={handleNavPagePrev}
      />
    </Box>
  );
};

export default FormIO;

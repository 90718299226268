import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';

import {
  EducationalResources,
  ExternalResearchOpportunities,
  HealthSummary,
  Missions,
  MyData,
  MyImpact,
  MyTasks,
  RegistryDashboard,
} from 'components/Dashboard';
import MyInvitations from 'components/Dashboard/MyInvitations';

const Dashboard = () => {
  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  return (
    <Stack
      alignItems='center'
      py={{ xs: '20px', sm: '46px' }}
      px={{ xs: '20px', sm: '20px', md: '40px', lg: '40px', xl: '80px' }}
    >
      <Grid
        container
        rowSpacing={{ xs: '10px', sm: '30px' }}
        columnSpacing='16px'
        maxWidth={{ xs: '800px', lg: '1312px' }}
      >
        <Grid item xs={12}>
          <RegistryDashboard disabled={true} />
        </Grid>
        <Grid item xs={12} xl={6}>
          <MyTasks
            disabled={true}
            sx={{ minHeight: { lg: '0', xl: '680px' } }}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <Missions
            disabled={true}
            sx={{ minHeight: { lg: '0', xl: '680px' } }}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <MyData />
        </Grid>
        <Grid item xs={12} xl={6}>
          <MyImpact />
        </Grid>
        <Grid item xs={12} xl={6}>
          <MyInvitations />
        </Grid>
        <Grid item xs={12} xl={6}>
          <ExternalResearchOpportunities />
        </Grid>
        <Grid item xs={12} xl={6}>
          <EducationalResources last />
        </Grid>
        {xl && <Grid item xs={12} xl={6} />}
        <Grid item xs={12} xl={6}>
          <HealthSummary last />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Dashboard;

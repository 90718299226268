import {
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const LoginProcessLayout = ({
  title,
  subtitle,
  description,
  footer,
  children,
}) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Stack py={{ xs: 0, sm: '50px' }}>
      <Container
        variant={sm ? 'outlined' : 'transparent'}
        sx={{ maxWidth: '600px' }}
      >
        <Stack pt='20px'>
          <Stack spacing='20px' mx={{ xs: '20px', sm: '30px' }}>
            {title && (
              <Typography variant='w600h24' color='gray.100'>
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography variant='w600h16' color='gray.100'>
                <i>{subtitle}</i>
              </Typography>
            )}
            {description && (
              <Typography variant='w400h16' color='gray.80'>
                {description}
              </Typography>
            )}
          </Stack>
          {children}
          <Stack mx={{ xs: '20px', sm: '30px' }}>{footer}</Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default LoginProcessLayout;

import { Box, Container, Stack, Typography } from '@mui/material';

import Chip from './Chip';
import LinkButton from './LinkButton';

const LinkCard = ({ title, subtitle, description, chipText, url }) => (
  <Container variant='outlined' sx={{ height: '100%' }}>
    <Stack p='25px' spacing='20px'>
      <Stack spacing='8px'>
        <Typography variant='w600h16' color='gray.100'>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant='w600h12' color='honeyFlower.100'>
            {subtitle}
          </Typography>
        )}
        {description && (
          <Typography variant='w400h14' color='gray.60'>
            {description}
          </Typography>
        )}
      </Stack>
      <Stack direction='row' alignItems='center'>
        {chipText && <Chip>{chipText}</Chip>}
        <Box flex={1} />
        <LinkButton url={url} />
      </Stack>
    </Stack>
  </Container>
);

export default LinkCard;

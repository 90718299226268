const formM = {
  title: 'Form M',
  label: 'Form M',
  type: 'panel',
  key: 'M',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Medications</strong><br/>The following questions will ask about your exposure to factor (clotting factor concentrate) as well as other medications for your bleeding disorder.',
      label: 'Content',
      refreshOnChange: false,
      key: 'M0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'Are you currently taking any medications to treat your bleeding disorder?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
        {
          label: "I don't know",
          value: 'DK',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'M1',
      type: 'radio',
      input: true,
    },
    {
      label:
        'Have you ever been infused with factor (clotting factor concentrate)?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
        {
          label: "I don't know",
          value: 'DK',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'M2',
      type: 'radio',
      input: true,
    },
    {
      label: 'M3',
      tableView: false,
      key: 'M3',
      customConditional:
        "show = data.M1 === 'Yes' && form.injectedValues.R19?.hemophiliaA;",
      type: 'container',
      input: true,
      components: [
        {
          label:
            'Please select any medication(s) you are currently taking or prescribed to treat Hemophilia A (factor VIII or factor 8 deficiency, classic hemophilia, FVIII/F8 carrier). Choose all that apply. ',
          optionsLabelPosition: 'right',
          tableView: true,
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            Advate: false,
            Adynovate: false,
            Afstyla: false,
            Alphanate: false,
            Altuviiio: false,
            DDAVP_inj: false,
            Eloctate: false,
            Esperoct: false,
            Hemlibra: false,
            Hemofil: false,
            Hum_P: false,
            Jivi: false,
            Koate: false,
            Kogenate: false,
            Kovaltry: false,
            Novoeight: false,
            NUWIQ: false,
            Obizur: false,
            Recombinate: false,
            Nasal_DDAVP: false,
            Xyntha: false,
          },
          values: [
            {
              label: 'Advate',
              value: 'Advate',
              shortcut: '',
            },
            {
              label: 'Adynovate',
              value: 'Adynovate',
              shortcut: '',
            },
            {
              label: 'Afstyla',
              value: 'Afstyla',
              shortcut: '',
            },
            {
              label: 'Alphanate',
              value: 'Alphanate',
              shortcut: '',
            },
            {
              label: 'Altuviiio',
              value: 'Altuviiio',
              shortcut: '',
            },
            {
              label: 'DDAVP injection (desmopressin acetate)',
              value: 'DDAVP_inj',
              shortcut: '',
            },
            {
              label: 'Eloctate',
              value: 'Eloctate',
              shortcut: '',
            },
            {
              label: 'Esperoct',
              value: 'Esperoct',
              shortcut: '',
            },
            {
              label: 'Hemlibra (emicizumab)',
              value: 'Hemlibra',
              shortcut: '',
            },
            {
              label: 'Hemofil M',
              value: 'Hemofil',
              shortcut: '',
            },
            {
              label: 'Humate-P',
              value: 'Hum_P',
              shortcut: '',
            },
            {
              label: 'Jivi',
              value: 'Jivi',
              shortcut: '',
            },
            {
              label: 'Koate DVI',
              value: 'Koate',
              shortcut: '',
            },
            {
              label: 'Kogenate FS',
              value: 'Kogenate',
              shortcut: '',
            },
            {
              label: 'Kovaltry',
              value: 'Kovaltry',
              shortcut: '',
            },
            {
              label: 'Novoeight',
              value: 'Novoeight',
              shortcut: '',
            },
            {
              label: 'NUWIQ',
              value: 'NUWIQ',
              shortcut: '',
            },
            {
              label: 'Obizur',
              value: 'Obizur',
              shortcut: '',
            },
            {
              label: 'Recombinate',
              value: 'Recombinate',
              shortcut: '',
            },
            {
              label: 'Intranasal DDAVP',
              value: 'Nasal_DDAVP',
              shortcut: '',
            },
            {
              label: 'Xyntha',
              value: 'Xyntha',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          validate: {
            required: true,
          },
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      label: 'M4',
      tableView: false,
      key: 'M4',
      customConditional:
        "show = data.M1 === 'Yes' && form.injectedValues.R19?.hemophiliaB;",
      type: 'container',
      input: true,
      components: [
        {
          label:
            'Please select any medication(s) you are currently taking or prescribed to treat Hemophilia B (factor IX or factor 9 deficiency, Christmas hemophilia, FIX/F9 carrier). Choose all that apply.',
          optionsLabelPosition: 'right',
          tableView: true,
          validate: {
            required: true,
          },
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            Alpha9: false,
            Alprolix: false,
            Benefix: false,
            Idelvion: false,
            Ixinity: false,
            Mononine: false,
            Profilnine: false,
            Rebinyn: false,
            Rixubis: false,
          },
          values: [
            {
              label: 'AlphaNine SD',
              value: 'Alpha9',
              shortcut: '',
            },
            {
              label: 'Alprolix',
              value: 'Alprolix',
              shortcut: '',
            },
            {
              label: 'Benefix',
              value: 'Benefix',
              shortcut: '',
            },
            {
              label: 'Idelvion',
              value: 'Idelvion',
              shortcut: '',
            },
            {
              label: 'Ixinity',
              value: 'Ixinity',
              shortcut: '',
            },
            {
              label: 'Mononine',
              value: 'Mononine',
              shortcut: '',
            },
            {
              label: 'Profilnine',
              value: 'Profilnine',
              shortcut: '',
            },
            {
              label: 'Rebinyn',
              value: 'Rebinyn',
              shortcut: '',
            },
            {
              label: 'Rixubis',
              value: 'Rixubis',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      label: 'M5',
      tableView: false,
      key: 'M5',
      type: 'container',
      customConditional:
        "show = data.M1 === 'Yes' && form.injectedValues.R19?.factorIDeficiency;",
      input: true,
      components: [
        {
          label:
            'Please select any medication(s) you are currently taking or prescribed to treat Factor I deficiency (1, various fibrinogen deficiencies). Choose all that apply.',
          optionsLabelPosition: 'right',
          tableView: true,
          validate: {
            required: true,
          },
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            Cryoprecipitate: false,
            Fibryga: false,
            RiaSTAP: false,
          },
          values: [
            {
              label: 'Cryoprecipitate',
              value: 'Cryoprecipitate',
              shortcut: '',
            },
            {
              label: 'Fibryga',
              value: 'Fibryga',
              shortcut: '',
            },
            {
              label: 'RiaSTAP',
              value: 'RiaSTAP',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      label: 'M6',
      tableView: false,
      key: 'M6',
      type: 'container',
      customConditional:
        "show = data.M1 === 'Yes' && form.injectedValues.R19?.factorIiDeficiency;",
      input: true,
      components: [
        {
          label:
            'Please select any medication(s) you are currently taking or prescribed to treat Factor II deficiency (2, prothrombin). Choose all that apply.',
          optionsLabelPosition: 'right',
          tableView: true,
          validate: {
            required: true,
          },
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            Bebulin: false,
            Coagadex: false,
            Profilnine: false,
          },
          values: [
            {
              label: 'Bebulin',
              value: 'Bebulin',
              shortcut: '',
            },
            {
              label: 'Coagadex',
              value: 'Coagadex',
              shortcut: '',
            },
            {
              label: 'Profilnine',
              value: 'Profilnine',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      label: 'M7',
      tableView: false,
      key: 'M7',
      type: 'container',
      customConditional:
        "show = data.M1 === 'Yes' && form.injectedValues.R19?.factorVDeficiency;",
      input: true,
      components: [
        {
          label:
            'Please select any medication(s) you are currently taking or prescribed to treat Factor V deficiency (5). Choose all that apply.',
          optionsLabelPosition: 'right',
          tableView: true,
          validate: {
            required: true,
          },
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            FF_plasma: false,
            Octaplas: false,
          },
          values: [
            {
              label: 'Fresh frozen plasma',
              value: 'FF_plasma',
              shortcut: '',
            },
            {
              label: 'Octaplas',
              value: 'Octaplas',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      label: 'M8',
      tableView: false,
      key: 'M8',
      type: 'container',
      customConditional:
        "show = data.M1 === 'Yes' && form.injectedValues.R19?.factorViiDeficiency;",
      input: true,
      components: [
        {
          label:
            'Please select any medication(s) you are currently taking or prescribed to treat Factor VII deficiency (7). Choose all that apply.',
          optionsLabelPosition: 'right',
          tableView: true,
          validate: {
            required: true,
          },
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            Novo7_RT: false,
            SevenFACT: false,
          },
          values: [
            {
              label: 'NovoSeven RT',
              value: 'Novo7_RT',
              shortcut: '',
            },
            {
              label: 'SevenFACT',
              value: 'SevenFACT',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      label: 'M9',
      tableView: false,
      key: 'M9',
      type: 'container',
      customConditional:
        "show = data.M1 === 'Yes' && form.injectedValues.R19?.factorXDeficiency;",
      input: true,
      components: [
        {
          label:
            'Please select any medication(s) you are currently taking or prescribed to treat Factor X deficiency (10). Choose all that apply.',
          optionsLabelPosition: 'right',
          tableView: true,
          validate: {
            required: true,
          },
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            Coagadex: false,
            Profilnine: false,
          },
          values: [
            {
              label: 'Coagadex',
              value: 'Coagadex',
              shortcut: '',
            },
            {
              label: 'Profilnine',
              value: 'Profilnine',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      label: 'M10',
      tableView: false,
      key: 'M10',
      type: 'container',
      customConditional:
        "show = data.M1 === 'Yes' && form.injectedValues.R19?.factorXiDeficiency;",
      input: true,
      components: [
        {
          label:
            'Please select any medication(s) you are currently taking or prescribed to treat Factor XI deficiency (11 or hemophilia C). Choose all that apply.',
          optionsLabelPosition: 'right',
          tableView: true,
          validate: {
            required: true,
          },
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            FF_plasma: false,
            Octaplas: false,
          },
          values: [
            {
              label: 'Fresh frozen plasma',
              value: 'FF_plasma',
              shortcut: '',
            },
            {
              label: 'Octaplas',
              value: 'Octaplas',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      label: 'M11',
      tableView: false,
      key: 'M11',
      type: 'container',
      customConditional:
        "show = data.M1 === 'Yes' && form.injectedValues.R19?.factorXiiiDeficiency;",
      input: true,
      components: [
        {
          label:
            'Please select any medication(s) you are currently taking or prescribed to treat Factor XIII deficiency (13). Choose all that apply.',
          optionsLabelPosition: 'right',
          tableView: true,
          validate: {
            required: true,
          },
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            Corifact: false,
            Tretten: false,
          },
          values: [
            {
              label: 'Corifact',
              value: 'Corifact',
              shortcut: '',
            },
            {
              label: 'Tretten',
              value: 'Tretten',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      label: 'M12',
      tableView: false,
      key: 'M12',
      type: 'container',
      customConditional:
        "show = data.M1 === 'Yes' && form.injectedValues.R19?.vonWillebrandDisease;",
      input: true,
      components: [
        {
          label:
            'Please select any medication(s) you are currently taking or prescribed to treat Von Willebrand Disease (VWD). Choose all that apply.',
          optionsLabelPosition: 'right',
          validate: {
            required: true,
          },
          tableView: true,
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            Alphanate: false,
            DDAVP_inj: false,
            Hum_P: false,
            Nasal_DDAVP: false,
            Vonvendi: false,
            Wilate: false,
          },
          values: [
            {
              label: 'Alphanate',
              value: 'Alphanate',
              shortcut: '',
            },
            {
              label: 'DDAVP injection (desmopressin acetate)',
              value: 'DDAVP_inj',
              shortcut: '',
            },
            {
              label: 'Humate-P',
              value: 'Hum_P',
              shortcut: '',
            },
            {
              label: 'Intranasal DDAVP',
              value: 'Nasal_DDAVP',
              shortcut: '',
            },
            {
              label: 'Vonvendi',
              value: 'Vonvendi',
              shortcut: '',
            },
            {
              label: 'Wilate',
              value: 'Wilate',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      label: 'M13',
      tableView: false,
      key: 'M13',
      type: 'container',
      customConditional:
        "show = data.M1 === 'Yes' && form.injectedValues.R19?.plateletDisorder;",
      input: true,
      components: [
        {
          label:
            'Please select any medication(s) you are currently taking or prescribed to treat platelet disorder. Choose all that apply.',
          optionsLabelPosition: 'right',
          tableView: true,
          validate: {
            required: true,
          },
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            DDAVP_inj: false,
            Novo7_RT: false,
            Platelets: false,
            SevenFACT: false,
            Nasal_DDAVP: false,
          },
          values: [
            {
              label: 'DDAVP injection (desmopressin acetate)',
              value: 'DDAVP_inj',
              shortcut: '',
            },
            {
              label: 'NovoSeven RT',
              value: 'Novo7_RT',
              shortcut: '',
            },
            {
              label: 'Platelets',
              value: 'Platelets',
              shortcut: '',
            },
            {
              label: 'SevenFACT',
              value: 'SevenFACT',
              shortcut: '',
            },
            {
              label: 'Intranasal DDAVP',
              value: 'Nasal_DDAVP',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      label: 'M14',
      tableView: false,
      key: 'M14',
      type: 'container',
      customConditional: "show = data.M1 === 'Yes'",
      input: true,
      components: [
        {
          label:
            'Please select any antifibrinolytics you are currently taking or prescribed. Choose all that apply.',
          optionsLabelPosition: 'right',
          tableView: true,
          defaultValue: {
            noneOfTheAbove: false,
            other: false,
            NAK: false,
            Corifact: false,
            Trx_acid: false,
            Amicar: false,
          },
          values: [
            {
              label: 'Aminocaproic acid (Amicar)',
              value: 'Amicar',
              shortcut: '',
            },
            {
              label: 'Tranexamic acid (Lysteda or Cyklokapron)',
              value: 'Trx_acid',
              shortcut: '',
            },
            {
              label: 'None of the above',
              value: 'NAK',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          clearOnHide: false,
          validate: {
            required: true,
          },
          key: 'medication',
          logic: [
            {
              name: 'None of the above',
              trigger: {
                type: 'javascript',
                javascript: 'result = row?.medication?.NAK;',
              },
              actions: [
                {
                  name: 'Unselect',
                  type: 'customAction',
                  customAction:
                    "value = Object.keys(row.medication).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
                },
              ],
            },
          ],
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Include other medications',
          reorder: false,
          hideLabel: true,
          addAnotherPosition: 'bottom',
          layoutFixed: true,
          enableRowGroups: false,
          initEmpty: false,
          tableView: true,
          defaultValue: [
            {
              medication: '',
            },
          ],
          key: 'other',
          customConditional: 'show = row?.medication?.other',
          type: 'datagrid',
          input: true,
          components: [
            {
              label: 'Other medications',
              placeholder: 'Medication name...',
              applyMaskOn: 'change',
              tableView: true,
              key: 'medication',
              type: 'textfield',
              input: true,
              validate: {
                required: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
    {
      html: '<br/><strong>Bleeding Disorders Treatment</strong><br/>The next questions will ask about your bleeding disorder medication treatment plan. We want to better understand how medications are being used in the real world.',
      label: 'Content',
      refreshOnChange: false,
      key: 'M15_16_header',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'Describe how you use each bleeding disorder medication',
      description:
        '1. <u>On-demand or episodic:</u> to stop an active bleeding episode.<br/>2. <u>Prophylactic:</u> to treat on a regular schedule to prevent bleeding or decrease the intensity of a bleeding episode; <strong>this includes before a specific activity, medical or dental procedure, or during menstrual periods</strong>.<br/>3. <u>Both:</u> used both on-demand or episodic AND prophylactic.',
      disableAddingRemovingRows: true,
      reorder: false,
      addAnotherPosition: 'bottom',
      layoutFixed: true,
      enableRowGroups: false,
      initEmpty: true,
      tableView: true,
      defaultValue: [
        {
          medication: '',
          M15: '',
          M16: '',
          M16_other: '',
          Hemlibra: '',
        },
      ],
      calculateValue:
        // eslint-disable-next-line no-template-curly-in-string
        'const filterOut = ["other", "NAK"];\nconst questionKeys = ["I6", ...Array(12).fill().map((element, index) => `M${index + 3}`)];\n\nconst containers = questionKeys\n  .map(questionKey => utils.getComponent(form.components, questionKey)) // map key to container\n  .filter(component => component) // remove invalid containers\n  \nconst regularQuestions = containers.map(({ key, components: { 0: { values } } }) => \n    values.filter(({ value }) => \n     !filterOut.includes(value) // remove special categories\n        && data[key]?.medication?.[value] // remove not selected\n      ));\n  \nconst otherQuestions = questionKeys.map((key) => \n  data[key]?.other?.map?.(({ medication }) => ({ value: medication, label: medication }))?.filter?.(field => field?.label && field.label !== \'\') ?? []);\n\nconst combinedQuestions = [...regularQuestions, ...otherQuestions].reduce((acc, curr) => [...curr, ...acc], []);\n\nconst questions = combinedQuestions.sort((lhs, rhs) => (lhs.label.localeCompare(rhs.label)))\n  .filter((item, pos, ary) => !pos || item.value != ary[pos - 1].value);\n\nconst oldMedications = data[\'M15_M16\']?.map(({ key }) => key);\nconst newMedications = questions.map(({ value }) => value);\n\nif (!_.isEqual(oldMedications, newMedications)) {\n  const mapValue = value.reduce((acc, curr) => ({ ...acc, [curr.key]: curr }), {});\n  const newValue = questions.map(({ value: key, label }) => (mapValue[key] ?? { key, label, M15: "", M16: "", M16_other: "" }));\n\n  const delta = questions.length - value.length;\n  if (delta > 0) {\n    for (let i = 0; i < delta; i++) instance.addRow();\n  } else {\n    for (let i = 0; i < (-delta); i++) instance.removeRow(0);\n  }\n  value = newValue\n}',
      key: 'M15_M16',
      customConditional: "show = data.M1 === 'Yes';",
      type: 'datagrid',
      input: true,
      components: [
        {
          label: 'Medication',
          applyMaskOn: 'change',
          disabled: true,
          tableView: true,
          customDefaultValue: 'value = row.label',
          validate: {
            required: true,
            maxLength: 250,
          },
          key: 'medication',
          type: 'textfield',
          input: true,
        },
        {
          label: 'How do you use your medication?',
          widget: 'choicesjs',
          placeholder: 'Usage',
          tableView: true,
          data: {
            values: [
              {
                label: 'On-demand<sup>1</sup>',
                value: 'OD',
              },
              {
                label: 'Prophylactic<sup>2</sup>',
                value: 'Prophy',
              },
              {
                label: 'Both<sup>3</sup>',
                value: 'Both',
              },
            ],
          },
          validate: {
            required: true,
          },
          key: 'M15',
          logic: [
            {
              name: 'Check for medication',
              trigger: {
                type: 'javascript',
                javascript: "result = (row.key === 'Hemlibra');",
              },
              actions: [
                {
                  name: 'Disable',
                  type: 'property',
                  property: {
                    label: 'Disabled',
                    value: 'disabled',
                    type: 'boolean',
                  },
                  state: true,
                },
                {
                  name: 'Value',
                  type: 'value',
                  value: "if (result) value = 'Prophy'",
                },
              ],
            },
            {
              name: 'Check for changes in medication',
              trigger: {
                type: 'javascript',
                javascript: 'result = !!data.M15_M16;',
              },
              actions: [
                {
                  name: 'Make label match data',
                  type: 'customAction',
                  customAction:
                    "if(instance?.selectData?.label){if (row.M15 =='OD'){ instance.selectData.label = 'On-demand<sup>1</sup>'} \n else if (row.M15 =='Prophy'){ instance.selectData.label = 'Prophylactic<sup>2</sup>'} \n else if (row.M15 =='Both'){ instance.selectData.label = 'Both<sup>3</sup>'}}",
                },
              ],
            },
          ],
          type: 'select',
          input: true,
        },
        {
          label: 'What is your typical <strong>prophylactic</strong> schedule?',
          widget: 'choicesjs',
          placeholder: 'Schedule',
          tableView: true,
          dataSrc: 'custom',
          data: {
            custom:
              "values = [\n  {\n    label: 'Several times a day',\n    value: '11',\n  },\n  {\n    label: 'Once a day',\n    value: '10',\n  },\n  {\n    label: 'Every other day',\n    value: '9',\n  },\n  {\n    label: '1 time per week',\n    value: '8',\n  },\n  {\n    label: '2 times per week',\n    value: '7',\n  },\n  {\n    label: '3 times per week',\n    value: '6',\n  },\n  {\n    label: 'Every 4 days',\n    value: '5',\n  },\n  {\n    label: 'Every 10 days',\n    value: '4',\n  },\n  {\n    label: 'Every 14 days',\n    value: '3',\n  },\n  {\n    label: 'Every 21 days',\n    value: '2',\n  },\n  {\n    label: 'Once a month',\n    value: '1',\n  },\n  ...(form.injectedValues.R8 !== 'male' ? [{\n    label: 'Around my menstrual cycle',\n    value: 'Menst',\n  }] : []),\n  {\n    label: 'Prior to physical activity',\n    value: 'Activity',\n  },\n  {\n    label: 'Other, please describe prophylaxis schedule',\n    value: 'other',\n  },\n];",
          },
          valueProperty: 'value',
          refreshOn: 'M15_M16.M15',
          clearOnRefresh: true,
          validate: {
            required: true,
          },
          key: 'M16',
          logic: [
            {
              name: 'Changed',
              trigger: {
                type: 'javascript',
                javascript:
                  "result = row.M15 !== 'Prophy' && row.M15 !== 'Both';",
              },
              actions: [
                {
                  name: 'Disable',
                  type: 'property',
                  property: {
                    label: 'Disabled',
                    value: 'disabled',
                    type: 'boolean',
                  },
                  state: true,
                },
                {
                  name: 'Required',
                  type: 'property',
                  property: {
                    label: 'Required',
                    value: 'validate.required',
                    type: 'boolean',
                  },
                  state: false,
                },
                {
                  name: 'Change Placeholder',
                  type: 'property',
                  property: {
                    label: 'Placeholder',
                    value: 'placeholder',
                    type: 'string',
                  },
                  text: 'No Schedule',
                },
              ],
            },
          ],
          type: 'select',
          input: true,
        },
        {
          label: 'Describe <strong>other prophylactic</strong> schedule',
          placeholder: 'Other...',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
            maxLength: 250,
          },
          key: 'M16_other',
          logic: [
            {
              name: 'Select other',
              trigger: {
                type: 'javascript',
                javascript: "result = row['M16'] !== 'other'",
              },
              actions: [
                {
                  name: 'Disable',
                  type: 'property',
                  property: {
                    label: 'Disabled',
                    value: 'disabled',
                    type: 'boolean',
                  },
                  state: true,
                },
                {
                  name: 'Required',
                  type: 'property',
                  property: {
                    label: 'Required',
                    value: 'validate.required',
                    type: 'boolean',
                  },
                  state: false,
                },
                {
                  name: 'Value',
                  type: 'value',
                  value: 'value = ""',
                },
              ],
            },
          ],
          type: 'textfield',
          input: true,
        },
      ],
    },
  ],
};

export default formM;

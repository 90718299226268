import { Card, Stack, Typography, CircularProgress } from '@mui/material';

import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg';

const ActionCard = ({ Icon, title, description, onClick, loading }) => (
  <Card
    variant='outlined'
    sx={{
      cursor: loading ? 'not-allowed' : 'pointer',
      opacity: loading ? '0.5' : 1,
    }}
    onClick={onClick}
  >
    <Stack direction='row' alignItems='center' spacing='15px' p='15px'>
      <Icon />
      <Stack spacing='2px' flex={1}>
        <Typography variant='w600h14' color='gray.100'>
          {title}
        </Typography>
        <Typography variant='w400h12' color='gray.60'>
          {description}
        </Typography>
      </Stack>
      <Stack alignItems='center' justifyContent='center'>
        <Typography color='primary'>
          {loading ? <CircularProgress size={18} /> : <ArrowRightIcon />}
        </Typography>
      </Stack>
    </Stack>
  </Card>
);

export default ActionCard;

const { default: usePersonAttributes } = require('hooks/usePersonAttributes');

const useShowBaselineSurvey = () => {
  const { attributes, isLoading } = usePersonAttributes();

  const bdCarrier = attributes?.bd_carrier?.value === 'yes';
  const bdHistSymptoms = attributes?.bd_hist_symptoms?.value === 'yes';
  const bleedingDisorder =
    attributes?.bleeding_disorder?.value ===
    'yesIHaveBeenFormallyDiagnosedWithABleedingDisorderIncludingBeingACarrierByAHealthCareProvider';
  const show = bdCarrier || bdHistSymptoms || bleedingDisorder;
  return { show, isLoading };
};

export default useShowBaselineSurvey;

import { Stack } from '@mui/material';

import Verify2FA from 'components/Enrollment/AccountSecurityStep/Verify2FA';
import useMFA from 'hooks/useMFA';

const Verify = () => {
  const {
    mfaLoaders,
    accountLocked,
    code,
    timeLeft,
    authenticators,
    setCode,
    handleSubmit2FAVerification,
    handleResendCode,
  } = useMFA();

  const authenticatorId = authenticators?.[0]?.id;

  const isAuthenticatorApp =
    !authenticatorId || Boolean(authenticatorId?.includes('otp'));

  return (
    <Stack
      p={{ xs: '20px 0 0 0', sm: '40px 20px 40px 20px' }}
      alignItems='center'
      spacing='30px'
      flex={1}
    >
      <Verify2FA
        mfaLoaders={mfaLoaders}
        accountLocked={accountLocked}
        code={code}
        timeLeft={timeLeft}
        isAuthenticatorApp={isAuthenticatorApp}
        setCode={setCode}
        handleResendCode={handleResendCode}
        handleSubmit={handleSubmit2FAVerification}
      />
    </Stack>
  );
};

export default Verify;

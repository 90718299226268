const formRM = {
  title: 'Form RM',
  label: 'Form RM',
  type: 'panel',
  key: 'RM',
  input: false,
  tableView: false,
  customConditional: 'show = data.MC1 === "Yes"',
  components: [
    {
      html: '<strong>Menstrual Cycles (Periods) - continued</strong>',
      label: 'Content',
      refreshOnChange: false,
      key: 'RM0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'On average, during the last three months, for how many days did your period last?',
      widget: 'choicesjs',
      placeholder: 'On average...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Less than 3 days',
            value: '0',
          },
          {
            label: 'Between 3 and 7 days',
            value: '1',
          },
          {
            label: 'Between 8 and 10 days',
            value: '2',
          },
          {
            label: 'More than 10 days',
            value: '3',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'RM1',
      type: 'select',
      input: true,
    },
    {
      label:
        'On average, during the last three months, were your periods regular or irregular?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Regular',
          value: '0',
        },
        {
          label: 'Irregular',
          value: '1',
        },
      ],
      validate: {
        required: true,
      },
      key: 'RM2',
      type: 'radio',
      input: true,
    },
    {
      label:
        'On average, during the last three months, how many days were there from the first day of a period to the first day of the next period?',
      widget: 'choicesjs',
      placeholder: 'On average...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Less than 21 days',
            value: '2',
          },
          {
            label: 'Between 21 and 35 days',
            value: '1',
          },
          {
            label: 'More than 35 days',
            value: '0',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'RM3',
      type: 'select',
      input: true,
    },
    {
      label:
        'On average, during the last three months, would you describe your periods as….?',
      widget: 'choicesjs',
      placeholder: 'On average...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Light',
            value: '0',
          },
          {
            label: 'Moderate',
            value: '1',
          },
          {
            label: 'Heavy',
            value: '2',
          },
          {
            label: 'Very heavy',
            value: '3',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'RM4',
      type: 'select',
      input: true,
    },
    {
      label:
        'On average, during the last three months, for how many days of each period was the bleeding heavy?',
      widget: 'choicesjs',
      placeholder: 'On average...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Not heavy',
            value: '0',
          },
          {
            label: 'Between 1 and 3 days',
            value: '1',
          },
          {
            label: 'Between 4 and 6 days',
            value: '2',
          },
          {
            label: 'Between 7 and 10 days',
            value: '3',
          },
          {
            label: 'More than 10 days',
            value: '4',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'RM5',
      type: 'select',
      input: true,
    },
    {
      label:
        'During the last three months, have you passed any clots of blood?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: '1',
        },
        {
          label: 'No',
          value: '0',
        },
      ],
      validate: {
        required: true,
      },
      key: 'RM6',
      type: 'radio',
      input: true,
    },
    {
      label:
        'On average, during the last three months, have your periods been associated with any pain?',
      widget: 'choicesjs',
      placeholder: 'On average...',
      tableView: true,
      data: {
        values: [
          {
            label: 'No pain at all',
            value: '0',
          },
          {
            label: 'Slight pain',
            value: '1',
          },
          {
            label: 'Moderate pain',
            value: '2',
          },
          {
            label: 'Severe pain',
            value: '3',
          },
          {
            label: 'Very severe pain',
            value: '4',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'RM7',
      type: 'select',
      input: true,
    },
    {
      label:
        'On average, during the last three months, have you had any problems with soiling or staining any of the following because of your periods? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'No, not at all',
          value: '0',
          shortcut: '',
        },
        {
          label: 'Soiling or staining of your underclothes or undergarments',
          value: '1',
          shortcut: '',
        },
        {
          label: 'Soiling or staining of your outer clothes or overgarments',
          value: '2',
          shortcut: '',
        },
        {
          label: 'Soiling or staining of your bed linen',
          value: '3',
          shortcut: '',
        },
        {
          label: 'Soiling or staining of your upholstery',
          value: '4',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'RM8',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      logic: [
        {
          name: 'noneOfTheAbove',
          trigger: {
            type: 'javascript',
            javascript: "result = data['RM8'][0];",
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data['RM8']).reduce((acc, curr) => ({...acc, [curr]: curr === '0'}), {})",
            },
          ],
        },
      ],
    },
    {
      label:
        'On average, during the last three months, have your periods prevented you from carrying out your work, housework or other daily activities?',
      widget: 'choicesjs',
      placeholder: 'On average...',
      tableView: true,
      data: {
        values: [
          {
            label: 'No pain at all',
            value: '0',
          },
          {
            label: 'I could continue to work, but my work suffered',
            value: '1',
          },
          {
            label: 'Yes, usually with no more than one day with each period',
            value: '2',
          },
          {
            label: 'Yes, usually more than one day with each period',
            value: '3',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'RM9',
      type: 'select',
      input: true,
    },
    {
      label:
        'On average, during the past three months, have you been confined to bed with each period?',
      widget: 'choicesjs',
      placeholder: 'On average...',
      tableView: true,
      data: {
        values: [
          {
            label: 'No, not at all',
            value: '0',
          },
          {
            label: 'Yes, usually for part of one day',
            value: '1',
          },
          {
            label: 'Yes, usually for the whole of one day',
            value: '2',
          },
          {
            label: 'Yes, usually for more than one day',
            value: '3',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'RM10',
      type: 'select',
      input: true,
    },
    {
      label:
        'On average, during the last three months, have your leisure activities been affected by your heavy periods? (including sport, hobbies, social life)',
      widget: 'choicesjs',
      placeholder: 'On average...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Not affected by heavy periods',
            value: '0',
          },
          {
            label: 'Mildly affected by heavy periods',
            value: '1',
          },
          {
            label: 'Moderately affected by heavy periods',
            value: '2',
          },
          {
            label: 'Severely affected by heavy periods',
            value: '3',
          },
          {
            label: 'Heavy periods have prevented any social life at all',
            value: '4',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'RM11',
      type: 'select',
      input: true,
    },
    {
      label:
        'On average, during the last three months, has your sex life been affected by your heavy periods?',
      widget: 'choicesjs',
      placeholder: 'On average...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Not affected by heavy periods',
            value: '0',
          },
          {
            label: 'Mildly affected by heavy periods',
            value: '1',
          },
          {
            label: 'Moderately affected by heavy periods',
            value: '2',
          },
          {
            label: 'Severely affected by heavy periods',
            value: '3',
          },
          {
            label: 'Heavy periods have prevented any sex life at all',
            value: '4',
          },
          {
            label: 'Does not apply',
            value: 'No_apply',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'RM12',
      type: 'select',
      input: true,
    },
    {
      label:
        'On average, during the last three months, how many tampons might you use on the heaviest day of your period?',
      widget: 'choicesjs',
      placeholder: 'On average...',
      tableView: true,
      data: {
        values: [
          {
            label: 'No tampons at all',
            value: '0',
          },
          {
            label: 'Between 1 and 5 tampons',
            value: '1',
          },
          {
            label: 'Between 6 and 10 tampons',
            value: '2',
          },
          {
            label: 'Between 11 and 15 tampons',
            value: '3',
          },
          {
            label: 'More than 15 tampons',
            value: '4',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'RM13',
      type: 'select',
      input: true,
    },
    {
      label:
        'On average, how many pads might you use on the heaviest day of your period?',
      widget: 'choicesjs',
      placeholder: 'On average...',
      tableView: true,
      data: {
        values: [
          {
            label: 'No pads at all',
            value: '0',
          },
          {
            label: 'Between 1 and 5 pads',
            value: '1',
          },
          {
            label: 'Between 6 and 10 pads',
            value: '2',
          },
          {
            label: 'Between 11 and 15 pads',
            value: '3',
          },
          {
            label: 'More than 15 pads',
            value: '4',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'RM14',
      type: 'select',
      input: true,
    },
    {
      label:
        'At any time during the last three months, did you require more than one form of protection at the same time (not including mini pads or mini pant-liners)?',
      widget: 'choicesjs',
      placeholder: 'On average...',
      tableView: true,
      data: {
        values: [
          {
            label: 'No',
            value: '0',
          },
          {
            label: 'Tampon and pad together',
            value: '1',
          },
          {
            label: 'Two pads together',
            value: '2',
          },
          {
            label: 'Tampon and two pads together',
            value: '3',
          },
          {
            label:
              'More protection than this (i.e., disposable diaper, towels, etc.)',
            value: '4',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'RM15',
      type: 'select',
      input: true,
    },
  ],
};

export default formRM;

import { useEffect, useState } from 'react';

const useTrackScroll = () => {
  const [scrollPosition, setScrollPosition] = useState([
    window.scrollX,
    window.scrollY,
  ]);
  const handleScroll = () => {
    const posX = window.scrollX;
    const posY = window.scrollY;
    setScrollPosition([posX, posY]);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollPosition;
};

export default useTrackScroll;

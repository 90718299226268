import apiBaseQuery from 'store/api/baseQuery';
import { API_URL } from 'utils/constants';
import { NhfApiVersions } from 'utils/enums';

const jsonHeaders = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json',
};

const baseQuery =
  () =>
  ({ apiVersion, headers, needAuthentication = true, ...args }, ...params) => {
    const headersForJson = {
      ...(apiVersion === NhfApiVersions.V3 && jsonHeaders),
      ...headers,
    };
    return apiBaseQuery(
      {
        baseURL: `${API_URL}/${apiVersion}`,
        headers: headersForJson,
        needAuthentication,
        ...args,
      },
      ...params
    );
  };

export default baseQuery;

import { useTheme, Grid, Stack, Typography } from '@mui/material';

import {
  AboutRegistry,
  Consent,
  Contact,
  Password,
  PersonalInformation,
} from 'components/MyAccount';
import { useMobile } from 'hooks/useMobile';
import usePersonAttributes from 'hooks/usePersonAttributes';
import { useMemo } from 'react';
import { parseAttributes } from 'components/Enrollment/EnrollmentStep/utils';
import { PersonalInformationMock } from 'mocks/personalInformation';
import AccountSecurity from 'components/MyAccount/AccountSecurity';
const MyAccount = () => {
  const { attributes, isLoading } = usePersonAttributes();

  const defaultValues = useMemo(
    () => parseAttributes(attributes),
    [attributes]
  );
  const date = useMemo(() => {
    if (isLoading) {
      return '12 jan 2023';
    }
    const d = new Date(defaultValues.date_of_birth);
    return `${
      d.getMonth() + 1
    }-${d.getDate()}-${defaultValues.date_of_birth.substring(0, 4)}`;
  }, [defaultValues, isLoading]);

  const userInfo = useMemo(() => {
    if (isLoading) {
      return PersonalInformationMock;
    }
    return {
      'First Name': defaultValues.first_name,
      'Middle Name': defaultValues.middle_name || '',
      'Last Name': defaultValues.last_name,
      Email: defaultValues.email,
      'Alternative email address': defaultValues.alternative_email,
      'Date of Birth': date,
      'Primary phone number': defaultValues.phone,
      'Mobile phone number': defaultValues.home_phone,
      'Street Address': defaultValues.address,
      'Apartment Number': defaultValues.apt_number,
      City: defaultValues.city,
      'State or US territory': defaultValues.state,
      'Zip Code': defaultValues.postal_code,
      County: defaultValues.county,
    };
  }, [defaultValues, date, isLoading]);

  const isMFAEnabled = useMemo(
    () => attributes.mfa_enrolled.value === 'yes',
    [attributes.mfa_enrolled]
  );

  const theme = useTheme();
  const isMobile = useMobile();
  return (
    <Stack
      spacing={4.5}
      sx={{
        px: isMobile ? 0 : '5rem',
        marginY: '2.25rem',
      }}
    >
      <Typography
        variant='h4'
        sx={{
          fontSize: '1.85rem',
          fontWeight: theme.typography.fontWeightMedium,
          marginLeft: '0.75rem',
        }}
      >
        My Account
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} md={6}>
          <Stack spacing={2.5}>
            <PersonalInformation userInfo={userInfo} />
            <Password />
            <AboutRegistry />
            <Contact />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2.5}>
            {/* <ProfilePicture /> */}
            {/* <CommunicationPreferences /> */}
            <AccountSecurity isMFAEnabled={isMFAEnabled} />
            <Consent />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default MyAccount;

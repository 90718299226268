import { HTCData } from 'utils/enums';

const parseExistingHTC = (data) => {
  const htc = data?.HCP4;
  const {
    name,
    state,
    phone,
    address1,
    address2,
    address3,
    address4,
    city,
    zipcode,
  } = HTCData[htc] ?? {};

  return [
    name ?? '',
    address1 ?? '',
    address2 ?? '',
    address3 ?? '',
    address4 ?? '',
    `${city ?? ''}, ${state ?? ''} ${zipcode ?? ''}`,
    phone ?? '',
  ];
};

const parseOtherHTC = (data) => {
  const htcOther = data?.HCP4_other;
  const state = data?.HCP3;
  const phone = data?.HCP6;
  const street = data?.HCP7?.HCP7_1;
  const suite = data?.HCP7?.HCP7_2;
  const department = data?.HCP7?.HCP7_3;
  const city = data?.HCP7?.HCP7_4;
  const zipcode = data?.HCP7?.HCP7_5;

  return [
    htcOther ?? '',
    street,
    suite ?? '',
    department ?? '',
    '',
    `${city}, ${state} ${zipcode}`,
    phone,
  ];
};

export const parseHtc = (data) => {
  const htc = data?.HCP4;

  if (!htc) return [];

  const htcList =
    htc === 'other' || htc === 'DK'
      ? parseOtherHTC(data)
      : parseExistingHTC(data);

  return htcList
    .map((value) => (value === '' ? '-' : value))
    .map((description, id) => ({ id, description }));
};

import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { PERSIST, PURGE } from 'redux-persist/es/constants';
import storage from 'redux-persist/lib/storage';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import apis from './api';
import slices from './slices';

const persistConfig = {
  key: 'root',
  blacklist: apis.map(({ reducerPath }) => reducerPath),
  storage,
};

const reducers = {
  ...Object.fromEntries(
    [...apis, ...slices].map(({ name, reducerPath, reducer }) => [
      name ?? reducerPath,
      reducer,
    ])
  ),
};

const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(apis.map(({ middleware }) => middleware))
      .concat(
        createStateSyncMiddleware({
          blacklist: [PERSIST, PURGE],
        })
      ),
});

initMessageListener(store);

setupListeners(store.dispatch);

export const persistor = persistStore(store);

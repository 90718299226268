import { useMemo } from 'react';

import { Skeleton } from '@mui/material';

import Item from 'components/MyHealthSummary/Content/Items/Item';
import usePersonAttributes from 'hooks/usePersonAttributes';
import {
  DiagnosesLabels,
  DiagnosesTypes,
  HemophiliaSeverityLabels,
  HemophiliaSeverityTypes,
  PlateletDisorderLabels,
  VonWillebrandDiseaseLabels,
  VonWillebrandDiseaseTypes,
  VwdType2Labels,
  VwdType2Types,
} from 'utils/enums';
import parseJson from 'utils/parseJson';

const skeletonList = [
  { id: 1, description: <Skeleton width='210px' /> },
  { id: 2, description: <Skeleton width='260px' /> },
  { id: 3, description: <Skeleton width='180px' /> },
];

const parseDiagnoses = (attributes) => {
  if (!attributes) return [];

  const bleeding_disorder_diag = Object.entries(
    parseJson(attributes?.bleeding_disorder_diag?.value) ?? {},
  );
  const bleeding_disorder_diag_oth_txt =
    attributes?.bleeding_disorder_diag_oth_txt?.value;

  const vwd_type = attributes?.vwd_type?.value;
  const vwd_type_oth = attributes?.vwd_type_oth?.value;

  const vwd_type2 = attributes?.vwd_type2?.value;
  const vwd_type2_oth = attributes?.vwd_type2_oth?.value;

  const bd_hemophilia_severity = attributes?.bd_hemophilia_severity?.value;
  const bd_hemophilia_severity_oth = attributes?.bd_hemophilia_severity?.value;

  const platelet_disorder = attributes?.platelet_disorder?.value;

  return bleeding_disorder_diag
    .filter(([_, value]) => value)
    .map(([key]) => {
      const descriptionArray = [
        key === DiagnosesTypes.Other
          ? bleeding_disorder_diag_oth_txt
          : DiagnosesLabels[key],
      ];

      switch (key) {
        case DiagnosesTypes.HemophiliaA:
        case DiagnosesTypes.HemophiliaB: {
          descriptionArray.push(
            bd_hemophilia_severity === HemophiliaSeverityTypes.Other
              ? bd_hemophilia_severity_oth
              : HemophiliaSeverityLabels[bd_hemophilia_severity],
          );
          break;
        }
        case DiagnosesTypes.VonWillebrandDisease: {
          descriptionArray.push(
            vwd_type === VonWillebrandDiseaseTypes.Other
              ? vwd_type_oth
              : VonWillebrandDiseaseLabels[vwd_type],
          );
          if (vwd_type === VonWillebrandDiseaseTypes.Type2) {
            descriptionArray.push(
              vwd_type2 === VwdType2Types.Other
                ? vwd_type2_oth
                : VwdType2Labels[vwd_type2],
            );
          }
          break;
        }
        case DiagnosesTypes.PlateletDisorder: {
          descriptionArray.push(PlateletDisorderLabels[platelet_disorder]);
          break;
        }
        default: {
        }
      }

      return { id: key, description: descriptionArray.join(' / ') };
    });
};

const Diagnoses = (props) => {
  const { isLoading, attributes } = usePersonAttributes();

  const diagnosesNames = useMemo(
    () => parseDiagnoses(attributes),
    [attributes],
  );

  return (
    <Item
      label='Diagnoses'
      content={
        isLoading
          ? skeletonList
          : diagnosesNames.length > 0
            ? diagnosesNames
            : '--'
      }
      {...props}
    />
  );
};

export default Diagnoses;

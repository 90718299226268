import { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Avatar,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import MyInvitationsModal from 'components/NHFModal/MyInvitationsModal';
import { useAuth } from 'context/AuthContext';
import usePersonAttributes from 'hooks/usePersonAttributes';
import { ReactComponent as AccountIcon } from 'images/account.svg';
import { ReactComponent as DashboardIcon } from 'images/dashboard.svg';
import { ReactComponent as UserPlus } from 'images/grey-user-plus.svg';
import { ReactComponent as SignOutIcon } from 'images/signout.svg';
import { useCurrentPersonQuery } from 'store/api/nhf/endpoints/people';
import { PersonStatus } from 'utils/enums';

const User = () => {
  const { isAuthenticated, logout: handleSignOut } = useAuth();
  const [openModal, setOpenModal] = useState(false);

  const handleSetOpen = () => {
    setOpenModal(true);
  };
  const { isEnrollmentCompleted } = useCurrentPersonQuery(
    {},
    {
      skip: !isAuthenticated,
      selectFromResult: ({ data }) => ({
        isEnrollmentCompleted:
          data?.attributes?.status === PersonStatus.Onboarded ||
          data?.attributes?.status === PersonStatus.Enrolled,
      }),
    }
  );

  const { attributes } = usePersonAttributes();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleDashboard = () => navigate('/homepage');
  const handleMyAccount = () => navigate('/homepage/my-account');

  const { pathname } = useLocation();
  const isDashboardPath = pathname === '/homepage';
  const isMyAccountPath = pathname === '/homepage/my-account';

  const name = useMemo(
    () =>
      [
        attributes?.first_name?.value,
        attributes?.middle_name?.value,
        attributes?.last_name?.value,
      ]
        .filter((name) => !!name)
        .join(' '),
    [attributes?.first_name, attributes?.last_name, attributes?.middle_name]
  );

  return (
    <>
      <Stack direction='row' spacing='12px' alignItems='center'>
        {!sm && (
          <Typography
            variant='w600h14'
            color='gray.100'
            onClick={isMyAccountPath ? () => {} : handleMyAccount}
            sx={isMyAccountPath ? {} : { '&:hover': { cursor: 'pointer' } }}
          >
            {name}
          </Typography>
        )}
        <Avatar onClick={handleClick} />
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isEnrollmentCompleted && !isDashboardPath && (
          <MenuItem onClick={handleDashboard}>
            <DashboardIcon />
            <span>Dashboard</span>
          </MenuItem>
        )}
        {isEnrollmentCompleted && !isMyAccountPath && (
          <MenuItem onClick={handleMyAccount}>
            <AccountIcon />
            <span>My Account</span>
          </MenuItem>
        )}
        <MenuItem onClick={handleSetOpen}>
          <UserPlus />
          <span>Invite to CVR</span>
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <SignOutIcon />
          <span>Sign Out</span>
        </MenuItem>
      </Menu>
      <MyInvitationsModal open={openModal} setOpen={setOpenModal} />
    </>
  );
};

export default User;

import usCountiesStates from 'mocks/usCountiesStates';

const enrollment = {
  display: 'wizard',
  navBar: {
    submitLabel: 'next',
    saveLabel: 'save and continue later',
  },
  components: [
    {
      title: 'Page 1',
      label: 'Page 1',
      type: 'panel',
      key: 'page1',
      components: [
        {
          html: 'The enrollment survey will take approximately 5 minutes to complete. You can do it all at once or start, stop, and resume it at a later time. Please take your time and read the questions carefully before answering them.<br/><br/>We ask personal information about your gender identity, race, ethnicity, sexual orientation, and location / address, and primary language spoken because these are important social determinants of health used in research. Remember that the information you provide will be confidential and only reported in a de-identified, aggregated (grouped) manner.<br/><br/><span style="color: var(--palette-error)">*</span> All required fields are indicated with an asterisk.',
          label: 'Content',
          refreshOnChange: false,
          key: 'content',
          type: 'content',
          input: false,
          tableView: false,
        },
        {
          label: 'First name',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          tableView: true,
          disabled: true,
          validate: {
            required: true,
            maxLength: 100,
            custom:
              'valid = input.match(/^[a-zA-Z]{1,100}([a-zA-Z\'\\-\\s]{1,98}[a-zA-Z])?$/u) || input.length === 0 ? true : "You can\'t use special characters in the beginning or the end of your name, the limit of characters is 100."',
          },
          key: 'first_name',
          type: 'textfield',
          input: true,
        },
        {
          label: 'Middle name',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          tableView: true,
          disabled: true,
          validate: {
            maxLength: 100,
            custom:
              'valid = input.match(/^[a-zA-Z]{1,100}([a-zA-Z\'\\-\\s]{1,98}[a-zA-Z])?$/u) || input.length === 0 ? true : "You can\'t use special characters in the beginning or the end of your name, the limit of characters is 100."',
          },
          key: 'middle_name',
          type: 'textfield',
          input: true,
        },
        {
          label: 'Last name',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          tableView: true,
          disabled: true,
          validate: {
            required: true,
            maxLength: 100,
            custom:
              'valid = input.match(/^[a-zA-Z]{1,100}([a-zA-Z\'\\-\\s]{1,98}[a-zA-Z])?$/u) || input.length === 0 ? true : "You can\'t use special characters in the beginning or the end of your name, the limit of characters is 100."',
          },
          key: 'last_name',
          type: 'textfield',
          input: true,
        },
        {
          label: 'Primary email address',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          disabled: true,
          tableView: true,
          validate: {
            required: true,
          },
          kickbox: {
            enabled: true,
          },
          key: 'email',
          type: 'email',
          input: true,
        },
        {
          label: 'Primary phone number',
          placeholder: 'Enter',
          inputMask: '(999) 999-9999',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
          },
          key: 'phone',
          type: 'textfield',
          input: true,
        },
        {
          html: "Research indicates that where people live has a major impact on their health, well-being, and quality of life. CVR asks for your complete address for research purposes only. It will help researchers understand how this important concept affects the bleeding disorder's community.",
          label: 'Content',
          refreshOnChange: false,
          key: 'content10',
          type: 'content',
          input: false,
          tableView: false,
        },
        {
          label: 'Street Address',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'address',
          type: 'textfield',
          input: true,
        },
        {
          label: 'Apartment Number',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            maxLength: 255,
          },
          key: 'apt_number',
          type: 'textfield',
          input: true,
        },
        {
          label: 'City',
          placeholder: 'Enter',
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'city',
          type: 'textfield',
          input: true,
        },
        {
          label: 'What state or US territory do you live in?',
          widget: 'choicesjs',
          placeholder: 'Select state',
          tableView: true,
          disabled: true,
          data: {
            values: Object.keys(usCountiesStates).map((key) => ({
              label: key,
              value: key,
            })),
          },
          validate: {
            required: true,
          },
          key: 'state',
          type: 'select',
          input: true,
        },
        {
          label: 'Zip Code',
          placeholder: 'Enter',
          inputMask: '99999',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
          },
          key: 'postal_code',
          type: 'textfield',
          input: true,
        },
        {
          label: 'County',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          dataSrc: 'custom',
          data: {
            custom: `values = (JSON.parse(${JSON.stringify(
              JSON.stringify(usCountiesStates),
            )})[data.state] ?? []).map(county => ({ label: county, value: county }))`,
          },
          valueProperty: 'value',
          refreshOn: 'state',
          clearOnRefresh: true,
          validate: {
            required: true,
          },
          key: 'county',
          clearOnHide: false,
          conditional: {
            show: false,
            when: 'state',
          },
          type: 'select',
          input: true,
        },
        {
          label: 'Date of Birth',
          format: 'MM/dd/yyyy',
          placeholder: 'Select',
          tableView: false,
          disabled: true,
          datePicker: {
            disableWeekends: false,
            disableWeekdays: false,
            maxDate: 'moment()',
          },
          enableTime: false,
          validate: {
            required: true,
          },
          enableMinDateInput: false,
          enableMaxDateInput: true,
          key: 'date_of_birth',
          type: 'datetime',
          input: true,
          widget: {
            type: 'calendar',
            displayInTimezone: 'viewer',
            locale: 'en',
            useLocaleSettings: false,
            allowInput: true,
            mode: 'single',
            enableTime: false,
            noCalendar: false,
            format: 'MM/dd/yyyy',
            hourIncrement: 1,
            minuteIncrement: 1,
            time_24hr: false,
            minDate: null,
            disableWeekends: false,
            disableWeekdays: false,
            maxDate: 'moment()',
          },
        },
        {
          label: 'Birth Sex (please choose only one)',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          data: {
            values: [
              {
                label: 'Male',
                value: 'male',
              },
              {
                label: 'Female',
                value: 'female',
              },
              {
                label:
                  'Intersex (I have both male and female genitalia, hormones, chromosomes, or reproductive organs)',
                value: 'intersex',
              },
            ],
          },
          validate: {
            required: true,
            onlyAvailableItems: true,
          },
          key: 'sex_at_birth',
          type: 'select',
          input: true,
        },
        {
          label: 'Gender Identity (please choose only one)',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          data: {
            values: [
              {
                label: 'Man',
                value: 'man',
              },
              {
                label: 'Woman',
                value: 'woman',
              },
              {
                label: 'Transman',
                value: 'transman',
              },
              {
                label: 'Transwoman',
                value: 'transwoman',
              },
              {
                label: 'Non-binary',
                value: 'nonBinary',
              },
              {
                label: 'I choose not to answer',
                value: 'iChooseNotToAnswer',
              },
              {
                label: 'Other',
                value: 'other',
              },
            ],
          },
          validate: {
            required: true,
            onlyAvailableItems: true,
          },
          key: 'gender',
          type: 'select',
          input: true,
        },
        {
          label: 'Other',
          placeholder: 'Other...',
          applyMaskOn: 'change',
          hideLabel: true,
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'gender_other',
          clearOnHide: false,
          conditional: {
            show: true,
            when: 'gender',
            eq: 'other',
          },
          type: 'textfield',
          input: true,
        },
        {
          label: 'What is your sexual orientation (please choose only one)',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          data: {
            values: [
              {
                label: 'Heterosexual / Straight',
                value: 'heterosexualStraight',
              },
              {
                label: 'Homosexual / Gay',
                value: 'homosexualGay',
              },
              {
                label: 'Lesbian',
                value: 'lesbian',
              },
              {
                label: 'Bisexual',
                value: 'bisexual',
              },
              {
                label: 'Queer',
                value: 'queer',
              },
              {
                label: 'I choose not to answer',
                value: 'iChooseNotToAnswer',
              },
              {
                label: 'Other',
                value: 'other',
              },
            ],
          },
          validate: {
            required: true,
            onlyAvailableItems: true,
          },
          key: 'sexual_orientation',
          type: 'select',
          input: true,
        },
        {
          label: '',
          placeholder: 'Other...',
          applyMaskOn: 'change',
          hideLabel: true,
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'sexual_orientation_oth',
          clearOnHide: false,
          conditional: {
            show: true,
            when: 'sexual_orientation',
            eq: 'other',
          },
          type: 'textfield',
          input: true,
        },
        {
          label:
            'Are you of Hispanic or Latino origin or descent? (please choose only one)',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          data: {
            values: [
              {
                label: 'Yes, Hispanic or Latino',
                value: 'yesHispanicOrLatino',
              },
              {
                label: 'No, not of Hispanic or Latino origin or descendent',
                value: 'noNotOfHispanicOrLatinoOriginOrDescendent',
              },
              {
                label: 'Do not know / I choose not to answer',
                value: 'doNotKnowPreferNotToAnswer',
              },
            ],
          },
          validate: {
            required: true,
            onlyAvailableItems: true,
          },
          key: 'ethnicity',
          type: 'select',
          input: true,
        },
        {
          label: 'What race do you identify with? (choose all that apply)',
          optionsLabelPosition: 'right',
          tableView: false,
          defaultValue: {
            americanIndianOrAlaskaNative: false,
            blackAndOrAfricanAmerican: false,
            white: false,
            asian: false,
            southAsian: false,
            middleEasternOrNorthAfrican: false,
            nativeHawaiianOrOtherPacificIslander: false,
            unknown: false,
            iChooseNotToAnswer: false,
          },
          values: [
            {
              label: 'American Indian or Alaska Native',
              value: 'americanIndianOrAlaskaNative',
              shortcut: '',
            },
            {
              label: 'Black and / or African American',
              value: 'blackAndOrAfricanAmerican',
              shortcut: '',
            },
            {
              label: 'White',
              value: 'white',
              shortcut: '',
            },
            {
              label: 'Asian',
              value: 'asian',
              shortcut: '',
            },
            {
              label:
                'South Asian (India, Pakistan, Bangladesh, Nepal, Sri Lanka, Bhutan, etc.)',
              value: 'southAsian',
              shortcut: '',
            },
            {
              label:
                'Middle Eastern or North African (Saudi Arabia, Yemen, UAE Iran, Egypt, etc.)',
              value: 'middleEasternOrNorthAfrican',
              shortcut: '',
            },
            {
              label: 'Native Hawaiian or Other Pacific Islander',
              value: 'nativeHawaiianOrOtherPacificIslander',
              shortcut: '',
            },
            {
              label: 'Unknown',
              value: 'unknown',
              shortcut: '',
            },
            {
              label: 'I choose not to answer',
              value: 'iChooseNotToAnswer',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          logic: [
            {
              name: 'Choose not to answer',
              trigger: {
                type: 'javascript',
                javascript: 'result = data?.race?.iChooseNotToAnswer ',
              },
              actions: [
                {
                  name: 'Clear all',
                  type: 'value',
                  value:
                    "value = Object.keys(data?.race).reduce((acc, curr) => ({...acc, [curr]: curr === 'iChooseNotToAnswer'}), {})",
                },
              ],
            },
            {
              name: 'Race Unknown',
              trigger: {
                type: 'javascript',
                javascript: 'result = data?.race?.unknown',
              },
              actions: [
                {
                  name: 'Clear all',
                  type: 'value',
                  value:
                    "value = Object.keys(data?.race).reduce((acc, curr) => ({...acc, [curr]: curr === 'unknown' }), {})",
                },
              ],
            },
          ],
          key: 'race',
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Were you born in the United States?',
          optionsLabelPosition: 'right',
          inline: true,
          tableView: false,
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'born_in_usa',
          type: 'radio',
          input: true,
        },
        {
          label: 'What country were you born in?',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          data: {
            values: [
              {
                label: 'Afghanistan',
                value: 'afghanistan',
              },
              {
                label: 'Albania',
                value: 'albania',
              },
              {
                label: 'Algeria',
                value: 'algeria',
              },
              {
                label: 'Andorra',
                value: 'andorra',
              },
              {
                label: 'Angola',
                value: 'angola',
              },
              {
                label: 'Antigua and Barbuda',
                value: 'antiguaAndBarbuda',
              },
              {
                label: 'Argentina',
                value: 'argentina',
              },
              {
                label: 'Armenia',
                value: 'armenia',
              },
              {
                label: 'Australia',
                value: 'australia',
              },
              {
                label: 'Austria',
                value: 'austria',
              },
              {
                label: 'Azerbaijan',
                value: 'azerbaijan',
              },
              {
                label: 'Bahrain',
                value: 'bahrain',
              },
              {
                label: 'Bangladesh',
                value: 'bangladesh',
              },
              {
                label: 'Barbados',
                value: 'barbados',
              },
              {
                label: 'Belarus',
                value: 'belarus',
              },
              {
                label: 'Belgium',
                value: 'belgium',
              },
              {
                label: 'Belize',
                value: 'belize',
              },
              {
                label: 'Benin',
                value: 'benin',
              },
              {
                label: 'Bhutan',
                value: 'bhutan',
              },
              {
                label: 'Bolivia',
                value: 'bolivia',
              },
              {
                label: 'Bosnia and Herzegovina',
                value: 'bosniaAndHerzegovina',
              },
              {
                label: 'Botswana',
                value: 'botswana',
              },
              {
                label: 'Brazil',
                value: 'brazil',
              },
              {
                label: 'Brunei',
                value: 'brunei',
              },
              {
                label: 'Bulgaria',
                value: 'bulgaria',
              },
              {
                label: 'Burkina Faso',
                value: 'burkinaFaso',
              },
              {
                label: 'Burundi',
                value: 'burundi',
              },
              {
                label: 'Cabo Verde',
                value: 'caboVerde',
              },
              {
                label: 'Cambodia',
                value: 'cambodia',
              },
              {
                label: 'Cameroon',
                value: 'cameroon',
              },
              {
                label: 'Canada',
                value: 'canada',
              },
              {
                label: 'Central African Republic',
                value: 'centralAfricanRepublic',
              },
              {
                label: 'Chad',
                value: 'chad',
              },
              {
                label: 'Channel Islands',
                value: 'channelIslands',
              },
              {
                label: 'Chile',
                value: 'chile',
              },
              {
                label: 'China',
                value: 'china',
              },
              {
                label: 'Colombia',
                value: 'colombia',
              },
              {
                label: 'Comoros',
                value: 'comoros',
              },
              {
                label: 'Congo',
                value: 'congo',
              },
              {
                label: 'Costa Rica',
                value: 'costaRica',
              },
              {
                label: "Côte d'Ivoire",
                value: 'coteDIvoire',
              },
              {
                label: 'Croatia',
                value: 'croatia',
              },
              {
                label: 'Cuba',
                value: 'cuba',
              },
              {
                label: 'Cyprus',
                value: 'cyprus',
              },
              {
                label: 'Czech Republic',
                value: 'czechRepublic',
              },
              {
                label: 'Denmark',
                value: 'denmark',
              },
              {
                label: 'Djibouti',
                value: 'djibouti',
              },
              {
                label: 'Dominica',
                value: 'dominica',
              },
              {
                label: 'Dominican Republic',
                value: 'dominicanRepublic',
              },
              {
                label: 'DR Congo',
                value: 'dRCongo',
              },
              {
                label: 'Ecuador',
                value: 'ecuador',
              },
              {
                label: 'Egypt',
                value: 'egypt',
              },
              {
                label: 'El Salvador',
                value: 'elSalvador',
              },
              {
                label: 'Equatorial Guinea',
                value: 'equatorialGuinea',
              },
              {
                label: 'Eritrea',
                value: 'eritrea',
              },
              {
                label: 'Estonia',
                value: 'estonia',
              },
              {
                label: 'Eswatini',
                value: 'eswatini',
              },
              {
                label: 'Ethiopia',
                value: 'ethiopia',
              },
              {
                label: 'Faeroe Islands',
                value: 'faeroeIslands',
              },
              {
                label: 'Finland',
                value: 'finland',
              },
              {
                label: 'France',
                value: 'france',
              },
              {
                label: 'French Guiana',
                value: 'frenchGuiana',
              },
              {
                label: 'Gabon',
                value: 'gabon',
              },
              {
                label: 'Gambia',
                value: 'gambia',
              },
              {
                label: 'Georgia',
                value: 'georgia',
              },
              {
                label: 'Germany',
                value: 'germany',
              },
              {
                label: 'Ghana',
                value: 'ghana',
              },
              {
                label: 'Gibraltar',
                value: 'gibraltar',
              },
              {
                label: 'Greece',
                value: 'greece',
              },
              {
                label: 'Grenada',
                value: 'grenada',
              },
              {
                label: 'Guatemala',
                value: 'guatemala',
              },
              {
                label: 'Guinea',
                value: 'guinea',
              },
              {
                label: 'Guinea-Bissau',
                value: 'guineaBissau',
              },
              {
                label: 'Guyana',
                value: 'guyana',
              },
              {
                label: 'Haiti',
                value: 'haiti',
              },
              {
                label: 'Holy See',
                value: 'holySee',
              },
              {
                label: 'Honduras',
                value: 'honduras',
              },
              {
                label: 'Hong Kong',
                value: 'hongKong',
              },
              {
                label: 'Hungary',
                value: 'hungary',
              },
              {
                label: 'Iceland',
                value: 'iceland',
              },
              {
                label: 'India',
                value: 'india',
              },
              {
                label: 'Indonesia',
                value: 'indonesia',
              },
              {
                label: 'Iran',
                value: 'iran',
              },
              {
                label: 'Iraq',
                value: 'iraq',
              },
              {
                label: 'Ireland',
                value: 'ireland',
              },
              {
                label: 'Isle of Man',
                value: 'isleOfMan',
              },
              {
                label: 'Israel',
                value: 'israel',
              },
              {
                label: 'Italy',
                value: 'italy',
              },
              {
                label: 'Jamaica',
                value: 'jamaica',
              },
              {
                label: 'Japan',
                value: 'japan',
              },
              {
                label: 'Jordan',
                value: 'jordan',
              },
              {
                label: 'Kazakhstan',
                value: 'kazakhstan',
              },
              {
                label: 'Kenya',
                value: 'kenya',
              },
              {
                label: 'Kuwait',
                value: 'kuwait',
              },
              {
                label: 'Kyrgyzstan',
                value: 'kyrgyzstan',
              },
              {
                label: 'Laos',
                value: 'laos',
              },
              {
                label: 'Latvia',
                value: 'latvia',
              },
              {
                label: 'Lebanon',
                value: 'lebanon',
              },
              {
                label: 'Lesotho',
                value: 'lesotho',
              },
              {
                label: 'Liberia',
                value: 'liberia',
              },
              {
                label: 'Libya',
                value: 'libya',
              },
              {
                label: 'Liechtenstein',
                value: 'liechtenstein',
              },
              {
                label: 'Lithuania',
                value: 'lithuania',
              },
              {
                label: 'Luxembourg',
                value: 'luxembourg',
              },
              {
                label: 'Macao',
                value: 'macao',
              },
              {
                label: 'Madagascar',
                value: 'madagascar',
              },
              {
                label: 'Malawi',
                value: 'malawi',
              },
              {
                label: 'Malaysia',
                value: 'malaysia',
              },
              {
                label: 'Maldives',
                value: 'maldives',
              },
              {
                label: 'Mali',
                value: 'mali',
              },
              {
                label: 'Malta',
                value: 'malta',
              },
              {
                label: 'Mauritania',
                value: 'mauritania',
              },
              {
                label: 'Mauritius',
                value: 'mauritius',
              },
              {
                label: 'Mayotte',
                value: 'mayotte',
              },
              {
                label: 'Mexico',
                value: 'mexico',
              },
              {
                label: 'Moldova',
                value: 'moldova',
              },
              {
                label: 'Monaco',
                value: 'monaco',
              },
              {
                label: 'Mongolia',
                value: 'mongolia',
              },
              {
                label: 'Montenegro',
                value: 'montenegro',
              },
              {
                label: 'Morocco',
                value: 'morocco',
              },
              {
                label: 'Mozambique',
                value: 'mozambique',
              },
              {
                label: 'Myanmar',
                value: 'myanmar',
              },
              {
                label: 'Namibia',
                value: 'namibia',
              },
              {
                label: 'Nepal',
                value: 'nepal',
              },
              {
                label: 'Netherlands',
                value: 'netherlands',
              },
              {
                label: 'Nicaragua',
                value: 'nicaragua',
              },
              {
                label: 'Niger',
                value: 'niger',
              },
              {
                label: 'Nigeria',
                value: 'nigeria',
              },
              {
                label: 'North Korea',
                value: 'northKorea',
              },
              {
                label: 'North Macedonia',
                value: 'northMacedonia',
              },
              {
                label: 'Norway',
                value: 'norway',
              },
              {
                label: 'Oman',
                value: 'oman',
              },
              {
                label: 'Pakistan',
                value: 'pakistan',
              },
              {
                label: 'Panama',
                value: 'panama',
              },
              {
                label: 'Paraguay',
                value: 'paraguay',
              },
              {
                label: 'Peru',
                value: 'peru',
              },
              {
                label: 'Philippines',
                value: 'philippines',
              },
              {
                label: 'Poland',
                value: 'poland',
              },
              {
                label: 'Portugal',
                value: 'portugal',
              },
              {
                label: 'Qatar',
                value: 'qatar',
              },
              {
                label: 'Réunion',
                value: 'reunion',
              },
              {
                label: 'Romania',
                value: 'romania',
              },
              {
                label: 'Russia',
                value: 'russia',
              },
              {
                label: 'Rwanda',
                value: 'rwanda',
              },
              {
                label: 'Saint Helena',
                value: 'saintHelena',
              },
              {
                label: 'Saint Kitts and Nevis',
                value: 'saintKittsAndNevis',
              },
              {
                label: 'Saint Lucia',
                value: 'saintLucia',
              },
              {
                label: 'Saint Vincent and the Grenadines',
                value: 'saintVincentAndTheGrenadines',
              },
              {
                label: 'San Marino',
                value: 'sanMarino',
              },
              {
                label: 'Sao Tome & Principe',
                value: 'saoTomePrincipe',
              },
              {
                label: 'Saudi Arabia',
                value: 'saudiArabia',
              },
              {
                label: 'Senegal',
                value: 'senegal',
              },
              {
                label: 'Serbia',
                value: 'serbia',
              },
              {
                label: 'Seychelles',
                value: 'seychelles',
              },
              {
                label: 'Sierra Leone',
                value: 'sierraLeone',
              },
              {
                label: 'Singapore',
                value: 'singapore',
              },
              {
                label: 'Slovakia',
                value: 'slovakia',
              },
              {
                label: 'Slovenia',
                value: 'slovenia',
              },
              {
                label: 'Somalia',
                value: 'somalia',
              },
              {
                label: 'South Africa',
                value: 'southAfrica',
              },
              {
                label: 'South Korea',
                value: 'southKorea',
              },
              {
                label: 'South Sudan',
                value: 'southSudan',
              },
              {
                label: 'Spain',
                value: 'spain',
              },
              {
                label: 'Sri Lanka',
                value: 'sriLanka',
              },
              {
                label: 'State of Palestine',
                value: 'stateOfPalestine',
              },
              {
                label: 'Sudan',
                value: 'sudan',
              },
              {
                label: 'Suriname',
                value: 'suriname',
              },
              {
                label: 'Sweden',
                value: 'sweden',
              },
              {
                label: 'Switzerland',
                value: 'switzerland',
              },
              {
                label: 'Syria',
                value: 'syria',
              },
              {
                label: 'Taiwan',
                value: 'taiwan',
              },
              {
                label: 'Tajikistan',
                value: 'tajikistan',
              },
              {
                label: 'Tanzania',
                value: 'tanzania',
              },
              {
                label: 'Thailand',
                value: 'thailand',
              },
              {
                label: 'The Bahamas',
                value: 'theBahamas',
              },
              {
                label: 'Timor-Leste',
                value: 'timorLeste',
              },
              {
                label: 'Togo',
                value: 'togo',
              },
              {
                label: 'Trinidad and Tobago',
                value: 'trinidadAndTobago',
              },
              {
                label: 'Tunisia',
                value: 'tunisia',
              },
              {
                label: 'Turkey',
                value: 'turkey',
              },
              {
                label: 'Turkmenistan',
                value: 'turkmenistan',
              },
              {
                label: 'Uganda',
                value: 'uganda',
              },
              {
                label: 'Ukraine',
                value: 'ukraine',
              },
              {
                label: 'United Arab Emirates',
                value: 'unitedArabEmirates',
              },
              {
                label: 'United Kingdom',
                value: 'unitedKingdom',
              },
              {
                label: 'Uruguay',
                value: 'uruguay',
              },
              {
                label: 'Uzbekistan',
                value: 'uzbekistan',
              },
              {
                label: 'Venezuela',
                value: 'venezuela',
              },
              {
                label: 'Vietnam',
                value: 'vietnam',
              },
              {
                label: 'Western Sahara',
                value: 'westernSahara',
              },
              {
                label: 'Yemen',
                value: 'yemen',
              },
              {
                label: 'Zambia',
                value: 'zambia',
              },
              {
                label: 'Zimbabwe',
                value: 'zimbabwe',
              },
            ],
          },
          validate: {
            required: true,
            onlyAvailableItems: true,
          },
          key: 'birth_country',
          clearOnHide: false,
          conditional: {
            show: true,
            when: 'born_in_usa',
            eq: 'no',
          },
          type: 'select',
          input: true,
        },
        {
          label:
            'What language are you most comfortable speaking? (choose only one)',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          data: {
            values: [
              {
                label: 'English',
                value: 'english',
              },
              {
                label: 'Spanish',
                value: 'spanish',
              },
              {
                label: 'American Sign language (ASL)',
                value: 'americanSignlanguage',
              },
              {
                label: 'Arabic',
                value: 'arabic',
              },
              {
                label: 'Bengali',
                value: 'bengali',
              },
              {
                label: 'Chinese',
                value: 'chinese',
              },
              {
                label: 'Dutch',
                value: 'dutch',
              },
              {
                label: 'French',
                value: 'french',
              },
              {
                label: 'German',
                value: 'german',
              },
              {
                label: 'Hebrew',
                value: 'hebrew',
              },
              {
                label: 'Hindi',
                value: 'hindi',
              },
              {
                label: 'Italian',
                value: 'italian',
              },
              {
                label: 'Japanese',
                value: 'japanese',
              },
              {
                label: 'Korean',
                value: 'korean',
              },
              {
                label: 'Lahnda',
                value: 'lahnda',
              },
              {
                label: 'Malay',
                value: 'malay',
              },
              {
                label: 'Persian',
                value: 'persian',
              },
              {
                label: 'Polish',
                value: 'polish',
              },
              {
                label: 'Portuguese',
                value: 'portuguese',
              },
              {
                label: 'Russian',
                value: 'russian',
              },
              {
                label: 'Tamil',
                value: 'tamil',
              },
              {
                label: 'Telugu',
                value: 'telugu',
              },
              {
                label: 'Turkish',
                value: 'turkish',
              },
              {
                label: 'Urdu',
                value: 'urdu',
              },
              {
                label: 'Vietnamese',
                value: 'vietnamese',
              },
              {
                label: 'Other',
                value: 'other',
              },
            ],
          },
          validate: {
            required: true,
            onlyAvailableItems: true,
          },
          key: 'language',
          type: 'select',
          input: true,
        },
        {
          label: 'Other',
          placeholder: 'Other...',
          applyMaskOn: 'change',
          hideLabel: true,
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'oth_pri_lang',
          clearOnHide: false,
          conditional: {
            show: true,
            when: 'language',
            eq: 'other',
          },
          type: 'textfield',
          input: true,
        },
        {
          html: 'The next few questions will ask about your diagnosis.',
          label: 'Content',
          refreshOnChange: false,
          type: 'content',
          input: false,
          tableView: false,
        },
        {
          label: 'Are you a carrier for an inheritable bleeding disorder?',
          optionsLabelPosition: 'right',
          inline: true,
          tableView: false,
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
            {
              label: 'I don’t know',
              value: 'iDontKnow',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'bd_carrier',
          clearOnHide: false,
          customConditional:
            "show = data.sex_at_birth === 'female' || data.sex_at_birth === 'intersex'",
          type: 'radio',
          input: true,
        },
        {
          html: '<p>Abnormal bleeding symptoms include:</p><ul><li>Easy bruising</li><li>Joint bleeding</li><li>Prolonged or heavy periods (bleeding for more than 7 days, requiring changing sanitary products more often than every 2 hours, passing clots or experiencing flooding, soiling clothes)</li><li>Prolonged bleeding during and after giving birth</li><li>Excessive bleeding during and after surgeries or other procedures</li><li>Frequent or profuse nose bleeding</li><li>Bleeding that required medical intervention to stop</li></ul>',
          label: 'Content',
          refreshOnChange: false,
          type: 'content',
          input: false,
          tableView: false,
        },
        {
          label:
            'Do you currently have or have had a history of abnormal bleeding symptoms?',
          optionsLabelPosition: 'right',
          inline: true,
          tableView: false,
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'bd_hist_symptoms',
          type: 'radio',
          input: true,
        },
        {
          label:
            'Do you have a bleeding disorder yourself? (please choose only one)',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          data: {
            values: [
              {
                label:
                  'Yes, I have been formally diagnosed with a bleeding disorder (including being a carrier) by a health care provider',
                value:
                  'yesIHaveBeenFormallyDiagnosedWithABleedingDisorderIncludingBeingACarrierByAHealthCareProvider',
              },
              {
                label:
                  'Yes, although I have NOT been formally diagnosed, I know I do',
                value: 'yesAlthoughIHaveNotBeenFormallyDiagnosedIKnowIDo',
              },
              {
                label:
                  'No, I have been evaluated by a health care provider and I was told I do not have a bleeding disorder',
                value:
                  'noIHaveBeenEvaluatedByAHealthCareProviderAndIWasToldIDoNotHaveABleedingDisorder',
              },
              {
                label:
                  'No, I have not been evaluated by a health care provider because there is no need',
                value:
                  'noIHaveNotBeenEvaluatedByAHealthCareProviderBecauseThereIsNoNeed',
              },
              {
                label:
                  'I am not sure, I have been evaluated by a health care provider but I don’t have a diagnosis',
                value:
                  'iAmNotSureIHaveBeenEvaluatedByAHealthCareProviderButIDontHaveADiagnosis',
              },
              {
                label:
                  ' I am not sure, I have NOT been evaluated by a health care provider',
                value: 'iAmNotSureIHaveNotBeenEvaluatedByAHealthCareProvider',
              },
              {
                label: 'Other',
                value: 'other',
              },
            ],
          },
          validate: {
            required: true,
          },
          key: 'bleeding_disorder',
          type: 'select',
          input: true,
        },
        {
          label: 'Other',
          placeholder: 'Other...',
          applyMaskOn: 'change',
          hideLabel: true,
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'bleeding_disorder_oth',
          clearOnHide: false,
          conditional: {
            show: true,
            when: 'bleeding_disorder',
            eq: 'other',
          },
          type: 'textfield',
          input: true,
        },
        {
          label:
            'What is your bleeding disorder diagnosis (including diagnosis as a carrier)? (choose all that apply)',
          optionsLabelPosition: 'right',
          tableView: false,
          defaultValue: {
            hemophiliaA: false,
            hemophiliaB: false,
            vonWillebrandDisease: false,
            plateletDisorder: false,
            factorIDeficiency: false,
            factorIiDeficiency: false,
            factorVDeficiency: false,
            factorViiDeficiency: false,
            factorXDeficiency: false,
            factorXiDeficiency: false,
            factorXiiiDeficiency: false,
            pai1Deficiency: false,
            a2AntiPlasminDeficiency: false,
            ehlersDanlosSyndrome: false,
            connectiveTissueDisorderOtherThanEhlersDanlos: false,
            bleedingOfUnknownCause: false,
            other: false,
          },
          values: [
            {
              label:
                'Hemophilia A (Factor VIII or factor 8 deficiency, classic hemophilia)',
              value: 'hemophiliaA',
              shortcut: '',
            },
            {
              label:
                'Hemophilia B (Factor IX or factor 9 deficiency, Christmas hemophilia)',
              value: 'hemophiliaB',
              shortcut: '',
            },
            {
              label: 'Von Willebrand Disease (VWD)',
              value: 'vonWillebrandDisease',
              shortcut: '',
            },
            {
              label: 'Platelet disorder',
              value: 'plateletDisorder',
              shortcut: '',
            },
            {
              label: 'Factor I deficiency (1, various fibrinogen deficiencies)',
              value: 'factorIDeficiency',
              shortcut: '',
            },
            {
              label: 'Factor II deficiency (2, prothrombin)',
              value: 'factorIiDeficiency',
              shortcut: '',
            },
            {
              label: 'Factor V deficiency (5)',
              value: 'factorVDeficiency',
              shortcut: '',
            },
            {
              label: 'Factor VII deficiency (7)',
              value: 'factorViiDeficiency',
              shortcut: '',
            },
            {
              label: 'Factor X deficiency (10)',
              value: 'factorXDeficiency',
              shortcut: '',
            },
            {
              label: 'Factor XI deficiency (11 or Hemophilia C)',
              value: 'factorXiDeficiency',
              shortcut: '',
            },
            {
              label: 'Factor XIII deficiency (13)',
              value: 'factorXiiiDeficiency',
              shortcut: '',
            },
            {
              label:
                'PAI-1 deficiency (plasminogen activator inhibitor-1 deficiency)',
              value: 'pai1Deficiency',
              shortcut: '',
            },
            {
              label: 'a (alpha) 2 anti-plasmin deficiency (a2-aP)',
              value: 'a2AntiPlasminDeficiency',
              shortcut: '',
            },
            {
              label: 'Ehlers-Danlos Syndrome',
              value: 'ehlersDanlosSyndrome',
              shortcut: '',
            },
            {
              label: 'Connective tissue disorder other than Ehlers-Danlos',
              value: 'connectiveTissueDisorderOtherThanEhlersDanlos',
              shortcut: '',
            },
            {
              label: 'Bleeding of unknown cause',
              value: 'bleedingOfUnknownCause',
              shortcut: '',
            },
            {
              label: 'Other rare bleeding disorder',
              value: 'other',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'bleeding_disorder_diag',
          clearOnHide: false,
          customConditional:
            "show = utils.getComponent(instance.root.components, 'bleeding_disorder')?.visible && data.bleeding_disorder === 'yesIHaveBeenFormallyDiagnosedWithABleedingDisorderIncludingBeingACarrierByAHealthCareProvider'",
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Other',
          placeholder: 'Other...',
          applyMaskOn: 'change',
          hideLabel: true,
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'bleeding_disorder_diag_oth_txt',
          clearOnHide: false,
          customConditional:
            "show = utils.getComponent(instance.root.components, 'bleeding_disorder_diag')?.visible && data.bleeding_disorder_diag.other",
          type: 'textfield',
          input: true,
        },
        {
          label: 'What is your VWD type? (please choose only one)',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          data: {
            values: [
              {
                label: 'Type 1',
                value: 'type1',
              },
              {
                label: 'Type 1C',
                value: 'type1C',
              },
              {
                label: 'Type 2',
                value: 'type2',
              },
              {
                label: 'Type 3',
                value: 'type3',
              },
              {
                label: 'Unknown by my doctor',
                value: 'unknownByMyDoctor',
              },
              {
                label: 'Unknown by me',
                value: 'unknownByMe',
              },
              {
                label: 'Other',
                value: 'other',
              },
            ],
          },
          validate: {
            required: true,
            onlyAvailableItems: true,
          },
          key: 'vwd_type',
          clearOnHide: false,
          customConditional:
            "show = utils.getComponent(instance.root.components, 'bleeding_disorder_diag')?.visible && data.bleeding_disorder_diag.vonWillebrandDisease",
          type: 'select',
          input: true,
        },
        {
          label: 'Other',
          placeholder: 'Other...',
          applyMaskOn: 'change',
          hideLabel: true,
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'vwd_type_oth',
          clearOnHide: false,
          customConditional:
            "show = utils.getComponent(instance.root.components, 'vwd_type')?.visible && data.vwd_type === 'other'",
          type: 'textfield',
          input: true,
        },
        {
          label: 'What is your VWD Type 2 category? (please choose only one)',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          data: {
            values: [
              {
                label: '2A',
                value: '2A',
              },
              {
                label: '2B',
                value: '2B',
              },
              {
                label: '2M',
                value: '2M',
              },
              {
                label: '2N',
                value: '2N',
              },
              {
                label: 'Unknown by my doctor',
                value: 'unknownByMyDoctor',
              },
              {
                label: 'Unknown by me',
                value: 'unknownByMe',
              },
              {
                label: 'Other',
                value: 'other',
              },
            ],
          },
          validate: {
            required: true,
            onlyAvailableItems: true,
          },
          key: 'vwd_type2',
          clearOnHide: false,
          customConditional:
            "show = utils.getComponent(instance.root.components, 'vwd_type')?.visible && data.vwd_type === 'type2'",
          type: 'select',
          input: true,
        },
        {
          label: 'Other',
          placeholder: 'Other...',
          applyMaskOn: 'change',
          hideLabel: true,
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'vwd_type2_oth',
          clearOnHide: false,
          customConditional:
            "show = utils.getComponent(instance.root.components, 'vwd_type2')?.visible && data.vwd_type2 === 'other'",
          type: 'textfield',
          input: true,
        },
        {
          label: 'What is your hemophilia severity? (please choose only one)',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          data: {
            values: [
              {
                label: 'Mild (greater than 5% but less than 50%)',
                value: 'mild',
              },
              {
                label: 'Moderate (1%-5%)',
                value: 'moderate',
              },
              {
                label: 'Severe (less than 1%)',
                value: 'severe',
              },
              {
                label: 'I am not sure about my severity',
                value: 'iAmNotSureAboutMySeverity',
              },
              {
                label: 'Other',
                value: 'other',
              },
            ],
          },
          validate: {
            required: true,
            onlyAvailableItems: true,
          },
          key: 'bd_hemophilia_severity',
          clearOnHide: false,
          customConditional:
            "show = utils.getComponent(instance.root.components, 'bleeding_disorder_diag')?.visible && (data.bleeding_disorder_diag?.hemophiliaA || data.bleeding_disorder_diag?.hemophiliaB || data.bleeding_disorder_diag?.otherRareBleedingDisorder)",
          type: 'select',
          input: true,
        },
        {
          label: 'Other',
          placeholder: 'Other...',
          applyMaskOn: 'change',
          hideLabel: true,
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'bd_hemophilia_severity_oth',
          clearOnHide: false,
          customConditional:
            "show = utils.getComponent(instance.root.components, 'bd_hemophilia_severity')?.visible && data.bd_hemophilia_severity === 'other'",
          type: 'textfield',
          input: true,
        },
        {
          label: 'What is your platelet disorder? (please choose only one)',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          data: {
            values: [
              {
                label: 'Glanzmann Thrombasthenia (GT)',
                value: 'glanzmannThrombasthenia',
              },
              {
                label: 'Bernard-Soulier Syndrome',
                value: 'bernardSoulierSyndrome',
              },
              {
                label:
                  'Grey Platelet Syndrome (Alpha-Storage Pool Deficiency, Alpha-Granule Deficiency)',
                value: 'greyPlateletSyndrome',
              },
              {
                label: 'Hermansky-Pudlack Syndrome',
                value: 'hermanskyPudlackSyndrome',
              },
              {
                label: 'Inherited Thrombocytopenia',
                value: 'inheritedThrombocytopenia',
              },
              {
                label: 'Platelet Release Defect',
                value: 'plateletReleaseDefect',
              },
              {
                label: 'Platelet Storage Pool Defect',
                value: 'plateletStoragePoolDefect',
              },
              {
                label: 'Unknown by my doctor',
                value: 'unknownByMyDoctor',
              },
              {
                label: 'Unknown by me',
                value: 'unknownByMe',
              },
            ],
          },
          validate: {
            required: true,
            onlyAvailableItems: true,
          },
          key: 'platelet_disorder',
          clearOnHide: false,
          customConditional:
            "show = utils.getComponent(instance.root.components, 'bleeding_disorder_diag')?.visible && data.bleeding_disorder_diag.plateletDisorder",
          type: 'select',
          input: true,
        },
        {
          label:
            'Which of your bleeding disorders causes you the most difficulty? (please choose only one)',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          dataSrc: 'custom',
          valueProperty: 'value',
          data: {
            custom:
              'const defaultOptions = [\n  { label: "I’m not sure", value: "imNotSure" },\n  { label: "My doctor is not sure", value: "myDoctorIsNotSure" }\n  ];\n\nconst bleedingDisorder = utils.searchComponents(form.components, { key: \'bleeding_disorder_diag\'})?.[0]?.values;\nconst other = data.bleeding_disorder_diag_oth_txt;\n\nconst selectedOptions = bleedingDisorder\n  ?.filter?.(({ value }) => data.bleeding_disorder_diag[value])\n  ?.map(({ value, label }) => value === \'other\' ? { value, label: other } : ({ value, label}))\n    ?? [];\n\nvalues = [...selectedOptions, ...defaultOptions];',
          },
          refreshOn: 'bleeding_disorder_diag',
          clearOnRefresh: true,
          validate: {
            required: true,
          },
          key: 'bd_type',
          clearOnHide: false,
          customConditional:
            "show = utils.getComponent(instance.root.components, 'bleeding_disorder_diag')?.visible && data.bleeding_disorder_diag && Object.values(data.bleeding_disorder_diag).filter(value => value).length > 1",
          type: 'select',
          input: true,
        },
        {
          html: 'The next few questions will ask about your family history (including both living and deceased) of bleeding disorders',
          label: 'Content',
          refreshOnChange: false,
          key: 'content3',
          type: 'content',
          input: false,
          tableView: false,
        },
        {
          label:
            'Do you have biological (by blood) family with a history of a bleeding disorder?',
          optionsLabelPosition: 'right',
          inline: true,
          tableView: false,
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          clearOnHide: false,
          key: 'bd_hist_bio',
          type: 'radio',
          input: true,
        },
        {
          label:
            'Who are your biological (by blood) family members with a history of bleeding disorder? (choose all that apply) ',
          optionsLabelPosition: 'right',
          tableView: false,
          defaultValue: {
            mother: false,
            father: false,
            sister: false,
            brother: false,
            daughter: false,
            son: false,
            granddaughter: false,
            grandson: false,
            grandmother: false,
            grandfather: false,
            other: false,
          },
          values: [
            {
              label: 'Mother',
              value: 'mother',
              shortcut: '',
            },
            {
              label: 'Father',
              value: 'father',
              shortcut: '',
            },
            {
              label: 'Sister',
              value: 'sister',
              shortcut: '',
            },
            {
              label: 'Brother',
              value: 'brother',
              shortcut: '',
            },
            {
              label: 'Daughter',
              value: 'daughter',
              shortcut: '',
            },
            {
              label: 'Son',
              value: 'son',
              shortcut: '',
            },
            {
              label: 'Granddaughter',
              value: 'granddaughter',
              shortcut: '',
            },
            {
              label: 'Grandson',
              value: 'grandson',
              shortcut: '',
            },
            {
              label: 'Grandmother',
              value: 'grandmother',
              shortcut: '',
            },
            {
              label: 'Grandfather',
              value: 'grandfather',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'bd_family_hist',
          clearOnHide: false,
          conditional: {
            show: true,
            when: 'bd_hist_bio',
            eq: 'yes',
          },
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Other',
          placeholder: 'Other...',
          applyMaskOn: 'change',
          hideLabel: true,
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'bd_family_hist_oth',
          clearOnHide: false,
          customConditional:
            "show = utils.getComponent(instance.root.components, 'bd_family_hist')?.visible && !!data.bd_family_hist?.other",
          type: 'textfield',
          input: true,
        },
        {
          label:
            'Do you have non-biological (by adoption or marriage) family with a history of a bleeding disorder?',
          optionsLabelPosition: 'right',
          inline: true,
          tableView: false,
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'bd_hist_non_bio',
          type: 'radio',
          input: true,
        },
        {
          label:
            'Who are your non-biological (by adoption or marriage) family members with a history of bleeding disorder? (choose all that apply)',
          optionsLabelPosition: 'right',
          tableView: false,
          defaultValue: {
            spouseSignificantOther: false,
            adoptedChild: false,
            stepChild: false,
            stepParent: false,
            other: false,
          },
          values: [
            {
              label: 'Spouse or significant other',
              value: 'spouseSignificantOther',
              shortcut: '',
            },
            {
              label: 'Adopted child',
              value: 'adoptedChild',
              shortcut: '',
            },
            {
              label: 'Step child',
              value: 'stepChild',
              shortcut: '',
            },
            {
              label: 'Step parent',
              value: 'stepParent',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'bd_non_bio_hist',
          conditional: {
            show: true,
            when: 'bd_hist_non_bio',
            eq: 'yes',
          },
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Other',
          placeholder: 'Other...',
          applyMaskOn: 'change',
          hideLabel: true,
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'bd_non_bio_hist_oth',
          clearOnHide: false,
          customConditional:
            "show =  utils.getComponent(instance.root.components, 'bd_non_bio_hist')?.visible && !!data.bd_non_bio_hist?.other",
          type: 'textfield',
          input: true,
        },
        {
          label:
            'How did you learn about Community Voices in Research (CVR)? (choose all that apply)',
          optionsLabelPosition: 'right',
          tableView: false,
          values: [
            {
              label: 'Conference or presentation',
              value: 'conferencePresentation',
              shortcut: '',
            },
            {
              label: 'Print advertisement (brochures, flyers, etc.)',
              value: 'printAdvertisement',
              shortcut: '',
            },
            {
              label: 'Word of Mouth',
              value: 'wordOfMouth',
              shortcut: '',
            },
            {
              label: 'Friends or family members',
              value: 'friendsFamilyMember',
              shortcut: '',
            },
            {
              label: 'Health care provider',
              value: 'physicianHealthCareProvider',
              shortcut: '',
            },
            {
              label: 'Hemophilia Treatment Center (HTC) staff',
              value: 'hemophiliaTreatmentCenterStaff',
              shortcut: '',
            },
            {
              label: 'Email',
              value: 'email',
              shortcut: '',
            },
            {
              label: 'HemAware publication',
              value: 'hemAwarePublication',
              shortcut: '',
            },
            {
              label: 'Instagram',
              value: 'instagram',
              shortcut: '',
            },
            {
              label: 'Facebook',
              value: 'facebook',
              shortcut: '',
            },
            {
              label: 'X, formally known as Twitter',
              value: 'twitter',
              shortcut: '',
            },
            {
              label: 'LinkedIn',
              value: 'linkedIn',
              shortcut: '',
            },
            {
              label: 'NBDF website',
              value: 'nbdfWebsite',
              shortcut: '',
            },
            {
              label: 'Local NBDF chapter',
              value: 'localNbdfChapter',
              shortcut: '',
            },
            {
              label: 'Home Health Agency',
              value: 'homeHealthAgency',
              shortcut: '',
            },
            {
              label: 'Specialty Pharmacy',
              value: 'specialtyPharmacy',
              shortcut: '',
            },
            {
              label: 'Other',
              value: 'other',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'cvr_communications',
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        },
        {
          label: 'Other',
          placeholder: 'Other...',
          applyMaskOn: 'change',
          hideLabel: true,
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'cvr_communications_oth',
          clearOnHide: false,
          customConditional: 'show = !!data.cvr_communications?.other',
          type: 'textfield',
          input: true,
        },
      ],
      input: false,
      tableView: false,
    },
  ],
};

export default enrollment;

const formPSEQ = {
  title: 'Form PSEQ',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'PSEQ',
  customConditional:
    'const allow = [2, "2", 3, "3", 4, "4", 5, "5"]; show = allow.includes(data?.PN1) || allow.includes(data?.PN2_PN3?.PN2) || allow.includes(data?.PN2_PN3?.PN3);',
  type: 'panel',
  label: 'Form PSEQ',
  input: false,
  tableView: false,
  components: [
    {
      label:
        'Please rate how confident you are that you can do the following things at present, despite the pain',
      tableView: false,
      questions: [
        {
          label:
            'I can still accomplish most of my goals in life, despite the pain.',
          value: 'PSEQ1',
          tooltip: '',
        },
        {
          label: 'I can live a normal lifestyle, despite the pain.',
          value: 'PSEQ2',
          tooltip: '',
        },
      ],
      values: [
        {
          label: 'Not at all Confident',
          value: '0',
          tooltip: '',
        },
        {
          label: '1',
          value: '1',
          tooltip: '',
        },
        {
          label: '2',
          value: '2',
          tooltip: '',
        },
        {
          label: '3',
          value: '3',
          tooltip: '',
        },
        {
          label: '4',
          value: '4',
          tooltip: '',
        },
        {
          label: '5',
          value: '5',
          tooltip: '',
        },
        {
          label: 'Completely Confident',
          value: '6',
          tooltip: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'PSEQ1_PSEQ2',
      type: 'survey',
      input: true,
    },
  ],
};

export default formPSEQ;

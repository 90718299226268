import * as d3 from 'd3';

export const getTooltip = (id, theme) => {
  const tooltipId = `${id}-tooltip`;
  d3.select(`#${tooltipId}`)?.remove?.();
  return d3
    .select('body')
    .append('div')
    .attr('id', tooltipId)
    .style('position', 'absolute')
    .style('opacity', 0)
    .style('background-color', theme.palette.white)
    .style('border', 'solid')
    .style('border-width', '1px')
    .style('border-radius', '5px')
    .style('padding', '10px 0')
    .style('font-family', 'sans-serif')
    .style('z-index', '1000')
    .style('pointer-events', 'none');
};

export const tooltipMouseMoveHandler = (data, tooltip, theme) => (event, d) =>
  tooltip
    .interrupt()
    .html(
      [
        `
  <table style="
    text-align: left;
    border-spacing: 8px;
    border-collapse: collapse;
  ">
    <tbody>
    `,
        ...(data?.children?.map?.(
          ({ color, name, value }) => `
        <tr style="
          background-color: ${
            d.data.name === name ? theme.palette.gray[100] : 'unset'
          };
          color: ${d.data.name === name ? theme.palette.white : 'unset'}">
          <td>
            <div style="
              margin: 0 10px;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: ${color};
            " />
          </td>
          <td>
            <span style="font-weight: 500">
              ${name}
            </span>
          </td>
          <td align="right">
            <span style="
              margin: 0 10px;
              font-weight: 700;
            ">
              ${value}%
            </span>
          </td>
        </tr>
`
        ) ?? []),
        `
    </tbody>
  </table>
`,
      ].join('\n')
    )
    .style('opacity', 0.9)
    .style('top', `${event.pageY}px`)
    .style('left', `${event.pageX}px`);

export const tooltipMouseLeaveEvent = (tooltip) => () =>
  tooltip.transition().duration(200).style('opacity', 0.0);

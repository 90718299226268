import nhfApi from 'store/api/nhf';
import { NhfApiVersions, RequestMethods } from 'utils/enums';

const personAttributesApi = nhfApi.injectEndpoints({
  endpoints: (build) => ({
    upsertPersonAttribute: build.mutation({
      query: ({ body, personId }) => ({
        url: `/app/people/${personId}/attributes`,
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Patch,
        body,
        bypassCaseConverter: true,
      }),
      invalidatesTags: ['PersonAttributes'],
    }),
    deletePersonAttribute: build.mutation({
      query: ({ body, personId }) => ({
        url: `/app/people/${personId}/attributes`,
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Delete,
        body,
        bypassCaseConverter: true,
      }),
      invalidatesTags: ['PersonAttributes'],
    }),
    getPersonAttributes: build.query({
      query: ({ personId, limit = 100 }) => ({
        url: `/people/${personId}/attributes?page[limit]=${limit}`,
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Get,
      }),
      providesTags: ['PersonAttributes'],
      transformResponse: (result) =>
        result?.data
          ?.map?.((attribute) => ({
            ...attribute.attributes,
            id: attribute.id,
          }))
          .reduce(
            (acc, { attribute, ...rest }) => ({ ...acc, [attribute]: rest }),
            {},
          ),
    }),
  }),
  overrideExisting: false,
});

export const {
  useUpsertPersonAttributeMutation,
  useDeletePersonAttributeMutation,
  useGetPersonAttributesQuery,
} = personAttributesApi;

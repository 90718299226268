import * as d3 from 'd3';

export const createTooltip = (id) => {
  const tooltipId = `${id}-tooltip`;
  d3.select(`#${tooltipId}`)?.remove?.();
  return d3
    .select('body')
    .append('div')
    .attr('id', tooltipId)
    .style('position', 'absolute')
    .style('opacity', 0)
    .style('background-color', 'white')
    .style('border', 'solid')
    .style('border-width', '1px')
    .style('border-radius', '5px')
    .style('font-family', 'sans-serif')
    .style('padding', '10px')
    .style('z-index', '1000')
    .style('pointer-events', 'none');
};

const createLegendUnit = ({
  svg,
  color,
  text,
  x,
  legendRadius,
  legendWidth,
}) => {
  const legendUnit = svg.append('g').attr('transform', `translate(${x}, ${0})`);
  legendUnit.append('circle').attr('r', legendRadius).style('fill', color);
  legendUnit
    .append('text')
    .attr('x', legendWidth)
    .text(text)
    .style('font-size', '15px')
    .style('font-weight', '600')
    .attr('alignment-baseline', 'middle');
  return legendUnit;
};

export const createLegend = ({
  svg,
  palette,
  x,
  y,
  legendRadius,
  legendWidth,
  legendPadding,
}) => {
  const legend = svg.append('g');

  const base = {
    legendRadius,
    legendWidth,
  };

  const legendYesWidth = createLegendUnit({
    svg: legend,
    color: palette.nbdfBlue[100],
    text: 'Yes',
    x: 0,
    ...base,
  })
    .node()
    .getBBox().width;
  createLegendUnit({
    svg: legend,
    color: palette.salmon[100],
    text: 'No',
    x: legendYesWidth + legendPadding,
    ...base,
  });
  const { width, height } = legend.node().getBBox();
  legend.attr('transform', `translate(${x - width / 2}, ${y - height / 2})`);
};

const createPartBar = ({
  svg,
  tooltip,
  color,
  textColor,
  text,
  x,
  width,
  height,
  html,
}) => {
  const partBar = svg.append('g').attr('transform', `translate(${x}, 0)`);

  partBar
    .append('rect')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', width)
    .attr('height', height)
    .attr('fill', color)
    .on('mousemove', (event) =>
      tooltip
        .interrupt()
        .html(html)
        .style('opacity', 0.9)
        .style('top', `${event.pageY}px`)
        .style('left', `${event.pageX}px`)
    )
    .on('mouseleave', () =>
      tooltip.transition().duration(200).style('opacity', 0.0)
    );

  partBar
    .append('text')
    .attr('x', width / 2)
    .attr('y', height / 2)
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .text(() => text)
    .attr('fill', textColor);
};

const createTooltipHtml = (color, text, value) => `
  <svg height="12" width="12">
    <circle cx="6" cy="6" r="6" style="fill: ${color};"></circle>
  </svg>
  ${text} <br>
  ${value}%
`;

const createBar = ({
  svg,
  tooltip,
  palette,
  key,
  value,
  x,
  y,
  width,
  height,
}) => {
  const bar = svg.append('g').attr('transform', `translate(${x}, ${y})`);

  const yesWidth = (width * value) / 100;
  const noWidth = width - yesWidth;

  const base = {
    svg: bar,
    tooltip,
    height,
  };

  createPartBar({
    color: palette.nbdfBlue[100],
    textColor: palette.white,
    text: `${value}%`,
    x: 0,
    width: yesWidth,
    html: createTooltipHtml(palette.nbdfBlue[100], `${key} Yes`, value),
    ...base,
  });

  createPartBar({
    color: palette.salmon[100],
    textColor: palette.black,
    text: `${100 - value}%`,
    x: yesWidth,
    width: noWidth,
    html: createTooltipHtml(palette.salmon[100], `${key} No`, 100 - value),
    ...base,
  });
};

export const createGraph = ({
  svg,
  tooltip,
  palette,
  key,
  value,
  x,
  y,
  width,
  height,
  legendWidth,
  legendGap,
}) => {
  const bar = svg.append('g').attr('transform', `translate(${x}, ${y})`);
  bar
    .append('text')
    .attr('x', legendWidth)
    .attr('y', height / 2)
    .attr('alignment-baseline', 'middle')
    .attr('text-anchor', 'end')
    .text(() => key)
    .attr('fill', palette.black);

  createBar({
    svg: bar,
    tooltip,
    palette,
    key,
    value,
    x: legendWidth + legendGap,
    y: 0,
    width: width - (legendWidth + legendGap),
    height,
  });
};

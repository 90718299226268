import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import Container from 'components/Enrollment/Container';
import ResendCode from 'components/ResendCode';

const Verify2FA = ({
  mfaLoaders,
  accountLocked,
  code,
  isAuthenticatorApp,
  setCode,
  handleSubmit,
  handleResendCode,
}) => {
  return (
    <Container maxWidth='600px'>
      <Divider />
      <Stack spacing='20px' py='30px' px={{ xs: '20px', sm: '30px' }}>
        <Typography variant='subtitle2' fontWeight='normal'>
          Enter the code
        </Typography>
        <TextField
          placeholder={'Enter the code here'}
          onChange={(e) => setCode(e.target.value)}
        />
        {!isAuthenticatorApp && (
          <ResendCode handleResendCode={handleResendCode} />
        )}
      </Stack>

      {Boolean(handleSubmit) && (
        <>
          <Divider />
          <Stack
            direction='row'
            spacing='20px'
            py='30px'
            px={{ xs: '20px', sm: '30px' }}
            justifyContent='right'
          >
            <Button
              disabled={mfaLoaders.confirm || accountLocked || code === ''}
              onClick={() => handleSubmit({ code })}
              title={
                accountLocked
                  ? 'You account has been locked'
                  : code === ''
                  ? 'Enter your code to enable the button'
                  : ''
              }
            >
              Confirm{' '}
              {mfaLoaders.confirm && (
                <CircularProgress sx={{ ml: 1 }} size={16} />
              )}
            </Button>
          </Stack>
        </>
      )}
    </Container>
  );
};

export default Verify2FA;

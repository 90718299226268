const progressBarThickness = '2px';

const MuiStepper = {
  variants: [
    {
      props: { alternativeLabel: true },
      style: ({ theme, ownerState }) => {
        const { activeStep, children } = ownerState;
        const primary = theme.palette.primary.main;
        const gray = theme.palette.gray[20];

        if (activeStep === children.length) return {};

        return {
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'relative',
            paddingBottom: `calc(10px + ${progressBarThickness})`,
            '::before': {
              content: '" "',
              width: '100%',
              height: progressBarThickness,
              backgroundColor: gray,
              position: 'absolute',
              bottom: 0,
              left: 0,
            },
            '::after': {
              content: '" "',
              width: `calc(100% * ${activeStep + 1} / ${children.length})`,
              height: progressBarThickness,
              backgroundColor: primary,
              position: 'absolute',
              bottom: 0,
              left: 0,
            },
          },
        };
      },
    },
  ],
};

export default MuiStepper;

import { useTheme, Grid } from '@mui/material';

import ColoredBox from 'pages/SplashPage/components/ColoredBox';
import boxImage1 from 'images/SplashPage/boxes/box-image-1.png';
import boxImage2 from 'images/SplashPage/boxes/box-image-2.png';

const ColoredBoxesList = () => {
  const theme = useTheme();
  return (
    <Grid container columnSpacing={{ md: 3 }} paddingX={{ md: 22.5 }}>
      <Grid item xs={12} md={6}>
        <ColoredBox
          title='NBDF Education'
          backgroundColor={theme.palette.honeyFlower[10]}
          boxImage={boxImage1}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ColoredBox
          title='Public Data Summaries'
          backgroundColor={theme.palette.salmon[10]}
          boxImage={boxImage2}
        />
      </Grid>
    </Grid>
  );
};

export default ColoredBoxesList;

import nhfApi from 'store/api/nhf';
import baseQuery from 'store/api/nhf/baseQuery';
import { NhfApiVersions, RequestMethods } from 'utils/enums';

const usersApi = nhfApi.injectEndpoints({
  endpoints: (build) => ({
    createUser: build.mutation({
      query: ({ attributes }) => ({
        url: '/users',
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Post,
        body: {
          data: {
            type: 'users',
            attributes,
          },
        },
      }),
      invalidatesTags: ['User'],
    }),
    currentUser: build.query({
      query: () => ({
        url: '/users/current',
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Get,
        autoLogout: false,
      }),
      providesTags: ['User'],
      transformResponse: ({ data }) => data,
    }),
    updateAuth0Metadata: build.mutation({
      query: ({ userId, userMetadata }) => ({
        url: `users/${userId}/update-metadata`,
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Post,
        autoLogout: false,
        body: {
          data: {
            user_metadata: userMetadata,
          },
        },
      }),
      invalidatesTags: ['MFAAuthenticators'],
    }),
    changeUserPassword: build.mutation({
      query: ({ userId, body }) => ({
        url: `users/${userId}/change-password`,
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Post,
        autoLogout: false,
        body,
      }),
      invalidatesTags: ['MFAAuthenticators'],
    }),
    resendVerificationEmail: build.mutation({
      query: ({ userId }) => ({
        url: `users/${userId}/resend-verification-emails`,
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Post,
        autoLogout: false,
      }),
      providesTags: ['Auth0Metadata'],
    }),
    checkCurrentUserExists: build.query({
      queryFn: async (_, ...args) => {
        const result = await baseQuery()(
          {
            url: '/users/current?include=person',
            apiVersion: NhfApiVersions.V3,
            method: RequestMethods.Get,
            autoLogout: false,
          },
          ...args
        );
        const errorStatus = result?.error?.status;
        const error401 = errorStatus === 401;
        return !!errorStatus && !error401 ? result : { data: !error401 };
      },
      providesTags: ['Person', 'User'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateUserMutation,
  useUpdateAuth0MetadataMutation,
  useChangeUserPasswordMutation,
  useResendVerificationEmailMutation,
  useCurrentUserQuery,
  useCheckCurrentUserExistsQuery,
} = usersApi;

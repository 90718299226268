import LoginProcessForm from 'components/LoginProcessForm';
import setNewPassword from 'forms/setNewPassword';

const SetNewPassword = () => {
  return (
    <LoginProcessForm
      title='Set new password'
      description='Please set a new password for your account'
      form={setNewPassword}
      onSubmit={(data) => console.log(data)}
    />
  );
};

export default SetNewPassword;

const formPA = {
  title: 'Form PA',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'PA',
  type: 'panel',
  label: 'Form PA',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Physical Activity</strong><br/>This section will ask about your physical activity.',
      label: 'Content',
      refreshOnChange: false,
      key: 'PA0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'Have you participated in any of the following physical activities <strong>during the past year?</strong> Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          value: 'Aquatics',
          label: 'Aquatics',
        },
        {
          value: 'Archery',
          label: 'Archery',
        },
        {
          value: 'Baseball',
          label: 'Baseball or softball',
        },
        {
          value: 'Basketball',
          label: 'Basketball',
        },
        {
          value: 'Bicycling',
          label: 'Bicycling',
        },
        {
          value: 'BMX',
          label: 'BMX racing',
        },
        {
          value: 'B_sculpting',
          label: 'Body sculpting',
        },
        {
          value: 'Workout',
          label: 'Boot camp workout class',
        },
        {
          value: 'Bounce',
          label: 'Bounce houses',
        },
        {
          value: 'Bowling',
          label: 'Bowling',
        },
        {
          value: 'Boxing',
          label: 'Boxing',
        },
        {
          value: 'Canoeing',
          label: 'Canoeing',
        },
        {
          value: 'Kickboxing',
          label: 'Cardio kickboxing class',
        },
        {
          value: 'Cheerleading',
          label: 'Cheerleading',
        },
        {
          value: 'Cir_train',
          label: 'Circuit training',
        },
        {
          value: 'Comp_diving',
          label: 'Competitive diving',
        },
        {
          value: 'X-country_ski',
          label: 'Cross-country skiing',
        },
        {
          value: 'Crossfit',
          label: 'Crossfit',
        },
        {
          value: 'Dance',
          label: 'Dance',
        },
        {
          value: 'Skiing',
          label: 'Downhill or water skiing',
        },
        {
          value: 'Elliptical',
          label: 'Elliptical machine',
        },
        {
          value: 'Fishing',
          label: 'Fishing',
        },
        {
          value: 'Flag_ftball',
          label: 'Flag or touch football',
        },
        {
          value: 'Fris_golf',
          label: 'Frisbee golf',
        },
        {
          value: 'Golf',
          label: 'Golf',
        },
        {
          value: 'Gymnastics',
          label: 'Gymnastics',
        },
        {
          value: 'HI_fx_training',
          label: 'High intensity functional training class',
        },
        {
          value: 'Hiking',
          label: 'Hiking',
        },
        {
          value: 'Hockey',
          label: 'Hockey',
        },
        {
          value: 'Horse',
          label: 'Horseback riding',
        },
        {
          value: 'Ind_cycle',
          label: 'Indoor cycling class',
        },
        {
          value: 'Ind_rock',
          label: 'Indoor rock climbing or ropes course',
        },
        {
          value: 'Jetski',
          label: 'Jet ski',
        },
        {
          value: 'J_rope',
          label: 'Jumping rope',
        },
        {
          value: 'Kayaking',
          label: 'Kayaking',
        },
        {
          value: 'Lacrosse',
          label: 'Lacrosse',
        },
        {
          value: 'ATV',
          label: 'Motorcycle or motocross (ATV, dirt bikes)',
        },
        {
          value: 'Mount_bike',
          label: 'Mountain biking',
        },
        {
          value: 'Pilates',
          label: 'Pilates',
        },
        {
          value: 'P_lifting',
          label: 'Power lifting',
        },
        {
          value: 'Racquetball',
          label: 'Racquetball',
        },
        {
          value: 'Rec_diving',
          label: 'Recreational diving',
        },
        {
          value: 'Rec_frisbee',
          label: 'Recreational Frisbee',
        },
        {
          value: 'Res_train',
          label: 'Resistance training',
        },
        {
          value: 'Rafting',
          label: 'River rafting',
        },
        {
          value: 'Rodeo',
          label: 'Rodeo',
        },
        {
          value: 'Rowing',
          label: 'Rowing',
        },
        {
          value: 'Row_machine',
          label: 'Rowing machine',
        },
        {
          value: 'Rugby',
          label: 'Rugby',
        },
        {
          value: 'Run',
          label: 'Running or jogging',
        },
        {
          value: 'Scooters',
          label: 'Scooters (motorized, nonmotorized)',
        },
        {
          value: 'Scuba',
          label: 'Scuba diving',
        },
        {
          value: 'Skate',
          label: 'Skating or skateboarding',
        },
        {
          value: 'Ski_mach',
          label: 'Ski machine',
        },
        {
          value: 'Snorkeling',
          label: 'Snorkeling',
        },
        {
          value: 'Snowboarding',
          label: 'Snowboarding',
        },
        {
          value: 'Snowmobiling',
          label: 'Snowmobiling',
        },
        {
          value: 'Soccer',
          label: 'Soccer',
        },
        {
          value: 'Softball',
          label: 'Softball',
        },
        {
          value: 'Station_bike',
          label: 'Stationary bike',
        },
        {
          value: 'Stepper',
          label: 'Stepper',
        },
        {
          value: 'Strength_train',
          label: 'Strength training',
        },
        {
          value: 'Surfing',
          label: 'Surfing',
        },
        {
          value: 'Swimming',
          label: 'Swimming',
        },
        {
          value: 'Tackle_ftbl',
          label: 'Tackle football',
        },
        {
          value: 'Tai_Chi',
          label: 'Tai Chi',
        },
        {
          value: 'T_ball',
          label: 'Tee-ball',
        },
        {
          value: 'Tennis',
          label: 'Tennis',
        },
        {
          value: 'Track',
          label: 'Track and field',
        },
        {
          value: 'MMA',
          label: 'Traditional and mixed martial arts',
        },
        {
          value: 'Trampoline',
          label: 'Trampoline',
        },
        {
          value: 'Treadmill',
          label: 'Treadmill',
        },
        {
          value: 'Ult_frisbee',
          label: 'Ultimate Frisbee',
        },
        {
          value: 'Volleyball',
          label: 'Volleyball',
        },
        {
          value: 'Walking',
          label: 'Walking',
        },
        {
          value: 'W_polo',
          label: 'Water polo',
        },
        {
          value: 'Lifting',
          label: 'Weight lifting',
        },
        {
          value: 'Wrestling',
          label: 'Wrestling',
        },
        {
          value: 'Yoga',
          label: 'Yoga',
        },
        {
          value: 'Zumba',
          label: 'Zumba',
        },
      ],
      validate: {
        required: true,
      },
      key: 'PA1',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Would you like to be more physically active than you are now?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'PA2',
      type: 'radio',
      input: true,
    },
    {
      label:
        'What limits you from being more physically active? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          value: 'Nothing',
          label: 'Nothing. My physical activity is not limited in any way.',
        },
        {
          value: 'No_partner',
          label: 'I don’t have anyone to be active with',
        },
        {
          value: 'Joint_pain',
          label: 'Painful joints',
        },
        {
          value: 'Gen_pain',
          label: 'Pain in general (not related to joints)',
        },
        {
          value: 'ROM_restrict',
          label: 'Difficulty moving (range of motion restrictions)',
        },
        {
          value: 'Weight',
          label: 'Weight issues',
        },
        {
          value: 'Time',
          label: 'Lack of time',
        },
        {
          value: 'Bleeds',
          label: 'It causes me to develop bleeds',
        },
        {
          value: 'Sweat',
          label: 'I hate to sweat',
        },
        {
          value: 'Don’t',
          label: "like I just don't like being physically active",
        },
        {
          value: 'other',
          label: 'Other',
        },
      ],
      validate: {
        required: true,
      },
      key: 'PA3',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'PA3_other',
      customConditional: "show = data[component.key.split('_')[0]]?.other;",
      type: 'textfield',
      input: true,
    },
    {
      html: 'During the last 7 days, on how many days did you do vigorous physical activities like heavy lifting, digging, aerobics, or fast bicycling?',
      refreshOnChange: false,
      key: 'PA4_label',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'No vigorous physical activities',
      tableView: false,
      defaultValue: false,
      key: 'PA4_no',
      type: 'checkbox',
      input: true,
    },
    {
      placeholder: 'Please enter number of days',
      suffix: 'days',
      hideLabel: true,
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      customConditional: 'show = !data.PA4_no',
      validate: {
        required: true,
        min: 1,
        max: 7,
      },
      key: 'PA4',
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label:
        'How much time did you usually spend doing vigorous physical activities on one of those days?',
      placeholder: 'Please enter number of hours',
      suffix: 'hours',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        min: 0,
        max: 23,
        custom: 'valid = (data.PA6 || input) ? true : "This field is required"',
      },
      key: 'PA5',
      customConditional: 'show = data.PA4 > 0',
      logic: [
        {
          name: 'PA5_PA6_DK',
          trigger: {
            type: 'simple',
            simple: {
              show: true,
              when: 'PA5_PA6_DK',
              eq: 'true',
            },
          },
          actions: [
            {
              name: 'Clear value',
              type: 'value',
              value: 'value = ""',
            },
            {
              name: 'Set schema',
              type: 'mergeComponentSchema',
              schemaDefinition:
                'schema = { validate: { required: false }, disabled: true }',
            },
          ],
        },
      ],
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'Please enter number of minutes',
      placeholder: 'Please enter number of minutes',
      suffix: 'minutes',
      applyMaskOn: 'change',
      hideLabel: true,
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        min: 0,
        max: 59,
        custom: 'valid = (data.PA5 || input) ? true : "This field is required"',
      },
      key: 'PA6',
      customConditional: 'show = data.PA4 > 0',
      logic: [
        {
          name: 'PA5_PA6_DK',
          trigger: {
            type: 'simple',
            simple: {
              show: true,
              when: 'PA5_PA6_DK',
              eq: 'true',
            },
          },
          actions: [
            {
              name: 'Clear value',
              type: 'value',
              value: 'value = ""',
            },
            {
              name: 'Set schema',
              type: 'mergeComponentSchema',
              schemaDefinition:
                'schema = { validate: { required: false }, disabled: true }',
            },
          ],
        },
      ],
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'Don’t know/not sure',
      tableView: false,
      key: 'PA5_PA6_DK',
      type: 'checkbox',
      input: true,
      defaultValue: false,
      customConditional: 'show = data.PA4 > 0',
    },
    {
      html: 'During the last 7 days, on how many days did you do moderate physical activities like carrying light loads, bicycling at a regular pace, or doubles tennis? Do not include walking.',
      refreshOnChange: false,
      key: 'PA7_label',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'No moderate physical activities',
      tableView: false,
      defaultValue: false,
      key: 'PA7_no',
      type: 'checkbox',
      input: true,
    },
    {
      hideLabel: true,
      placeholder: 'Please enter number of days',
      suffix: 'days',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      customConditional: 'show = !data.PA7_no',
      validate: {
        required: true,
        min: 1,
        max: 7,
      },
      key: 'PA7',
      type: 'number',
      decimalLimit: 0,
      input: true,
    },
    {
      label:
        'How much time did you usually spend doing moderate physical activities on one of those days?',
      placeholder: 'Please enter number of hours',
      suffix: 'hours',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        min: 0,
        max: 23,
        custom: 'valid = (data.PA9 || input) ? true : "This field is required"',
      },
      key: 'PA8',
      customConditional: 'show = data.PA7 > 0',
      logic: [
        {
          name: 'PA8_PA9_DK',
          trigger: {
            type: 'simple',
            simple: {
              show: true,
              when: 'PA8_PA9_DK',
              eq: 'true',
            },
          },
          actions: [
            {
              name: 'Clear value',
              type: 'value',
              value: 'value = ""',
            },
            {
              name: 'Set schema',
              type: 'mergeComponentSchema',
              schemaDefinition:
                'schema = { validate: { required: false }, disabled: true }',
            },
          ],
        },
      ],
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'Please enter number of minutes',
      placeholder: 'Please enter number of minutes',
      suffix: 'minutes',
      applyMaskOn: 'change',
      hideLabel: true,
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        min: 0,
        max: 59,
        custom: 'valid = (data.PA8 || input) ? true : "This field is required"',
      },
      key: 'PA9',
      customConditional: 'show = data.PA7 > 0',
      logic: [
        {
          name: 'PA8_PA9_DK',
          trigger: {
            type: 'simple',
            simple: {
              show: true,
              when: 'PA8_PA9_DK',
              eq: 'true',
            },
          },
          actions: [
            {
              name: 'Clear value',
              type: 'value',
              value: 'value = ""',
            },
            {
              name: 'Set schema',
              type: 'mergeComponentSchema',
              schemaDefinition:
                'schema = { validate: { required: false }, disabled: true }',
            },
          ],
        },
      ],
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'Don’t know/not sure',
      tableView: false,
      key: 'PA8_PA9_DK',
      type: 'checkbox',
      input: true,
      defaultValue: false,
      customConditional: 'show = data.PA7 > 0',
    },
    {
      html: 'During the last 7 days, on how many days did you walk for at least 10 minutes at a time?',
      refreshOnChange: false,
      key: 'PA10_label',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'No walking',
      tableView: false,
      defaultValue: false,
      key: 'PA10_no',
      type: 'checkbox',
      input: true,
    },
    {
      hideLabel: true,
      placeholder: 'Please enter number of days',
      suffix: 'days',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      customConditional: 'show = !data.PA10_no',
      validate: {
        required: true,
        min: 1,
        max: 7,
      },
      key: 'PA10',
      type: 'number',
      decimalLimit: 0,
      input: true,
    },
    {
      label:
        'How much time did you usually spend walking on one of those days?',
      placeholder: 'Please enter number of hours',
      suffix: 'hours',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        min: 0,
        max: 23,
        custom:
          'valid = (data.PA12 || input) ? true : "This field is required"',
      },
      key: 'PA11',
      customConditional: 'show = data.PA10 > 0',
      logic: [
        {
          name: 'PA11_PA12_DK',
          trigger: {
            type: 'simple',
            simple: {
              show: true,
              when: 'PA11_PA12_DK',
              eq: 'true',
            },
          },
          actions: [
            {
              name: 'Clear value',
              type: 'value',
              value: 'value = ""',
            },
            {
              name: 'Set schema',
              type: 'mergeComponentSchema',
              schemaDefinition:
                'schema = { validate: { required: false }, disabled: true }',
            },
          ],
        },
      ],
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'Please enter number of minutes',
      placeholder: 'Please enter number of minutes',
      suffix: 'minutes',
      applyMaskOn: 'change',
      hideLabel: true,
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        min: 0,
        max: 59,
        custom:
          'valid = (data.PA11 || input) ? true : "This field is required"',
      },
      key: 'PA12',
      customConditional: 'show = data.PA10 > 0',
      logic: [
        {
          name: 'PA11_PA12_DK',
          trigger: {
            type: 'simple',
            simple: {
              show: true,
              when: 'PA11_PA12_DK',
              eq: 'true',
            },
          },
          actions: [
            {
              name: 'Clear value',
              type: 'value',
              value: 'value = ""',
            },
            {
              name: 'Set schema',
              type: 'mergeComponentSchema',
              schemaDefinition:
                'schema = { validate: { required: false }, disabled: true }',
            },
          ],
        },
      ],
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'Don’t know/not sure',
      tableView: false,
      key: 'PA11_PA12_DK',
      type: 'checkbox',
      input: true,
      defaultValue: false,
      customConditional: 'show = data.PA10 > 0',
    },
    {
      label:
        'During the last 7 days, how much time did you spend sitting on a week day?',
      placeholder: 'Please enter number of hours',
      suffix: 'hours',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        min: 0,
        max: 23,
        custom:
          'valid = (data.PA14 || input) ? true : "This field is required"',
      },
      key: 'PA13',
      logic: [
        {
          name: 'PA13_PA14_DK',
          trigger: {
            type: 'simple',
            simple: {
              show: true,
              when: 'PA13_PA14_DK',
              eq: 'true',
            },
          },
          actions: [
            {
              name: 'Clear value',
              type: 'value',
              value: 'value = ""',
            },
            {
              name: 'Set schema',
              type: 'mergeComponentSchema',
              schemaDefinition:
                'schema = { validate: { required: false }, disabled: true }',
            },
          ],
        },
      ],
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'Please enter number of minutes',
      placeholder: 'Please enter number of minutes',
      suffix: 'minutes',
      applyMaskOn: 'change',
      hideLabel: true,
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        min: 0,
        max: 59,
        custom:
          'valid = (data.PA13 || input) ? true : "This field is required"',
      },
      key: 'PA14',
      logic: [
        {
          name: 'PA13_PA14_DK',
          trigger: {
            type: 'simple',
            simple: {
              show: true,
              when: 'PA13_PA14_DK',
              eq: 'true',
            },
          },
          actions: [
            {
              name: 'Clear value',
              type: 'value',
              value: 'value = ""',
            },
            {
              name: 'Set schema',
              type: 'mergeComponentSchema',
              schemaDefinition:
                'schema = { validate: { required: false }, disabled: true }',
            },
          ],
        },
      ],
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'Don’t know/not sure',
      tableView: false,
      key: 'PA13_PA14_DK',
      type: 'checkbox',
      input: true,
      defaultValue: false,
    },
  ],
};

export default formPA;

import { useState } from 'react';

import { Grid, Button } from '@mui/material';

import ContentBox from 'components/MyAccount/ContentBox';
import ContentText from 'components/MyAccount/ContentText';
import PersonalInformationModal from 'components/NHFModal/PersonalInformationModal';

const PersonalInformation = ({ userInfo }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleSetOpen = () => {
    setOpenModal(true);
  };
  return (
    <>
      <ContentBox title='Personal Information'>
        <Grid container>
          {Object.entries(userInfo).map(([key, value]) => (
            <ContentText key={key} title={key} subtitle={value || '--'} />
          ))}
          <Button fullWidth variant='outlined' onClick={handleSetOpen}>
            Edit Personal information
          </Button>
        </Grid>
      </ContentBox>
      <PersonalInformationModal open={openModal} setOpen={setOpenModal} />
    </>
  );
};

export default PersonalInformation;

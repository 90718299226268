import nhfApi from 'store/api/nhf';
import { NhfApiVersions, RequestMethods } from 'utils/enums';

const peopleApi = nhfApi.injectEndpoints({
  endpoints: (build) => ({
    createPerson: build.mutation({
      query: () => ({
        url: '/people',
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Post,
        body: {
          data: {
            type: 'people',
            attributes: {
              state: 'NY',
              status: 'authenticated',
              statusReason: 'Awaiting email verification',
            },
          },
        },
      }),
    }),
    updatePerson: build.mutation({
      query: ({ attributes, personId: id }) => ({
        url: `/people/${id}`,
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Patch,
        body: {
          data: {
            type: 'people',
            id,
            attributes,
          },
        },
      }),
      invalidatesTags: ['Person'],
    }),
    currentPerson: build.query({
      query: () => ({
        url: '/users/current?include=person',
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Get,
        autoLogout: false,
      }),
      providesTags: ['Person', 'User'],
      transformResponse: (response) => response?.included?.[0],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreatePersonMutation,
  useUpdatePersonMutation,
  useCurrentPersonQuery,
} = peopleApi;

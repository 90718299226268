import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import { ReactComponent as ChevronIcon } from 'images/chevron-right.svg';

const BreadCrumb = ({ path }) => {
  const navigate = useNavigate();
  const handleDashboard = () => navigate('/homepage');
  return (
    <Stack
      direction='row'
      spacing='15px'
      alignItems='center'
      divider={
        <Typography color='nbdfBlue.100'>
          <ChevronIcon />
        </Typography>
      }
    >
      <Typography
        variant='w400h16'
        color='nbdfBlue.100'
        onClick={handleDashboard}
        sx={{ '&:hover': { cursor: 'pointer' } }}
      >
        Dashboard
      </Typography>

      <Typography variant='w400h16' color='nbdfBlue.100'>
        {path}
      </Typography>
    </Stack>
  );
};

export default BreadCrumb;

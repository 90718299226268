const sectionMKeys = Array(12)
  .fill()
  .map((_, index) => `M${index + 3}`);

const questionKeys = ['I5', 'I6', 'PMed3', ...sectionMKeys];

const filterOut = ['other', 'NAK', 'NS', 'noneOfTheAbove'];

const generateLabelMapping = (baselineSurvey) => {
  const formI = getFormComponents(baselineSurvey, 'I');
  const i5 = findByKey(formI, 'I5')?.values;
  const i6 = findByKey(formI, 'I6')?.components?.[0]?.values;

  const formM = getFormComponents(baselineSurvey, 'M');
  const m = sectionMKeys.map(
    (key) => findByKey(formM, key)?.components?.[0]?.values,
  );

  const formPMed = getFormComponents(baselineSurvey, 'PMed');
  const pMed3 = findByKey(formPMed, 'PMed3')?.values;

  const allValues = [i5, i6, ...m, pMed3].reduce(
    (acc, curr) => [...acc, ...curr],
    [],
  );

  const canonicalValues = allValues.map(({ label, value }) => [value, label]);

  const values = Object.fromEntries(canonicalValues);

  filterOut.forEach((key) => delete values[key]);

  return values;
};

const fetchMedicationLabels = (data, key, labelMapping) => {
  const arrayOfOptions = Object.entries(
    data[key]?.medication ?? data[key] ?? {},
  );

  const arrayOfSelectedMedications = arrayOfOptions.filter(
    ([key, value]) => value && !filterOut.includes(key),
  );
  return arrayOfSelectedMedications.map(([key]) => labelMapping[key]);
};

const fetchOtherMedications = (data, key) =>
  (data[key]?.other?.map?.(({ medication }) => medication) ?? []).filter(
    (medication) => !!medication,
  ); // remove nulls

const medicationToContentArray = (medication) => ({
  id: medication,
  description: medication,
});

const findByKey = (target, targetKey) =>
  target?.find?.(({ key }) => key === targetKey);

const getFormComponents = (baselineSurvey, targetKey) =>
  findByKey(baselineSurvey?.components, targetKey)?.components;

export const parseMedications = (data, baselineSurvey) => {
  if (!data || !baselineSurvey) return [];

  const labelMapping = generateLabelMapping(baselineSurvey);

  const arrayOfArrayOfMedicationLabels = questionKeys.map((key) => [
    ...fetchMedicationLabels(data, key, labelMapping),
    ...fetchOtherMedications(data, key),
    ...[data?.I5_other, data?.PMed3_other].filter((other) => !!other),
  ]);

  return [
    ...new Set(
      arrayOfArrayOfMedicationLabels.reduce((acc, curr) => [...curr, ...acc]),
    ),
  ] // combine array of arrays
    .sort((lhs, rhs) => lhs.localeCompare(rhs)) //sort medications
    .map(medicationToContentArray);
};

import { Grid, Stack } from '@mui/material';

import Section from 'components/Dashboard/Section';

const Layout = ({ children, ...rest }) => {
  return (
    <Section title='my tasks' {...rest}>
      <Stack>
        <Grid
          container
          spacing='16px'
          columns={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 2 }}
        >
          {children}
        </Grid>
      </Stack>
    </Section>
  );
};

export default Layout;

import { Typography } from '@mui/material';

const Paragraph = (props) => (
  <Typography
    variant='w400h14'
    color='gray.80'
    sx={{
      'ol, ul': { marginBlock: 0, paddingInlineStart: '18px' },
      b: { fontWeight: 600 },
    }}
    {...props}
  />
);

export default Paragraph;

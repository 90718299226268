import NHFModal from 'components/NHFModal';

const WithdrawConsentModal = ({
  open,
  setOpen,
  setOpenConfirmWithdrawConsentModal,
}) => {
  return (
    <NHFModal
      handleSubmit={() => {
        setOpenConfirmWithdrawConsentModal(true);
      }}
      title='Withdraw Consent'
      open={open}
      setOpen={setOpen}
    >
      Are you sure you want to withdraw your consent?
    </NHFModal>
  );
};

export default WithdrawConsentModal;

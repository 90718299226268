import { Grid, Stack, Typography, useTheme } from '@mui/material';

import ContentBox from 'components/MyAccount/ContentBox';
import ContentText from 'components/MyAccount/ContentText';
import LinkedIn from 'images/social-medias/linkeding.svg';
import Facebook from 'images/social-medias/facebook.svg';
import Twitter from 'images/social-medias/twitter.svg';
import Instagram from 'images/social-medias/instagram.svg';
import Youtube from 'images/social-medias/youtube.svg';

const staff_directory = () => {
  return (
    <div>
      To contact a specific staff member, please email Maria Santaella at{' '}
      <a href={`mailto:msantaella@hemophilia.org`}>msantaella@hemophilia.org</a>{' '}
      or Matthew Hartnett at{' '}
      <a href={`mailto:mhartnett@hemophilia.org`}>mhartnett@hemophilia.org</a>.
    </div>
  );
};

const Contact = () => {
  const theme = useTheme();
  return (
    <ContentBox title='Contacts'>
      <Stack spacing={4}>
        <Typography variant='p'>
          If you have questions or feedback about the CVR 2.0, contact us:
        </Typography>
        <Grid container>
          <ContentText title='Phone' subtitle='212.328.3700' />
          <ContentText title='Toll-free Number' subtitle='888.463.6643' />
          <ContentText title='Email' subtitle='CVR@hemophilia.org' />
          <ContentText
            title='NBDF Office Address'
            subtitle='1230 Avenue of the Americas, 16th Fl. New York, NY 10020'
          />
          <ContentText title='Staff Directory' subtitle={staff_directory()} />
          <ContentText
            title='Business Hours'
            subtitle='Monday-Friday, 9-5PM ET'
          />
        </Grid>
        <Stack spacing={1}>
          <Typography
            color='gray.60'
            sx={{
              fontSize: '0.75rem',
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            Social Medias
          </Typography>
          <Grid container gap={1}>
            <Grid item>
              <a
                href='https://twitter.com/nbd_foundation'
                target='_blank'
                rel='noreferrer'
              >
                <img src={Twitter} alt='Twitter Social Media' />
              </a>
            </Grid>
            <Grid item>
              <a
                href='https://www.facebook.com/NationalBleedingDisordersFoundation'
                target='_blank'
                rel='noreferrer'
              >
                <img src={Facebook} alt='Facebook Social Media' />
              </a>
            </Grid>
            <Grid item>
              <a
                href='https://www.youtube.com/@NBDFvideo'
                target='_blank'
                rel='noreferrer'
              >
                <img src={Youtube} alt='Youtube Social Media' />
              </a>
            </Grid>
            <Grid item>
              <a
                href='https://www.instagram.com/nbd_foundation/'
                target='_blank'
                rel='noreferrer'
              >
                <img src={Instagram} alt='Instagram Social Media' />
              </a>
            </Grid>
            <Grid item>
              <a
                href='https://www.linkedin.com/company/nbdf'
                target='_blank'
                rel='noreferrer'
              >
                <img src={LinkedIn} alt='LinkedIn Social Media' />
              </a>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </ContentBox>
  );
};

export default Contact;

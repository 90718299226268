import auth0Api from 'store/api/auth0';
import { RequestMethods } from 'utils/enums';

const auth0OAuthApi = auth0Api.injectEndpoints({
  endpoints: (build) => ({
    resendVerificationEmail: build.mutation({
      query: ({ body, token }) => ({
        url: '/api/v2/jobs/verification-email',
        method: RequestMethods.Post,
        body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    setNewPassword: build.mutation({
      query: ({ password, id, token }) => ({
        url: `/api/v2/users/${id}`,
        method: 'patch',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          password: password,
        }),
      }),
    }),
    associateMFA: build.mutation({
      query: ({ body, token }) => ({
        url: '/mfa/associate',
        method: RequestMethods.Post,
        body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      invalidatesTags: ['MFAAuthenticators'],
    }),
    getMFAAuthenticators: build.query({
      query: ({ token }) => ({
        url: '/mfa/authenticators',
        method: RequestMethods.GET,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['MFAAuthenticators'],
    }),
    auth0OAuthToken: build.mutation({
      query: ({ body }) => ({
        url: '/oauth/token',
        method: RequestMethods.Post,
        body,
      }),
    }),
    challenMFAToken: build.mutation({
      query: ({ body }) => ({
        url: '/mfa/challenge',
        method: RequestMethods.Post,
        body,
      }),
    }),
    deleteMFAChannel: build.mutation({
      query: ({ authenticatorId }) => ({
        url: `/mfa/authenticators/${authenticatorId}`,
        method: RequestMethods.Delete,
      }),
      invalidatesTags: ['MFAAuthenticators'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useSetNewPasswordMutation,
  useResendVerificationEmailMutation,
  useAssociateMFAMutation,
  useGetMFAAuthenticatorsQuery,
  useAuth0OAuthTokenMutation,
  useChallenMFATokenMutation,
  useDeleteMFAChannelMutation,
} = auth0OAuthApi;

import { Box, Grid, Typography, useTheme } from '@mui/material';

import Logo from 'images/logo/CVR-logo-full.png';

const Footer = () => {
  const theme = useTheme();
  return (
    <Grid container bgcolor='background.default' p={8}>
      <Grid
        item
        xs={12}
        md={2}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={Logo} alt='CVR Logo' width='178' />
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box>
          <Typography
            variant='h6'
            color='gray.80'
            sx={{
              textAlign: 'center',
              fontSize: '0.875rem',
              fontWeight: theme.typography.fontWeightLight,
              marginTop: 1.5,
            }}
          >
            © 2023 NBDF. All rights reserved.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;

import component from './component';
import input from './input';
import wizard from './wizard';
import wizardNav from './wizardNav';

const enrollment = {
  component,
  input,
  wizard,
  wizardNav,
};

export default enrollment;

import { Grid, Typography, useTheme } from '@mui/material';

import NHFAccordion from 'components/NHFAccordion';
import FaqContent from 'mocks/faqContent';

const FAQ = () => {
  const theme = useTheme();
  return (
    <Grid container marginY={12} paddingX={{ xs: 3, md: 44.5 }}>
      <Typography
        variant='h3'
        width='100%'
        fontSize='1.875rem'
        fontWeight={theme.typography.fontWeightBold}
        textAlign='center'
        marginBottom={6}
      >
        FAQ
      </Typography>
      <NHFAccordion data={FaqContent} hasDangerousElements={true} />
    </Grid>
  );
};

export default FAQ;

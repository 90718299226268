import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import Card from 'components/Dashboard/Card';
import { ReactComponent as ChevronRight } from 'images/chevron-right.svg';

const HealthSummary = (props) => (
  <Card
    title='my health summary'
    description={
      'Click the link below to access your detailed information to print and take with you to your next provider appointment.'
    }
    {...props}
  >
    <Button
      variant='text'
      endIcon={<ChevronRight />}
      component={Link}
      sx={{ width: 'fit-content' }}
      to='/homepage/my-health-summary'
    >
      My Health Summary
    </Button>
  </Card>
);

export default HealthSummary;

import { Link } from 'react-router-dom';
import { Button, Card, Chip, Stack, Typography } from '@mui/material';

import { ReactComponent as ArrowRight } from 'images/arrow-right.svg';
import { useGetParticipantTaskQuery } from 'store/api/nhf/endpoints/participantTasks';
import { useCurrentPersonQuery } from 'store/api/nhf/endpoints/people';
import { TaskStatus } from 'utils/enums';
import parseJson from 'utils/parseJson';
import DateStatus from './DateStatus';
import Skeleton from './Skeleton';

const statusToButtonLabel = (status) =>
  status === TaskStatus.Inprogress
    ? 'Resume'
    : status === TaskStatus.Incomplete
    ? 'Start'
    : 'View';

const TaskCard = ({ id }) => {
  const { data: personData, isFetching: isPersonLoading } =
    useCurrentPersonQuery();
  const personId = personData.id;
  const {
    status,
    title,
    description,
    isParticipantTaskLoading,
    updatedAt,
    disabled,
  } = useGetParticipantTaskQuery(
    { personId, participantTaskId: id },
    {
      skip: !personId,
      selectFromResult: ({ data, isFetching }) => ({
        status: data?.data?.status,
        title: data?.data?.task?.title,
        description: data?.data?.task?.description,
        updatedAt: data?.data?.updated_at,
        isParticipantTaskLoading: isFetching,
        disabled:
          parseJson(
            data?.data?.task?.attributes.find(
              (data) => data.attribute === 'meta'
            )?.value
          )?.enabled !== 'true',
      }),
    }
  );

  const isLoading = isPersonLoading || isParticipantTaskLoading;

  if (isLoading) return <Skeleton />;

  return (
    <Card variant='outlined' sx={{ height: '290px' }} disabled={disabled}>
      <Stack justifyContent='space-between' height='100%' p='20px'>
        <Stack spacing='6px'>
          <Typography variant='w600h16' color='gray.100'>
            Complete {title}
          </Typography>
          <Typography variant='w400h14' color='gray.80'>
            {description}
          </Typography>
        </Stack>
        {status !== TaskStatus.Incomplete && !disabled && (
          <Stack spacing='6px'>
            <DateStatus status={status} updatedAt={updatedAt} />
          </Stack>
        )}
        {disabled && <Chip label='Coming soon' variant='outlined' />}
        <Stack>
          <Button
            variant='contained'
            size='large'
            component={Link}
            disabled={disabled}
            endIcon={<ArrowRight />}
            to={`/homepage/survey/${id}`}
          >
            {statusToButtonLabel(status)}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default TaskCard;

const dev = {
  API_URL: 'https://nhf-cvr-study-dev.ihp.iqvia.com/api',
  AUDIENCE: 'https://ihp-nhf-cvr-dev.us.auth0.com/api/v2/',
  AUTH0_DOMAIN: 'ihp-nhf-cvr-dev.us.auth0.com',
  AUTH0_AUDIENCE: 'https://ihp-nhf-cvr-dev.us.auth0.com/mfa/',
  AUTH0_CLIENT_ID: 'fFa8FS4y8fO3ZMm5hcJPAhIaiVOPzQWW',
  AUTH0_CLIENT_SECRET: 'vydlRSEiqO9aT188Sq5qQeALh3OEKOIZej7vL16sOjl_-jjJrrlxO-ewb3-7rq5H',
  AUTH0_API_URL: 'https://ihp-nhf-cvr-dev.us.auth0.com',
  AUTH0_API_CLIENT_ID: 'Ldlf4X9DIuV0f8HsqoWIcEdtYoBMmESt',
  AUTH0_API_CLIENT_SECRET: 'gM_OrazwCQt_lK_Gl24BtIdhJDhQF0y4M9jK6Z1brp6crxmv1N_jkmrcFIpEn1c_',
  AUTH0_API_AUDIENCE: 'https://nhf-cvr-study-dev.ihp.iqvia.com/api/',
  EMAIL_FROM: 'noreply@nhf-cvr-dev.ihp.iqvia.com',
  MFA_GRANT_TYPE: 'http://auth0.com/oauth/grant-type',
  RECAPTCHA_PUBLIC_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  AZURE_STORAGE_BASE_URL: '',
  AZURE_STORAGE_BLOB_TOKEN: '',
  AZURE_STORAGE_BLOB_SAS_URL: '',
};

export default dev;

const formP = {
  title: 'Form P',
  label: 'Form P',
  type: 'panel',
  key: 'P',
  input: false,
  tableView: false,
  customConditional:
    'if (form.injectedValues) show = form.injectedValues.R8 !== "male"',
  components: [
    {
      html: '<strong>Pregnancy and Childbirth</strong>',
      label: 'Content',
      refreshOnChange: false,
      key: 'M0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'Have you ever been pregnant?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'no',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'P1',
      type: 'radio',
      input: true,
    },
    {
      label: 'Have you had difficulty getting pregnant?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'no',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'P2',
      type: 'radio',
      input: true,
    },
    {
      label: 'How many times have you been pregnant?',
      placeholder: 'Please enter number of pregnancies ',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        required: true,
        min: 1,
        max: 999,
      },
      key: 'P3',
      conditional: {
        show: true,
        when: 'P1',
        eq: 'yes',
      },
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'Have you ever had a miscarriage or spontaneous abortion?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'no',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      conditional: {
        show: true,
        when: 'P1',
        eq: 'yes',
      },
      key: 'P4',
      type: 'radio',
      input: true,
    },

    {
      label: 'How many times have you miscarried or spontaneously aborted?',
      placeholder: 'Please enter number of miscarriages',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        required: true,
        min: 1,
        max: 999,
      },
      key: 'P5',
      conditional: {
        show: true,
        when: 'P4',
        eq: 'yes',
      },
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'Have you ever had bleeding during pregnancy, BEFORE childbirth?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'no',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      conditional: {
        show: true,
        when: 'P1',
        eq: 'yes',
      },
      key: 'P6',
      type: 'radio',
      input: true,
    },
    {
      label: 'How was this bleeding handled? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label:
            'I spoke to my doctor about heavy bleeding but it did not need medical treatment',
          value: 'Spoke HCP',
          shortcut: '',
        },
        {
          label:
            'I had to take iron supplements due to anemia (low red blood cell count) caused by heavy bleeding',
          value: 'Iron',
          shortcut: '',
        },
        {
          label:
            'I had to take antifibrinolytics such as aminocaproic acid (Amicar) or tranexamic acid (Lysteda or Cyklokapron)',
          value: 'Antifibrinolytics',
          shortcut: '',
        },
        {
          label: 'I was placed on bed rest',
          value: 'Bed_rest',
          shortcut: '',
        },
        {
          label: 'I needed factor infusions',
          value: 'Factor',
          shortcut: '',
        },
        {
          label: 'I needed a blood, cryoprecipitate, or platelet transfusion',
          value: 'Transfusion',
          shortcut: '',
        },
        {
          label: 'I had surgery or other procedure',
          value: 'Procedure',
          shortcut: '',
        },
        {
          label: 'I had to go to the emergency room (ER)',
          value: 'ER',
          shortcut: '',
        },
        {
          label:
            'I was in the Intensive Care Unit (ICU) as a result of bleeding',
          value: 'ICU',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'Other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'P7',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        'Spoke HCP': false,
        Iron: false,
        Antifibrinolytics: false,
        Bed_rest: false,
        Factor: false,
        Transfusion: false,
        Procedure: false,
        ER: false,
        ICU: false,
        Other: false,
      },
      conditional: {
        show: true,
        when: 'P6',
        eq: 'yes',
      },
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'P7_other',
      customConditional: 'show = !!data.P7?.Other',
      type: 'textfield',
      input: true,
    },
    {
      label:
        'Based on my prior bleeding symptoms and/or factor levels, my physician provided medication at the time of delivery to prevent excessive/extra bleeding.',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'yes',
        },
        {
          label: 'No',
          value: 'no',
        },
        {
          label: 'I don’t know',
          value: 'IDK',
        },
      ],
      validate: {
        required: true,
      },
      conditional: {
        show: true,
        when: 'P1',
        eq: 'yes',
      },
      key: 'P8',
      type: 'radio',
      input: true,
    },
    {
      label: 'What was this medication? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          value: 'DDAVP',
          label: 'DDAVP/desmopressin',
        },
        {
          value: 'H_factor',
          label: 'Hemophilia factor concentrate',
        },
        {
          value: 'VW_factor',
          label: 'Von Willebrand factor concentrate',
        },
        {
          value: 'Oth_factor',
          label: 'Another type of factor concentrate',
        },
        {
          value: 'Transfusion',
          label: 'Blood, cryoprecipitate, or platelet transfusion',
        },
        {
          value: 'A_acid',
          label: 'Aminocaproic acid (Amicar)',
        },
        {
          value: 'T_acid',
          label: 'Tranexamic acid (Lysteda or Cyklokapron)',
        },
        {
          value: 'DK',
          label: 'I don’t know',
        },
        {
          label: 'Other',
          value: 'Other',
          shortcut: '',
        },
      ],
      logic: [
        {
          name: 'DK',
          trigger: {
            type: 'javascript',
            javascript: "result = data['P9']?.DK;",
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data['P9']).reduce((acc, curr) => ({...acc, [curr]: curr === 'DK'}), {})",
            },
          ],
        },
      ],
      validate: {
        required: true,
      },
      key: 'P9',
      conditional: {
        show: true,
        when: 'P8',
        eq: 'yes',
      },
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        DDAVP: false,
        H_factor: false,
        VW_factor: false,
        Oth_factor: false,
        Transfusion: false,
        A_acid: false,
        T_acid: false,
        DK: false,
        Other: false,
      },
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'P9_other',
      customConditional: 'show = !!data.P9?.Other',
      type: 'textfield',
      input: true,
    },

    {
      label: 'Have you ever had bleeding DURING labor or childbirth? ',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'no',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      conditional: {
        show: true,
        when: 'P1',
        eq: 'yes',
      },
      key: 'P10',
      type: 'radio',
      input: true,
    },
    {
      label: 'How was this bleeding handled? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          value: 'Spoke_HCP',
          label:
            'I spoke to my doctor about heavy bleeding but it did not need medical treatment',
        },
        {
          value: 'Pitocin',
          label:
            'I needed medication to contract (tighten) the womb (such as Pitocin)',
        },
        {
          value: 'Antifibrinolytics',
          label:
            'I had to take antifibrinolytics such as aminocaproic acid (Amicar) or tranexamic acid (Lysteda or Cyklokapron)',
        },
        {
          value: 'Factor',
          label: 'I needed factor infusion(s)',
        },
        {
          value: 'Transfusion',
          label: 'I needed blood, cryoprecipitate, or platelet transfusion(s)',
        },
        {
          value: 'Procedure',
          label: 'I had surgery or other procedure',
        },
        {
          value: 'ICU',
          label:
            'I was in the Intensive Care Unit (ICU) as a result of bleeding',
        },
        {
          label: 'Other',
          value: 'Other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'P11',
      conditional: {
        show: true,
        when: 'P10',
        eq: 'yes',
      },
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        Spoke_HCP: false,
        Pitocin: false,
        Antifibrinolytics: false,
        Factor: false,
        Transfusion: false,
        Procedure: false,
        ICU: false,
        Other: false,
      },
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'P11_other',
      customConditional: 'show = !!data.P11?.Other',
      type: 'textfield',
      input: true,
    },
    {
      label: 'Have you ever had bleeding AFTER childbirth?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'no',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      conditional: {
        show: true,
        when: 'P1',
        eq: 'yes',
      },
      key: 'P12',
      type: 'radio',
      input: true,
    },
    {
      label:
        'How long did your bleeding last AFTER childbirth? If you had more than one childbirth with bleeding, please select the one with longest bleeding time.',
      widget: 'choicesjs',
      placeholder: 'How long...',
      tableView: true,
      data: {
        values: [
          {
            label: '1-4 days',
            value: '1',
          },
          {
            label: '5-10 days',
            value: '2',
          },
          {
            label: '11-28 days',
            value: '3',
          },
          {
            label: '4-6 weeks',
            value: '4',
          },
          {
            label: 'More than 6 weeks',
            value: '5',
          },
        ],
      },
      validate: {
        required: true,
      },
      conditional: {
        show: true,
        when: 'P12',
        eq: 'yes',
      },
      key: 'P13',
      type: 'select',
      input: true,
    },
    {
      label: 'How was this bleeding handled? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          value: 'Spoke_HCP',
          label:
            'I spoke to my doctor about heavy bleeding but it did not need medical treatment',
        },
        {
          value: 'Pitocin',
          label:
            'I needed medication to contract (tighten) the womb (such as Pitocin)',
        },
        {
          value: 'Iron',
          label:
            'I had to take iron supplements due to anemia (low red blood cell count) caused by heavy bleeding',
        },
        {
          value: 'Antifibrinolytics',
          label:
            'I had to take antifibrinolytics such as aminocaproic acid (Amicar) or tranexamic acid (Lysteda or Cyklokapron)',
        },
        {
          value: 'Factor',
          label: 'I needed factor infusions',
        },
        {
          value: 'Transfusion',
          label: 'I needed a blood, cryoprecipitate, or platelet transfusion',
        },
        {
          value: 'Procedure',
          label: 'I had surgery or another procedure',
        },
        {
          value: 'Bed_rest',
          label: 'I was placed on bed rest',
        },
        {
          value: 'ER',
          label: 'I had to go to the emergency room (ER)',
        },
        {
          value: 'ICU',
          label:
            'I was in the Intensive Care Unit (ICU) as a result of bleeding',
        },
        {
          label: 'Other',
          value: 'Other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'P14',
      conditional: {
        show: true,
        when: 'P12',
        eq: 'yes',
      },
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        Spoke_HCP: false,
        Pitocin: false,
        Iron: false,
        Antifibrinolytics: false,
        Factor: false,
        Transfusion: false,
        Procedure: false,
        Bed_rest: false,
        ER: false,
        ICU: false,
        Other: false,
      },
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'P14_other',
      customConditional: 'show = !!data.P14?.Other',
      type: 'textfield',
      input: true,
    },
  ],
};

export default formP;

import { store } from 'store';
import { ErrorDescriptions, SnackbarError } from './enums';
import { openSnackbar } from 'store/slices/application';

export const handleError = (error) => {
  switch (error.data.errorDescription) {
    case ErrorDescriptions.TooManyAttempts:
      store.dispatch(openSnackbar(SnackbarError.TooManyAttempts()));
      return { accountLocked: true };
    case ErrorDescriptions.InvalidMFACode:
      store.dispatch(openSnackbar(SnackbarError.InvalidMFACode()));
      return { accountLocked: false };
    case ErrorDescriptions.TooManyMFACodeAttempts:
      store.dispatch(openSnackbar(SnackbarError.TooManyMFACodeAttempts()));
      return { accountLocked: true };
    case ErrorDescriptions.InvalidOTPCode:
      store.dispatch(openSnackbar(SnackbarError.InvalidMFACode()));
      return { accountLocked: false };
    case ErrorDescriptions.InvalidMFAPhoneNumber:
      store.dispatch(openSnackbar(SnackbarError.InvalidMFAPhoneNumber()));
      return { accountLocked: false };
    case ErrorDescriptions.TooManySMSAttempts:
      store.dispatch(openSnackbar(SnackbarError.TooManySMSAttempts()));
      return { accountLocked: false };
    default:
      store.dispatch(openSnackbar(SnackbarError.TryAgain()));
      return { accountLocked: false };
  }
};

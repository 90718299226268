import { useState } from 'react';
import { Box, Stack, useTheme } from '@mui/material';

import Section from 'components/DataStory/Section';
import TreemapChart from 'components/DataStory/charts/TreemapChart';
import FormIO from 'components/FormIO';
import impactOfPain from 'forms/impactOfPain';
import impactOfPainData from './data';
import { parseData } from './utils';
import { sortTreeMapData } from '../utils';

const ImpactOfPain = () => {
  const theme = useTheme();
  const [formValue, setFormValue] = useState({});

  const handleChange = ({ bleedingDisorder, howOften }) =>
    setFormValue({ bleedingDisorder, howOften });

  const sortedImpactOfPainData = sortTreeMapData(impactOfPainData);
  return (
    <Section
      title='Impact of pain'
      description='Let’s see the impact of pain in the CVR population in the past 7 days:'
    >
      <Stack direction='row' spacing='50px' alignItems='flex-end'>
        <Box sx={{ flex: '1 1 0px' }}>
          <FormIO
            form={impactOfPain}
            template='enrollment'
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ flex: '1 1 0px' }}>
          <TreemapChart
            id='ImpactOfPainTreemapChart'
            data={parseData(theme, sortedImpactOfPainData, formValue)}
            height='300px'
          />
        </Box>
      </Stack>
    </Section>
  );
};

export default ImpactOfPain;

import { useSelector } from 'react-redux';
import { promisify } from 'util';

import { useCurrentPersonQuery } from 'store/api/nhf/endpoints/people';
import {
  useDeletePersonAttributeMutation,
  useGetPersonAttributesQuery,
  useUpsertPersonAttributeMutation,
} from 'store/api/nhf/endpoints/personAttributes';
import { selectIsAuthenticated } from 'store/slices/auth';

export const formatAttributes = (attributes) =>
  Object.entries(attributes).map(([attribute, value]) => ({
    data: {
      type: 'person-attributes',
      attributes: { attribute, value },
    },
  }));

export const formatDeleteAttributes = (attributes) =>
  Object.entries(attributes).map(([attribute, value]) => ({
    data: {
      type: 'person-attributes',
      attributes: { attribute, value: 'placeholder' },
    },
  }));

export const getChangedAttributes = (attributes, currentAttributes) =>
  Object.fromEntries(
    Object.entries(attributes).filter(
      ([attribute, value]) =>
        currentAttributes?.[attribute]?.value !== value &&
        !!value &&
        value !== '',
    ),
  );

export const toBeUpdated = (attributes, currentAttributes) =>
  Object.fromEntries(
    Object.entries(attributes).filter(
      ([attribute, value]) =>
        currentAttributes?.[attribute]?.value !== value &&
        !!value &&
        value !== '',
    ),
  );

export const toBeDeleted = (attributes, currentAttributes) =>
  Object.fromEntries(
    Object.entries(attributes).filter(
      ([attribute, value]) =>
        attribute in currentAttributes &&
        currentAttributes?.[attribute]?.value !== value &&
        (!value || value === ''),
    ),
  );

const usePersonAttributes = ({ skip = false } = {}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated());
  const { personId, isPersonIdLoading } = useCurrentPersonQuery(
    {},
    {
      skip: !isAuthenticated || skip,
      selectFromResult: ({ data, isFetching }) => ({
        personId: data?.id,
        isPersonIdLoading: isFetching,
      }),
    },
  );

  const { data: personAttributes, isFetching: isPersonAttributesLoading } =
    useGetPersonAttributesQuery({ personId }, { skip: !personId || skip });

  const [upsertPersonAttribute, { isLoading: isUpsertPersonAttributeLoading }] =
    useUpsertPersonAttributeMutation();
  const [deletePersonAttribute, { isLoading: isDeletePersonAttributeLoading }] =
    useDeletePersonAttributeMutation();

  const upsertAttributes = promisify(async (attributes, callback) => {
    {
      const toBeUpdatedAttributes = toBeUpdated(attributes, personAttributes);
      const body = formatAttributes(toBeUpdatedAttributes);
      if (body.length > 0) {
        const { error, data } = await upsertPersonAttribute({ body, personId });
        callback(error, data);
      }
    }
    {
      const toBeDeletedAttributes = toBeDeleted(attributes, personAttributes);
      const body = formatDeleteAttributes(toBeDeletedAttributes);
      if (body.length > 0) {
        const { error, data } = await deletePersonAttribute({ body, personId });
        callback(error, data);
      }
    }
    callback(undefined, {});
  });

  const isLoading =
    isPersonIdLoading ||
    isPersonAttributesLoading ||
    isUpsertPersonAttributeLoading ||
    isDeletePersonAttributeLoading;

  return {
    isLoading,
    upsertAttributes,
    attributes: personAttributes,
    personId,
  };
};

export default usePersonAttributes;

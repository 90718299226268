const formBP = {
  title: 'Form BP',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'BP',
  type: 'panel',
  label: 'Form BP',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Blood Transfusions</strong><br/>The next questions will ask about your exposure to blood products (transfusions).',
      label: 'Content',
      refreshOnChange: false,
      key: 'BP0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'Have you ever received any of the following blood products? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        Blood: false,
        Plasma: false,
        FF_plasma: false,
        Cryoprecipitate: false,
        RBCs: false,
        Platelets: false,
        NS: false,
        DK: false,
        No_transf: false,
      },
      values: [
        {
          label: 'Whole blood',
          value: 'Blood',
          shortcut: '',
        },
        {
          label: 'Plasma',
          value: 'Plasma',
          shortcut: '',
        },
        {
          label: 'Fresh frozen plasma',
          value: 'FF_plasma',
          shortcut: '',
        },
        {
          label: 'Cryoprecipitate',
          value: 'Cryoprecipitate',
          shortcut: '',
        },
        {
          label: 'Packed Red Blood Cells',
          value: 'RBCs',
          shortcut: '',
        },
        {
          label: 'Platelets',
          value: 'Platelets',
          shortcut: '',
        },
        {
          label: "I've received transfusions but I'm not sure which kind",
          value: 'NS',
          shortcut: '',
        },
        {
          label: "I don't know if I have ever been transfused",
          value: 'DK',
          shortcut: '',
        },
        {
          label: 'I have never been transfused',
          value: 'No_transf',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'BP1',
      logic: [
        {
          name: 'DK',
          trigger: {
            type: 'javascript',
            javascript: "result = data['BP1']?.DK;",
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data['BP1']).reduce((acc, curr) => ({...acc, [curr]: curr === 'DK'}), {})",
            },
          ],
        },
        {
          name: 'NS',
          trigger: {
            type: 'javascript',
            javascript: "result = data['BP1']?.NS;",
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data['BP1']).reduce((acc, curr) => ({...acc, [curr]: curr === 'NS'}), {})",
            },
          ],
        },
        {
          name: 'No_transf',
          trigger: {
            type: 'javascript',
            javascript: "result = data['BP1']?.No_transf;",
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data['BP1']).reduce((acc, curr) => ({...acc, [curr]: curr === 'No_transf'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
  ],
};

export default formBP;

import {
  Button as MUIButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { ReactComponent as PrinterIcon } from 'images/printer.svg';

const Button = ({ icon, children, onClick }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  return sm ? (
    <MUIButton variant='outlined' onClick={onClick}>
      {icon}
    </MUIButton>
  ) : (
    <MUIButton
      variant='outlined'
      startIcon={icon}
      onClick={onClick}
      sx={{ flex: 1 }}
    >
      {children}
    </MUIButton>
  );
};

const Title = () => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing='15px'
      alignItems={{ xs: 'stretch', sm: 'center' }}
      mt={{ xs: 0, sm: '40px' }}
      mb='30px'
      mx={{ xs: '20px', sm: '50px' }}
    >
      <Typography variant='w600h24' color='gray.100' flex={1}>
        NBDF’s Community Voices in Research (CVR) Participant Agreement
      </Typography>
      <Stack direction='row' spacing='15px'>
        <Button onClick={handlePrint} icon={<PrinterIcon />}>
          Print
        </Button>
      </Stack>
    </Stack>
  );
};

export default Title;

import { Link, Stack } from '@mui/material';

import { ReactComponent as OpenNew } from 'images/open-new.svg';

const LinkButton = ({ url }) => (
  <Link target='_blank' rel='noopener' href={url} variant='w600h16'>
    <Stack direction='row' spacing='12px' p='13px 18px' alignItems='center'>
      <span>Learn more</span>
      <OpenNew />
    </Stack>
  </Link>
);

export default LinkButton;

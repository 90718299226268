const form = `
  <div class="formio-enrollment">
    <div class="wizard-page">
      {% if (component.description) { %}
        <div class="page-title">
          {{ component.description }}
        </div>
      {% } %}
      <div class="page-content" ref="{{ wizardKey }}">
        {{ components }}
      </div>
    </div>
    {{ wizardNav }}
  </div>
`;

export default form;

import { useMemo } from 'react';

import { Skeleton } from '@mui/material';

import Item from 'components/MyHealthSummary/Content/Items/Item';
import useGetBaseline from 'hooks/useGetBaseline';

import { parseMedications } from './utils';

const skeletonList = [
  { id: 1, description: <Skeleton width='210px' /> },
  { id: 2, description: <Skeleton width='260px' /> },
  { id: 3, description: <Skeleton width='180px' /> },
];

const Medications = (props) => {
  const { data, instrument, isLoading } = useGetBaseline();
  const medicationList = useMemo(
    () => parseMedications(data, instrument),
    [data, instrument],
  );
  return (
    <Item
      label='Medications'
      content={
        isLoading
          ? skeletonList
          : medicationList.length === 0
            ? '--'
            : medicationList
      }
      {...props}
    />
  );
};

export default Medications;

import { Button as MUIButton, Stack, Typography } from '@mui/material';

import { ReactComponent as PrinterIcon } from 'images/printer.svg';
import { ReactComponent as DownloadIcon } from 'images/download.svg';
const Title = ({ displayDate }) => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing='15px'
      alignItems={{ xs: 'stretch', sm: 'center' }}
      mt={{ xs: 0, sm: '40px' }}
      mb='30px'
      mx={{ xs: '20px', sm: '50px' }}
    >
      <Typography
        variant='h1'
        color='gray.60'
        sx={{
          fontSize: '0.8rem',
          fontWeight: (theme) => theme.typography.fontWeightLight,
        }}
        flex={1}
      >
        Consent signed on {displayDate}
      </Typography>
      <Stack direction='row' spacing='15px'>
        <MUIButton
          variant='outlined'
          startIcon={<DownloadIcon />}
          onClick={handlePrint}
          sx={{ flex: 1 }}
        >
          Download
        </MUIButton>
        <MUIButton
          variant='outlined'
          startIcon={<PrinterIcon />}
          onClick={handlePrint}
          sx={{ flex: 1 }}
        >
          Print
        </MUIButton>
      </Stack>
    </Stack>
  );
};

export default Title;

const formBH = {
  title: 'Form BH',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'BH',
  customConditional:
    'if (form.injectedValues) show = form.injectedValues?.R17 !== "no"',
  type: 'panel',
  label: 'Form BH',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Bleed History</strong></br/>The next few questions will ask about your bleeding history – answers will help us understand bleeding patterns of the different bleeding disorders.',
      label: 'Content',
      refreshOnChange: false,
      key: 'BH0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'Have you had a bleed in the past 6 months? This includes heavy menstrual bleeding, which is periods lasting more than 7 days, having to change sanitary products more often than every 2 hours, flooding [rushing or gushing of blood from the vagina], or passing large clots or clumps of blood from the vagina.',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'BH1',
      type: 'radio',
      input: true,
    },
    {
      label:
        'What types of bleeds have you experienced in the last 6 months? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        Brain: false,
        Head: false,
        Surgical: false,
        Injury: false,
        Tooth: false,
        Mouth: false,
        Joint: false,
        Nose: false,
        Bruising: false,
        Muscle: false,
        Neck: false,
        Sex: false,
        GI: false,
        Urine: false,
        NAK: false,
        noneOfTheAbove: false,
        other: false,
        Cir: false,
        Menstrual: false,
        Pregnancy: false,
        Miscarriage: false,
        Childbirth: false,
        Postpartum: false,
      },
      values: [
        {
          value: 'Cir',
          label: 'Circumcision',
          type: 'male',
          shortcut: '',
        },
        {
          value: 'Brain',
          label:
            'Internal head bleeding (in the brain: subdural, intracerebral, subarachnoid)',
          shortcut: '',
        },
        {
          value: 'Head',
          label:
            'External head bleeding (outside the brain: cephalohematoma, goose egg)',
          shortcut: '',
        },
        {
          value: 'Surgical',
          label: 'During or after surgery (excessive)',
          shortcut: '',
        },
        {
          value: 'Injury',
          label: 'After accident or injury (excessive)',
          shortcut: '',
        },
        {
          value: 'Tooth',
          label: 'Tooth extraction (excessive or abnormal)',
          shortcut: '',
        },
        {
          value: 'Mouth',
          label: 'Mouth (e.g. when brushing or flossing, gum bleeding)',
          shortcut: '',
        },
        {
          value: 'Joint',
          label: 'Joint',
          shortcut: '',
        },
        {
          value: 'Nose',
          label: 'Nose',
          shortcut: '',
        },
        {
          value: 'Bruising',
          label: 'Bruising',
          shortcut: '',
        },
        {
          value: 'Muscle',
          label: 'Muscle hematoma (deep lumpy bruising)',
          shortcut: '',
        },
        {
          value: 'Neck',
          label: 'Neck',
          shortcut: '',
        },
        {
          value: 'Menstrual',
          label:
            'Heavy periods (lasting more than 7 days, requiring changing sanitary products more often than every 2 hours, passing clots or experiencing flooding - soiling clothes and/or bedsheets)',
          type: 'female',
          shortcut: '',
        },
        {
          value: 'Pregnancy',
          label: 'During pregnancy',
          type: 'female',
          shortcut: '',
        },
        {
          value: 'Miscarriage',
          label: 'Related to a miscarriage',
          type: 'female',
          shortcut: '',
        },
        {
          value: 'Childbirth',
          label: 'During childbirth (excessive)',
          type: 'female',
          shortcut: '',
        },
        {
          value: 'Postpartum',
          label: 'After delivery (excessive)',
          type: 'female',
          shortcut: '',
        },
        {
          value: 'Sex',
          label: 'During sexual activity or intercourse',
          shortcut: '',
        },
        {
          value: 'GI',
          label:
            'Gastro-intestinal bleeding (blood in stool, black tarry stools, vomiting blood, bleeding ulcer, rectal)',
          shortcut: '',
        },
        {
          value: 'Urine',
          label: 'In urine',
          shortcut: '',
        },
        {
          value: 'NAK',
          label: 'Not sure',
          shortcut: '',
        },
        {
          value: 'noneOfTheAbove',
          label: 'None of the above',
          shortcut: '',
        },
        {
          value: 'other',
          label: 'Other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'BH2',
      conditional: {
        show: true,
        when: 'BH1',
        eq: 'Yes',
      },
      logic: [
        {
          name: 'Check for values',
          trigger: {
            type: 'simple',
            simple: {
              show: true,
              when: 'BH1',
              eq: 'Yes',
            },
          },
          actions: [
            {
              name: 'Add values',
              type: 'mergeComponentSchema',
              schemaDefinition:
                "\nconst birthSex = form?.injectedValues?.R8;\nschema = { values: component.values.filter(({ type }) => birthSex === 'intersex' || birthSex === type || !type) }",
            },
          ],
        },
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: "result = data['BH2']?.noneOfTheAbove;",
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(value).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {});",
            },
          ],
        },
        {
          name: 'Not sure',
          trigger: {
            type: 'javascript',
            javascript: "result = data['BH2']?.NAK;",
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(value).reduce((acc, curr) => ({...acc, [curr]: curr === 'NAK'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'BH2_other',
      customConditional: 'show = data.BH2?.other',
      type: 'textfield',
      input: true,
    },
    {
      label:
        "In the last 6 months, please indicate the number of times you've experienced the following bleed(s):",
      disableAddingRemovingRows: true,
      reorder: false,
      addAnotherPosition: 'bottom',
      layoutFixed: false,
      enableRowGroups: false,
      initEmpty: true,
      tableView: false,
      calculateValue:
        '\nconst filterOut = ["other", "Cir", "NAK", "noneOfTheAbove"];\nconst bh2Values = utils.getComponent(form.components, \'BH2\')?.values\n  ?.filter(({ value }) =>\n    !filterOut.includes(value) // remove special categories\n    && data[\'BH2\']?.[value]); // remove not selected\n\nconst other = data[\'BH2\']?.other ? [{ value: "other", label: data[\'BH2_other\'] }] : [];\nconst bh2ValuesWithOther = [...bh2Values, ...other];\n\nconst mapValue = value.reduce((acc, curr) => ({ ...acc, [curr.key]: curr }), {});\nconst newValue = bh2ValuesWithOther.map(({ value: key, label }) => ({ \n  key,\n  label,\n  numberOfBleeds: mapValue[key]?.numberOfBleeds ?? "",\n}));\n\n  const delta = bh2ValuesWithOther.length - value.length;\n  if (delta > 0) {\n    for (let i = 0; i < delta; i++) instance.addRow();\n  } else {\n    for (let i = 0; i < (-delta); i++) instance.removeRow(0);\n  }\n  value = newValue;',
      key: 'BH3',
      customDefaultValue: 'instance.focusOnNewRowElement = () => {}',
      customConditional:
        'const filterOut = ["Cir", "NAK", "noneOfTheAbove"];\nshow = !!data[\'BH2\'] && !!Object.entries(data[\'BH2\'])?.find?.(([key, value]) => value && !filterOut.includes(key));',
      type: 'datagrid',
      input: true,
      components: [
        {
          label: 'Type of bleeds',
          applyMaskOn: 'change',
          autoExpand: false,
          disabled: true,
          tableView: true,
          key: 'label',
          type: 'textarea',
          input: true,
          customClass: 'width-100',
        },
        {
          label: 'Number of bleeds',
          placeholder: 'Type the number of bleeds',
          applyMaskOn: 'change',
          customClass: 'width-150px',
          autocomplete: 'off',
          mask: false,
          tableView: false,
          defaultValue: [null],
          delimiter: false,
          requireDecimal: false,
          inputFormat: 'plain',
          truncateMultipleSpaces: false,
          validate: {
            required: true,
            min: 1,
            max: 999,
          },
          key: 'numberOfBleeds',
          type: 'number',
          input: true,
        },
      ],
    },
    {
      label:
        'In the past 6 months, have you had 3 or more bleeds in the same joint on the same side of the body?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'BH4',
      customConditional: "show = data['BH2']?.Joint",
      type: 'radio',
      input: true,
    },
    {
      label: 'Which joint(s)? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        L_ank: false,
        R_ank: false,
        L_knee: false,
        R_knee: false,
        L_hip: false,
        R_hip: false,
        L_sho: false,
        R_sho: false,
        L_elb: false,
        R_elb: false,
        L_wrist: false,
        R_wrist: false,
        noneOfTheAbove: false,
        other: false,
      },
      values: [
        {
          value: 'L_ank',
          label: 'Left ankle',
          shortcut: '',
        },
        {
          value: 'R_ank',
          label: 'Right ankle',
          shortcut: '',
        },
        {
          value: 'L_knee',
          label: 'Left knee',
          shortcut: '',
        },
        {
          value: 'R_knee',
          label: 'Right knee',
          shortcut: '',
        },
        {
          value: 'L_hip',
          label: 'Left hip',
          shortcut: '',
        },
        {
          value: 'R_hip',
          label: 'Right hip',
          shortcut: '',
        },
        {
          value: 'L_sho',
          label: 'Left shoulder',
          shortcut: '',
        },
        {
          value: 'R_sho',
          label: 'Right shoulder',
          shortcut: '',
        },
        {
          value: 'L_elb',
          label: 'Left elbow',
          shortcut: '',
        },
        {
          value: 'R_elb',
          label: 'Right elbow',
          shortcut: '',
        },
        {
          value: 'L_wrist',
          label: 'Left wrist',
          shortcut: '',
        },
        {
          value: 'R_wrist',
          label: 'Right wrist',
          shortcut: '',
        },
        {
          value: 'noneOfTheAbove',
          label: 'None of the above',
          shortcut: '',
        },
        {
          value: 'other',
          label: 'Other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'BH5',
      conditional: {
        show: true,
        when: 'BH4',
        eq: 'Yes',
      },
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: "result = data['BH5']?.noneOfTheAbove;",
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data['BH5']).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'BH5_other',
      customConditional: 'show = data.BH5?.other',
      type: 'textfield',
      input: true,
    },
    {
      label:
        'Have you ever had a bleed where you were at risk for losing your arm or leg due to bleeding (often called “compartment syndrome”)?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'BH6',
      type: 'radio',
      input: true,
    },
    {
      label: 'Have you ever had a bleed where you were at risk for death?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'BH7',
      type: 'radio',
      input: true,
    },
    {
      label: 'What types of bleeds were these? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          value: 'Pregnancy',
          label: 'During pregnancy',
          type: 'female',
          shortcut: '',
        },
        {
          value: 'Miscarriage',
          label: 'Related to a miscarriage',
          type: 'female',
          shortcut: '',
        },
        {
          value: 'Childbirth',
          label: 'Related to childbirth',
          type: 'female',
          shortcut: '',
        },
        {
          value: 'Menstrual',
          label: 'Menstruation',
          type: 'female',
          shortcut: '',
        },
        {
          value: 'Surgical',
          label: 'Related to surgery',
          shortcut: '',
        },
        {
          value: 'Trauma',
          label: 'Due to trauma or accident',
          shortcut: '',
        },
        {
          value: 'Brain',
          label: 'Intracranial hemorrhage (bleeding in the brain)',
          shortcut: '',
        },
        {
          value: 'Mouth',
          label: 'Mouth or throat',
          shortcut: '',
        },
        {
          value: 'Dental',
          label: 'Dental',
          shortcut: '',
        },
        {
          value: 'Neck',
          label: 'Neck',
          shortcut: '',
        },
        {
          value: 'GI',
          label: 'Gastrointestinal (e.g., stomach, ulcer, etc.)',
          shortcut: '',
        },
        {
          value: 'Organ',
          label: 'Internal organ',
          shortcut: '',
        },
        {
          value: 'Iliopsoas',
          label: 'Iliopsoas or "psoas" muscle',
          shortcut: '',
        },
        {
          value: 'Muscle',
          label: 'Major muscle bleed (not including iliopsoas)',
          shortcut: '',
        },
        {
          value: 'other',
          label: 'Other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'BH8',
      conditional: {
        show: true,
        when: 'BH7',
        eq: 'Yes',
      },
      logic: [
        {
          name: 'Check for values',
          trigger: {
            type: 'simple',
            simple: {
              show: true,
              when: 'BH7',
              eq: 'Yes',
            },
          },
          actions: [
            {
              name: 'Add values',
              type: 'mergeComponentSchema',
              schemaDefinition:
                "const birthSex = form?.injectedValues?.R8;\nschema = { values: component.values.filter(({ type }) => birthSex === 'intersex' || birthSex === type || !type) }",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        Pregnancy: false,
        Miscarriage: false,
        Childbirth: false,
        Menstrual: false,
        Surgical: false,
        Trauma: false,
        Brain: false,
        Mouth: false,
        Dental: false,
        Neck: false,
        GI: false,
        Organ: false,
        Iliopsoas: false,
        Muscle: false,
        other: false,
      },
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'BH8_other',
      customConditional: "show = data['BH8']?.other",
      type: 'textfield',
      input: true,
    },
    {
      label:
        'In the past 12 months, how many times have you visited a hospital emergency room and/or urgent care for a bleed or other complication of your bleeding disorder?',
      placeholder: 'Please enter the number of times',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        required: true,
        min: 0,
        max: 999,
      },
      key: 'BH9',
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label:
        'In the past 12 months, how many nights have you been hospitalized or admitted to the hospital (not including the emergency room) due to a bleed or other complication of your bleeding disorder?',
      placeholder: 'Please enter the number of nights',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        required: true,
        min: 0,
        max: 999,
      },
      key: 'BH10',
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
  ],
};

export default formBH;

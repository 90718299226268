import { useRef } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import TitleWithSubtitle from 'components/TitleWithSubtitle';
import useTrackScroll from 'hooks/useTrackScroll';

import ColoredBoxesList from './components/ColoredBoxesList';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import GetStarted from './components/GetStarted';
import Header from './components/Header';
import Hero from './components/Hero';
import IconsList from './components/IconsList';

const learnMoreOffset = 120;

const SplashPage = () => {
  const learnMoreRef = useRef(null);
  const [, posY] = useTrackScroll();

  const executeScroll = () =>
    window.scroll(
      window.scrollX,
      learnMoreRef.current.offsetTop - learnMoreOffset,
    );

  return (
    <Stack bgcolor='white'>
      <Header alpha={posY / 200} />
      <Hero onClickHandler={executeScroll} />
      <Box
        textAlign='center'
        sx={{ paddingX: { xs: 4.5, md: 45 }, marginY: { xs: 7.5, md: 18 } }}
      >
        <TitleWithSubtitle
          title='What is CVR?'
          subtitle='Community Voices in Research (CVR) is a community-powered registry that gathers information through surveys. It helps researchers understand what it is like to live with a bleeding disorder directly from you! It is open to all persons affected by an inheritable bleeding disorder as well as their non-affected family members (parents, spouses, grandparents, and siblings).'
          refProp={learnMoreRef}
        />
        <Typography mt={2} variant='subtitle1'>
          Join this exciting yet simple opportunity to contribute to research
          and make a positive impact on the lives of current and future
          generations.
        </Typography>
      </Box>
      <IconsList />
      <GetStarted />
      <Box
        textAlign='center'
        sx={{ paddingX: { xs: 4.5, md: 45 }, marginY: { xs: 7.5, md: 18 } }}
      >
        <TitleWithSubtitle
          title='A Community Registry Powered by You!'
          subtitle='The NBDF Education and Public Data Summaries are coming soon.'
        />
      </Box>
      <ColoredBoxesList />
      <FAQ />
      <Footer />
    </Stack>
  );
};

export default SplashPage;

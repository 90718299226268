import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from './baseQuery';

const nhfApi = createApi({
  reducerPath: 'nhfApi',
  baseQuery: baseQuery(),
  endpoints: () => ({}),
  tagTypes: [
    'Person',
    'PersonAttributes',
    'PersonStudyRoles',
    'User',
    'MFAAuthenticators',
    'ParticipantTasks',
  ],
});

export default nhfApi;

import { useRef } from 'react';

import { Grid } from '@mui/material';

import { GridPageLayout } from 'components/Layouts';
import { Content } from 'components/MyHealthSummary';
import useDownload from 'hooks/useDownload';
import usePrint from 'hooks/usePrint';
import { ReactComponent as DownloadIcon } from 'images/download.svg';
import { ReactComponent as PrinterIcon } from 'images/printer.svg';

const MyHealthSummary = () => {
  const ref = useRef(null);

  const { download, downloading } = useDownload(ref, 'my-health-summary.pdf');
  const { print, printing } = usePrint(ref);

  const printMode = downloading || printing;

  return (
    <GridPageLayout
      ref={ref}
      title='My Health Summary'
      description={
        <span>
          <br />
          <br />
          You can get your detailed information and either download it or print
          it to bring with you to your next appointment with a new provider.
          <br />
        </span>
      }
      buttons={[
        {
          icon: <DownloadIcon />,
          label: 'Download',
          onClick: download,
        },
        {
          icon: <PrinterIcon />,
          label: 'Print',
          onClick: print,
        },
      ]}
      print={printMode}
    >
      <Grid item xs={12}>
        <Content print={printMode} />
      </Grid>
    </GridPageLayout>
  );
};

export default MyHealthSummary;

import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { Stack, useTheme } from '@mui/material';

import orange_male_svg from 'images/orange_male.svg';
import orange_female_svg from 'images/orange_female.svg';
import purple_male_svg from 'images/purple_male.svg';
import purple_female_svg from 'images/purple_female.svg';

const id = 'PeopleChart';
const svgBaseWidth = 24;
const svgBaseHeight = 37;
const svgRate = svgBaseHeight / svgBaseWidth;
const layoutBreakpoint = 450;
const legendWidth = 70;
const legendOffset = 5;
const rowOffset = 15;

const PeopleChart = ({ percentage }) => {
  const svgRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (!svgRef?.current) return;
    const resizeObserver = new ResizeObserver(() => {
      const height = svgRef.current.offsetHeight - 5;
      const width = svgRef.current.offsetWidth;

      d3.select(`#${id}`).selectAll('svg').remove();

      const svg = d3
        .select(svgRef.current)
        .append('svg')
        .attr('viewBox', [0, 0, width, height]);

      const graphWidth = width - legendWidth;
      const legendPos = graphWidth + legendOffset;

      const columns = width < layoutBreakpoint ? 10 : 20;

      const svgWidth = graphWidth / columns;
      const svgHeight = svgWidth * svgRate;

      for (let row = 0, count = 0, isMale = false; count < 100; row++) {
        for (let col = 0; col < columns; col++, count++, isMale = !isMale) {
          const person = isMale
            ? count < percentage
              ? purple_male_svg
              : orange_male_svg
            : count < percentage
            ? purple_female_svg
            : orange_female_svg;

          svg
            .append('image')
            .attr('x', col * svgWidth)
            .attr('y', row * (svgHeight + rowOffset))
            .attr('width', svgWidth)
            .attr('height', svgHeight)
            .attr('xlink:href', person);
        }
      }

      const rows = 100 / columns;
      const totalHeight = rows * (svgHeight + rowOffset);
      d3.select(`#${id}`).style('height', `${totalHeight}px`);

      let yPos = 15;
      const symbolOffset = 20;
      svg
        .append('text')
        .attr('x', legendPos)
        .attr('y', yPos)
        .text(`${percentage}%`)
        .style('font-size', '30px')
        .style('font-weight', '600')
        .attr('alignment-baseline', 'middle')
        .attr('fill', theme.palette.nbdfBlue[100]);
      svg
        .append('text')
        .attr('x', legendPos)
        .attr('y', yPos + symbolOffset)
        .text('YES')
        .style('font-size', '12px')
        .style('font-weight', '600')
        .attr('alignment-baseline', 'middle')
        .attr('fill', theme.palette.nbdfBlue[100]);

      yPos += 60;
      svg
        .append('text')
        .attr('x', legendPos)
        .attr('y', yPos)
        .text(`${100 - percentage}%`)
        .style('font-size', '30px')
        .style('font-weight', '600')
        .attr('alignment-baseline', 'middle')
        .attr('fill', theme.palette.salmon[100]);
      svg
        .append('text')
        .attr('x', legendPos)
        .attr('y', yPos + symbolOffset)
        .text('NO')
        .style('font-size', '12px')
        .style('font-weight', '600')
        .attr('alignment-baseline', 'middle')
        .attr('fill', theme.palette.salmon[100]);
    });

    resizeObserver.observe(svgRef.current);

    return () => resizeObserver.disconnect();
  }, [percentage, theme.palette]);

  return (
    <Stack ref={svgRef} id={id} sx={{ overflow: 'hidden', flex: '1 1 0px' }} />
  );
};

export default PeopleChart;

const employmentDifficulties = {
  display: 'form',
  components: [
    {
      label: 'Bleeding Disorder',
      tableView: false,
      data: {
        values: [
          {
            label: 'Hemophilia A',
            value: 'hemophiliaA',
          },
          {
            label: 'Hemophilia B',
            value: 'hemophiliaB',
          },
          {
            label: 'Von Willebrand Disease',
            value: 'vonWillebrandDisease',
          },
          {
            label: 'Ultra-rare bleeding disorders',
            value: 'ultraRareBleedingDisorders',
          },
        ],
      },
      key: 'bleedingDisorder',
      widget: 'choicesjs',
      type: 'select',
      input: true,
      inputType: 'checkbox',
      logic: [
        {
          name: 'on clear',
          trigger: {
            type: 'javascript',
            javascript:
              "const content = data[component.key]\nresult = content === '' || content === undefined || content === null",
          },
          actions: [
            {
              name: 'Select first',
              type: 'value',
              value: 'value = component.data.values[0].value',
            },
          ],
        },
      ],
    },
    {
      optionsLabelPosition: 'right',
      tableView: false,
      hideLabel: true,
      values: [
        {
          label:
            'Have you ever had any difficulty finding employment because of your bleeding disorder?',
          value:
            'haveYouEverHadAnyDifficultyFindingEmploymentBecauseOfYourBleedingDisorder',
          shortcut: '',
        },
        {
          label:
            'Have you ever had any difficulty maintaining employment because of your bleeding disorder?',
          value:
            'haveYouEverHadAnyDifficultyMaintainingEmploymentBecauseOfYourBleedingDisorder',
          shortcut: '',
        },
        {
          label:
            'Have you ever declined employment opportunities because of your bleeding disorder?',
          value:
            'haveYouEverDeclinedEmploymentOpportunitiesBecauseOfYourBleedingDisorder',
          shortcut: '',
        },
      ],
      key: 'haveYou',
      type: 'radio',
      input: true,
      logic: [
        {
          name: 'on clear',
          trigger: {
            type: 'javascript',
            javascript:
              "const content = data[component.key]\nresult = content === '' || content === undefined || content === null",
          },
          actions: [
            {
              name: 'Select first',
              type: 'value',
              value: 'value = component.values[0].value',
            },
          ],
        },
      ],
    },
  ],
};

export default employmentDifficulties;

export const usStateCoordinates = {
  AL: { latitude: 31.4, longitude: -86.791 }, // Alabama
  AK: { latitude: 61.37, longitude: -152.404 }, // Alaska
  AZ: { latitude: 34.293, longitude: -111.664 }, // Arizona
  AR: { latitude: 34.893, longitude: -92.442 }, // Arkansas
  CA: { latitude: 36.778, longitude: -119.417 }, // California
  CO: { latitude: 39.059, longitude: -105.311 }, // Colorado
  CT: { latitude: 41.603, longitude: -72.689 }, // Connecticut
  DE: { latitude: 38.825, longitude: -75.562 }, // Delaware
  FL: { latitude: 27.766, longitude: -81.686 }, // Florida
  GA: { latitude: 31.28, longitude: -83.22 }, // Georgia
  HI: { latitude: 20.796, longitude: -156.331 }, // Hawaii
  ID: { latitude: 44.35, longitude: -114.613 }, // Idaho
  IL: { latitude: 40.041, longitude: -89.196 }, // Illinois
  IN: { latitude: 39.894, longitude: -86.281 }, // Indiana
  IA: { latitude: 42.059, longitude: -93.362 }, // Iowa
  KS: { latitude: 38.498, longitude: -98.32 }, // Kansas
  KY: { latitude: 37.534, longitude: -85.29 }, // Kentucky
  LA: { latitude: 31.169, longitude: -91.869 }, // Louisiana
  ME: { latitude: 45.384, longitude: -69.244 }, // Maine
  MD: { latitude: 39.055, longitude: -76.79 }, // Maryland
  MA: { latitude: 42.259, longitude: -71.808 }, // Massachusetts
  MI: { latitude: 44.346, longitude: -85.41 }, // Michigan
  MN: { latitude: 46.28, longitude: -94.308 }, // Minnesota
  MS: { latitude: 32.736, longitude: -89.667 }, // Mississippi
  MO: { latitude: 38.356, longitude: -92.458 }, // Missouri
  MT: { latitude: 47.052, longitude: -109.624 }, // Montana
  NE: { latitude: 41.495, longitude: -99.901 }, // Nebraska
  NV: { latitude: 38.504, longitude: -116.564 }, // Nevada
  NH: { latitude: 43.412, longitude: -71.584 }, // New Hampshire
  NJ: { latitude: 40.258, longitude: -74.408 }, // New Jersey
  NM: { latitude: 34.421, longitude: -106.108 }, // New Mexico
  NY: { latitude: 42.249, longitude: -74.602 }, // New York
  NC: { latitude: 35.552, longitude: -79.048 }, // North Carolina
  ND: { latitude: 47.468, longitude: -100.471 }, // North Dakota
  OH: { latitude: 40.417, longitude: -82.907 }, // Ohio
  OK: { latitude: 35.961, longitude: -97.338 }, // Oklahoma
  OR: { latitude: 43.935, longitude: -120.589 }, // Oregon
  PA: { latitude: 40.878, longitude: -77.799 }, // Pennsylvania
  RI: { latitude: 41.676, longitude: -71.566 }, // Rhode Island
  SC: { latitude: 33.628, longitude: -80.947 }, // South Carolina
  SD: { latitude: 44.383, longitude: -100.35 }, // South Dakota
  TN: { latitude: 35.747, longitude: -86.692 }, // Tennessee
  TX: { latitude: 31.554, longitude: -99.901 }, // Texas
  UT: { latitude: 40.15, longitude: -111.862 }, // Utah
  VT: { latitude: 44.068, longitude: -72.664 }, // Vermont
  VA: { latitude: 37.769, longitude: -78.169 }, // Virginia
  WA: { latitude: 47.4, longitude: -121.49 }, // Washington
  WV: { latitude: 38.491, longitude: -80.954 }, // West Virginia
  WI: { latitude: 44.269, longitude: -89.857 }, // Wisconsin
  WY: { latitude: 42.755, longitude: -107.302 }, // Wyoming
};

export const shadeColor = (percent) => {
  var color = '#C18EB5';
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 - percent)) / 100);
  G = parseInt((G * (100 - percent)) / 100);
  B = parseInt((B * (100 - percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  var RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};
export const chapters = [
  {
    State: 'Alaska',
    'Organization Name': 'Alaska Hemophilia Association',
    Website: 'www.alaskableedingdisorders.org',
  },
  {
    State: 'Arizona',
    'Organization Name': 'Arizona Bleeding Disorders',
    Website: 'www.arizonahemophilia.org',
  },
  {
    State: 'California',
    'Organization Name': 'Central California Hemophilia Foundation',
    Website: 'www.cchfsac.org',
  },
  {
    State: 'California',
    'Organization Name': 'Hemophilia Foundation of Northern California',
    Website: 'www.hemofoundation.org',
  },
  {
    State: 'California',
    'Organization Name': 'Hemophilia Foundation of Southern California',
    Website: 'www.hemosocal.org',
  },
  {
    State: 'California',
    'Organization Name': 'Hemophilia Association of San Diego County',
    Website: 'www.hasdc.org',
  },
  {
    State: 'Colorado',
    'Organization Name': 'Colorado Chapter, National Hemophilia Foundation',
    Website: 'www.cohemo.org',
  },
  {
    State: 'Florida',
    'Organization Name': 'Bleeding Disorders Foundation of Florida',
    Website: 'www.hemophiliaflorida.org',
  },
  {
    State: 'Florida',
    'Organization Name': 'Florida Hemophilia Association',
    Website: 'www.floridahemophilia.org',
  },
  {
    State: 'Georgia',
    'Organization Name': 'Hemophilia of Georgia, Inc.',
    Website: 'www.hog.org',
  },
  {
    State: 'Hawaii',
    'Organization Name': 'Hawaii Chapter, National Hemophilia Foundation',
    Website: 'www.hawaiinhf.org',
  },
  {
    State: 'Idaho',
    'Organization Name': 'Idaho Chapter, National Hemophilia Foundation',
    Website: 'www.idahoblood.org',
  },
  {
    State: 'Illinois',
    'Organization Name': 'Bleeding Disorders Alliance Illinois',
    Website: 'www.bdai.org',
  },
  {
    State: 'Indiana',
    'Organization Name': 'Hemophilia of Indiana',
    Website: 'www.hoii.org',
  },
  {
    State: 'Iowa',
    'Organization Name': 'Bleeding Disorders of the Heartland',
    Website: 'https://bdheartland.org/',
  },
  {
    State: 'Kentucky',
    'Organization Name': 'Kentucky Hemophilia Foundation',
    Website: 'www.kyhemo.org',
  },
  {
    State: 'Louisiana',
    'Organization Name': 'Louisiana Hemophilia Foundation',
    Website: 'www.lahemo.org',
  },
  {
    State: 'Maine',
    'Organization Name': 'Hemophilia Alliance of Maine, Inc.',
    Website: 'www.mainehemophilia.org',
  },
  {
    State: 'Massachusetts',
    'Organization Name': 'New England Hemophilia Association',
    Website: 'www.newenglandhemophilia.org',
  },
  {
    State: 'Michigan',
    'Organization Name': 'Hemophilia Foundation of Michigan',
    Website: 'www.hfmich.org',
  },
  {
    State: 'Minnesota',
    'Organization Name': 'Hemophilia Foundation of Minnesota and the Dakotas',
    Website: 'www.hfmd.org',
  },
  {
    State: 'Missouri',
    'Organization Name': 'Midwest Hemophilia Association',
    Website: 'www.midwesthemophilia.org',
  },
  {
    State: 'Missouri',
    'Organization Name': 'Gateway Hemophilia Association',
    Website: 'http://www.gatewayhemophilia.org',
  },
  {
    State: 'Montana',
    'Organization Name':
      'Rocky Mountain Hemophilia and Bleeding Disorders Association',
    Website: 'www.rmhbda.org',
  },
  {
    State: 'Nebraska',
    'Organization Name': 'Nebraska Chapter, National Hemophilia Foundation',
    Website: 'www.nebraskanhf.org',
  },
  {
    State: 'Nevada',
    'Organization Name': 'Nevada Chapter, National Hemophilia Foundation',
    Website: 'www.hfnv.org',
  },
  {
    State: 'New Mexico',
    'Organization Name':
      'Sangre de Oro, Bleeding Disorders Foundation of New Mexico',
    Website: 'http://sangredeoro.org',
  },
  {
    State: 'New York',
    'Organization Name': 'Western New York BloodCare',
    Website: 'www.wnybloodcare.org',
  },
  {
    State: 'New York',
    'Organization Name': 'New York City Hemophilia Chapter Inc.',
    Website: 'www.nyhemophilia.org',
  },
  {
    State: 'New York',
    'Organization Name':
      'Bleeding Disorders Association of Northeastern New York',
    Website: 'www.bdaneny.org',
  },
  {
    State: 'New York',
    'Organization Name': 'Mary M. Gooley Hemophilia Center',
    Website: 'www.hemocenter.org',
  },
  {
    State: 'North Carolina',
    'Organization Name': 'Bleeding Disorders Foundation of North Carolina',
    Website: 'www.bleedingdisordersnc.org',
  },
  {
    State: 'North Dakota',
    'Organization Name': 'Bleeding Disorders Alliance of North Dakota',
    Website: 'www.bdand.org',
  },
  {
    State: 'Ohio',
    'Organization Name': 'Southwestern Ohio Hemophilia Foundation',
    Website: 'www.swohf.org',
  },
  {
    State: 'Ohio',
    'Organization Name': 'Central Ohio Chapter, National Hemophilia Foundation',
    Website: 'www.nhfcentralohio.org',
  },
  {
    State: 'Ohio',
    'Organization Name': 'Northern Ohio Hemophilia Foundation',
    Website: 'www.nohf.org',
  },
  {
    State: 'Ohio',
    'Organization Name': 'Tri-State Bleeding Disorders Foundation',
    Website: 'www.tsbdf.com',
  },
  {
    State: 'Ohio',
    'Organization Name': 'Northwest Ohio Hemophilia Foundation',
    Website: 'www.nwohemophilia.org',
  },
  {
    State: 'Oklahoma',
    'Organization Name': 'Oklahoma Hemophilia Foundation',
    Website: 'www.okhemophilia.org',
  },
  {
    State: 'Oregon',
    'Organization Name': 'Pacific Northwest Bleeding Disorders',
    Website: 'www.pnwbd.org',
  },
  {
    State: 'Pennsylvania',
    'Organization Name': 'Eastern Pennsylvania Bleeding Disorders Foundation',
    Website: 'https://epbdf.org/',
  },
  {
    State: 'Pennsylvania',
    'Organization Name': 'Western Pennsylvania Bleeding Disorders Foundation',
    Website: 'www.wpbdf.org',
  },
  {
    State: 'Puerto Rico',
    'Organization Name':
      'Asociación Puertorriqueña de Hemofilia y Condiciones de Sang',
    Website: 'https://hemofiliapr.org',
  },
  {
    State: 'South Carolina',
    'Organization Name': 'Bleeding Disorders Association of South Carolina',
    Website: 'www.bda-sc.org',
  },
  {
    State: 'Tennessee',
    'Organization Name':
      'Tennessee Hemophilia and Bleeding Disorders Foundation',
    Website: 'www.thbdf.org',
  },
  {
    State: 'Texas',
    'Organization Name': 'Texas Central Bleeding Disorders',
    Website: 'www.texcen.org',
  },
  {
    State: 'Texas',
    'Organization Name': 'Lone Star Bleeding Disorders Foundation',
    Website: 'www.lonestarbleedingdisorders.org',
  },
  {
    State: 'Virginia',
    'Organization Name': 'Hemophilia Association of the Capital Area',
    Website: 'www.hacacares.org',
  },
  {
    State: 'Virginia',
    'Organization Name': 'Virginia Hemophilia Foundation',
    Website: 'www.vahemophilia.org',
  },
  {
    State: 'Washington',
    'Organization Name': 'Bleeding Disorder Foundation of Washington',
    Website: 'www.bdfwa.org',
  },
  {
    State: 'West Virginia',
    'Organization Name':
      'West Virginia Chapter, National Hemophilia Foundation',
    Website: 'http://wvnhf.org/',
  },
  {
    State: 'Wisconsin',
    'Organization Name': 'Great Lakes Hemophilia Foundation',
    Website: 'www.glhf.org',
  },
];
export const stateToRegionMapping = {
  // East Region
  Connecticut: 'East',
  Maine: 'East',
  Massachusetts: 'East',
  'New Hampshire': 'East',
  'New Jersey': 'East',
  'New York': 'East',
  Pennsylvania: 'East',
  'Rhode Island': 'East',
  Vermont: 'East',
  Mississippi: 'East',
  Virginia: 'East',
  Kentucky: 'East',
  Tennessee: 'East',
  Alabama: 'East',
  Florida: 'East',
  Georgia: 'East',
  'Puerto Rico': 'East',
  'South Carolina': 'East',
  'North Carolina': 'East',
  'West Virginia': 'East',
  Maryland: 'East',
  Delaware: 'East',
  Indiana: 'East',
  Ohio: 'East',
  Michigan: 'East',
  'District of Columbia': 'East',

  // North Region
  Illinois: 'North',
  Minnesota: 'North',
  'North Dakota': 'North',
  'South Dakota': 'North',
  Wisconsin: 'North',

  // South Region
  Iowa: 'South',
  Kansas: 'South',
  Arkansas: 'South',
  Louisiana: 'South',
  Oklahoma: 'South',
  Texas: 'South',
  Missouri: 'South',
  Nebraska: 'South',

  // West Region
  Alaska: 'West',
  Arizona: 'West',
  California: 'West',
  Guam: 'West',
  Colorado: 'West',
  Hawaii: 'West',
  Idaho: 'West',
  Montana: 'West',
  Nevada: 'West',
  'New Mexico': 'West',
  Oregon: 'West',
  Utah: 'West',
  Washington: 'West',
  Wyoming: 'West',
};
export const cardinalData = [
  {
    name: 'West',
    description: 'WEST',
    latitude: 43.617775,
    longitude: -116.199722,
  },
  {
    name: 'North',
    description: 'NORTH',
    latitude: 45.591087,
    longitude: -93.603729,
  },
  {
    name: 'South',
    description: 'SOUTH',
    latitude: 35.492207,
    longitude: -97.503342,
  },
  {
    name: 'East',
    description: 'EAST',
    latitude: 33.749027,
    longitude: -84.388229,
  },
];
export const HTCs = [
  {
    RegionName: 'Great Lakes',
    Facility: "Akron Children's Hospital",
    State: 'Ohio',
  },
  {
    RegionName: 'Mountain States',
    Facility: 'Alaska Bleeding Disorder Clinics',
    State: 'Alaska',
  },
  {
    RegionName: 'New England, Region II',
    Facility: 'Albany Medical Center',
    State: 'New York',
  },
  {
    RegionName: 'Northern States',
    Facility: "Ann & Robert H. Lurie Children's Hospital of Chicago",
    State: 'Illinois',
  },
  {
    RegionName: 'Mountain States',
    Facility:
      "Arizona Hemophilia and Thrombosis Center at Phoenix Children's Hospital",
    State: 'Arizona',
  },
  {
    RegionName: 'Mountain States',
    Facility:
      'Arizona Hemophilia and Thrombosis Center at the University of Arizona',
    State: 'Arizona',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'Arkansas Center for Bleeding Disorders',
    State: 'Arkansas',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility:
      "Arnold Palmer Hospital for Children- The Haley Center for Children's Cancer and Blood Disorders",
    State: 'Florida',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility: 'Augusta University Adult Hemophilia Treatment Center',
    State: 'Georgia',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility: 'Augusta University Pediatric Hemophilia Treatment Center',
    State: 'Georgia',
  },
  {
    RegionName: 'Northern States',
    Facility: 'Bleeding & Clotting Disorders Institute',
    State: 'Illinois',
  },
  {
    RegionName: 'New England, Region I',
    Facility: "Boston Hemophilia Center- Boston Children's Hospital",
    State: 'Massachusetts',
  },
  {
    RegionName: 'New England, Region I',
    Facility: "Boston Hemophilia Center- Brigham and Women's Faulkner Hospital",
    State: 'Massachusetts',
  },
  {
    RegionName: 'Great Lakes',
    Facility: 'Bronson Hemophilia Treatment Center',
    State: 'Michigan',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: 'Cardeza Foundation Hemophilia Center',
    State: 'Pennsylvania',
  },
  {
    RegionName: 'Great Lakes',
    Facility: 'Cascade Hemophilia Consortium',
    State: 'Michigan',
  },
  {
    RegionName: 'Western States',
    Facility:
      'Center for Comprehensive Care and Diagnosis of Inherited Blood Disorders',
    State: 'California',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: 'Charleston Area Medical Center',
    State: 'West Virginia',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility: "Children's Hospital at Memorial University Medical Center",
    State: 'Georgia',
  },
  {
    RegionName: 'Western States',
    Facility: 'Childrens Hospital Los Angeles',
    State: 'California',
  },
  {
    RegionName: 'Great Lakes',
    Facility: "Children's Hospital of Michigan",
    State: 'Michigan',
  },
  {
    RegionName: 'Great Plains',
    Facility: "Children's Hospital of New Orleans",
    State: 'Louisiana',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: "Children's Hospital of Philadelphia",
    State: 'Pennsylvania',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: "Children's Hospital of the King's Daughters",
    State: 'Virginia',
  },
  {
    RegionName: 'Great Plains',
    Facility: "Children's Medical Center Bleeding and Thrombosis Program",
    State: 'Texas',
  },
  {
    RegionName: 'Northern States',
    Facility: "Children's Minnesota",
    State: 'Minnesota',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: "Children's National Medical Center",
    State: 'District of Columbia',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility: "Children's Rehabilitation Services - Birmingham Clinic",
    State: 'Alabama',
  },
  {
    RegionName: 'Great Lakes',
    Facility: "Cincinnati Children's Hospital Medical Center",
    State: 'Ohio',
  },
  {
    RegionName: 'Northern States',
    Facility: 'Comprehensive Center for Bleeding Disorders',
    State: 'Wisconsin',
  },
  {
    RegionName: 'New England, Region I',
    Facility: 'Connecticut Bleeding Disorders Center',
    State: 'Connecticut',
  },
  {
    RegionName: 'New England, Region I',
    Facility: "Connecticut Children's Hemophilia Treatment Centers",
    State: 'Connecticut',
  },
  {
    RegionName: 'New England, Region I',
    Facility: 'Dartmouth-Hitchcock Hemophilia Center',
    State: 'New Hampshire',
  },
  {
    RegionName: 'Great Lakes',
    Facility: "Dayton Children's Hemostasis and Thrombosis Center",
    State: 'Ohio',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: 'Delaware Hemophilia Treatment Center',
    State: 'Delaware',
  },
  {
    RegionName: 'Great Lakes',
    Facility: 'Detroit Receiving Hospital',
    State: 'Michigan',
  },
  {
    RegionName: 'Southeast, Region IV-North',
    Facility: 'East Carolina University Health Hemophilia Treatment Center',
    State: 'North Carolina',
  },
  {
    RegionName: 'Southeast, Region IV-North',
    Facility: 'East Tennessee Comprehensive Hemophilia Center',
    State: 'Tennessee',
  },
  {
    RegionName: 'Great Lakes',
    Facility: 'Eastern Michigan Hemophilia Treatment Center',
    State: 'Michigan',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'Fort Worth Comprehensive Hemophilia Center',
    State: 'Texas',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: 'Georgetown University Hospital',
    State: 'District of Columbia',
  },
  {
    RegionName: 'Northern States',
    Facility: 'Great Lakes Hemophilia Foundation',
    State: 'Wisconsin',
  },
  {
    RegionName: 'Western States',
    Facility: 'Guam Comprehensive Hemophilia Care Program',
    State: 'Guam',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'Gulf States Hemophilia and Thrombophilia Center',
    State: 'Texas',
  },
  {
    RegionName: 'Northern States',
    Facility: 'Gundersen Clinic',
    State: 'Wisconsin',
  },
  {
    RegionName: 'Great Lakes',
    Facility: "Helen DeVos Children's Hospital",
    State: 'Michigan',
  },
  {
    RegionName: 'Mountain States',
    Facility:
      'Hemophilia and Thrombosis Center at the University of Colorado Anschutz',
    State: 'Colorado',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: 'Hemophilia and Thrombosis Center of Central Pennsylvania',
    State: 'Pennsylvania',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: 'Hemophilia Center of Western Pennsylvania',
    State: 'Pennsylvania',
  },
  {
    RegionName: 'Great Lakes',
    Facility: 'Hemophilia Foundation of Michigan',
    State: 'Michigan',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility:
      'Hemophilia of Georgia Center for Bleeding and Clotting Disorders of Emory, Adult Division',
    State: 'Georgia',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility:
      'Hemophilia of Georgia Center for Bleeding and Clotting Disorders of Emory, Pediatric Division',
    State: 'Georgia',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility: 'Hemophilia of Georgia, Inc.',
    State: 'Georgia',
  },
  {
    RegionName: 'Northern States',
    Facility: 'Hemophilia Outreach Center',
    State: 'Wisconsin',
  },
  {
    RegionName: 'Southeast, Region IV-North',
    Facility:
      "Hemophilia Treatment Center of Levine Cancer Center and Levine Children's Hospital",
    State: 'North Carolina',
  },
  {
    RegionName: 'Western States',
    Facility: 'Hemostasis and Thrombosis Center of Nevada (HTCNV)',
    State: 'Nevada',
  },
  {
    RegionName: 'Great Lakes',
    Facility:
      'Henry Ford Hospital Adult Hemophilia and Thrombosis Treatment Center',
    State: 'Michigan',
  },
  {
    RegionName: 'New England, Region II',
    Facility: 'Icahn School of Medicine at Mount Sinai',
    State: 'New York',
  },
  {
    RegionName: 'Great Lakes',
    Facility: 'Indiana Hemophilia and Thrombosis Center',
    State: 'Indiana',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility: "Johns Hopkins All Children's Hospital",
    State: 'Florida',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: 'Johns Hopkins University Medical Center',
    State: 'Maryland',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'Kansas City Regional Hemophilia Center',
    State: 'Missouri',
  },
  {
    RegionName: 'Western States',
    Facility: "Kapi'olani Medical Center for Women and Children",
    State: 'Hawaii',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility: 'Kidz Medical Services, Inc.',
    State: 'Florida',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: 'Lehigh Valley Hospital-Muhlenberg',
    State: 'Pennsylvania',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'Louisiana Center for Bleeding and Clotting Disorders',
    State: 'Louisiana',
  },
  {
    RegionName: 'New England, Region I',
    Facility: 'Maine Medical Center',
    State: 'Maine',
  },
  {
    RegionName: 'New England, Region II',
    Facility: 'Mary M. Gooley Hemophilia Center, Inc.',
    State: 'New York',
  },
  {
    RegionName: 'New England, Region I',
    Facility:
      'Massachusetts General Comprehensive Hemophilia and Thrombosis Treatment Center',
    State: 'Massachusetts',
  },
  {
    RegionName: 'Northern States',
    Facility: 'Mayo Comprehensive Hemophilia Center',
    State: 'Minnesota',
  },
  {
    RegionName: 'Great Lakes',
    Facility:
      'Michigan State University Center for Bleeding Disorders & Clotting Disorders',
    State: 'Michigan',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility: 'Mississippi Center for Advanced Medicine',
    State: 'Mississippi',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'Missouri Hemophilia Treatment Center',
    State: 'Missouri',
  },
  {
    RegionName: 'New England, Region II',
    Facility: 'Montefiore Medical Center',
    State: 'New York',
  },
  {
    RegionName: 'Great Lakes',
    Facility: "Nationwide Children's Hospital Hemostasis and Thrombosis Center",
    State: 'Ohio',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'Nebraska Regional Hemophilia Treatment Center',
    State: 'Nebraska',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility: "Nemours Children's Health",
    State: 'Florida',
  },
  {
    RegionName: 'New England, Region I',
    Facility: 'New England Hemophilia Center at UMASS Memorial',
    State: 'Massachusetts',
  },
  {
    RegionName: 'New England, Region II',
    Facility: 'Newark Beth Israel Medical Center',
    State: 'New Jersey',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'North Texas Comprehensive Hemophilia Center-Adult Program',
    State: 'Texas',
  },
  {
    RegionName: 'Great Lakes',
    Facility: 'Northern Regional Bleeding Disorder Center',
    State: 'Michigan',
  },
  {
    RegionName: 'New England, Region II',
    Facility: 'Northwell Hemostasis and Thrombosis Center',
    State: 'New York',
  },
  {
    RegionName: 'Great Lakes',
    Facility: 'Northwest Ohio Hemophilia Treatment Center',
    State: 'Ohio',
  },
  {
    RegionName: 'Northern States',
    Facility: 'Northwestern Center for Bleeding Disorders',
    State: 'Illinois',
  },
  {
    RegionName: 'Great Lakes',
    Facility: 'Ohio State University Medical Center',
    State: 'Ohio',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'Oklahoma Center for Bleeding and Clotting Disorders',
    State: 'Oklahoma',
  },
  {
    RegionName: 'Western States',
    Facility: 'Orthopaedic Institute for Children',
    State: 'California',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: 'Penn Comprehensive Hemophilia and Thrombosis Program',
    State: 'Pennsylvania',
  },
  {
    RegionName: 'Southeast, Region IV-North',
    Facility:
      'Prisma Health - Upstate Hemophilia Treatment Center of South Carolina',
    State: 'South Carolina',
  },
  {
    RegionName: 'Southeast, Region IV-North',
    Facility:
      'Prisma Health- Midlands Hemophilia Treatment Center of South Carolina',
    State: 'South Carolina',
  },
  {
    RegionName: 'Mountain States',
    Facility: "Providence Sacred Heart Children's Hospital",
    State: 'Washington',
  },
  {
    RegionName: 'New England, Region II',
    Facility: 'Puerto Rico Hemophilia Treatment Center',
    State: 'Puerto Rico',
  },
  {
    RegionName: 'Western States',
    Facility: "Rady Children's Hospital San Diego",
    State: 'California',
  },
  {
    RegionName: 'New England, Region I',
    Facility: 'RI Hemostasis & Thrombosis Center',
    State: 'Rhode Island',
  },
  {
    RegionName: 'Northern States',
    Facility: 'Rush University Medical Center',
    State: 'Illinois',
  },
  {
    RegionName: 'New England, Region II',
    Facility:
      'Rutgers, The State University, Robert Wood Johnson Medical School',
    State: 'New Jersey',
  },
  {
    RegionName: 'Great Plains',
    Facility:
      'Saint Louis University Center for Bleeding and Thrombotic Disorders',
    State: 'Missouri',
  },
  {
    RegionName: 'Northern States',
    Facility: 'Sanford Health - Center for Bleeding and Clotting Disorder',
    State: 'North Dakota',
  },
  {
    RegionName: 'Mountain States',
    Facility: "Seattle Children's Hospital",
    State: 'Washington',
  },
  {
    RegionName: 'Northern States',
    Facility: 'South Dakota Center for Blood Disorders',
    State: 'South Dakota',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'South Texas Comprehensive Hemophilia Center',
    State: 'Texas',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: "St. Christopher's Hospital for Children",
    State: 'Pennsylvania',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility:
      "St. Joseph's Hospital Center for Bleeding and Clotting Disorders",
    State: 'Florida',
  },
  {
    RegionName: 'Southeast, Region IV-North',
    Facility:
      "St. Jude Affiliate Clinic at Novant Health Hemby Children's Hospital",
    State: 'North Carolina',
  },
  {
    RegionName: 'Southeast, Region IV-North',
    Facility: "St. Jude Children's Research Hospital",
    State: 'Tennessee',
  },
  {
    RegionName: 'Mountain States',
    Facility: "St. Luke's Hemophilia Center",
    State: 'Idaho',
  },
  {
    RegionName: 'New England, Region II',
    Facility: "St. Michael's Medical Center",
    State: 'New Jersey',
  },
  {
    RegionName: 'Western States',
    Facility: 'Stanford University Medical Center',
    State: 'California',
  },
  {
    RegionName: 'New England, Region II',
    Facility: 'SUNY Upstate Medical University-Adult Program',
    State: 'New York',
  },
  {
    RegionName: 'New England, Region II',
    Facility: 'SUNY Upstate Medical University-Pediatric Program',
    State: 'New York',
  },
  {
    RegionName: 'Mountain States',
    Facility: 'Ted R. Montoya Hemophilia Program',
    State: 'New Mexico',
  },
  {
    RegionName: 'Great Plains',
    Facility: "Texas Children's Hemophilia and Thrombosis Center",
    State: 'Texas',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'The Central Texas Center for Bleeding and Clotting Disorders',
    State: 'Texas',
  },
  {
    RegionName: 'Mountain States',
    Facility: 'The Hemophilia Center at Oregon Health & Science University',
    State: 'Oregon',
  },
  {
    RegionName: 'New England, Region II',
    Facility:
      'The Joan & Sanford I Weill Cornell Medical College of Cornell University',
    State: 'New York',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'The John Bouhasin Center for Children with Bleeding Disorders',
    State: 'Missouri',
  },
  {
    RegionName: 'Western States',
    Facility: "UCSF Benioff Children's Hospital Oakland",
    State: 'California',
  },
  {
    RegionName: 'Great Lakes',
    Facility: 'UHHS Cleveland',
    State: 'Ohio',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility: 'University of Alabama at Birmingham',
    State: 'Alabama',
  },
  {
    RegionName: 'Western States',
    Facility: 'University of California at Davis',
    State: 'California',
  },
  {
    RegionName: 'Western States',
    Facility: 'University of California San Francisco',
    State: 'California',
  },
  {
    RegionName: 'Western States',
    Facility: 'University of California, San Diego',
    State: 'California',
  },
  {
    RegionName: 'Great Lakes',
    Facility: 'University of Cincinnati Medical Center',
    State: 'Ohio',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility: 'University of Florida Hemophilia Treatment Center',
    State: 'Florida',
  },
  {
    RegionName: 'Great Plains',
    Facility: 'University of Iowa Hospitals & Clinics',
    State: 'Iowa',
  },
  {
    RegionName: 'Southeast, Region IV-North',
    Facility: 'University of Kentucky Hemophilia Treatment Center',
    State: 'Kentucky',
  },
  {
    RegionName: 'Southeast, Region IV-North',
    Facility: 'University of Louisville Hemophilia Treatment Center',
    State: 'Kentucky',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility:
      'University of Miami Hemophilia Treatment Center Pediatrics and Adults',
    State: 'Florida',
  },
  {
    RegionName: 'Great Lakes',
    Facility: 'University of Michigan Hemophilia and Coagulation Disorders',
    State: 'Michigan',
  },
  {
    RegionName: 'Northern States',
    Facility:
      'University of Minnesota Health - Center for Bleeding and Clotting Disorders',
    State: 'Minnesota',
  },
  {
    RegionName: 'Southeast, Region IV-North',
    Facility: 'University of North Carolina at Chapel Hill',
    State: 'North Carolina',
  },
  {
    RegionName: 'Southeast, Region IV-South',
    Facility: 'University of South Florida - Adult',
    State: 'Florida',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: 'University of Virginia Hospital',
    State: 'Virginia',
  },
  {
    RegionName: 'Mountain States',
    Facility:
      "Utah Center for Bleeding & Clotting Disorders at Primary Children's Hospital and University of Utah",
    State: 'Utah',
  },
  {
    RegionName: 'Northern States',
    Facility: 'UWHC Comprehensive Program for Bleeding Disorders',
    State: 'Wisconsin',
  },
  {
    RegionName: 'Western States',
    Facility: "Valley Children's Hospital",
    State: 'California',
  },
  {
    RegionName: 'Southeast, Region IV-North',
    Facility: 'Vanderbilt University Medical Center',
    State: 'Tennessee',
  },
  {
    RegionName: 'New England, Region I',
    Facility: 'Vermont Regional Hemophilia Center',
    State: 'Vermont',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: 'Virginia Commonwealth University',
    State: 'Virginia',
  },
  {
    RegionName: 'Southeast, Region IV-North',
    Facility: 'Wake Forest University School of Medicine',
    State: 'North Carolina',
  },
  {
    RegionName: 'Mountain States',
    Facility:
      'Washington Center for Bleeding Disorders at Washington Institute for Coagulation',
    State: 'Washington',
  },
  {
    RegionName: 'Great Plains',
    Facility:
      'Washington University Center for Bleeding and Blood Clotting Disorders-Adult Program',
    State: 'Missouri',
  },
  {
    RegionName: 'Great Plains',
    Facility:
      'Washington University Center for Bleeding and Blood Clotting Disorders-Pediatric Progra',
    State: 'Missouri',
  },
  {
    RegionName: 'Mid-Atlantic',
    Facility: 'West Virginia University Medical Center',
    State: 'West Virginia',
  },
  {
    RegionName: 'New England, Region II',
    Facility: 'Western New York BloodCare',
    State: 'New York',
  },
  {
    RegionName: 'New England, Region I',
    Facility: 'Yale HTC',
    State: 'Connecticut',
  },
];

export const stateAcronym = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const sortTreeMapData = (data) => {
  const sortedData = {};
  for (const disease in data) {
    sortedData[disease] = {};
    for (const subCategory in data[disease]) {
      sortedData[disease][subCategory] = sortObjectByValues(
        data[disease][subCategory]
      );
    }
  }

  return sortedData;
};
const sortObjectByValues = (obj) => {
  const entries = Object.entries(obj);
  entries.sort((b, a) => a[1] - b[1]);
  const sortedObject = Object.fromEntries(entries);
  return sortedObject;
};

import nhfApi from 'store/api/nhf';
import { NhfApiVersions, RequestMethods } from 'utils/enums';
import parseJson from 'utils/parseJson';

const participantTasks = nhfApi.injectEndpoints({
  endpoints: (build) => ({
    getParticipantTask: build.query({
      query: ({ personId, participantTaskId, params }) => ({
        url: `/persons/${personId}/participant-tasks/${participantTaskId}`,
        apiVersion: NhfApiVersions.V1,
        method: RequestMethods.Get,
        params,
        bypassCaseConverter: true,
      }),
      providesTags: ['ParticipantTasks'],
    }),
    getParticipantTasks: build.query({
      query: ({ personId, params }) => ({
        url: `/persons/${personId}/participant-tasks`,
        apiVersion: NhfApiVersions.V1,
        method: RequestMethods.Get,
        params,
        bypassCaseConverter: true,
      }),
      providesTags: ['ParticipantTasks'],
      transformResponse: ({ data }, _, params) => {
        let result = data;
        if (params?.query?.title) {
          result = result?.filter(
            (survey) => survey.task.title === params.query.title,
          );
        }
        if (params?.query?.status) {
          result = result?.filter(
            (survey) => survey.status === params.query.status,
          );
        }
        if (params?.excludeQuery?.title) {
          result = result?.filter(
            (survey) => survey.task.title !== params.excludeQuery.title,
          );
        }
        return result
          ?.sort((lhs, rhs) => {
            const lhsEnabled =
              parseJson(lhs?.task?.attributes?.[0]?.value)?.enabled === 'true';
            const rhsEnabled =
              parseJson(rhs?.task?.attributes?.[0]?.value)?.enabled === 'true';
            return lhsEnabled === rhsEnabled ? 0 : lhsEnabled ? -1 : 1;
          })
          ?.map?.(({ id }) => id);
      },
    }),
    createParticipantTaskEntry: build.mutation({
      query: ({
        personId,
        participantTaskId,
        data,
        status,
        participantTaskEntryId: participant_task_entry_id,
      }) => ({
        url: `/persons/${personId}/participant-tasks/${participantTaskId}`,
        apiVersion: NhfApiVersions.V1,
        method: RequestMethods.Post,
        body: {
          data,
          status,
          participant_task_entry_id,
        },
        bypassCaseConverter: true,
      }),
      invalidatesTags: ['ParticipantTasks'],
    }),
    updateParticipantTaskEntry: build.mutation({
      query: ({
        personId,
        participantTaskId,
        data,
        status,
        participantTaskEntryId: participant_task_entry_id,
      }) => ({
        url: `/persons/${personId}/participant-tasks/${participantTaskId}`,
        apiVersion: NhfApiVersions.V1,
        method: RequestMethods.Patch,
        body: {
          data,
          status,
          participant_task_entry_id,
        },
        bypassCaseConverter: true,
      }),
      invalidatesTags: ['ParticipantTasks'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetParticipantTaskQuery,
  useGetParticipantTasksQuery,
  useCreateParticipantTaskEntryMutation,
  useUpdateParticipantTaskEntryMutation,
} = participantTasks;

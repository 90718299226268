import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';

import JoinCVR from 'components/JoinCVR';
import CreateAccount from 'components/CreateAccount';
import { useAuth } from 'context/AuthContext';
import ThankYouForYourInterest from 'components/ThankYouForYourInterest';
import {
  formatAttributes,
  getChangedAttributes,
} from 'hooks/usePersonAttributes';
import { openSnackbar } from 'store/slices/application';
import { useCreatePersonMutation } from 'store/api/nhf/endpoints/people';
import { useCreateUserMutation } from 'store/api/nhf/endpoints/users';
import { useCreatePersonStudyRoleMutation } from 'store/api/nhf/endpoints/personStudyRoles';
import { useUpsertPersonAttributeMutation } from 'store/api/nhf/endpoints/personAttributes';
import { SnackbarError, SnackbarSuccess } from 'utils/enums';

const minimumAllowedAge = (state) =>
  ({
    Alabama: 19,
    Nebraska: 19,
    Mississippi: 21,
    "Puerto Rico": 21,
  }[state] ?? 18);

const Join = () => {
  const dispatch = useDispatch();
  const { signup, userInfo, isAuthenticated } = useAuth();

  const [joinData, setJoinData] = useState(null);
  const [rejected, setRejected] = useState(false);
  const [isGeneratingUser, setGeneratingUser] = useState(false);

  const handleJoin = ({
    email,
    date_of_birth,
    usResident,
    state,
    euResident,
  }) => {
    const age = moment().diff(date_of_birth, 'years', false);
    const pass =
      usResident === 'yes' &&
      age >= minimumAllowedAge(state) &&
      euResident === 'no';
    pass ? setJoinData({ email, date_of_birth, state }) : setRejected(true);
  };

  const [createPerson] = useCreatePersonMutation();
  const [createUser] = useCreateUserMutation();
  const [upsertPersonAttribute] = useUpsertPersonAttributeMutation();
  const [createPersonStudyRole] = useCreatePersonStudyRoleMutation();

  const generateUserRef = useRef(true);
  useEffect(() => {
    const onError = () => dispatch(openSnackbar(SnackbarError.TryAgain()));
    const generateUser = async () => {
      // Create person
      const { error: personError, data: personData } = await createPerson();
      if (Boolean(personError)) return onError();

      // Create user
      const personId = personData.data.id;
      const { error: personUserError } = await createUser({
        attributes: {
          providerId: userInfo.sub,
          name: userInfo.name,
          personId: Number(personId),
        },
      });
      if (Boolean(personUserError)) return onError();

      // Move auth0 attributes to study-model
      const { error: personAttributesError } = await upsertPersonAttribute({
        body: formatAttributes(
          getChangedAttributes({
            first_name: userInfo.given_name,
            middle_name: userInfo.middle_name,
            last_name: userInfo.family_name,
            phone: userInfo.phone,
            email: userInfo.email,
            date_of_birth: userInfo.date_of_birth,
            state: userInfo.state,
          })
        ),
        personId,
      });
      if (Boolean(personAttributesError)) return onError();

      // Create study-roles
      const { error: studyRoleError } = await createPersonStudyRole({
        personId,
        studyRoleId: 1,
      });
      if (Boolean(studyRoleError)) return onError();
    };
    if (isAuthenticated && generateUserRef.current && isGeneratingUser) {
      generateUserRef.current = false;
      generateUser();
    }
  }, [
    createPerson,
    createPersonStudyRole,
    createUser,
    isAuthenticated,
    upsertPersonAttribute,
    userInfo,
    isGeneratingUser,
    dispatch,
  ]);

  const handleCreate = ({ password, ...data }) => {
    setGeneratingUser(true);
    signup({ ...joinData, password, ...data })
      .then(() => {
        dispatch(openSnackbar(SnackbarSuccess.AccountCreated()));
      })
      .catch(() => {
        dispatch(
          openSnackbar(SnackbarError.EmailAlreadyExists(joinData.email))
        );
        setJoinData(null);
        setGeneratingUser(false);
      });
  };

  if (isGeneratingUser) return <CircularProgress />;
  if (rejected) return <ThankYouForYourInterest />;
  if (joinData) return <CreateAccount onCreate={handleCreate} />;
  return <JoinCVR onJoin={handleJoin} />;
};

export default Join;

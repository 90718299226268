import ChevronDown from 'images/chevron-down.svg';

const MuiSelect = {
  styleOverrides: {
    root: ({ theme }) => ({
      '.MuiSelect-select': {
        ...theme.typography.w400h14,
        color: theme.palette.gray[100],
        padding: '0 18px !important',
        margin: 0,
        borderRadius: '6px',
        backgroundColor: theme.palette.white,
        border: `1px solid ${theme.palette.gray[20]}`,
        height: '44px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
      },
      '.MuiSelect-select::after': {
        content: '""',
        display: 'block',
        width: '14px',
        height: '14px',
        backgroundColor: theme.palette.gray[20],
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        maskImage: `url(${ChevronDown})`,
        transform: 'rotate(0deg)',
        transition: 'transform 350ms',
      },
      '.MuiSelect-select[aria-expanded=true]::after': {
        transform: 'rotate(180deg)',
        transition: 'transform 350ms',
      },
      'svg, fieldset': {
        display: 'none',
      },
    }),
  },
};

export default MuiSelect;

const formFBD = {
  title: 'Form FBD',
  label: 'Form FBD',
  type: 'panel',
  key: 'FBD',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>First Bleeding Symptoms and Diagnosis</strong></br/>Diagnosing a bleeding disorder can be challenging and, unfortunately for many, it can take many years. The next few questions will ask about the first signs and symptoms you experienced related to your bleeding disorder, and how you were diagnosed.',
      label: 'Content',
      refreshOnChange: false,
      key: 'FBD0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'How old were you when you first experienced bleeding symptoms?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Before age 1',
          value: '0',
          shortcut: '',
        },
        {
          label: 'After age 1',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'FBD1',
      customConditional: "show = form.injectedValues.R17 === 'yes'",
      type: 'radio',
      input: true,
    },
    {
      label: 'Please enter age',
      placeholder: 'Please enter age',
      applyMaskOn: 'change',
      hideLabel: true,
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        min: 1,
        required: true,
        max: 999,
      },
      key: 'FBD1_other',
      conditional: {
        show: true,
        when: 'FBD1',
        eq: 'other',
      },
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label:
        'What were your <strong>first</strong> bleeding symptoms? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'Umbilical cord/umbilical stump/belly button',
          value: 'Umb',
          shortcut: '',
          type: 'common',
        },
        {
          label: 'Circumcision',
          value: 'Cir',
          shortcut: '',
          type: 'male',
        },
        {
          label:
            'Internal head bleeding (in the brain: subdural, intracerebral, subarachnoid, brain bleeding)',
          value: 'Brain',
          shortcut: '',
          type: 'common',
        },
        {
          label:
            'External head bleeding (outside the brain: cephalohematoma, goose egg)',
          value: 'Head',
          shortcut: '',
          type: 'common',
        },
        {
          label: 'During pregnancy',
          value: 'Pregnancy',
          shortcut: '',
          type: 'female',
        },
        {
          label: 'Related to a miscarriage',
          value: 'Miscarriage',
          shortcut: '',
          type: 'female',
        },
        {
          label: 'During childbirth (excessive)',
          value: 'Childbirth',
          shortcut: '',
          type: 'female',
        },
        {
          label: 'After delivery (excessive)',
          value: 'Postpartum',
          shortcut: '',
          type: 'female',
        },
        {
          label: 'During or after surgery (excessive)',
          value: 'Surgical',
          shortcut: '',
          type: 'common',
        },
        {
          label: 'After accident or injury (excessive)',
          value: 'Injury',
          shortcut: '',
          type: 'common',
        },
        {
          label: 'Tooth extraction (excessive or abnormal)',
          value: 'Tooth',
          shortcut: '',
          type: 'common',
        },
        {
          label:
            'Mouth (e.g., tooth eruption – when first teeth started coming in, when brushing or flossing, gum bleeding, caused by injury)',
          value: 'Mouth',
          shortcut: '',
          type: 'common',
        },
        {
          label: 'Joint',
          value: 'Joint',
          shortcut: '',
          type: 'common',
        },
        {
          label: 'Nose',
          value: 'Nose',
          shortcut: '',
          type: 'common',
        },
        {
          label: 'Bruising',
          value: 'Bruising',
          shortcut: '',
          type: 'common',
        },
        {
          label: 'Muscle hematoma (deep lumpy bruising)',
          value: 'Muscle',
          shortcut: '',
          type: 'common',
        },
        {
          label: 'Neck',
          value: 'Neck',
          shortcut: '',
          type: 'common',
        },
        {
          label:
            'Heavy periods (lasting more than 7 days, requiring changing sanitary products more often than every 2 hours, passing clots or experiencing flooding - soiling clothes and/or bedsheets)',
          value: 'Menstrual',
          shortcut: '',
          type: 'female',
        },
        {
          label: 'During sexual activity or intercourse',
          value: 'Sex',
          shortcut: '',
          type: 'common',
        },
        {
          label:
            'Gastro-intestinal bleeding (blood in stool, black tarry stools, vomiting blood, bleeding ulcer, rectal)',
          value: 'GI',
          shortcut: '',
          type: 'common',
        },
        {
          label: 'In urine',
          value: 'Urine',
          shortcut: '',
          type: 'common',
        },
        {
          label: 'Not sure',
          value: 'Unsure',
          shortcut: '',
          type: 'common',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
          type: 'common',
        },
      ],
      validate: {
        required: true,
      },
      key: 'FBD2',
      customConditional: "show = form.injectedValues.R17 === 'yes'",
      logic: [
        {
          name: 'Change',
          trigger: {
            type: 'javascript',
            javascript: "result = form.injectedValues.R17 === 'yes'",
          },
          actions: [
            {
              name: 'Filter',
              type: 'mergeComponentSchema',
              schemaDefinition:
                "const birthSex = form?.injectedValues?.R8;\nschema = { values: component.values.filter(({ type }) => birthSex === 'intersex' || birthSex === type || type === 'common') }",
            },
          ],
        },
        {
          name: 'Not sure selected',
          trigger: {
            type: 'javascript',
            javascript: 'result = data?.FBD2?.Unsure',
          },
          actions: [
            {
              name: 'Clear all',
              type: 'value',
              value:
                "value = Object.keys(data?.FBD2).reduce((acc, curr) => ({...acc, [curr]: curr === 'Unsure'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        Umb: false,
        Brain: false,
        Head: false,
        Surgical: false,
        Injury: false,
        Tooth: false,
        Mouth: false,
        Joint: false,
        Nose: false,
        Bruising: false,
        Muscle: false,
        Neck: false,
        Sex: false,
        GI: false,
        Urine: false,
        Unsure: false,
        other: false,
      },
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'FBD2_other',
      conditional: {
        show: true,
        when: 'FBD2',
        eq: 'other',
      },
      type: 'textfield',
      input: true,
    },
    {
      label:
        'Which of these symptom(s) led to your diagnosis? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      validate: {
        required: true,
      },
      key: 'FBD3',
      customConditional:
        "show = form.injectedValues?.R18 === 'yesIHaveBeenFormallyDiagnosedWithABleedingDisorderIncludingBeingACarrierByAHealthCareProvider'",
      logic: [
        {
          name: 'Change',
          trigger: {
            type: 'javascript',
            javascript: 'result = true;',
          },
          actions: [
            {
              name: 'Filter',
              type: 'customAction',
              customAction:
                "const componentValues = utils.getComponent(form.components, 'FBD2')?.values;\nconst values = componentValues?.filter?.(({ value }) => value !== 'other' && !!data['FBD2']?.[value]) ?? []\ncomponent.values = [...values, ...(!!data['FBD2']?.['other'] ? [{ label: data['FBD2_other'], value: \"other\" }] : []), { label: \"I did not have symptoms before I was diagnosed\", value: \"None\" }]",
            },
          ],
        },
        {
          name: 'Not sure selected',
          trigger: {
            type: 'javascript',
            javascript: 'result = data?.FBD3?.Unsure',
          },
          actions: [
            {
              name: 'Clear all',
              type: 'value',
              value:
                "value = Object.keys(data?.FBD3).reduce((acc, curr) => ({...acc, [curr]: curr === 'Unsure'}), {})",
            },
          ],
        },
        {
          name: 'None selected',
          trigger: {
            type: 'javascript',
            javascript: 'result = data?.FBD3?.None',
          },
          actions: [
            {
              name: 'Clear all',
              type: 'customAction',
              customAction:
                "value = Object.keys(data?.FBD3).reduce((acc, curr) => ({...acc, [curr]: curr === 'None'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label:
        'How old were you when you were diagnosed? Choose only one response.',
      widget: 'choicesjs',
      placeholder: 'How old were you...',
      tableView: true,
      data: {
        values: [
          {
            label: 'In utero (while your mother was pregnant/before birth)',
            value: 'fetal',
          },
          {
            label: 'After birth and before age 1',
            value: '0',
          },
          {
            label: 'After age 1',
            value: 'other',
          },
          {
            label: 'I don’t know/I’m not sure',
            value: 'DK',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'FBD4',
      customConditional:
        "show = form.injectedValues?.R18 === 'yesIHaveBeenFormallyDiagnosedWithABleedingDisorderIncludingBeingACarrierByAHealthCareProvider'",
      type: 'select',
      input: true,
    },
    {
      label: 'Other',
      placeholder: 'Please enter age when diagnosed',
      applyMaskOn: 'change',
      hideLabel: true,
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        required: true,
        min: 1,
        max: 999,
      },
      key: 'FBD4_other',
      conditional: {
        show: true,
        when: 'FBD4',
        eq: 'other',
      },
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label:
        'At the time you were diagnosed, did you have a family history of a bleeding disorder?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
        {
          label: 'I don’t know',
          value: 'DK',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'FBD5',
      customConditional:
        "show = form.injectedValues?.R18 === 'yesIHaveBeenFormallyDiagnosedWithABleedingDisorderIncludingBeingACarrierByAHealthCareProvider'",
      type: 'radio',
      input: true,
    },
    {
      label:
        'Who made the initial diagnosis of a bleeding disorder? Choose only one response.',
      widget: 'choicesjs',
      placeholder: 'Select who',
      tableView: true,
      data: {
        values: [
          {
            label: 'Primary care provider or pediatrician',
            value: 'PCP',
          },
          {
            label: 'Hemophilia Treatment Center (HTC) hematologist',
            value: 'HTC',
          },
          {
            label: 'Non-HTC hematologist',
            value: 'Non-HTC',
          },
          {
            label: 'Dentist',
            value: 'Dentist',
          },
          {
            label: 'Emergency room or urgent care provider',
            value: 'ED',
          },
          {
            label: 'Surgeon',
            value: 'Surgeon',
          },
          {
            label: 'Ear/nose/throat (ENT) specialist',
            value: 'ENT',
          },
          {
            label: 'Obstetrician/gynecologist',
            value: 'OB-GYN',
          },
          {
            label: 'Other',
            value: 'other',
          },
          {
            label: 'I don’t know/I don’t remember',
            value: 'DK',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'FBD6',
      customConditional:
        "show = form.injectedValues?.R18 === 'yesIHaveBeenFormallyDiagnosedWithABleedingDisorderIncludingBeingACarrierByAHealthCareProvider'",
      type: 'select',
      input: true,
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'FBD6_other',
      conditional: {
        show: true,
        when: 'FBD6',
        eq: 'other',
      },
      type: 'textfield',
      input: true,
    },
    {
      label:
        'Have you ever experienced a reversal of diagnosis related to a bleeding disorder?',
      widget: 'choicesjs',
      placeholder: 'Have you ever....',
      tableView: true,
      data: {
        values: [
          {
            label:
              'Yes, I was diagnosed with a bleeding disorder but later told I did not have it',
            value: 'Yes-No',
          },
          {
            label:
              'Yes, I was diagnosed with a bleeding disorder and later diagnosed with a different one',
            value: 'Change_dx',
          },
          {
            label:
              'Yes, I was told that I did not have a bleeding disorder but was later diagnosed with one',
            value: 'No-Yes',
          },
          {
            label: 'No, I have not experienced any of the above',
            value: 'No',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'FBD7',
      type: 'select',
      input: true,
    },
  ],
};

export default formFBD;

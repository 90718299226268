import { Link, Stack } from '@mui/material';

import Header from './Header';
import Paragraph from './Paragraph';

const Content = () => (
  <Stack spacing='15px' my='40px' mx={{ xs: '20px', sm: '50px' }}>
    <Header>What is NBDF’s Community Voices in Research (CVR)?</Header>
    <Paragraph>
      CVR is the National Bleeding Disorders Foundation’s (NBDF)
      community-powered registry (also known as a database) for the bleeding
      disorders community. CVR gathers information from people with inherited
      bleeding disorders and their immediate family members over time.
      <br />
      <br />
      This information will help NBDF identify research priorities important to
      the community, develop educational programs, and tailor advocacy efforts
      to the community’s needs.
      <br />
      <br />
      CVR also provides you (the participant) a personalized dashboard so that
      you can see, among other things, your personal data, follow trends over
      time, and compare yourself with other participants anonymously. This
      approach will help everyone be an active participant in their healthcare.
      <br />
      <br />
    </Paragraph>
    <Header>What are CVR’s goals?</Header>
    <Paragraph>
      CVR gathers information directly from those who are affected by a bleeding
      disorder and their family members, without interpretation of the responses
      by a healthcare provider or anyone else. This provides a unique and very
      valuable perspective.
      <br />
      <br />
      CVR will help researchers understand what it means to live with a bleeding
      disorder from your point of view with the goal to improve quality of life
      (QOL), identify trends over time, and identify research questions relevant
      to the community.
      <br />
      <br />
      NBDF plans to gather data through CVR for 5 years, but our hope is to be
      able to support CVR indefinitely.
      <br />
      <br />
    </Paragraph>
    <Header>Who is eligible for CVR? How do I participate?</Header>
    <Paragraph>
      CVR is open to any person over the age of 18 with an inherited bleeding
      disorder as well as their immediate family members (parent, spouse,
      sibling, child, grandparent, grandchild).
      <br />
      <br />
      Participation in CVR occurs through online surveys. All surveys are
      confidential and voluntary. Participants may opt out at any time.
      <br />
      <br />
      The survey schedule is as follows:
      <br />
      <br />
      <ol>
        <li>
          Baseline Survey: Asks questions about your experience living with a
          bleeding disorder such as types and severity of symptoms, prescribed
          treatment, insurance coverage, etc. Depending on your answers, it may
          take up to 20 minutes to complete. Once completed you will have access
          to your personalized dashboard and the ability to view compensated
          research opportunities.
          <br />
          <br />
        </li>
        <li>
          Anniversary Survey: This survey is similar to the baseline survey and
          it becomes available once a year on your CVR enrollment anniversary.
          Asking similar questions every year will allow researchers to follow
          trends and identify patterns and changes over time. It asks about
          bleeding symptoms, joint health, medication regimen, pain level,
          activity level, mental health, etc. This survey should take about 20
          minutes to complete. You will need to complete this survey to remain
          eligible for compensated research opportunities throughout the year.
          <br />
          <br />
        </li>
        <li>
          Sub-surveys: These are shorter surveys focused on areas of interest.
          For example, target joints, pain, women's issues, etc. These surveys
          only take 5-10 minutes to complete.
          <br />
          <br />
        </li>
        <li>
          Compensated Research Surveys: NBDF will sometimes partner with
          external researchers and offer you the option to participate in their
          research surveys. These surveys will clearly be identified. Before you
          participate you will be presented with a document explaining who is
          involved in the study, its purpose, how the data will be collected,
          stored, and reported, available compensation if any, and how long the
          survey will take. Each research study will offer a different
          compensation rate depending on how long it takes to complete the
          survey. You will never have to complete a survey you don’t want to and
          you can stop at any time.
          <br />
          <br />
        </li>
      </ol>
    </Paragraph>
    <Header>How is information stored and reported?</Header>
    <Paragraph>
      NBDF is collaborating with IQVIA, an independent outside organization that
      provides technical and analytical support. They will also provide the safe
      and secure storage of the information gathered by CVR using the strictest
      of industry standards.
      <br />
      <br />
      NBDF staff does not and will not have access to participants’ identifiable
      information. The information collected will be kept confidential and will
      only be reported in a de-identified and aggregate manner. De-identified
      means that we will replace names with a coded number and have all personal
      identifiers (such as name, date of birth, emails, zip code, and any other
      information that might identify you) removed. Aggregate refers to
      combining de-identified information gathered from all other CVR
      participants. Information will always be reported in groups and
      percentages, never individually and never at a level that a person can be
      identified.
      <br />
      <br />
    </Paragraph>
    <Header>How will the information collected be used in research?</Header>
    <Paragraph>
      CVR is expected to be in existence for many years into the future. NBDF
      will regularly review the de-identified and aggregate information to look
      for relationships and trends.
      <br />
      <br />
      These reviews will result in the development of new research questions
      that may prompt the creation of new surveys. For example, CVR might reveal
      that 35% of the 1000 parents participating in CVR report high levels of
      depression. Researchers might dig deeper into this topic by either doing a
      more complete review, creating an additional survey to better understand
      the topic, and/or identifying helpful interventions.
      <br />
      <br />
      Research findings will be communicated to participants through their CVR
      Personal Dashboard. This dashboard will be available to participants once
      they have completed their baseline survey.
      <br />
      <br />
      Researchers outside of NBDF (external researchers), may apply to have
      access to CVR’s de-identified and aggregate data. All research proposals
      will be managed by NBDF’s Research Department. NBDF will not share any
      personal or other identifying information. Your information will not be
      sold to any third parties for marketing.
      <br />
      <br />
      External researchers may apply to:
      <br />
      <br />
      <ul>
        <li>
          Submit surveys to the enrolled participants who have completed their
          baseline surveys.
          <br />
          <br />
        </li>
        <li>
          Submit specific questions to query the data of a certain population
          who has completed specific surveys.
          <br />
          <br />
        </li>
      </ul>
      For example: A researcher may request a restricted set of the
      de-identified, aggregate responses of hemophilia A/B males over the age of
      18 experiencing chronic pain to better understand their pain issues. These
      data will be limited in scope and will always be de-identified.
      <br />
      <br />
      Before participating in a research survey, you will be given clear
      information that identifies the sponsor of the research, what they are
      studying, and the amount of compensation when applicable. You will need to
      consent before being taken to a survey from an external researcher. Only
      de-identified aggregate information will be reported out. No personal
      identifying information will be released.
      <br />
      <br />
    </Paragraph>
    <Header>Do I have to participate in CVR?</Header>
    <Paragraph>
      Taking part in CVR is completely voluntary. You do not have to join unless
      you want to. If you decide not to join, there is no penalty to you or your
      family, you will still be a member of the bleeding disorder community and
      will continue to have access to NBDF’s community events and programs.
      <br />
      <br />
      If you decide to participate in CVR, you can choose for how long and which
      surveys to answer. You can also change your mind at any time.
      <br />
      <br />
      If you change your mind and no longer want to be part of CVR, you can stop
      answering the surveys (opt out of them) or contact the CVR coordinator at{' '}
      <Link
        target='_blank'
        rel='noopener'
        href='mailto:CVR@hemophilia.org'
        variant='w400h14'
      >
        CVR@hemophilia.org
      </Link>{' '}
      and request that your account be deactivated. If you decide to deactivate
      your account, you will not need to offer a reason and will not be
      penalized or lose any benefits to which you are otherwise entitled.
      <br />
      <br />
      Any de-identified information previously shared for a research study prior
      to this request cannot be retrieved or deleted.
      <br />
      <br />
      Compensation and Cost to Participate
      <br />
      <br />
      <ul>
        <li>
          There is no cost to participate in CVR.
          <br />
          <br />
        </li>
        <li>
          You will not be compensated for participating in the NBDF CVR surveys.
          <br />
          <br />
        </li>
        <li>
          There may be opportunities to participate in external researcher’s
          surveys and receive compensation.
          <br />
          <br />
        </li>
        <li>
          You must complete NBDF’s CVR surveys (baseline and annual surveys) to
          remain eligible to participate in the external researcher
          opportunities.
          <br />
          <br />
        </li>
      </ul>
    </Paragraph>
    <Header>What are the risks of joining CVR?</Header>
    <Paragraph>
      It is important to recognize that each person with a bleeding disorder is
      unique and any health concerns should be addressed by your healthcare
      provider. By agreeing to participate in CVR, you may learn information
      that is difficult or upsetting to you. Although a small one, the loss of
      your private or confidential information is a risk.
      <br />
      <br />
    </Paragraph>
    <Header>Security and Confidentiality of the Information</Header>
    <Paragraph>
      NBDF takes the safety, confidentiality, and security of your information
      very seriously.
      <br />
      <br />
      CVR will never share your personal information with any third parties for
      marketing or any other purposes.
      <br />
      <br />
      In the unlikely event of an information breach, we will let you know right
      away. NBDF has contracted with IQVIA, an independent outside organization
      that specializes in data collection, visualization, and measurement of
      clinical trends and outcomes. IQVIA will provide technical expertise
      including significant security protections to mitigate the risks
      associated with data loss, corruption and/or breaches. A variety of
      encryption tools and security features are used to protect against data
      breaches and those security protections will be incorporated into CVR.
      <br />
      <br />
      Among the security tools that CVR uses are: software maintenance,
      continual security monitoring and anti-viral updates; multiple levels of
      restricted access to certain data (such as names and contact information),
      user access (for example, a password is needed to enter CVR), application
      access (meaning only staff with specific roles can access certain features
      or audit trails), and hosting services (including firewall and secure
      socket layers).While we are using many tools and practices to ensure the
      security of information saved in CVR, we cannot guarantee the security of
      the information on your computer or mobile device. We also cannot
      guarantee the security of data while it is being sent to the CVR database
      from your computer or mobile device. To decrease this risk, we recommend
      you send information to CVR via a secure Wi-Fi or cellular connection.
      <br />
      <br />
      Most NBDF and IQVIA individuals with access to the information gathered by
      CVR do so in a de-identified and aggregate manner. However, a handful of
      IQVIA staff have access to identified information. This access is required
      to ensure proper functioning of the infrastructure and dashboard. Access
      to identified information will be highly controlled and limited to
      essential personnel (based solely on need to know, job function, and role)
      trained to protect participant’s privacy.
      <br />
      <br />
      IQVIA staff with access to identified information follow strict guidelines
      for confidentiality. Some of these access controls include multi-factor
      authentication, single sign-on, and a strict “least-privileged”
      authorization policy.
      <br />
      <br />
      Least privilege is a powerful security principle which states that no
      single person or entity should be able to access information or exercise
      privileges beyond what is necessary to perform their assigned role. Each
      entry into the database is user, date and time stamped for monitoring
      purposes.
      <br />
      <br />
      For more details on privacy risks, please see the NBDF CVR Privacy Policy.
      <br />
      <br />
    </Paragraph>
    <Header>How do I benefit by participating in CVR?</Header>
    <Paragraph>
      Benefits of future research activities are generally indirect; research
      findings may benefit the bleeding disorder community in general but not
      individual participants. However, by participating in CVR, you will not
      only help improve the future of people living with inherited bleeding
      disorders and their immediate family members. You will also be able to
      benefit directly by utilizing the information and tools offered by CVR to
      take control of your health. All these benefits are optional for you to
      decide if you want to take advantage of them.
      <br />
      <br />
      These include:
      <br />
      <br />
      <b>
        <ul>
          <li>
            CVR Personalized Dashboard: It is populated by survey responses and
            through it you can:
            <br />
            <br />
            <ul>
              <li>
                Follow trends and compare changes over time.
                <br />
                <br />
              </li>
              <li>
                Compare your information in a de-identified and aggregated
                manner with other CVR participants.
                <br />
                <br />
              </li>
              <li>
                Make informed decisions about your healthcare.
                <br />
                <br />
              </li>
            </ul>
          </li>
          <li>
            My Health Summary: The information collected in CVR can be printed
            and shared with any healthcare professional such as your
            hematologist, nurse, or emergency room staff. This report would
            provide a comprehensive view of your individual medical condition
            encouraging shared decision making when evaluating treatment
            options. You decide what information to share; it could be personal
            health information or simply education on your specific bleeding
            disorder.
            <ul>
              <li>
                Example: A report for your dentist may contain your contact
                information, your healthcare provider’s contact information,
                your diagnosis, and medication list. The control of what to
                share and with whom always stays with you.
              </li>
            </ul>
            <br />
          </li>
          <li>
            Track your medications history: CVR can help you keep track of your
            medication history and how it relates to your everyday health.
            <br />
            <br />
          </li>
          <li>
            Bench-marking: CVR will allow you to track and compare your health
            information over time. CVR will also show you how your answers and
            information compare to that of other CVR participants (in an
            aggregate and de-identified manner). This way, you’ll know how
            common or unusual your experiences are.
            <br />
            <br />
          </li>
          <li>
            Resources: CVR will direct you to trusted, up-to-date, relevant, and
            evidence-based information about bleeding disorders and health. It
            will also help connect you to additional research opportunities.
          </li>
        </ul>
      </b>
      <br />
    </Paragraph>
    <Header>Who is sponsoring CVR?</Header>
    <Paragraph>
      CVR is solely funded by NBDF.
      <br />
      <br />
      As participation increases, external researchers will have the opportunity
      to query de-identified, aggregate information and be able to present
      research protocols that invite CVR participants to enroll. (See ‘How will
      the information collected be used in research?’) There may be an
      associated fee for certain data queries and/or survey requests by external
      for-profit researchers. These fees will help support the CVR
      infrastructure. Just as a reminder, no personal identifiable information
      will ever be reported or released to anyone. Any information gathered by
      the CVR will always be reported in a de-identified and aggregated manner.
      <br />
      <br />
      NBDF may apply for unrestricted grants to support different aspects of the
      CVR platform. These types of grants allow NBDF to continue to add features
      to CVR -making it a stronger and better tool for participants who use it.
      <br />
      <br />
      NBDF will never share any identifiable data with the grantor (the company
      who gives the grant to NBDF). Any and all information will always be
      reported in a de-identified and aggregated manner.
      <br />
      <br />
      For more information about research proposals from external researchers,
      see section, “How will the information collected be used in research?”
      <br />
      <br />
    </Paragraph>
    <Header>Whom should I contact if I have any questions?</Header>
    <Paragraph>
      If you have any questions about CVR or about your rights as a participant,
      contact the CVR Coordinator by email (
      <Link
        target='_blank'
        rel='noopener'
        href='mailto:CVR@hemophilia.org'
        variant='w400h14'
      >
        CVR@hemophilia.org
      </Link>
      ) or the principal investigator, Maria Santaella (
      <Link
        target='_blank'
        rel='noopener'
        href='mailto:msantaella@hemophilia.org'
        variant='w400h14'
      >
        msantaella@hemophilia.org
      </Link>
      ).
      <br />
      <br />
      By clicking the box below, you are stating you have read and understand
      the participant agreement.
      <br />
    </Paragraph>
  </Stack>
);

export default Content;

const getFixSelectionBugFunction =
  (classType, checkType) => (data, formioRef) => {
    if (Array.isArray(data) || typeof data !== 'object') return;

    Array.from(
      formioRef?.current?.getElementsByClassName?.(classType) ?? []
    ).forEach((element) => {
      const key = element.getAttribute('data-key');
      const value = isNaN(data[key]) ? data[key] : data[key].toString();

      if (Boolean(value) || value === '') {
        Array.from([
          ...(element?.getElementsByClassName?.('form-check') ?? []),
          ...(element?.getElementsByClassName?.('form-check-inline') ?? []),
        ]).forEach((option) => {
          const input = option?.getElementsByTagName?.('input')?.[0];
          checkType(value, input?.getAttribute?.('value'))
            ? option?.classList?.add('selected')
            : option?.classList?.remove('selected');
        });
      }
    });
  };

export const fixRadioSelectionBug = getFixSelectionBugFunction(
  'formio-component-radio',
  (value, attributeValue) => value === attributeValue
);
export const fixCheckboxSelectionBug = getFixSelectionBugFunction(
  'formio-component-selectboxes',
  (value, attributeValue) => Boolean(value[attributeValue])
);

export const connectSliderToOutput = (formioRef) => {
  Array.from(
    formioRef?.current?.getElementsByClassName?.('range-slider-core') ?? []
  ).forEach((element) => {
    const input = element.getElementsByTagName('input')?.[0];
    const output = element.getElementsByTagName('output')?.[0];
    element.style.setProperty('--curr-height', element.offsetHeight);
    element.style.setProperty('--curr-width', element.offsetWidth);

    if (input && output) {
      window[output.id].value = window[input.id].value;
      window[output.id].style.setProperty(
        '--curr-value',
        window[input.id].value
      );
      input.setAttribute(
        'oninput',
        `
          window["${output.id}"].value = window["${input.id}"].value;
          window["${output.id}"].style.setProperty("--curr-value", window["${input.id}"].value);
        `
      );
    }
  });
};

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import Snackbar from 'components/Snackbar';
import { AuthContextProvider } from 'context/AuthContext';
import NHFRoutes from 'routes';
import { persistor, store } from 'store';
import theme from 'theme';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } from 'utils/constants';

import 'formio/index.js';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthContextProvider
            domain={AUTH0_DOMAIN}
            clientID={AUTH0_CLIENT_ID}
            audience={AUTH0_AUDIENCE}
            connection='Username-Password-Authentication'
          >
            <BrowserRouter>
              <NHFRoutes />
              <Snackbar />
            </BrowserRouter>
          </AuthContextProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;

import { Box } from '@mui/material';

import Container from 'components/Enrollment/Container';
import FormIO from 'components/FormIO';
import enrollment from 'forms/enrollment';

const components = enrollment.components?.[0]?.components;
const inputs = components?.filter?.(({ input }) => input);
const keys = inputs.map(({ key }) => key);

const fillResult = (data) =>
  Object.fromEntries(keys.map((key) => [key, key in data ? data[key] : null]));

const Enrollment = ({ onSubmit, onSave }) => {
  return (
    <Box m="50px">
      <Container maxWidth="800px" m="50px">
        <FormIO
          form={enrollment}
          onSubmit={(data) => onSubmit(fillResult(data))}
          onSave={(data) => onSave(fillResult(data))}
          template="enrollment"
        />
      </Container>
    </Box>
  );
};

export default Enrollment;

const formS = {
  title: 'Form S',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'S',
  type: 'panel',
  label: 'Form S',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Smoking Status</strong>',
      label: 'Content',
      refreshOnChange: false,
      key: 'S0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'Which best represents your smoking status? Choose only one response.',
      widget: 'choicesjs',
      placeholder: 'Smoking status...',
      tableView: true,
      data: {
        values: [
          {
            label: 'I have never smoked',
            value: 'Never',
          },
          {
            label: 'I quit, I smoked less than 100 cigarettes in my lifetime',
            value: 'Less_100',
          },
          {
            label: 'I quit, I smoked more than 100 cigarettes in my lifetime',
            value: 'More_100',
          },
          {
            label: 'Current smoker',
            value: 'Smoker',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'S1',
      type: 'select',
      input: true,
    },
  ],
};

export default formS;

const sizeMapping = {
  medium: {
    height: '18px',
    width: '27px',
    padding: '2px',
  },
};

const MuiSwitch = {
  styleOverrides: {
    root: ({ theme, ownerState: { size } }) => {
      const { height, width, padding } = sizeMapping[size];
      return {
        padding: 0,
        height,
        width,
        '.MuiSwitch-input': {
          width: `${height} !important`,
          left: '0 !important',
        },
        '.MuiSwitch-switchBase': {
          padding,
        },
        '.MuiSwitch-switchBase.Mui-checked': {
          transform: `translateX(calc(${width} - ${height}))`,
        },
        '.MuiSwitch-switchBase:hover': {
          backgroundColor: 'unset',
        },
        '.MuiSwitch-track': {
          backgroundColor: theme.palette.gray[40],
          borderRadius: `calc(${height} / 2)`,
          opacity: 1,
        },
        '.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
        },
        '.MuiSwitch-thumb': {
          all: 'unset',
          backgroundColor: theme.palette.white,
          width: `calc(${height} - 2 * ${padding})`,
          height: `calc(${height} - 2 * ${padding})`,
          borderRadius: '50%',
        },
        '.MuiTouchRipple-root': {
          display: 'none',
        },
      };
    },
  },
};

export default MuiSwitch;

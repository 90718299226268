import { Button, Stack, Typography } from '@mui/material';

import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg';
import { ReactComponent as ThankYouIcon } from 'images/thank-you.svg';

const Content = ({ onClick }) => (
  <Stack spacing='10px' alignItems={{ xs: 'stretch', sm: 'center' }} flex={1}>
    <Stack spacing='10px' alignItems='center' flex={1}>
      <ThankYouIcon />
      <Typography variant='w600h24' color='gray.80' align='center'>
        Congratulations!
      </Typography>
      <Typography variant='w400h14' color='gray.80' align='center'>
        <p>
          You have successfully completed your enrollment in CVR. Thank you!
        </p>
        <p>
          Your participation will contribute to research and make a positive
          impact on the lives of current and future generations of persons
          affected by inheritable bleeding disorders.
        </p>
      </Typography>
    </Stack>
    <Button endIcon={<ArrowRightIcon />} onClick={onClick}>
      Preview Dashboard
    </Button>
  </Stack>
);

export default Content;

export const myImpactWithCVR = [
  {
    id: 1,
    title:
      'Pain Attitudes and Pain Outcomes among People with Bleeding Disorders: Results from Community Voices in Research',
    subtitle: 'Year of publication: 2024',
    description:
      'Current pain treatment practices have not adequately addressed pain in people with bleeding disorders. Strong evidence of a relationship between pain attitudes and pain outcomes was presented. No other studies report on pain attitudes in people with bleeding disorders, underscoring a need to corroborate findings in other patient populations.',
    url: 'https://pubmed.ncbi.nlm.nih.gov/38415392/#:~:text=Results%3A%20After%20adjustment%20for%20covariates,use%20and%20opioid%20pain%20medication',
  },
  {
    id: 2,
    title:
      'Gene Therapy for Hemophilia A: A Mixed Methods Study of Patient Preferences and Shared Decision-Making',
    subtitle: 'Year of publication: 2023',
    description:
      'As gene therapies for hemophilia are incorporated into clinical practice, shared decision-making (SDM) is recommended for implementation. The data in this study highlights the utility of a SDM tool for hemophilia gene therapy and key information needs.',
    url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10123005/',
  },
  {
    id: 3,
    title:
      'Development of a haemophilia A gene therapy shared decision-making tool for clinicians',
    subtitle: 'Year of publication: 2023',
    description:
      'Clinicians at US Hemophilia Treatment Centers completed semi-structured interviews about their experience with shared decision-making and provided feedback on a clinician shared decision-making tool prototype. These data highlight the need for shared decision-making tools for haemophilia A gene therapy. Key information to include in the tool are safety, efficacy, cost and detailed information on the gene therapy process.',
    url: 'https://pubmed.ncbi.nlm.nih.gov/37401924/',
  },
  {
    id: 4,
    title:
      'Relationship between Perceived Social Support, Mental Health, Activity, and Chronic Pain in Persons with Inheritable Bleeding Disorders',
    subtitle: 'Year of publication: 2022',
    description:
      'The aim of this analysis was to describe the relationship between self-reported mental health diagnosis, history of mental health treatment, participation in physical activity, chronic pain, and social support. Persons with inheritable bleeding disorders with self-reported mental health conditions had at least 1 mental health comorbidity, report a lack of perceived social support and were less physically active than Persons with inheritable bleeding disorders without a self-reported mental health condition',
    url: 'https://ashpublications.org/blood/article/140/Supplement%201/13156/487387/Relationship-between-Perceived-Social-Support',
  },
  {
    id: 5,
    title:
      'Gene therapy preferences and informed decision-making: Results from a National Hemophilia Foundation Community Voices in research survey',
    subtitle: 'Year of publication: 2022',
    description:
      'The aim of this study was to evaluate preferences and variables which influence decision-making related to gene therapy and other novel haemophilia therapies. Haemophilia Treatment Centers and patient advocacy groups must be able to educate persons with haemophilia about aspects of novel therapies which are important to the individual, especially short- and long-term safety and efficacy.',
    url: 'https://pubmed.ncbi.nlm.nih.gov/36469856/',
  },
  {
    id: 6,
    title:
      'Community Voices in Research (CVR): A Patient-Centric Approach Moving the Future of Inherited Bleeding Disorders Forward',
    subtitle: 'Year of publication: 2021',
    description:
      'Individuals and families in the bleeding disorders community and those who experience other chronic rare conditions have not typically been engaged in patient reported registries or in clinical studies during the initial study design phase. The more participants enrolled in CVR the stronger the data and the community. CVR will establish an audience to draw in for opportunities to participate in patient-reported outcome research and other efforts as well as providing the opportunity to educate and inform individuals and families in the bleeding disorders community.',
    url: 'https://www.hemophilia.org/research/research-projects/community-voices-in-research-cvr-a-patient-centric-approach-moving-the-future-of-inherited-bleeding-disorders-forward',
  },
  {
    id: 7,
    title:
      'The Relationship Between Self-Reported Physical Activity, Treatment Regimen, Mental Health and Pain In Persons with Hemophilia',
    subtitle: 'Year of publication: 2021',
    description:
      'The objective of this study was to assess the relationship between self-reported physical activity, treatment regimen, mental health, and pain in persons with hemophilia (PWH) enrolled in CVR. Despite education to the contrary, PWSH continue to engage in high-risk, aggressive physical activities and would like to be even more physically active. As treatment options progress, offering more opportunity for physical activity, research is required to understand an acceptable balance between benefit and harm in PWH.',
    url: 'https://www.hemophilia.org/research/research-projects/the-relationship-between-self-reported-physical-activity-treatment-regimen-mental-health-and-pain-in-persons-with-hemophilia',
  },
  {
    id: 8,
    title:
      'Utilizing a community registry to analyze pain limitations in persons with IBDs',
    subtitle: 'Year of publication: 2021',
    description:
      'This analysis evaluated relationships between pain limitations and 1) family history of IBD, social determinants, depression/anxiety scores and 2) use of marijuana, opioids and other medications, anxiety, and depression. The majority of CVR participants with an IBD report limitation in life or work activities associated with pain. Several moderating variables beyond pain intensity and medications were identified, suggesting opportunities for future interventional research.',
    url: 'https://www.painmanagementnursing.org/article/S1524-9042(22)00079-0/abstract',
  },
  {
    id: 9,
    title:
      'Bleeding Disorder Data Registry Reveals Racial/Ethnic Disparities That Could Significantly Impact Patient Journey',
    subtitle: 'Year of publication: 2021',
    description:
      'The demographic disparities between WCNHs and other participants in the CVR are critical and emphasize the need to focus on correlations between known social determinants of health and self-reported health outcomes and quality-of-life information.',
    url: 'https://ashpublications.org/blood/article/138/Supplement%201/5004/482060/Bleeding-Disorder-Data-Registry-Reveals-Racial',
  },
  {
    id: 10,
    title: 'How Data is Changing the Landscape of Bleeding Disorder Treatment',
    subtitle: 'CVR Publications',
    url: 'https://www.hemophilia.org/community-resources/request-information/read-our-publications',
  },
  {
    id: 11,
    title: 'Digital Health is Changing Medical Research',
    subtitle: 'Relevant News',
    url: 'https://www.hemophilia.org/news?search=&field_tags_target_id%5B%5D=248&field_tags_target_id%5B%5D=247',
  },
];

import { Stack, Divider } from '@mui/material';

import Container from 'components/Enrollment/Container';
import NHFModal from 'components/NHFModal';
import Title from 'components/NHFModal/ParticipationAgreementModal/Title';
import ButtonBar from 'components/Enrollment/ParticipationAgreementStep/ButtonBar';
import Content from 'components/Enrollment/ParticipationAgreementStep/Content';

const ParticipationAgreementModal = ({
  setOpenWithdrawConsentModal,
  displayDate,
  open,
  setOpen,
}) => {
  return (
    <NHFModal
      title='Participant Agreement'
      open={open}
      setOpen={setOpen}
      useFormioButtons={true}
    >
      <Container maxWidth='600px'>
        <Stack divider={<Divider />}>
          <Title displayDate={displayDate} />
          <Content />
          <ButtonBar
            firstButtonText='I Do Not Agree'
            secondButtonText='Close'
            onAgree={() => {
              setOpen(false);
            }}
            onReject={() => {
              setOpenWithdrawConsentModal(true);
            }}
          />
        </Stack>
      </Container>
    </NHFModal>
  );
};

export default ParticipationAgreementModal;

import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

const TitleWithSubtitle = ({
  title,
  subtitle,
  titleSize,
  subTitleSize,
  refProp,
  ...props
}) => {
  return (
    <Box textAlign='center' {...props}>
      <div ref={refProp}></div>
      <Typography variant='h3' fontSize={titleSize} fontWeight={700} mb={1.5}>
        {title}
      </Typography>
      <Typography variant='subtitle1' fontSize={subTitleSize}>
        {subtitle}
      </Typography>
    </Box>
  );
};

TitleWithSubtitle.defaultProps = {
  titleSize: 30,
  subTitleSize: 16,
};

TitleWithSubtitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  titleSize: PropTypes.number,
  subTitleSize: PropTypes.number,
};

export default TitleWithSubtitle;

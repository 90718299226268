import { Grid } from '@mui/material';

import SkeletonTaskCard from 'components/TaskCard/Skeleton';
import Layout from 'components/Dashboard/MyTasks/Layout';

const MyTasksSkeleton = ({ props }) => {
  return (
    <Layout {...props}>
      <Grid item xs={1}>
        <SkeletonTaskCard />
      </Grid>
      <Grid item xs={1}>
        <SkeletonTaskCard />
      </Grid>
    </Layout>
  );
};

export default MyTasksSkeleton;

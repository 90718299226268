import { useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { parseAttributes } from 'components/Enrollment/EnrollmentStep/utils';
import FormIO from 'components/FormIO';
import NHFModal from 'components/NHFModal';
import myInvitations from 'forms/myInvitations';
import usePersonAttributes from 'hooks/usePersonAttributes';
import { openSnackbar } from 'store/slices/application';
import { SnackbarError, SnackbarSuccess } from 'utils/enums';

const MyInvitationsModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const { attributes, upsertAttributes } = usePersonAttributes();
  const defaultValues = useMemo(
    () => parseAttributes(attributes),
    [attributes],
  );

  const onSubmit = (data) => {
    const invitees_email = data.email;

    upsertAttributes({
      ...defaultValues,
      ...{ invitees_email },
    })
      .then(() => {
        dispatch(
          openSnackbar({
            ...SnackbarSuccess.InviteSent(),
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }),
        );
      })
      .catch(() =>
        dispatch(
          openSnackbar({
            ...SnackbarError.TryAgain(),
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }),
        ),
      );

    setOpen(false);
  };

  return (
    <NHFModal
      title='My Invitations'
      open={open}
      setOpen={setOpen}
      useFormioButtons={true}
    >
      <FormIO
        form={myInvitations}
        onSubmit={onSubmit}
        unlockSubmit={true}
        onSave={() => {
          setOpen(false);
        }}
        template='enrollment'
      />
    </NHFModal>
  );
};

export default MyInvitationsModal;

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Stack } from '@mui/material';
import Color from 'color';
import { Link } from 'react-router-dom';

import { useMobile } from 'hooks/useMobile';
import { ReactComponent as LogoFull } from 'images/logo/CVR-logo-full.svg';
import { ReactComponent as LogoMini } from 'images/logo/CVR-logo-mini.svg';

const Header = ({ alpha }) => {
  const isMobile = useMobile();
  const truncatedAlpha = Math.min(alpha ?? 1, 1);

  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        position: 'fixed',
        zIndex: 10,
        paddingX: { xs: '26px', md: '40px' },
        paddingY: { xs: '22px', md: '15px' },
        width: '100%',
        backgroundColor: (theme) =>
          Color(theme.palette.white).alpha(truncatedAlpha).string(),
        boxShadow: (theme) =>
          `0 0 6px 0 ${Color(theme.palette.black)
            .alpha(truncatedAlpha / 5)
            .string()}`,
      }}
    >
      {isMobile ? <LogoMini /> : <LogoFull />}
      <Box flex={1} />
      {isMobile ? (
        <MenuIcon color='primary' sx={{ fontSize: '36px' }} />
      ) : (
        <Stack direction='row' spacing='10px'>
          <Button component={Link} to='/login' variant='outlined'>
            login
          </Button>
          <Button component={Link} to='/join'>
            join
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default Header;

import { memo } from 'react';

import { isEqual } from 'lodash';

import FormIO from 'components/FormIO';
import LoginProcessLayout from 'components/LoginProcessLayout';

const Form = memo(
  ({ form, onSubmit, onChange }) => (
    <FormIO
      form={form}
      onSubmit={onSubmit}
      onChange={onChange}
      template='enrollment'
      unlockSubmit={true}
    />
  ),
  (oldProps, newProps) =>
    isEqual(oldProps.form, newProps.form) &&
    oldProps.onSubmit === newProps.onSubmit &&
    oldProps.onChange === newProps.onChange,
);

const LoginProcessForm = ({
  title,
  subtitle,
  description,
  footer,
  form,
  onSubmit,
  onChange,
}) => (
  <LoginProcessLayout
    title={title}
    description={description}
    subtitle={subtitle}
    footer={footer}
  >
    <Form form={form} onSubmit={onSubmit} onChange={onChange} />
  </LoginProcessLayout>
);

export default LoginProcessForm;

import { Link, Stack, Typography } from '@mui/material';

import LoginProcessLayout from 'components/LoginProcessLayout';

const Contact = () => (
  <LoginProcessLayout title='Whom should I contact if I have any questions?'>
    <Stack p={{ xs: '10px 20px 20px 20px', sm: '10px 30px 30px 30px' }}>
      <Typography variant='w400h16' color='gray.100'>
        If you have any questions about CVR or about your rights as a
        participant, contact the CVR Coordinator by email (
        <Link
          target='_blank'
          rel='noopener'
          href='mailto:CVR@hemophilia.org'
          variant='w400h16'
        >
          CVR@hemophilia.org
        </Link>
        ) or the principal investigator, Maria Santaella (
        <Link
          target='_blank'
          rel='noopener'
          href='mailto:msantaella@hemophilia.org'
          variant='w400h16'
        >
          msantaella@hemophilia.org
        </Link>
        )
      </Typography>
    </Stack>
  </LoginProcessLayout>
);

export default Contact;

import { createTheme } from '@mui/material/styles';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';

import components from './components';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
  palette,
  components,
  typography,
});

export default theme;

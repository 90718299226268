const formAP = {
  title: 'Form AP',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'AP',
  type: 'panel',
  label: 'Form AP',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>NBDF/Other Organization Activity Participation</strong><br/>The next few questions will ask about your participation in events organized by the National Bleeding Disorders Foundation (NBDF) and its Chapters and other organizations.',
      label: 'Content',
      refreshOnChange: false,
      key: 'AP0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'Do you participate in any national, regional or local events organized for the bleeding disorders community? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label:
            "National events (for example: NBDF's Inhibitor Summits, NBDF’s Annual Bleeding Disorders Conference, HFA’s Symposium)",
          value: 'National',
          shortcut: '',
        },
        {
          label:
            'Local events organized by NBDF Chapters (for example: dinner events, educational events, advocacy, walks, local fundraisers, infusion workshops)',
          value: 'Chapter',
          shortcut: '',
        },
        {
          label:
            'Local events organized by another organization (for example: HFA, Coalition for Hemophilia B)',
          value: 'Oth_org',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
        {
          label:
            'No, I do not participate in any national, regional or local events organized for the bleeding disorders community',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.No;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(value).reduce((acc, curr) => ({...acc, [curr]: curr === 'No'}), {});",
            },
          ],
        },
      ],
      key: 'AP1',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'AP1_other',
      customConditional: "show = data[component.key.split('_')[0]]?.other;",
      type: 'textfield',
      input: true,
    },
    {
      label:
        'Select all NBDF Chapter(s) that you participate with. Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          value: '53467',
          label: 'Alaska: Alaska Hemophilia Association',
        },
        {
          value: '53500',
          label: 'Arizona: Arizona Bleeding Disorders',
        },
        {
          value: '53529',
          label: 'California: Central California Hemophilia Foundation',
        },
        {
          value: '53558',
          label: 'California: Hemophilia Foundation of Northern California',
        },
        {
          value: '53953',
          label: 'California: Hemophilia Foundation of Southern California',
        },
        {
          value: '53963',
          label: 'California: Hemophilia Association of San Diego County',
        },
        {
          value: '54009',
          label:
            'Colorado: Colorado Chapter, National Bleeding Disorders Foundation',
        },
        {
          value: '53542',
          label: 'Florida: Bleeding Disorders Foundation of Florida',
        },
        {
          value: '53505',
          label: 'Florida: Florida Hemophilia Association',
        },
        {
          value: '53458',
          label: 'Georgia: Hemophilia of Georgia, Inc.',
        },
        {
          value: '56673',
          label:
            'Hawaii: Hawaii Chapter, National Bleeding Disorders Foundation',
        },
        {
          value: '53994',
          label: 'Idaho: Idaho Chapter, National Bleeding Disorders Foundation',
        },
        {
          value: '53494',
          label: 'Illinois: Bleeding Disorders Alliance Illinois',
        },
        {
          value: '53496',
          label: 'Indiana: Hemophilia of Indiana',
        },
        {
          value: '53463',
          label: 'Iowa: Bleeding Disorders of the Heartland',
        },
        {
          value: '53520',
          label: 'Kentucky: Kentucky Hemophilia Foundation',
        },
        {
          value: '53601',
          label: 'Louisiana: Louisiana Hemophilia Foundation',
        },
        {
          value: '56014',
          label: 'Maine: Hemophilia Alliance of Maine, Inc.',
        },
        {
          value: '53545',
          label: 'Massachusetts: New England Hemophilia Association',
        },
        {
          value: '53512',
          label: 'Michigan: Hemophilia Foundation of Michigan',
        },
        {
          value: '53464',
          label:
            'Minnesota: Hemophilia Foundation of Minnesota and the Dakotas',
        },
        {
          value: '53673',
          label: 'Missouri: Midwest Hemophilia Association',
        },
        {
          value: '53831',
          label: 'Missouri: Gateway Hemophilia Association',
        },
        {
          value: '53961',
          label:
            'Montana: Rocky Mountain Hemophilia and Bleeding Disorders Association',
        },
        {
          value: '53487',
          label:
            'Nebraska: Nebraska Chapter, National Bleeding Disorders Foundation',
        },
        {
          value: '53948',
          label:
            'Nevada: Nevada Chapter, National Bleeding Disorders Foundation',
        },
        {
          value: '54102',
          label:
            'New Mexico: Sangre de Oro, Bleeding Disorders Foundation of New Mexico',
        },
        {
          value: '54840',
          label: 'New York: Western New York BloodCare',
        },
        {
          value: '54904',
          label: 'New York: New York City Hemophilia Chapter Inc.',
        },
        {
          value: '60162',
          label:
            'New York: Bleeding Disorders Association of Northeastern New York',
        },
        {
          value: '53677',
          label: 'New York: Mary M. Gooley Hemophilia Center',
        },
        {
          value: '53760',
          label:
            'North Carolina: Bleeding Disorders Foundation of North Carolina',
        },
        {
          value: '58228',
          label: 'North Dakota: Bleeding Disorders Alliance of North Dakota',
        },
        {
          value: '53768',
          label: 'Ohio: Southwestern Ohio Hemophilia Foundation',
        },
        {
          value: '53770',
          label:
            'Ohio: Central Ohio Chapter, National Bleeding Disorders Foundation',
        },
        {
          value: '53471',
          label: 'Ohio: Northern Ohio Hemophilia Foundation',
        },
        {
          value: '53498',
          label: 'Ohio: Tri-State Bleeding Disorders Foundation',
        },
        {
          value: '53507',
          label: 'Ohio: Northwest Ohio Hemophilia Foundation',
        },
        {
          value: '53944',
          label: 'Oklahoma: Oklahoma Hemophilia Foundation',
        },
        {
          value: '53559',
          label: 'Oregon: Pacific Northwest Bleeding Disorders',
        },
        {
          value: '53920',
          label: 'Pennsylvania: Eastern Pennsylvania Chapter of NBDF',
        },
        {
          value: '53787',
          label: 'Pennsylvania: Western Pennsylvania Chapter of NBDF',
        },
        {
          value: '72010',
          label:
            'Puerto Rico: Asociación Puertorriqueña de Hemofilia y Condiciones de Sang',
        },
        {
          value: '53788',
          label:
            'South Carolina: Bleeding Disorders Association of South Carolina',
        },
        {
          value: '53532',
          label:
            'Tennessee: Tennessee Hemophilia and Bleeding Disorders Foundation',
        },
        {
          value: '53761',
          label: 'Texas: Texas Central Bleeding Disorders',
        },
        {
          value: '53674',
          label: 'Texas: Lone Star Chapter of NBDF',
        },
        {
          value: '53815',
          label: 'Virginia: Hemophilia Association of the Capital Area',
        },
        {
          value: '54905',
          label: 'Virginia: Virginia Hemophilia Foundation',
        },
        {
          value: '53747',
          label: 'Washington: Bleeding Disorder Foundation of Washington',
        },
        {
          value: '55221',
          label:
            'West Virginia: West Virginia Chapter, National Bleeding Disorders Foundation',
        },
        {
          value: '53483',
          label: 'Wisconsin: Great Lakes Hemophilia Foundation',
        },
        {
          value: 'other',
          label: 'Other',
        },
      ],
      validate: {
        required: true,
      },
      key: 'AP2',
      customConditional: 'show = data.AP1?.Chapter;',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'AP2_other',
      customConditional: "show = data[component.key.split('_')[0]]?.other;",
      type: 'textfield',
      input: true,
    },
    {
      label: 'What is your role in the NBDF Chapter? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'Participant',
          value: 'Participant',
          shortcut: '',
        },
        {
          label: 'Volunteer',
          value: 'Volunteer',
          shortcut: '',
        },
        {
          label: 'Donor',
          value: 'Donor',
          shortcut: '',
        },
        {
          label: 'Board member',
          value: 'B_mem',
          shortcut: '',
        },
        {
          label: 'Staff member',
          value: 'S_mem',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      customConditional: 'show = data.AP1?.Chapter;',
      key: 'AP3',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'AP3_other',
      customConditional: "show = data[component.key.split('_')[0]]?.other;",
      type: 'textfield',
      input: true,
    },
    {
      label: 'How often do you participate in NBDF activities?',
      widget: 'choicesjs',
      placeholder: 'How often...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Daily',
            value: '7',
          },
          {
            label: 'Weekly',
            value: '6',
          },
          {
            label: 'Every 2 weeks',
            value: '5',
          },
          {
            label: 'Monthly',
            value: '4',
          },
          {
            label: '4-6 times per year',
            value: '3',
          },
          {
            label: '2-3 times per year',
            value: '2',
          },
          {
            label: 'Once a year',
            value: '1',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'AP4',
      customConditional: 'show = data.AP1?.National || data.AP1?.Chapter;',
      type: 'select',
      input: true,
    },
    {
      label:
        'What types of NBDF programs do you participate in? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'Bleeding Disorders Conference (BDC)',
          value: 'Bleeding Disorders Conference (BDC)',
          shortcut: '',
        },
        {
          label: 'Educational',
          value: 'Educational',
          shortcut: '',
        },
        {
          label: 'Social',
          value: 'Social',
          shortcut: '',
        },
        {
          label: 'Fundraising (walks and other events)',
          value: 'Fundraising',
          shortcut: '',
        },
        {
          label: 'Advocacy',
          value: 'Advocacy',
          shortcut: '',
        },
        {
          label: 'Camp',
          value: 'Camp',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      customConditional: 'show = data.AP1?.National || data.AP1?.Chapter;',
      key: 'AP5',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'AP5_other',
      customConditional: "show = data[component.key.split('_')[0]]?.other;",
      type: 'textfield',
      input: true,
    },
    {
      label:
        'What is your reason for NOT participating in NBDF or chapter activities? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          value: 'Distance',
          label: 'I live far away from chapter/events',
        },
        {
          value: 'Transportation',
          label: 'I don’t have reliable transportation',
        },
        {
          value: 'No_interest',
          label: 'I am not really interested in events or topics offered',
        },
        {
          value: 'No_time',
          label: 'I don’t have time',
        },
        {
          value: 'Schedule',
          label: 'I never know when they are scheduled',
        },
        {
          value: 'No_value',
          label: 'I don’t find value in participating',
        },
        {
          value: 'Language',
          label: 'Events are held in a language that I don’t understand',
        },
        {
          value: 'Child_grown',
          label: 'My children grew up so I don’t go anymore',
        },
        {
          value: 'Not_heard',
          label: 'I have never heard about chapters',
        },
        {
          value: 'other',
          label: 'Other',
        },
      ],
      validate: {
        required: true,
      },
      customConditional: 'show = !data.AP1?.National && !data.AP1?.Chapter;',
      key: 'AP6',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'AP6_other',
      customConditional: "show = data[component.key.split('_')[0]]?.other;",
      type: 'textfield',
      input: true,
    },
    {
      label:
        'What is your role in the other organization? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'Participant',
          value: 'Participant',
          shortcut: '',
        },
        {
          label: 'Volunteer',
          value: 'Volunteer',
          shortcut: '',
        },
        {
          label: 'Donor',
          value: 'Donor',
          shortcut: '',
        },
        {
          label: 'Board member',
          value: 'B_mem',
          shortcut: '',
        },
        {
          label: 'Staff member',
          value: 'S_mem',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      customConditional: 'show = data.AP1?.Oth_org || data.AP1?.other;',
      key: 'AP7',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'AP7_other',
      customConditional: "show = data[component.key.split('_')[0]]?.other;",
      type: 'textfield',
      input: true,
    },
    {
      label:
        'How often do you participate in activities with other organizations?',
      widget: 'choicesjs',
      placeholder: 'How often...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Daily',
            value: '7',
          },
          {
            label: 'Weekly',
            value: '6',
          },
          {
            label: 'Every 2 weeks',
            value: '5',
          },
          {
            label: 'Monthly',
            value: '4',
          },
          {
            label: '4-6 times per year',
            value: '3',
          },
          {
            label: '2-3 times per year',
            value: '2',
          },
          {
            label: 'Once a year',
            value: '1',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'AP8',
      customConditional: 'show = data.AP1?.Oth_org || data.AP1?.other;',
      type: 'select',
      input: true,
    },
    {
      label:
        'What types of programs do you participate in with other organizations? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'Educational',
          value: 'Educational',
          shortcut: '',
        },
        {
          label: 'Social',
          value: 'Social',
          shortcut: '',
        },
        {
          label: 'Fundraising (walks and other events)',
          value: 'Fundraising',
          shortcut: '',
        },
        {
          label: 'Advocacy',
          value: 'Advocacy',
          shortcut: '',
        },
        {
          label: 'Camp',
          value: 'Camp',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      customConditional: 'show = data.AP1?.Oth_org || data.AP1?.other;',
      key: 'AP9',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'AP9_other',
      customConditional: "show = data[component.key.split('_')[0]]?.other;",
      type: 'textfield',
      input: true,
    },
  ],
};

export default formAP;

import { forwardRef, useRef, useState } from 'react';

import { Stack } from '@mui/system';

import Captcha from 'components/Captcha';
import LoginProcessForm from 'components/LoginProcessForm';
import createAccount from 'forms/createAccount';

const CaptchaContainer = forwardRef(({ show, onChange }, ref) =>
  show ? (
    <Stack alignItems='center' pb='20px'>
      <Captcha ref={ref} onChange={onChange} />
    </Stack>
  ) : (
    <></>
  ),
);

const CreateAccount = ({ onCreate }) => {
  const captchaRef = useRef(null);
  const [data, setData] = useState();

  const handleCaptcha = () => {
    const challenge = captchaRef.current.getValue();
    const trimPos = Math.trunc(challenge.length / 4);
    onCreate({
      challenge_1: challenge.slice(0, trimPos),
      challenge_2: challenge.slice(trimPos, 2 * trimPos),
      challenge_3: challenge.slice(2 * trimPos, 3 * trimPos),
      challenge_4: challenge.slice(3 * trimPos),
      ...data,
    });
  };

  const handleCreate = setData;

  const handleChange = () => setData(null);

  return (
    <LoginProcessForm
      title='Create account'
      form={createAccount}
      onSubmit={handleCreate}
      onChange={handleChange}
      footer={
        <CaptchaContainer
          ref={captchaRef}
          show={!!data}
          onChange={handleCaptcha}
        />
      }
    />
  );
};

export default CreateAccount;

import { Link } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';

import Container from 'components/Enrollment/Container';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg';
import { ReactComponent as SuccessfulIcon } from 'images/successful.svg';
import { Layout } from './styles';

const ResetSuccessful = () => (
  <Stack py={{ xs: 0, sm: '50px' }}>
    <Container maxWidth='600px'>
      <Layout>
        <Stack
          spacing='10px'
          alignItems={{ xs: 'stretch', sm: 'center' }}
          flex={1}
        >
          <Stack spacing='10px' alignItems='center' flex={1}>
            <SuccessfulIcon />
            <Typography variant='w600h24' color='gray.80' align='center'>
              Reset successful
            </Typography>
            <Typography variant='w400h14' color='gray.80' align='center'>
              <p>Your password has been reset successfully</p>
              <p>Please log in to access your account</p>
            </Typography>
          </Stack>
          <Button endIcon={<ArrowRightIcon />} component={Link} to='/login'>
            Log In
          </Button>
        </Stack>
      </Layout>
    </Container>
  </Stack>
);

export default ResetSuccessful;

import { Card, Stack } from '@mui/material';

import Text from './Text';
import Button from './Button';
import Title from './Title';

const SkeletonTaskCard = () => (
  <Card variant='outlined' sx={{ height: '290px' }}>
    <Stack justifyContent='space-between' height='100%' p='20px'>
      <Stack spacing='6px'>
        <Title />
        <Stack>
          <Text />
          <Text />
          <Text width='60%' />
        </Stack>
      </Stack>
      <Stack>
        <Button />
      </Stack>
    </Stack>
  </Card>
);

export default SkeletonTaskCard;

import Color from 'color';
import { Card, Chip, Stack, useMediaQuery, useTheme } from '@mui/material';

import { useMissionCardDef, useMissionCardBadge } from 'hooks/MissionCard';
import { ReactComponent as Check } from 'images/check.svg';
import ProgressBar from './ProgressBar';
import Text from './Text';
import TrophyBadge from './TrophyBadge';
import { BadgeWrapper, ChipWrapper, IconWrapper } from './styles';

const MissionCard = ({ type, count, total, comingSoon = true }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const { title, badge, color } = useMissionCardDef(theme, type);
  const Badge = useMissionCardBadge(type);

  const completed = count === total;

  const cardColor = completed
    ? color.secondary
    : Color(theme.palette.gray[60]).alpha(0.2).string();
  return (
    <Card
      variant={completed ? null : 'outlined'}
      color={cardColor}
      sx={{ height: { xs: '118px', sm: '290px' }, position: 'relative' }}
    >
      <IconWrapper show={completed} color={color.primary}>
        <Check />
      </IconWrapper>
      {comingSoon && (
        <ChipWrapper>
          <Chip
            label='Coming soon'
            variant='outlined'
            size={sm ? '' : 'small'}
          />
        </ChipWrapper>
      )}
      <Stack
        direction={{ xs: 'row', sm: 'column' }}
        alignItems='center'
        spacing={{ xs: '23px', sm: '32px' }}
        p={{ xs: '13px 22px 13px 20px', sm: '49px 39px 39px 39px' }}
      >
        <BadgeWrapper>
          <Badge
            primaryColor={!completed ? theme.palette.gray[60] : color.primary}
            secondaryColor={Color(theme.palette.white).alpha(0.2).string()}
          />
        </BadgeWrapper>
        <Stack
          spacing='11px'
          alignItems={{ xs: 'left', sm: 'center' }}
          width='100%'
        >
          <Stack
            spacing='8px'
            alignItems={{ xs: 'left', sm: 'center' }}
            width='100%'
          >
            <Text title={title} count={count} total={total} />
            <ProgressBar
              current={count}
              total={total}
              color={!completed ? theme.palette.gray[50] : color.primary}
            />
          </Stack>
          <TrophyBadge
            text={badge}
            disabled={!completed}
            completed={completed}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export default MissionCard;

const formPN = {
  title: 'Form PN',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'PN',
  type: 'panel',
  label: 'Form PN',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Pain</strong><br/>Pain can become a big problem in people with a bleeding disorder. The next questions will ask about the quality of your pain and how much it interferes with your day-to-day activities.',
      label: 'Content',
      refreshOnChange: false,
      key: 'PN0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'Right now, what is your level of pain?',
      widget: 'choicesjs',
      placeholder: 'Level of pain...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Have no pain',
            value: '1',
          },
          {
            label: 'Mild',
            value: '2',
          },
          {
            label: 'Moderate',
            value: '3',
          },
          {
            label: 'Severe',
            value: '4',
          },
          {
            label: 'Very severe',
            value: '5',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'PN1',
      type: 'select',
      input: true,
    },
    {
      label: 'PN2_PN3',
      hideLabel: true,
      tableView: false,
      questions: [
        {
          label: 'In the past 6 months how often did you have pain?',
          value: 'PN2',
          tooltip: '',
        },
        {
          label:
            'In the past 6 months, how often did pain limit your life or work activities?',
          value: 'PN3',
          tooltip: '',
        },
      ],
      values: [
        {
          label: 'Never',
          value: '1',
          tooltip: '',
        },
        {
          label: 'Some days',
          value: '2',
          tooltip: '',
        },
        {
          label: 'Most days',
          value: '3',
          tooltip: '',
        },
        {
          label: 'Every day',
          value: '4',
          tooltip: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'PN2_PN3',
      type: 'survey',
      input: true,
    },
  ],
};

export default formPN;

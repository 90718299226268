import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Divider, Stack, Typography } from '@mui/material';

import LoginProcessLayout from 'components/LoginProcessLayout';
import { ReactComponent as EnvelopeIcon } from 'images/envelope.svg';
import { ReactComponent as HomeIcon } from 'images/home.svg';

const CheckYourEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.email;
  useEffect(() => {
    if (!email) navigate('/');
  }, [email, navigate]);
  return (
    <LoginProcessLayout title='Check your email'>
      <Stack p={{ xs: '10px 20px 20px 20px', sm: '10px 30px 30px 30px' }}>
        <Stack alignItems='center' justifyContent='center' py='20px'>
          <EnvelopeIcon />
        </Stack>
        <Typography variant='w400h14' color='gray.100'>
          <p>
            We sent an email to <b>{email}</b>
          </p>
          <p>
            If you don’t see it, you may need to <b>check your spam</b> folder.
          </p>
          <p>It may take up to 5 minutes to receive the email in your inbox.</p>
        </Typography>
        <Divider />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'stretch', sm: 'center' }}
          justifyContent='center'
          spacing='20px'
          py='20px'
        >
          <Button
            component={Link}
            to='/'
            startIcon={<HomeIcon />}
            variant='outlined'
          >
            Go back home
          </Button>
        </Stack>
        <Typography variant='w400h14' color='gray.100' align='center'>
          Need help?{' '}
          <Link to='/contact'>
            <Typography variant='w600h14' color='gray.100'>
              Contact Us
            </Typography>
          </Link>
        </Typography>
      </Stack>
    </LoginProcessLayout>
  );
};

export default CheckYourEmail;

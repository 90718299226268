import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Stack, Divider, CircularProgress } from '@mui/material';

import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg';
import Container from 'components/Enrollment/Container';
import { useAuth } from 'context/AuthContext';
import usePersonAttributes from 'hooks/usePersonAttributes';
import { openSnackbar } from 'store/slices/application';
import {
  useCurrentPersonQuery,
  useUpdatePersonMutation,
} from 'store/api/nhf/endpoints/people';
import { PersonStatus, PersonStatusReason, SnackbarError } from 'utils/enums';
import ButtonBar from './ButtonBar';
import Content from './Content';
import Title from './Title';

const disableNext = false;

if (disableNext)
  console.error(
    '"disableNext" flag is for development purpose. It should always be false'
  );

const acceptStatus = {
  status: PersonStatus.Consented,
  statusReason: PersonStatusReason.AcceptedConsent,
};

const rejectStatus = {
  status: PersonStatus.Withdrawn,
  statusReason: PersonStatusReason.DeclinedConsent,
};

const ParticipationAgreementStep = ({ onNext }) => {
  const dispatch = useDispatch();
  const { isLoading: isAuthLoading, logout } = useAuth();
  const {
    personId,
    upsertAttributes,
    isLoading: isPersonAttributesLoading,
  } = usePersonAttributes();
  const { status, isPersonStatusLoading } = useCurrentPersonQuery(
    {},
    {
      skip: !personId,
      selectFromResult: ({ data, isFetching }) => ({
        status: data?.attributes?.status,
        isPersonStatusLoading: isFetching,
      }),
    }
  );
  const [updatePerson, { isLoading: isUpdatePersonLoading }] =
    useUpdatePersonMutation();

  const consented =
    status === PersonStatus.Consented || status === PersonStatus.Onboarded;

  useEffect(() => {
    if (consented && !disableNext) onNext();
  }, [consented, onNext]);

  const useUpdateStatus =
    ({ attributes, onError, onSuccess }) =>
    async () => {
      try {
        await upsertAttributes({ consent_date: new Date() });
        const { error } = await updatePerson({
          personId: personId.toString(),
          attributes,
        });
        if (error) return onError?.();
      } catch {
        return onError?.();
      }
      onSuccess?.();
    };

  const onError = () => dispatch(openSnackbar(SnackbarError.TryAgain()));
  const handleAgree = useUpdateStatus({ attributes: acceptStatus, onError });
  const handleReject = useUpdateStatus({
    attributes: rejectStatus,
    onError,
    onSuccess: logout,
  });

  const isLoading =
    isPersonAttributesLoading ||
    isUpdatePersonLoading ||
    isAuthLoading ||
    isPersonStatusLoading;

  if (isLoading || (consented && !disableNext)) return <CircularProgress />;

  return (
    <Container maxWidth='600px'>
      <Stack divider={<Divider />}>
        <Title />
        <Content />
        <ButtonBar
          firstButtonText='I do not agree'
          secondButtonText='I agree, Continue'
          secondButtonIcon={<ArrowRightIcon />}
          onAgree={handleAgree}
          onReject={handleReject}
        />
      </Stack>
    </Container>
  );
};

export default ParticipationAgreementStep;

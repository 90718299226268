import dev from './dev';
import local from './local';
import prod from './prod';
import test from './test';
import uat from './uat';

console.log(window);
console.log(window?.__ENV__);

const getEnvVariables = () =>
  ({
    dev,
    test,
    uat,
    prod,
  })[window?.__ENV__] ?? local;

export default getEnvVariables;

import { useState } from 'react';
import { Stack } from '@mui/material';

import {
  EnrollmentStep,
  ParticipationAgreementStep,
  Stepper,
  ThankYouStep,
} from 'components/Enrollment';

const steps = [
  {
    label: 'Participation Agreement',
    page: ParticipationAgreementStep,
  },
  {
    label: 'Enrollment',
    page: EnrollmentStep,
  },
];

const Enrollment = () => {
  const [step, setStep] = useState(0);

  const CurrentStep = step === steps.length ? ThankYouStep : steps[step].page;
  const handleNextStep = () => setStep((oldStep) => oldStep + 1);

  return (
    <Stack
      p={{ xs: '20px 0 0 0', sm: '40px 20px 40px 20px' }}
      alignItems='center'
      spacing='30px'
      flex={1}
    >
      <Stepper step={step} steps={steps} />
      <CurrentStep onNext={handleNextStep} />
    </Stack>
  );
};

export default Enrollment;

import { useMemo } from 'react';

import { Skeleton } from '@mui/material';

import Item from 'components/MyHealthSummary/Content/Items/Item';
import usePersonAttributes from 'hooks/usePersonAttributes';

const Name = (props) => {
  const { isLoading, attributes } = usePersonAttributes();

  const name = useMemo(
    () =>
      [
        attributes?.first_name?.value,
        attributes?.middle_name?.value,
        attributes?.last_name?.value,
      ]
        .filter((name) => !!name)
        .join(' '),
    [attributes?.first_name, attributes?.last_name, attributes?.middle_name],
  );

  return (
    <Item
      label='Name'
      content={isLoading ? <Skeleton width='200px' /> : name}
      {...props}
    />
  );
};

export default Name;

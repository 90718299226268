import { Stack, Typography } from '@mui/material';

import { ReactComponent as TrophyIcon } from 'images/trophy.svg';
import { Container } from './styles';

const TrophyBadge = ({ disabled = false, completed, text }) => (
  <Container completed={completed}>
    <Typography
      color={completed ? 'mustard.100' : disabled ? 'gray.60' : 'gray.80'}
      variant='w600h13'
    >
      <Stack
        direction='row'
        alignItems='center'
        spacing='6px'
        height='100%'
        useFlexGap
      >
        <TrophyIcon width='16px' height='20px' />
        {text}
      </Stack>
    </Typography>
  </Container>
);

export default TrophyBadge;

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import Card from 'components/Dashboard/Card';
import { ReactComponent as ChevronRight } from 'images/chevron-right.svg';

const MyDataMakingADifference = (props) => (
  <Card
    title='my impact through cvr'
    isCommingSoon={false}
    subtitle={
      'Using CVR data to explain the experience of living with a bleeding disorder'
    }
    description={
      'This section outlines publications that used CVR data to improve bleeding disorder research.'
    }
    {...props}
  >
    <Button
      variant='text'
      component={Link}
      endIcon={<ChevronRight />}
      sx={{ width: 'fit-content' }}
      to='/homepage/my-impact-with-cvr'
    >
      View All
    </Button>
  </Card>
);

export default MyDataMakingADifference;

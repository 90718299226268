import LoginProcessForm from 'components/LoginProcessForm';
import joinCVR from 'forms/joinCVR';
import Footer from './Footer';

const JoinCVR = ({ onJoin }) => (
  <LoginProcessForm
    title='Join CVR'
    form={joinCVR}
    onSubmit={onJoin}
    footer={<Footer />}
  />
);
export default JoinCVR;

import {
  styled,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    '&:first-of-type': {
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px',
    },
    '&:last-of-type': {
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
  },
  border: `1px solid ${theme.palette.gray[10]}`,
  borderRadius: '10px',
  boxShadow: 'none',
  overflow: 'hidden',
  marginBottom: '12px',
}));

export const StyledAccordionSummary = styled(AccordionSummary)({
  padding: '10px 20px',
});

export const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '20px',
  borderRadius: '10px',
});

export const AccordionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

export const AccordionContent = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '24px',
  color: theme.palette.gray[80],
}));

import usCountiesStates from 'mocks/usCountiesStates';

const joinCVR = {
  display: 'wizard',
  navBar: {
    submitLabel: 'submit',
    footNotes:
      '<span class="subtitle" hidden>By continuing, you agree to the <a>Terms and Conditions</a> and <a>Privacy Policy</a></span>',
  },
  components: [
    {
      title: 'Page 1',
      navigateOnEnter: false,
      saveOnEnter: false,
      scrollToTop: false,
      collapsible: false,
      key: 'page1',
      type: 'panel',
      label: 'Page 1',
      input: false,
      tableView: true,
      components: [
        {
          label: 'Email Address',
          placeholder: 'Enter email',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
          },
          kickbox: {
            enabled: true,
          },
          key: 'email',
          type: 'email',
          input: true,
          autocomplete: 'email',
          spellcheck: false,
        },
        {
          label: 'What is your date of birth?',
          format: 'MM/dd/yyyy',
          placeholder: 'Enter Date',
          tableView: false,
          datePicker: {
            disableWeekends: false,
            disableWeekdays: false,
            maxDate: 'moment()',
          },
          enableTime: false,
          validate: {
            required: true,
          },
          enableMinDateInput: false,
          enableMaxDateInput: true,
          key: 'date_of_birth',
          type: 'datetime',
          input: true,
          widget: {
            type: 'calendar',
            displayInTimezone: 'viewer',
            locale: 'en',
            useLocaleSettings: false,
            allowInput: true,
            mode: 'single',
            enableTime: false,
            noCalendar: false,
            format: 'MM/dd/yyyy',
            hourIncrement: 1,
            minuteIncrement: 1,
            time_24hr: false,
            minDate: null,
            disableWeekends: false,
            disableWeekdays: false,
            maxDate: 'moment()',
          },
          autocomplete: 'bday',
          spellcheck: false,
        },
        {
          label: 'Do you live in the United States or US territory?',
          optionsLabelPosition: 'right',
          inline: true,
          tableView: false,
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'usResident',
          type: 'radio',
          input: true,
        },
        {
          label: 'What state or US territory do you live in?',
          widget: 'choicesjs',
          placeholder: 'Select state',
          tableView: true,
          data: {
            values: Object.keys(usCountiesStates).map((key) => ({
              label: key,
              value: key,
            })),
          },
          validate: {
            required: true,
          },
          key: 'state',
          conditional: {
            show: true,
            when: 'usResident',
            eq: 'yes',
          },
          type: 'select',
          input: true,
        },
        {
          label: 'Do you reside in the EU or the United Kingdom?',
          optionsLabelPosition: 'right',
          inline: true,
          tableView: false,
          validate: {
            required: true,
          },
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
          ],
          key: 'euResident',
          type: 'radio',
          input: true,
        },
      ],
    },
  ],
};

export default joinCVR;

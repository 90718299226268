import { Button, Stack, Typography } from '@mui/material';
import Confetti from 'react-confetti';
import { Link } from 'react-router-dom';
import useWindowSize from 'react-use/lib/useWindowSize';

import Container from 'components/Surveys/Container';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg';
import { ReactComponent as ThankYouIcon } from 'images/thank-you-survey.svg';

import Layout from './Layout';

const ThankYou = ({ surveyName }) => {
  const { width, height } = useWindowSize();
  return (
    <Container maxWidth='600px' sx={{ marginTop: { xs: '80px', sm: '40px' } }}>
      <Layout>
        <Stack
          spacing='1px'
          alignItems={{ xs: 'stretch', sm: 'center' }}
          flex={1}
        >
          <Stack spacing='1px' alignItems='center' flex={1}>
            <ThankYouIcon />
            <Typography variant='w600h24' color='gray.80' align='center'>
              <p>
                Congratulations!
                <br />
                You have successfully completed the {surveyName}.
                <br />
                Thank You!
              </p>
            </Typography>
            <Typography variant='w400h14' color='gray.80' align='center'>
              <p>
                Your participation will help people living with inheritable
                bleeding disorders and their immediate family members have a
                better future.
              </p>
            </Typography>
          </Stack>
          <Button endIcon={<ArrowRightIcon />} component={Link} to='/homepage'>
            GO TO DASHBOARD
          </Button>
        </Stack>
      </Layout>
      <Confetti width={width} height={height} />
    </Container>
  );
};

export default ThankYou;

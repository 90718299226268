import { Box } from '@mui/material';

import FormIO from 'components/FormIO';
import Container from 'components/Surveys/Container';

const defaultValues = {
  a: 'no',
  b: 'yes',
  c: 'no',
  d: 'no',
};
const survey = {
  display: 'wizard',
  navBar: {
    previousLabel: 'previous',
    saveLabel: 'save and continue later',
    nextLabel: 'next',
    submitLabel: 'submit',
    cancelLabel: 'cancel',
    closeLabel: 'close',
  },
  components: [
    {
      title: 'Form a',
      label: 'Form',
      type: 'panel',
      key: 'form',
      input: false,
      tableView: false,
      components: [
        {
          label: 'Question A',
          optionsLabelPosition: 'right',
          inline: true,
          tableView: false,
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'a',
          type: 'radio',
          input: true,
        },
        {
          label: 'Question B',
          optionsLabelPosition: 'right',
          inline: true,
          tableView: false,
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          customConditional: `show = data.a === 'yes';`,
          key: 'b',
          type: 'radio',
          input: true,
        },
        {
          label: 'Question C',
          optionsLabelPosition: 'right',
          inline: true,
          tableView: false,
          customConditional: `show = data.b === 'yes' && data.a === 'yes';`,
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'c',
          type: 'radio',
          input: true,
        },
      ],
    },
    {
      title: 'Form b',
      label: 'Form',
      type: 'panel',
      key: 'form',
      input: false,
      tableView: false,
      customConditional: `show = data.a === 'yes';`,
      components: [
        {
          label: 'Question D',
          optionsLabelPosition: 'right',
          inline: true,
          tableView: false,
          values: [
            {
              label: 'Yes',
              value: 'yes',
              shortcut: '',
            },
            {
              label: 'No',
              value: 'no',
              shortcut: '',
            },
          ],
          validate: {
            required: true,
          },
          key: 'd',
          type: 'radio',
          input: true,
        },
      ],
    },
  ],
};

const FormioTest1 = () => {
  const handleSave = (...args) => console.log(args);
  const handleSubmit = (...args) => console.log(args);

  return (
    <Box m={{ xs: '0', sm: '50px' }}>
      <Container maxWidth='800px' m='50px'>
        <FormIO
          form={survey}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          onSave={handleSave}
          template='enrollment'
          unlockSubmit={true}
        />
      </Container>
    </Box>
  );
};

export default FormioTest1;

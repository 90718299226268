import { ReactComponent as Check } from 'images/check.svg';
import {
  Container,
  BackgroundBar,
  ForegroundBar,
  CompletedIconWrapper,
} from './styles';

const ProgressBar = ({ color, current, total }) => (
  <Container>
    <BackgroundBar />
    <ForegroundBar color={color} current={current} total={total} />
    <CompletedIconWrapper color={color} current={current} total={total}>
      <Check />
    </CompletedIconWrapper>
  </Container>
);

export default ProgressBar;

const interferenceFromPain = {
  display: 'form',
  components: [
    {
      label: 'Bleeding Disorder',
      tableView: false,
      data: {
        values: [
          {
            label: 'Hemophilia A',
            value: 'hemophiliaA',
          },
          {
            label: 'Hemophilia B',
            value: 'hemophiliaB',
          },
          {
            label: 'Von Willebrand Disease',
            value: 'vonWillebrandDisease',
          },
          {
            label: 'Ultra-rare bleeding disorders',
            value: 'ultraRareBleedingDisorders',
          },
        ],
      },
      key: 'bleedingDisorder',
      widget: 'choicesjs',
      type: 'select',
      input: true,
      logic: [
        {
          name: 'on clear',
          trigger: {
            type: 'javascript',
            javascript:
              "const content = data[component.key]\nresult = content === '' || content === undefined || content === null",
          },
          actions: [
            {
              name: 'Select first',
              type: 'value',
              value: 'value = component.data.values[0].value',
            },
          ],
        },
      ],
    },
  ],
};

export default interferenceFromPain;

import { Grid, Typography } from '@mui/material';

import {
  BleedingDisorders,
  ChapterParticipation,
  Demographics,
  EmploymentDifficulties,
  ImpactOfPain,
  InterferenceFromPain,
} from 'components/DataStory';
import { GridPageLayout } from 'components/Layouts';
import usePersonAttributes from 'hooks/usePersonAttributes';

const DataStory = () => {
  const { attributes, isLoading } = usePersonAttributes();
  return (
    <GridPageLayout title='Data Stories'>
      <Grid item xs={12} xl={6}>
        <BleedingDisorders attributes={attributes} isLoading={isLoading} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <Demographics attributes={attributes} isLoading={isLoading} />
      </Grid>
      <Grid item xs={12}>
        <ImpactOfPain />
      </Grid>
      <Grid item xs={12}>
        <InterferenceFromPain />
      </Grid>
      <Grid item xs={12}>
        <EmploymentDifficulties />
      </Grid>
      <Grid item xs={12}>
        <ChapterParticipation />
      </Grid>
      <Grid item xs={12}>
        <footer>
          <Typography variant='w600h16' color='gray.100' textAlign='center'>
            THIS WEBSITE DOES NOT PROVIDE MEDICAL ADVICE
          </Typography>
          <br></br>
          <Typography variant='w400h16' color='gray.100' textAlign='center'>
            The information contained on this website is for informational
            purposes only. No information on this site is intended to be a
            substitute for professional medical advice, diagnosis, or treatment.
          </Typography>
        </footer>
      </Grid>
    </GridPageLayout>
  );
};

export default DataStory;

import { useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';
import Session from 'store-management';

import ConfirmMFA from 'components/Enrollment/AccountSecurityStep/ConfirmMFA';
import useMFA from 'hooks/useMFA';

const Confirm = () => {
  const session = new Session();
  const location = useLocation();
  const {
    mfaLoaders,
    code,
    timeLeft,
    isConfirmPageLoading,
    setCode,
    handleSkipMFA,
    handleConfirmMFA,
    handleChallenge2FA,
    handleResendCode,
    handleMFAMethodSelected,
  } = useMFA();

  return (
    <Stack
      p={{ xs: '20px 0 0 0', sm: '40px 20px 40px 20px' }}
      alignItems='center'
      spacing='30px'
      flex={1}
    >
      <ConfirmMFA
        authenticationType={location?.state?.authenticationType}
        mfaLoaders={mfaLoaders}
        code={code}
        timeLeft={timeLeft}
        pageLoading={isConfirmPageLoading}
        mfaCode={session.get('mfa_code')}
        setCode={setCode}
        handleSkipMFA={handleSkipMFA}
        handleChallenge2FA={handleChallenge2FA}
        handleResendCode={handleResendCode}
        handleMFAMethodSelected={handleMFAMethodSelected}
        handleSubmit={handleConfirmMFA}
      />
    </Stack>
  );
};

export default Confirm;

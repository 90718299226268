import Section from 'components/DataStory/Section';
import BubbleMap from 'components/DataStory/Demographics/BubbleMap';

const regionsPercetange = { West: 22, North: 8, South: 22, East: 48 };

const Demographics = ({ attributes, isLoading }) => {
  return (
    <Section
      title='Demographics, Chapters, and Hemophilia Treatment Centers (HTCs)'
      description='Let’s see where the rest of the CVR population lives:'
    >
      <BubbleMap
        width={600}
        height={500}
        regionsPercetange={regionsPercetange}
        person={attributes}
        isPersonLoading={isLoading}
      />
    </Section>
  );
};

export default Demographics;

import { Link, useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { openSnackbar } from 'store/slices/application';
import { SnackbarError } from 'utils/enums';
import LoginProcessForm from 'components/LoginProcessForm';
import { useAuth } from 'context/AuthContext';
import loginForm from 'forms/login';
import { ErrorDescriptions } from 'utils/enums';

const Footer = () => {
  return (
    <Stack spacing='20px' mb='25px' align='center'>
      <Link to='/reset-password'>
        <Typography variant='w600h14' color='primary' align='center'>
          Reset Password
        </Typography>
      </Link>
      <Typography variant='w400h14' color='gray.80' align='center'>
        Don’t have an account?{' '}
        <Link to='/join'>
          <Typography variant='w600h14' color='primary'>
            Join
          </Typography>
        </Link>
      </Typography>
    </Stack>
  );
};

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const dispatch = useDispatch();
  const handleSubmit = ({ email, password }) =>
    login({ email, password }).catch((error) => {
      if (error.code === ErrorDescriptions.MFARequired) {
        navigate('/verify-2fa', { state: { email, password } });
      } else {
        dispatch(
          openSnackbar({
            ...SnackbarError.WrongEmailPassword(),
          })
        );
      }
    });

  return (
    <LoginProcessForm
      title='Login'
      form={loginForm}
      onSubmit={handleSubmit}
      footer={<Footer />}
    />
  );
};

export default Login;

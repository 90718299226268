const formETOH = {
  title: 'Form ETOH',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'ETOH',
  type: 'panel',
  label: 'Form ETOH',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Alcohol Use</strong>',
      label: 'Content',
      refreshOnChange: false,
      key: 'ETOH0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'How often did you have a drink containing alcohol in the past year?',
      widget: 'choicesjs',
      placeholder: 'How often...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Never',
            value: '0',
          },
          {
            label: 'Monthly or less',
            value: '1',
          },
          {
            label: 'Two to four times a month',
            value: '2',
          },
          {
            label: 'Two to three times a week',
            value: '3',
          },
          {
            label: 'Four or more times a week',
            value: '4',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'ETOH1',
      type: 'select',
      input: true,
    },
    {
      label:
        'How many drinks did you have on a typical day when you were drinking in the past year?',
      widget: 'choicesjs',
      placeholder: 'How many drinks...',
      tableView: true,
      data: {
        values: [
          {
            label: 'None, I do not drink',
            value: '0',
          },
          {
            label: '1 or 2',
            value: '0',
          },
          {
            label: '3 or 4',
            value: '1',
          },
          {
            label: '5 or 6',
            value: '2',
          },
          {
            label: '7 to 9',
            value: '3',
          },
          {
            label: '10 or more',
            value: '4',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'ETOH2',
      type: 'select',
      input: true,
    },
    {
      label: 'How often do you have six or more drinks on one occasion?',
      widget: 'choicesjs',
      placeholder: 'How often...',
      tableView: true,
      data: {
        values: [
          {
            label: 'Never',
            value: '0',
          },
          {
            label: 'Less than monthly',
            value: '1',
          },
          {
            label: 'Monthly',
            value: '2',
          },
          {
            label: 'Weekly',
            value: '3',
          },
          {
            label: 'Daily or almost daily',
            value: '4',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'ETOH3',
      type: 'select',
      input: true,
    },
  ],
};

export default formETOH;

import { useMemo } from 'react';

import { Skeleton } from '@mui/material';

import Item from 'components/MyHealthSummary/Content/Items/Item';
import useGetBaseline from 'hooks/useGetBaseline';

import { parseHtc } from './utils';

const skeletonList = [
  { id: 1, description: <Skeleton width='210px' /> },
  { id: 2, description: <Skeleton width='260px' /> },
  { id: 3, description: <Skeleton width='180px' /> },
];

const BleedingDisorderCareProvider = (props) => {
  const { data, isLoading } = useGetBaseline();
  const htcContent = useMemo(() => parseHtc(data), [data]);

  return (
    <Item
      label='Bleeding Disorder Care Provider'
      content={
        isLoading ? skeletonList : htcContent.length > 0 ? htcContent : '--'
      }
      {...props}
    />
  );
};

export default BleedingDisorderCareProvider;

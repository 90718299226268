const MuiChip = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.w700h14,
      height: '33px',
      width: 'fit-content',
    }),
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: ({ theme }) => ({
        color: theme.palette.pacificBlue[100],
        backgroundColor: 'unset',
        border: `1.5px solid ${theme.palette.pacificBlue[100]}`,
      }),
    },
    {
      props: { size: 'small' },
      style: ({ theme }) => ({
        ...theme.typography.w700h11,
        height: '28px',
      }),
    },
  ],
};

export default MuiChip;

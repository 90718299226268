const formMC = {
  title: 'Form MC',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'MC',
  customConditional:
    'if (form.injectedValues) show = ["female", "intersex"].includes(form.injectedValues.R8)',
  type: 'panel',
  label: 'Form MC',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Menstrual Cycles (Periods) </strong></br/>For many reasons, periods can be especially tough for people with bleeding disorders. It is important to understand how they affect a person’s life to be able to think of ways to make them better. The next few questions will ask about your periods (menstrual cycles).',
      label: 'Content',
      refreshOnChange: false,
      key: 'MC0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'Do you have menstrual periods?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'MC1',
      type: 'radio',
      input: true,
    },
    {
      label: 'Why do you not have menstrual periods? Choose only one response.',
      widget: 'choicesjs',
      placeholder: 'Select a reason...',
      tableView: true,
      data: {
        values: [
          {
            value: 'Not_started',
            label: 'I have not started my period (menstruating) yet',
          },
          {
            value: 'BC_pill',
            label: 'I use birth control pills',
          },
          {
            value: 'IUD',
            label:
              'I have an IUD (intrauterine device) such as: Mirena, Kyleena, Liletta or Skyla',
          },
          {
            value: 'Depo',
            label: 'I use Depo-Provera (Depo shot)',
          },
          {
            value: 'Ring_patch',
            label: 'I use a vaginal ring or hormone patch',
          },
          {
            value: 'Menopause',
            label: "I've gone through menopause",
          },
          {
            value: 'Pregnant',
            label: 'I am pregnant',
          },
          {
            value: 'Hysterectomy',
            label: "I've had a hysterectomy (surgical removal of the uterus)",
          },
          {
            value: 'Procedure',
            label:
              "I've had a medical procedure that stopped it (such as a uterine ablation)",
          },
          {
            value: 'other',
            label: 'Other',
          },
        ],
      },
      key: 'MC2',
      conditional: {
        show: true,
        when: 'MC1',
        eq: 'No',
      },
      type: 'select',
      input: true,
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      key: 'MC2_other',
      conditional: {
        show: true,
        when: 'MC2',
        eq: 'other',
      },
      validate: {
        required: true,
        maxLength: 250,
      },
      type: 'textfield',
      input: true,
    },
    {
      html: 'Think back to when you were having periods and answer the next questions based on what they were typically like.',
      label: 'Content',
      refreshOnChange: false,
      key: 'content2',
      type: 'content',
      input: false,
      customConditional:
        'show = data.MC2 !== "Not_started" && data.MC1 === "No";',
      tableView: false,
    },
    {
      label:
        'Have you ever had heavy menstrual periods (periods lasting more than 7 days, having to change sanitary products more often than every 2 hours, flooding or passing large clots)',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'MC3',
      customConditional: 'show = data.MC2 !== "Not_started";',
      type: 'radio',
      input: true,
    },
    {
      label:
        'Describe your usual experiences regarding your menstrual bleeding. Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        Mood: false,
        Always_heavy: false,
        Seven_days: false,
        Clots: false,
        Flooding: false,
        Freq_pads: false,
        Double: false,
        Stay_home: false,
        Fatigue: false,
        Diff_concept: false,
        Spoke_HCP: false,
        Anemia: false,
        Need_iron: false,
        Use_BC: false,
        Antifibrinolytics: false,
        IUD: false,
        Factor: false,
        Procedure: false,
        Transfusion: false,
        Hospitalized: false,
        noneOfTheAbove: false,
      },
      values: [
        {
          value: 'Always_heavy',
          label: 'My periods have always been heavy',
          shortcut: '',
        },
        {
          value: 'Seven_days',
          label: 'My periods last more than 7 days',
          shortcut: '',
        },
        {
          value: 'Clots',
          label: 'I pass clots/clumps',
          shortcut: '',
        },
        {
          value: 'Flooding',
          label:
            'I have flooding (a feeling of “gushing” sometimes soiling clothes and bed sheets)',
          shortcut: '',
        },
        {
          value: 'Freq_pads',
          label:
            'I need to change pads or tampons every 2 hours or more often on worse days',
          shortcut: '',
        },
        {
          value: 'Double',
          label:
            'I need to use double protection methods (wearing two pads at the same time or wearing a tampon and a pad together)',
          shortcut: '',
        },
        {
          value: 'Stay_home',
          label:
            'I need to stay home from work or school more than twice a year because of heavy periods',
          shortcut: '',
        },
        {
          value: 'Fatigue',
          label: 'I feel weak, tired or fatigued',
          shortcut: '',
        },
        {
          value: 'Mood',
          label:
            'I feel increased stress, anxiety, depression, sadness, and/or moodiness',
          shortcut: '',
        },
        {
          value: 'Diff_concent',
          label: 'I have difficulty concentrating (experience brain fog)',
          shortcut: '',
        },
        {
          value: 'Spoke_HCP',
          label:
            'I have spoken to my healthcare provider about concerns with my heavy periods',
          shortcut: '',
        },
        {
          value: 'Anemia',
          label: 'I have been diagnosed with anemia (low red blood cell count)',
          shortcut: '',
        },
        {
          value: 'Need_iron',
          label:
            'I have had to take iron supplements due to anemia (this includes IV (intravenous) iron)',
          shortcut: '',
        },
        {
          value: 'IUD',
          label: 'I have an IUD to make my periods lighter or shorter',
          shortcut: '',
        },
        {
          value: 'Procedure',
          label:
            'I have had a medical procedure to decrease my bleeding (such as an ablation)',
          shortcut: '',
        },
        {
          value: 'Antifibrinolytics',
          label:
            'I use antifibrinolytics such as tranexamic acid (Lysteda or Cyklokapron) or aminocaproic acid (Amicar) to make my periods lighter or shorter',
          shortcut: '',
        },
        {
          value: 'Use_BC',
          label:
            'I use birth control pills or hormones to stop my periods or to make them lighter or shorter',
          shortcut: '',
        },
        {
          value: 'Factor',
          label:
            'I take clotting factor during my periods to make them lighter',
          shortcut: '',
        },
        {
          value: 'Transfusion',
          label:
            'I have needed a blood transfusion as a result of my heavy bleeding',
          shortcut: '',
        },
        {
          value: 'Hospitalized',
          label:
            'I have been admitted to the hospital to try to control my bleeding',
          shortcut: '',
        },
        {
          value: 'noneOfTheAbove',
          label: 'None of the above',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'MC4',
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: "result = data['MC4']?.noneOfTheAbove;",
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(value).reduce((acc, curr) => ({...acc, [curr]: curr === 'noneOfTheAbove'}), {});",
            },
          ],
        },
      ],
      type: 'selectboxes',
      customConditional: 'show = data.MC2 !== "Not_started";',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'What best describes your average clot/clump size?',
      widget: 'choicesjs',
      placeholder: 'Select average size',
      tableView: true,
      data: {
        values: [
          {
            label: 'Less than the size of a dime',
            value: '1',
          },
          {
            label: 'The size of a dime',
            value: '2',
          },
          {
            label: 'The size of a nickel',
            value: '3',
          },
          {
            label: 'The size of a quarter or larger',
            value: '4',
          },
          {
            label: 'It is hard for me to estimate the size',
            value: 'NS',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'MC5',
      customConditional: 'show = data.MC2 !== "Not_started";',
      type: 'select',
      input: true,
    },
    {
      label:
        'Thinking back about your periods, did you have pain in the immediate days before, during or after your period?',
      widget: 'choicesjs',
      placeholder: 'Select level of pain',
      tableView: true,
      data: {
        values: [
          {
            label: 'No pain at all',
            value: '0',
          },
          {
            label: 'Slight pain',
            value: '1',
          },
          {
            label: 'Moderate pain',
            value: '2',
          },
          {
            label: 'Severe pain',
            value: '3',
          },
          {
            label: 'Very severe pain',
            value: '4',
          },
        ],
      },
      validate: {
        required: true,
      },
      key: 'MC6',
      customConditional:
        'show = data.MC2 !== "Not_started" && data.MC1 === "No";',
      type: 'select',
      input: true,
    },
    {
      label:
        'Thinking back about your periods, please describe your usual experience.',
      tableView: false,
      questions: [
        {
          label:
            'How much did your periods interfere with your usual school or work activities?',
          value: 'MC7',
          tooltip: '',
        },
        {
          label: 'How much did your periods interfere with household chores?',
          value: 'MC8',
          tooltip: '',
        },
        {
          label:
            'How much your periods interfere your participation in social activities?',
          value: 'MC9',
          tooltip: '',
        },
      ],
      values: [
        {
          label: 'Not at all',
          value: '0',
          tooltip: '',
        },
        {
          label: 'A little bit',
          value: '1',
          tooltip: '',
        },
        {
          label: 'Somewhat',
          value: '2',
          tooltip: '',
        },
        {
          label: 'Quite a bit',
          value: '3',
          tooltip: '',
        },
        {
          label: 'Very much',
          value: '4',
          tooltip: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'MC7_MC9',
      customConditional:
        'show = data.MC2 !== "Not_started" && data.MC1 === "No";',
      type: 'survey',
      input: true,
    },
  ],
};

export default formMC;

import { Outlet } from 'react-router-dom';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

import Header from 'components/Header';

const HeaderRoute = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Stack bgcolor={sm ? undefined : 'background.paper'} flex={1}>
      <Header />
      <Outlet />
    </Stack>
  );
};

export default HeaderRoute;

import { Stack } from '@mui/material';

import { AccountSecurityStep } from 'components/Enrollment';
import useMFA from 'hooks/useMFA';

const AccountSecurity = () => {
  const {
    mfaLoaders,
    isLoading,
    handleMFAMethodSelected,
    handleSkipMFA,
  } = useMFA();

  return (
    <Stack
      p={{ xs: '20px 0 0 0', sm: '40px 20px 40px 20px' }}
      alignItems='center'
      spacing='30px'
      flex={1}
    >
      <AccountSecurityStep
        onSkip={handleSkipMFA}
        onMethodSelected={handleMFAMethodSelected}
        loaders={mfaLoaders}
        pageLoading={isLoading}
      />
    </Stack>
  );
};

export default AccountSecurity;

import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0 15px 0 20px',
  backgroundColor: theme.palette.background.paper,
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '70px',
  background: theme.palette.white,
  borderBottom: `1px solid ${theme.palette.gray[20]}`,
}));

const formHTC = {
  title: 'Form HTC',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'HTC',
  type: 'panel',
  label: 'Form HTC',
  input: false,
  tableView: false,
  components: [
    {
      html: 'Hemophilia Treatment Centers (HTCs) are specialized health care centers supported by the Federal government. They bring together a team of doctors, nurses, social workers and other health professionals who are experts in treating people with bleeding disorders. Comprehensive HTCs emphasize prevention services to help prevent, reduce or eliminate complications. These centers focus on preventing problems and connecting patients with groups that provide education and support. For example, the National Bleeding Disorders Foundation works with HTCs and the Centers for Disease Control and Prevention (CDC) to educate people with bleeding disorders.',
      label: 'Content',
      refreshOnChange: false,
      key: 'HTC0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'Before today, did you know what a federally funded HTC was?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'HTC1',
      type: 'radio',
      input: true,
    },
  ],
};

export default formHTC;

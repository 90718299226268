const formL = {
  title: 'Form L',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'L',
  type: 'panel',
  label: 'Form L',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Liver transplant</strong></br/>Liver transplants are rare but when people with bleeding disorders get one it may improve or even cure their bleeding disorders!',
      label: 'Content',
      refreshOnChange: false,
      key: 'L0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'Have you had a liver transplant?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'L1',
      type: 'radio',
      input: true,
    },
    {
      label: 'What is your current factor level?',
      placeholder: 'Please enter your current factor level',
      applyMaskOn: 'change',
      tableView: false,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'L2',
      conditional: {
        show: true,
        when: 'L1',
        eq: 'Yes',
      },
      logic: [
        {
          name: 'Disable component',
          trigger: {
            type: 'javascript',
            javascript: 'result = data.L2_NS;',
          },
          actions: [
            {
              name: 'Disable',
              type: 'property',
              property: {
                label: 'Disabled',
                value: 'disabled',
                type: 'boolean',
              },
              state: true,
            },
            {
              name: 'Clear',
              type: 'value',
              value: 'value = ""',
            },
            {
              name: 'Not required',
              type: 'property',
              property: {
                label: 'Required',
                value: 'validate.required',
                type: 'boolean',
              },
              state: false,
            },
          ],
        },
      ],
      type: 'textfield',
      input: true,
    },
    {
      label: 'I’m not sure',
      tableView: false,
      defaultValue: false,
      key: 'L2_NS',
      conditional: {
        show: true,
        when: 'L1',
        eq: 'Yes',
      },
      type: 'checkbox',
      input: true,
    },
  ],
};

export default formL;

import { format } from 'date-fns';
import { Typography } from '@mui/material';

import { TaskStatus } from 'utils/enums';

const displayDate = (d) => format(new Date(d), 'dd MMM yyyy');

const DateStatus = ({ status, updatedAt }) =>
  status !== TaskStatus.Incomplete && (
    <Typography variant='w400h14' color='gray.80'>
      <span>
        <strong>
          {status === TaskStatus.Completed ? 'Submitted:' : 'Last updated'}
        </strong>{' '}
        {displayDate(updatedAt)}
      </span>
    </Typography>
  );

export default DateStatus;

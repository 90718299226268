const formOC = {
  title: 'Form OC',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'OC',
  type: 'panel',
  label: 'Form OC',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Oral care</strong>',
      label: 'Content',
      refreshOnChange: false,
      key: 'OC0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'Have you been to the dentist within the past 12 months?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'OC1',
      type: 'radio',
      input: true,
    },
    {
      label: 'Why not? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label:
            'There are no dentists who see people with bleeding disorders where I live',
          value: 'No_dentist',
          shortcut: '',
        },
        {
          label: 'I am afraid of bleeding',
          value: 'Bleeding',
          shortcut: '',
        },
        {
          label:
            'I have a dental phobia (intense fear about going to any dentist)',
          value: 'Phobia',
          shortcut: '',
        },
        {
          label: 'I’ve had bad experiences in the past',
          value: 'Bad_exp',
          shortcut: '',
        },
        {
          label: "I don't have dental insurance",
          value: 'Insurance',
          shortcut: '',
        },
        {
          label: "I can't afford it",
          value: 'No_afford',
          shortcut: '',
        },
        {
          label: 'It is too far away',
          value: 'Distance',
          shortcut: '',
        },
        {
          label: "I don't have time",
          value: 'Time',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'OC2',
      conditional: {
        show: true,
        when: 'OC1',
        eq: 'No',
      },
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
      defaultValue: {
        Camper: false,
        Volunteer: false,
        Organizer: false,
        other: false,
      },
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'OC2_other',
      customConditional: "show = data[component.key.split('_')[0]]?.other;",
      type: 'textfield',
      input: true,
    },
    {
      label: 'Do you brush your teeth every day?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'OC3',
      type: 'radio',
      input: true,
    },
    {
      label: 'Why not? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        Camper: false,
        Volunteer: false,
        Organizer: false,
        other: false,
      },
      values: [
        {
          label: "I can't reach my mouth",
          value: 'No_reach',
          shortcut: '',
        },
        {
          label: 'It causes too much bleeding',
          value: 'Bleeding',
          shortcut: '',
        },
        {
          label: 'It causes too much pain',
          value: 'Pain',
          shortcut: '',
        },
        {
          label: 'I have no teeth',
          value: 'No_teeth',
          shortcut: '',
        },
        {
          label: "I don't care",
          value: 'No_care',
          shortcut: '',
        },
        {
          label: 'I forget',
          value: 'Forget',
          shortcut: '',
        },
        {
          label: "I don't think it is helpful",
          value: 'Not_helpful',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'OC4',
      conditional: {
        show: true,
        when: 'OC3',
        eq: 'No',
      },
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'OC4_other',
      customConditional: "show = data[component.key.split('_')[0]]?.other;",
      type: 'textfield',
      input: true,
    },
  ],
};

export default formOC;

const formAD = {
  title: 'Form AD',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'AD',
  type: 'panel',
  label: 'Form AD',
  input: false,
  tableView: false,
  components: [
    {
      html: '<h3><center>You’re about halfway complete with the survey, keep it up!</center></h3><br/><strong>Assist devices and mobility aids</strong>',
      label: 'Content',
      refreshOnChange: false,
      key: 'AD0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'In the past 12 months, did you use crutches, a cane, wheelchair, scooter or other type of mobility aid or assistive device?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'AD1',
      type: 'radio',
      input: true,
    },
  ],
};

export default formAD;

import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const Header = ({ title, description, buttons }) => {
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Stack spacing='4px'>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='w600h30' color='gray.100'>
          {title}
        </Typography>
        <Stack direction='row' spacing='10px'>
          {buttons?.map?.(({ icon, label, onClick }) =>
            large ? (
              <Button
                key={label}
                variant='outlined'
                startIcon={icon}
                sx={{ flex: 1 }}
                onClick={onClick}
              >
                {label}
              </Button>
            ) : (
              <Button key={label} variant='outlined' onClick={onClick}>
                {icon}
              </Button>
            )
          )}
        </Stack>
      </Stack>
      {description && (
        <Typography variant='w400h14' color='gray.100' maxWidth='956px'>
          {description}
        </Typography>
      )}
    </Stack>
  );
};

export default Header;

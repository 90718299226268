import { useSelector, useDispatch } from 'react-redux';
import { Alert, Snackbar as MuiSnackBar } from '@mui/material';

import { closeSnackbar, selectSnackbar } from 'store/slices/application';

export const Snackbar = () => {
  const { open, type, message, anchorOrigin } = useSelector(selectSnackbar());

  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeSnackbar());

  return (
    <MuiSnackBar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant='filled'
        onClose={handleClose}
        severity={type}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </MuiSnackBar>
  );
};

export default Snackbar;

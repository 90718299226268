import Check from 'images/check.svg';

const size = '34px';
const thickness = '2px';
const linePadding = '10px';

const MuiStep = {
  variants: [
    {
      props: { alternativeLabel: true },
      style: ({ theme, ownerState }) => {
        const { disabled, completed, active, index } = ownerState;
        const primary = theme.palette.primary.main;
        const gray = theme.palette.gray[60];
        const white = theme.palette.white;

        return {
          svg: { display: 'none' },
          '.MuiStepLabel-iconContainer': {
            ...theme.typography.w700h14,
            width: size,
            height: size,
            borderRadius: `calc(${size} / 2)`,

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            border: `${thickness} solid ${disabled ? gray : primary}`,
            ...(completed && { backgroundColor: primary }),
            color: completed ? white : disabled ? gray : primary,
          },
          '.MuiStepLabel-iconContainer::after': completed
            ? {
                content: '" "',
                backgroundImage: `url(${Check})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '14px',
                height: '14px',
              }
            : {
                content: `"${index + 1}"`,
              },
          '.MuiStepConnector-root': {
            top: `calc(${size} / 2 - ${thickness} / 2)`,
            left: `calc(-50% + ${size} / 2 + ${linePadding})`,
            right: `calc(50% + ${size} / 2 + ${linePadding})`,
          },
          '.MuiStepConnector-line': {
            borderColor: active || completed ? primary : gray,
            borderTopStyle: 'solid',
            borderTopWidth: thickness,
          },
          '.MuiStepLabel-label': {
            ...theme.typography.w600h12,
            color: active ? primary : gray,
            marginTop: '10px',
          },
          [theme.breakpoints.down('sm')]: {
            display: active ? 'inherit' : 'none',
            padding: 0,
            '.MuiStepLabel-root': {
              flexDirection: 'row !important',
              alignItems: 'baseline',
              gap: '10px',
            },
            '.MuiStepConnector-root': {
              display: 'none',
            },
            '.MuiStepLabel-labelContainer': {
              textAlign: 'left',
            },
          },
        };
      },
    },
  ],
};

export default MuiStep;

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import auth0 from 'auth0-js';
import { useIdleTimer } from 'react-idle-timer';
import { batch, useDispatch, useSelector } from 'react-redux';
import { promisify } from 'util';

import useAuthenticationApi from 'hooks/useAuth';
import Session from 'store-management';
import apis from 'store/api';
import {
  selectAccessToken,
  selectSession,
  setSession,
} from 'store/slices/auth';
import { resetAll } from 'store/slices/reset';
import { Auth0Scopes } from 'utils/enums';
import { formatPhoneNumber } from 'utils/string';

const AuthContext = createContext();

const session = new Session();

const useAuth = () => useContext(AuthContext);

const scope = `${Auth0Scopes.DefaultScopes} ${Auth0Scopes.MFA}`;

const AuthContextProvider = ({
  domain,
  clientID,
  connection,
  audience,
  children,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const revokeToken = useAuthenticationApi();
  const { isAuthenticated, refreshToken, userInfo } =
    useSelector(selectSession());
  const accessToken = useSelector(selectAccessToken());

  const onAction = () =>
    new auth0.Authentication({
      clientID,
      domain,
      scope: `${scope} read:current_user`,
      audience: 'https://ihp-nhf-cvr-dev.us.auth0.com/api/v2/',
    }).oauthToken(
      {
        refreshToken,
        grantType: 'refresh_token',
      },
      (error, result) => {
        result && dispatch(setSession(result));
        error && handleCleanup();
      },
    );

  useIdleTimer({
    disabled: !isAuthenticated,
    onAction,
    throttle: 60 * 1000, // s to ms
  });

  const authParams = useMemo(
    () => ({
      domain,
      clientID,
      audience,
      scope,
    }),
    [audience, clientID, domain],
  );
  const handleCleanup = useCallback(() => {
    if (refreshToken) {
      revokeToken(refreshToken).then(() => {
        return new auth0.WebAuth(authParams).logout({
          returnTo: `${window.location.origin}/login`,
        });
      });
    }
    batch(() => {
      apis.forEach((api) => dispatch(api.util.resetApiState()));
      dispatch(resetAll());
      setLoading(false);
    });
  }, [dispatch, refreshToken, revokeToken, authParams]);
  const login = useCallback(
    ({ email, password }) =>
      promisify(({ email, password }, callback) => {
        setLoading(true);
        session.put('temporary_password', password);
        new auth0.Authentication(authParams).login(
          {
            username: email,
            password,
            realm: connection,
          },
          (error, result) => {
            result && dispatch(setSession(result));
            if (error) {
              session.put(
                'mfa_token',
                error?.original?.response?.body?.mfa_token,
              );
            }
            setLoading(false);
            return callback(error, result);
          },
        );
      })({ email, password }),
    [authParams, connection, dispatch],
  );
  const testPassword = useCallback(
    ({ password }) =>
      promisify(({ password }, callback) => {
        new auth0.Authentication(authParams).login(
          {
            username: userInfo.email,
            password,
            realm: connection,
          },
          (error, result) => {
            return callback(error, result);
          },
        );
      })({ password }),
    [userInfo, authParams, connection],
  );
  const signup = useCallback(
    (...args) =>
      promisify(
        (
          {
            email,
            state,
            date_of_birth,
            password,
            given_name,
            middle_name,
            family_name,
            phone,
            // Four challenges due to meta attribute size limitation
            challenge_1,
            challenge_2,
            challenge_3,
            challenge_4,
          },
          callback,
        ) => {
          setLoading(true);
          session.put('temporary_password', password);

          new auth0.WebAuth(authParams).signupAndAuthorize(
            {
              email,
              password,
              given_name,
              family_name,
              userMetadata: {
                state,
                date_of_birth,
                middle_name,
                phone: formatPhoneNumber(phone),
                mfa_enabled: 'false',
                challenge_1,
                challenge_2,
                challenge_3,
                challenge_4,
              },
              connection,
            },
            (error, result) => {
              setLoading(false);
              result && dispatch(setSession(result));
              return callback(error, result);
            },
          );
        },
      )(...args),
    [authParams, connection, dispatch],
  );

  const resetPassword = useCallback(
    (...args) =>
      promisify(({ email }, callback) => {
        setLoading(true);
        new auth0.WebAuth(authParams).changePassword(
          {
            email,
            connection,
          },
          (error, result) => {
            return callback(error, result);
          },
        );
      })(...args),
    [authParams, connection],
  );

  const logout = useCallback(() => {
    setLoading(true);
    handleCleanup();
  }, [handleCleanup]);

  const variables = {
    login,
    signup,
    resetPassword,
    testPassword,
    userInfo,
    logout,
    isAuthenticated,
    accessToken,
    isLoading,
  };

  return (
    <AuthContext.Provider value={variables}>{children}</AuthContext.Provider>
  );
};

export { useAuth, AuthContextProvider };

import { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Stack, useTheme } from '@mui/material';

import { createTooltip, createGraph, createLegend } from './utils';

const id = 'ChapterParticipationChart';

const padding = 0;
const textColumnWidth = 90;
const barHeight = 50;
const barGap = 13;
const legendGap = 20;
const legendWidth = 20;
const legendRadius = 10;
const legendPadding = 20;
const baseLegendSpacing = 70;

const ChapterParticipationChart = ({ percentages, texts }) => {
  const svgRef = useRef();
  const theme = useTheme();

  useEffect(() => {
    if (!svgRef?.current) return;
    const resizeObserver = new ResizeObserver(() => {
      const height = svgRef.current.offsetHeight;
      const width = svgRef.current.offsetWidth;

      d3.select(`#${id}`).selectAll('svg').remove();

      const svg = d3
        .select(svgRef.current)
        .append('svg')
        .attr('viewBox', [0, 0, width, height]);

      const tooltip = createTooltip(id);

      const barSpace = width - 2 * padding - textColumnWidth;
      const entries = Object.entries(percentages);
      entries.forEach(([key, value], index) =>
        createGraph({
          svg,
          tooltip,
          palette: theme.palette,
          key,
          value,
          x: padding,
          y: index * (barHeight + barGap),
          width: width - 2 * padding,
          height: barHeight,
          legendWidth: textColumnWidth,
          legendGap,
        })
      );

      const baseLegendY =
        entries.length * (barHeight + barGap) + baseLegendSpacing;

      createLegend({
        svg,
        palette: theme.palette,
        x: padding + textColumnWidth + legendGap + barSpace / 2,
        y: baseLegendY,
        legendWidth,
        legendRadius,
        legendPadding,
      });

      d3.select(`#${id}`).style('height', `${baseLegendY + padding}px`);
    });

    resizeObserver.observe(svgRef.current);

    return () => resizeObserver.disconnect();
  }, [texts, percentages, theme.palette]);

  return (
    <Stack
      ref={svgRef}
      id={id}
      justifyContent='flex-end'
      sx={{ overflow: 'hidden' }}
    />
  );
};

export default ChapterParticipationChart;

import { Chip as MUIChip } from '@mui/material';

const Chip = ({ children }) => (
  <MUIChip
    label={children}
    variant='outlined'
    size='small'
    sx={{
      color: (theme) => theme.palette.salmon[100],
      borderColor: (theme) => theme.palette.salmon[100],
    }}
  />
);

export default Chip;

import { Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import LoginProcessForm from 'components/LoginProcessForm';
import { useAuth } from 'context/AuthContext';
import resetPasswordForm from 'forms/resetPassword';

const Footer = () => {
  return (
    <Stack spacing='20px' mb='25px'>
      <Typography variant='w400h14' color='gray.80' align='center'>
        Already have an account?{' '}
        <Link to='/login'>
          <Typography variant='w600h14' color='primary'>
            Login
          </Typography>
        </Link>
      </Typography>
      <Typography variant='w400h14' color='gray.80' align='center'>
        Don’t have an account?{' '}
        <Link to='/join'>
          <Typography variant='w600h14' color='primary'>
            Join CVR
          </Typography>
        </Link>
      </Typography>
    </Stack>
  );
};

const ResetPassword = () => {
  const { resetPassword } = useAuth();

  const navigate = useNavigate();

  const handleSubmit = ({ email }) => {
    resetPassword({ email })
      .then(() => navigate('/check-your-email', { state: { email } }))
      .catch(() => {
        navigate('/check-your-email', { state: { email } });
      });
  };

  return (
    <LoginProcessForm
      title='Reset Password'
      subtitle='NBDF does not process data in relation to the offering of goods or services to individuals in the European Union (EU) and the United Kingdom (UK) and does not monitor the behavior of individuals within the EU and UK .  This community registry is solely for individuals who are not located in the EU and UK, and by registering I hereby certify that I am not an EU or UK resident and am not subject to the General Data Protection Regulation (GDPR).'
      description='Enter your email address associated with your account and we`ll send you a link to reset your password.'
      form={resetPasswordForm}
      onSubmit={handleSubmit}
      footer={<Footer />}
    />
  );
};

export default ResetPassword;

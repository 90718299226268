import { Grid } from '@mui/material';

import MissionCard from 'components/MissionCard';
import useShowBaselineSurvey from 'hooks/useShowBaselineSurvey';
import { useGetParticipantTasksQuery } from 'store/api/nhf/endpoints/participantTasks';
import { useCurrentPersonQuery } from 'store/api/nhf/endpoints/people';
import { MissionTypes, TaskStatus, TaskTitles } from 'utils/enums';

import Layout from './Layout';

const Missions = ({ disabled, ...rest }) => {
  const { data: personData } = useCurrentPersonQuery();
  const personId = personData?.id;
  const { data: completedBaselineSurvey } = useGetParticipantTasksQuery(
    {
      personId,
      query: { title: TaskTitles.BaselineSurvey, status: TaskStatus.Completed },
    },
    { skip: !personId },
  );

  const { show, isLoading: isShowLoading } = useShowBaselineSurvey();
  const { data: hasBaselineSurvey } = useGetParticipantTasksQuery(
    {
      personId,
      query: { title: TaskTitles.BaselineSurvey },
    },
    { skip: !personId || isShowLoading },
  );

  return (
    <Layout>
      <Grid item xs={1}>
        <MissionCard
          type={MissionTypes.JoinCVR}
          count={1}
          total={1}
          comingSoon={false}
        />
      </Grid>
      {show && hasBaselineSurvey?.length > 0 && (
        <Grid item xs={1}>
          <MissionCard
            comingSoon={false}
            type={MissionTypes.BaselineSurvey}
            count={completedBaselineSurvey?.length > 0 ? 1 : 0}
            total={1}
          />
        </Grid>
      )}
      {/* <Grid item xs={1}>
            <MissionCard
              disabled={disabled}
              type={MissionTypes.SurveysCompleted}
              count={0}
              total={5}
            />
          </Grid>
          <Grid item xs={1}>
            <MissionCard
              disabled={disabled}
              type={MissionTypes.InvitationSent}
              count={0}
              total={3}
            />
          </Grid>
          <Grid item xs={1}>
            <MissionCard
              disabled={disabled}
              type={MissionTypes.ReadArticle}
              count={0}
              total={5}
            />
          </Grid> */}
    </Layout>
  );
};
export default Missions;

import { Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Stack spacing='20px' mb='25px'>
      <Typography variant='w400h14' color='gray.80' align='center'>
        Already have an account?{' '}
        <Link to='/login'>
          <Typography variant='w600h14' color='primary'>
            Login
          </Typography>
        </Link>
      </Typography>
    </Stack>
  );
};

export default Footer;

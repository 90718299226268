export const PersonalInformationMock = {
  'First Name': 'Joana',
  'Middle Name': '',
  'Last Name': 'Barlow',
  Email: 'something@gmail.com',
  'Date of Birth': '2-28-1990',
  'Phone number': '999-999-9999',
  'Street Address': '240 Greenwich Street',
  City: 'New York',
  'State or US territory': 'New York',
  Zip: 'Zip',
};

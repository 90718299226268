const generalHealth = [
  {
    title: 'Page 1',
    label: 'Page 1',
    type: 'panel',
    key: 'page1',
    input: false,
    tableView: false,
    components: [
      {
        html: '<strong><h3>General Health Today</h3></strong><strong>The next few questions will help us understand the how persons with bleeding disorders perceive their health-related quality of life to be.</strong><br/><br/>Please tap the ONE box that best describes your health TODAY.<br/><br/>',
        label: 'Content',
        refreshOnChange: false,
        type: 'content',
        input: false,
        tableView: false,
      },
      {
        label: '<strong>MOBILITY</strong>',
        tableView: true,
        optionsLabelPosition: 'right',
        values: [
          {
            value: '1',
            label: 'I have no problems walking',
          },
          {
            value: '2',
            label: 'I have slight problems walking',
          },
          {
            value: '3',
            label: 'I have moderate problems walking',
          },
          {
            value: '4',
            label: 'I have severe problems walking',
          },
          {
            value: '5',
            label: 'I am unable to walk',
          },
        ],
        validate: {
          required: true,
        },
        key: 'GH1',
        type: 'radio',
        input: true,
      },
      {
        html: `
          <div
            style="
              font: var(--typography-w400h12);
              color: var(--palette-gray-60);
              text-align: center;
              margin-top: 20px;
            "
          >
            © EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation. UK (English) v1.0
          </div>`,
        label: 'Content',
        refreshOnChange: false,
        type: 'content',
        input: false,
        tableView: false,
      },
    ],
  },
  {
    title: 'Page 2',
    label: 'Page 2',
    type: 'panel',
    key: 'page2',
    input: false,
    tableView: false,
    components: [
      {
        html: 'Please tap the ONE box that best describes your health TODAY.<br/><br/>',
        label: 'Content',
        refreshOnChange: false,
        type: 'content',
        input: false,
        tableView: false,
      },
      {
        label: '<strong>SELF-CARE</strong>',
        tableView: true,
        optionsLabelPosition: 'right',
        values: [
          {
            value: '1',
            label: 'I have no problems washing or dressing myself',
          },
          {
            value: '2',
            label: 'I have slight problems washing or dressing myself',
          },
          {
            value: '3',
            label: 'I have moderate problems washing or dressing myself',
          },
          {
            value: '4',
            label: 'I have severe problems washing or dressing myself',
          },
          {
            value: '5',
            label: 'I am unable to wash or dress myself',
          },
        ],
        validate: {
          required: true,
        },
        key: 'GH2',
        type: 'radio',
        input: true,
      },
      {
        html: `
          <div
            style="
              font: var(--typography-w400h12);
              color: var(--palette-gray-60);
              text-align: center;
              margin-top: 20px;
            "
          >
            © EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation. UK (English) v1.0
          </div>`,
        label: 'Content',
        refreshOnChange: false,
        type: 'content',
        input: false,
        tableView: false,
      },
    ],
  },
  {
    title: 'Page 3',
    label: 'Page 3',
    type: 'panel',
    key: 'page3',
    input: false,
    tableView: false,
    components: [
      {
        html: 'Please tap the ONE box that best describes your health TODAY.<br/><br/>',
        label: 'Content',
        refreshOnChange: false,
        type: 'content',
        input: false,
        tableView: false,
      },
      {
        label:
          '<strong>USUAL ACTIVITIES</strong> (e.g., work, study, housework, family or leisure activities)',
        tableView: true,
        optionsLabelPosition: 'right',
        values: [
          {
            value: '1',
            label: 'I have no problems doing my usual activities',
          },
          {
            value: '2',
            label: 'I have slight problems doing my usual activities',
          },
          {
            value: '3',
            label: 'I have moderate problems doing my usual activities',
          },
          {
            value: '4',
            label: 'I have severe problems doing my usual activities',
          },
          {
            value: '5',
            label: 'I am unable to do my usual activities',
          },
        ],
        validate: {
          required: true,
        },
        key: 'GH3',
        type: 'radio',
        input: true,
      },
      {
        html: `
          <div
            style="
              font: var(--typography-w400h12);
              color: var(--palette-gray-60);
              text-align: center;
              margin-top: 20px;
            "
          >
            © EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation. UK (English) v1.0
          </div>`,
        label: 'Content',
        refreshOnChange: false,
        type: 'content',
        input: false,
        tableView: false,
      },
    ],
  },
  {
    title: 'Page 4',
    label: 'Page 4',
    type: 'panel',
    key: 'page4',
    input: false,
    tableView: false,
    components: [
      {
        html: 'Please tap the ONE box that best describes your health TODAY.<br/><br/>',
        label: 'Content',
        refreshOnChange: false,
        type: 'content',
        input: false,
        tableView: false,
      },
      {
        label: '<strong>PAIN / DISCOMFORT</strong>',
        tableView: true,
        optionsLabelPosition: 'right',
        values: [
          {
            value: '1',
            label: 'I have no pain or discomfort',
          },
          {
            value: '2',
            label: 'I have slight pain or discomfort',
          },
          {
            value: '3',
            label: 'I have moderate pain or discomfort',
          },
          {
            value: '4',
            label: 'I have severe pain or discomfort',
          },
          {
            value: '5',
            label: 'I have extreme pain or discomfort',
          },
        ],
        validate: {
          required: true,
        },
        key: 'GH4',
        type: 'radio',
        input: true,
      },
      {
        html: `
          <div
            style="
              font: var(--typography-w400h12);
              color: var(--palette-gray-60);
              text-align: center;
              margin-top: 20px;
            "
          >
            © EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation. UK (English) v1.0
          </div>`,
        label: 'Content',
        refreshOnChange: false,
        type: 'content',
        input: false,
        tableView: false,
      },
    ],
  },
  {
    title: 'Page 5',
    label: 'Page 5',
    type: 'panel',
    key: 'page5',
    input: false,
    tableView: false,
    components: [
      {
        html: 'Please tap the ONE box that best describes your health TODAY.<br/><br/>',
        label: 'Content',
        refreshOnChange: false,
        type: 'content',
        input: false,
        tableView: false,
      },
      {
        label: '<strong>ANXIETY / DEPRESSION</strong>',
        tableView: true,
        optionsLabelPosition: 'right',
        values: [
          {
            value: '1',
            label: 'I am not anxious or depressed',
          },
          {
            value: '2',
            label: 'I am slightly anxious or depressed',
          },
          {
            value: '3',
            label: 'I am moderately anxious or depressed',
          },
          {
            value: '4',
            label: 'I am severely anxious or depressed',
          },
          {
            value: '5',
            label: 'I am extremely anxious or depressed',
          },
        ],
        validate: {
          required: true,
        },
        key: 'GH5',
        type: 'radio',
        input: true,
      },
      {
        html: `
          <div
            style="
              font: var(--typography-w400h12);
              color: var(--palette-gray-60);
              text-align: center;
              margin-top: 20px;
            "
          >
            © EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation. UK (English) v1.0
          </div>`,
        label: 'Content',
        refreshOnChange: false,
        type: 'content',
        input: false,
        tableView: false,
      },
    ],
  },
  {
    title: 'Page 6',
    label: 'Page 6',
    type: 'panel',
    key: 'page6',
    input: false,
    tableView: false,
    components: [
      {
        label: 'Table',
        cellAlignment: 'left',
        key: 'table',
        type: 'table',
        input: false,
        tableView: false,
        rows: [
          [
            {
              components: [
                {
                  html: `
                    <ul style="padding-inline-start: 10px;">
                      <li>We would like to know how good or bad your health is TODAY.<br/><br/></li>
                      <li>This scale numbered from 0 to 100.<br/><br/></li>
                      <li>100 means the <u>best</u> health you can imagine.<br/>0 means the <u>worst</u> health you can imagine.<br/><br/></li>
                      <li>Please tap on the scale to indicate how your health is TODAY.<br/><br/></li>
                    </ul>
                  `,
                  label: 'Content',
                  refreshOnChange: false,
                  key: 'PA0',
                  type: 'content',
                  input: false,
                  tableView: false,
                },
                {
                  html: '',
                  label: 'Content',
                  refreshOnChange: false,
                  key: 'GH6label',
                  type: 'content',
                  input: false,
                  tableView: false,
                  logic: [
                    {
                      name: 'Change value',
                      trigger: {
                        type: 'javascript',
                        javascript: 'result = data.GH6 + 1',
                      },
                      actions: [
                        {
                          name: 'Update content',
                          type: 'mergeComponentSchema',
                          schemaDefinition:
                            // eslint-disable-next-line no-template-curly-in-string
                            'schema = { html: `<div style="margin: 5vh auto 0 auto; padding: 5px 10px; width: 100px; background-color: var(--palette-gray-100); text-align: center;"><div style="font: var(--typography-w400h12); color: var(--palette-gray-20);">YOUR HEALTH TODAY = </div><div style="font: var(--typography-w600h20); color: var(--palette-gray-0);">${result - 1}</div></div>` }',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              components: [
                {
                  label: 'How good or bad is your health TODAY',
                  placeholder:
                    'Please enter a number between 0 and 100, where 0 is very bad and 100 is very good',
                  applyMaskOn: 'change',
                  tableView: false,
                  key: 'GH6',
                  type: 'number',
                  hideLabel: true,
                  input: true,
                  defaultValue: 50,
                  slider: true,
                  sliderProps: {
                    min: 0,
                    minLabel: 'The worst health you can imagine',
                    max: 100,
                    maxLabel: 'The best health you can imagine',
                    step: 1,
                  },
                },
              ],
            },
          ],
        ],
        numRows: 1,
        numCols: 2,
      },
      {
        html: `
          <div
            style="
              font: var(--typography-w400h12);
              color: var(--palette-gray-60);
              text-align: center;
              margin-top: 20px;
            "
          >
            © EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation. UK (English) v1.0
          </div>`,
        label: 'Content',
        refreshOnChange: false,
        type: 'content',
        input: false,
        tableView: false,
      },
    ],
  },
];

export default generalHealth;

import NHFModal from 'components/NHFModal';

const ConfirmSubmitModal = ({ open, setOpen, onSubmit, isConfirmLoading }) => (
  <NHFModal
    handleSubmit={onSubmit}
    title='Confirm Submit'
    confirmLabel='Submit'
    open={open}
    setOpen={setOpen}
    isConfirmLoading={isConfirmLoading}
  >
    Are you sure you want to submit your answers? Once you submit, you will not
    be able to return to the survey to make any changes.
  </NHFModal>
);

export default ConfirmSubmitModal;

import {
  Container as MUIContainer,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const AccountSecurityStep = ({ maxWidth, sx, ...rest }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <MUIContainer
      variant={sm ? 'outlined' : 'transparent'}
      sx={{
        maxWidth,
        display: 'flex',
        flexDirection: 'column',
        flex: { xs: 1, sm: 0 },
        ...(sx ?? {}),
      }}
      {...rest}
    />
  );
};

export default AccountSecurityStep;

import { useCallback, useEffect, useRef } from 'react';

const useScrollTools = () => {
  const scrollPosition = useRef(null);

  const handleScrollEvent = useCallback(
    () => (scrollPosition.current = [window.scrollX, window.scrollY]),
    []
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);
    handleScrollEvent();
    return () =>
      window.removeEventListener?.('scroll', handleScrollEvent, {
        passive: true,
      });
  }, [handleScrollEvent]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    handleScrollEvent();
  };

  const restoreScrollPosition = () =>
    window.scrollTo(...scrollPosition.current);

  return { scrollToTop, restoreScrollPosition };
};

export default useScrollTools;

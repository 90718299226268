const MuiMenu = {
  styleOverrides: {
    root: ({ theme }) => ({
      '.MuiMenu-paper': {
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.gray[20]}`,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        padding: '10px 0px',
        boxShadow: 'none',
      },
      '.MuiMenu-paper .MuiMenu-list': {
        padding: 0,
      },
      '.MuiMenu-paper .MuiMenu-list .MuiMenuItem-root': {
        ...theme.typography.w400h14,
        padding: '10px 18px',
        color: theme.palette.gray[100],
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
      },
      '.MuiMenu-paper .MuiMenu-list .MuiMenuItem-root.Mui-selected': {
        ...theme.typography.w600h14,
        color: theme.palette.primary.main,
        backgroundColor: 'unset',
      },
      '.MuiMenu-paper .MuiMenu-list .MuiMenuItem-root:hover': {
        backgroundColor: theme.palette.background.default,
      },
    }),
  },
};

export default MuiMenu;

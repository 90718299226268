import SplashIcons from './splashIcons';

const ListIcons = [
  {
    id: '1',
    icon: SplashIcons.IdIcon,
    title: 'Personalized Data Dashboard',
    subtitle:
      'View your responses and see if they change over time. Learn how they compare across the community.',
  },
  {
    id: '2',
    icon: SplashIcons.HealthIcon,
    title: 'My Health Summary',
    subtitle:
      'Gather your relevant information into a single report and take it to your healthcare provider or be ready for an emergency.',
  },
  {
    id: '3',
    icon: SplashIcons.LaptopIcon,
    title: 'Individualized Community Resources',
    subtitle:
      'Access trustworthy community resources specifically tailored to you.',
  },
  {
    id: '4',
    icon: SplashIcons.FileIcon,
    title: 'Discover Research Opportunities',
    subtitle:
      'Discover other research opportunities that may interest you. Some might compensate you for your time.',
  },
];

export default ListIcons;

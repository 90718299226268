import { memo } from 'react';
import { Box } from '@mui/material';

import Container from 'components/Surveys/Container';
import FormIO from 'components/FormIO';
import baselineSurvey from 'forms/baselineSurvey';

const Baseline = memo(({ injectedValues, onSubmit, onSave, ...rest }) => {
  return (
    <Box m={{ xs: '0', sm: '50px' }}>
      <Container maxWidth='800px' m='50px'>
        <FormIO
          form={{ injectedValues, ...baselineSurvey }}
          onSubmit={onSubmit}
          onSave={onSave}
          template='enrollment'
          unlockSubmit={true}
          {...rest}
        />
      </Container>
    </Box>
  );
});

export default Baseline;

import { Grid, Stack, Typography } from '@mui/material';

const Item = ({ label, content, print }) => (
  <Grid item xs={12} md={print ? 12 : 6}>
    <Stack spacing='6px'>
      <Typography variant='w600h12' color='gray.60'>
        {label}
      </Typography>
      <Typography
        variant='w400h14'
        color='gray.100'
        sx={{ overflowWrap: 'break-word' }}
      >
        <Stack>
          {Array.isArray(content)
            ? content.map(({ id, description }) => (
                <span key={id}>{description}</span>
              ))
            : content}
        </Stack>
      </Typography>
    </Stack>
  </Grid>
);

export default Item;

import { useAuth } from 'context/AuthContext';
import usePersonAttributes from 'hooks/usePersonAttributes';
import { useCheckCurrentUserExistsQuery } from 'store/api/nhf/endpoints/users';
import { useGetPersonStudyRolesQuery } from 'store/api/nhf/endpoints/personStudyRoles';

const useCheckUserExists = () => {
  const { userInfo, isAuthenticated, isLoading: isAuthLoading } = useAuth();
  const { data: userExists, isFetching: isCheckCurrentUsersExistsLoading } =
    useCheckCurrentUserExistsQuery({}, { skip: !userInfo });
  const {
    personId,
    attributes,
    isLoading: isPersonAttributesLoading,
  } = usePersonAttributes({ skip: !userExists });

  const { hasStudyRoles, studyRolesLoading } = useGetPersonStudyRolesQuery(
    { personId },
    {
      skip: !personId || !isAuthenticated,
      selectFromResult: ({ data, isFetching }) => ({
        hasStudyRoles: data?.data?.length !== 0,
        studyRolesLoading: isFetching,
      }),
    }
  );

  const isLoading =
    isAuthLoading ||
    isCheckCurrentUsersExistsLoading ||
    isPersonAttributesLoading ||
    studyRolesLoading;

  return { isLoading, userExists: userExists && attributes && hasStudyRoles };
};

export default useCheckUserExists;

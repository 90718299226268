import { useState } from 'react';
import { Box, Stack, useTheme } from '@mui/material';

import Section from 'components/DataStory/Section';
import TreemapChart from 'components/DataStory/charts/TreemapChart';
import FormIO from 'components/FormIO';
import interferenceFromPain from 'forms/interferenceFromPain';
import interferenceFromPainData from './data';
import { parseData } from './utils';
import { sortTreeMapData } from '../utils';
const InterferenceFromPain = () => {
  const theme = useTheme();
  const [formValue, setFormValue] = useState({});

  const handleChange = ({ bleedingDisorder, howMuch }) =>
    setFormValue({ bleedingDisorder, howMuch });
  const sortedinterferenceFromPainData = sortTreeMapData(
    interferenceFromPainData
  );
  return (
    <Section
      title='Interference from pain'
      description='How much did the pain interfere with CVR population daily activities in the past 7 days?'
    >
      <Stack direction='row' spacing='50px' alignItems='flex-end'>
        <Box sx={{ flex: '1 1 0px' }}>
          <FormIO
            form={interferenceFromPain}
            template='enrollment'
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ flex: '1 1 0px' }}>
          <TreemapChart
            id='InterferenceFromPainTreemapChart'
            data={parseData(theme, sortedinterferenceFromPainData, formValue)}
            height='300px'
          />
        </Box>
      </Stack>
    </Section>
  );
};

export default InterferenceFromPain;

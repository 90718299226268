import { useState } from 'react';

import { Button } from '@mui/material';

import Card from 'components/Dashboard/Card';
import MyInvitationsModal from 'components/NHFModal/MyInvitationsModal';
import { ReactComponent as UserPlus } from 'images/user-plus.svg';

const MyInvitations = (props) => {
  const [openModal, setOpenModal] = useState(false);

  const handleSetOpen = () => setOpenModal(true);

  return (
    <Card
      title='My Invitations'
      description={
        'Know someone who would be interested or benefit from CVR? Invite them to join via the link below.'
      }
      {...props}
    >
      <Button
        variant='text'
        startIcon={<UserPlus />}
        sx={{ width: 'fit-content' }}
        onClick={handleSetOpen}
      >
        Invite
      </Button>
      <MyInvitationsModal open={openModal} setOpen={setOpenModal} />
    </Card>
  );
};

export default MyInvitations;

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import Card from 'components/Dashboard/Card';
import { ReactComponent as ChevronRight } from 'images/chevron-right.svg';

const MyData = (props) => (
  <Card
    title='my data'
    description={
      'This is where you will have access to personalized data where you will be able to see your survey responses over time and compare them with the de-identified and aggregate information of others.'
    }
    {...props}
  >
    <Button
      variant='text'
      component={Link}
      endIcon={<ChevronRight />}
      sx={{ width: 'fit-content' }}
      to='/homepage/data-story'
    >
      My Data
    </Button>
  </Card>
);

export default MyData;

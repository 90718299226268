import React, { useEffect, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import * as d3 from 'd3';
import * as topojson from 'topojson';

import {
  chapters,
  stateToRegionMapping,
  cardinalData,
  HTCs,
  stateAcronym,
  usStateCoordinates,
} from 'components/DataStory/utils';

var tooltip = null;
const BubbleMap = ({
  width,
  height,
  regionsPercetange,
  person,
  isPersonLoading,
}) => {
  const svgRef = useRef(null);
  const legendRef = useRef(null);
  const tooltipRef = useRef(null);

  var tooltipFixed = false;
  const colorScale = d3
    .scaleSequential(d3.interpolateRgb('#250e6280', '#250e62'))
    .domain([0, 100]);

  const handleTooltipToggle = () => {
    tooltipFixed = !tooltipFixed;
  };

  useEffect(() => {
    const topoJSONUrl =
      'https://cdn.jsdelivr.net/npm/us-atlas@3/counties-albers-10m.json';

    d3.json(topoJSONUrl).then((us) => {
      const projection = d3.geoAlbersUsa().scale(1300).translate([487.5, 305]);

      const path = d3.geoPath();
      const svg = d3
        .create('svg')
        .attr('viewBox', '0 0 975 610')
        .attr('width', width)
        .attr('height', height);

      var statesGroup = svg.append('g').attr('class', 'states');
      //draw states
      statesGroup
        .selectAll('.state')
        .data(topojson.feature(us, us.objects.states).features)
        .enter()
        .append('path')
        .attr('class', 'state')
        .attr('d', path)
        .attr('fill', function (d) {
          // Find the corresponding color based on the region's percentage using colorScale
          const regionPercentage =
            regionsPercetange[stateToRegionMapping[d.properties.name]];
          return colorScale(regionPercentage);
        });

      //draws state lines
      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', '#fff')
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr(
          'd',
          path(topojson.mesh(us, us.objects.states, (a, b) => a !== b))
        );
      //draw population radius bubbles with tooltip included
      //add text to circles
      svg
        .selectAll('g text')
        .data(cardinalData)
        .join('text')
        .attr(
          'transform',
          ({ longitude, latitude }) =>
            `translate(${projection([longitude, latitude]).join(',')})`
        )
        .attr('y', 0)
        .attr('x', -50)
        .text(
          ({ description, name }) =>
            `${description} ${regionsPercetange[name]}%`
        )
        .style('font-size', 20)
        .style('font-weight', 800);
      svg
        .append('g')
        .attr('text-anchor', 'middle')
        .attr('font-family', 'sans-serif')
        .attr('font-size', 10)
        .selectAll('g')
        .data(cardinalData)
        .join('g')
        .attr(
          'transform',
          ({ longitude, latitude }) =>
            `translate(${projection([longitude, latitude]).join(',')})`
        )
        .append('circle')
        .attr('r', function (d) {
          const radius = regionsPercetange[d.name] * 3;
          return radius;
        })
        .attr('fill', 'rgba(255, 0, 0, 0.2)')
        .attr('stroke', 'red')
        .on('mouseover', function (event, d) {
          const regionName = d.name;
          const regionChapters = chapters.filter(
            (chapter) => stateToRegionMapping[chapter.State] === regionName
          );
          const regionHTCs = HTCs.filter(
            (htc) => stateToRegionMapping[htc.State] === regionName
          );
          // Show the tooltip with associated chapters
          tooltip
            .style('visibility', 'visible')
            .html(
              `<div id='teste' class="tooltip-content" style=" font-size: 20px">
              <div class="column">
                 <div class="header" style="display: flex; align-items: center; justify-content: space-between;">
                    <h3 style="margin: 0;">${regionName} Chapters</h1>
                    <div>
                    <span class="print-btn" style="cursor: pointer;"><svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.25 6H1.75C0.765625 6 0 6.79297 0 7.75V10.375C0 10.8672 0.382812 11.25 0.875 11.25H1.75V13.875C1.75 14.3672 2.13281 14.75 2.625 14.75H11.375C11.8398 14.75 12.25 14.3672 12.25 13.875V11.25H13.125C13.5898 11.25 14 10.8672 14 10.375V7.75C14 6.79297 13.207 6 12.25 6ZM10.5 13H3.5V10.375H10.5V13ZM11.8125 8.84375C11.4297 8.84375 11.1562 8.57031 11.1562 8.1875C11.1562 7.83203 11.4297 7.53125 11.8125 7.53125C12.168 7.53125 12.4688 7.83203 12.4688 8.1875C12.4688 8.57031 12.168 8.84375 11.8125 8.84375ZM3.5 2.5H9.76172L10.5 3.23828V5.125H12.25V2.88281C12.25 2.63672 12.1406 2.41797 11.9766 2.25391L10.7461 1.02344C10.582 0.859375 10.3633 0.75 10.1172 0.75H2.625C2.13281 0.75 1.75 1.16016 1.75 1.625V5.125H3.5V2.5Z" fill="currentColor"/>
                    </svg></span>
                    <span class="close-btn" style="cursor: pointer; font-size: 20px">X</span>
                    </div>

                 </div>
                 <ul >
                    ${regionChapters
                      .map(
                        (chapter) =>
                          `
                    <li>  <a href="https://${
                      chapter.Website
                    }" target="_blank">${chapter['Organization Name']} (${
                            stateAcronym[chapter.State]
                          })</a>
                    </li>
                    `
                      )
                      .join('')}
                 </ul>
              </div>
              <div class="column">
                 <h3>${regionName} HTCs</h3>
                 <ul>
                    ${regionHTCs
                      .map(
                        (htc) =>
                          `
                    <li>${htc['Facility']} (${stateAcronym[htc.State]})
                    </li>
                    `
                      )
                      .join('')}
                 </ul>
              </div>
           </div>`
            )
            .style('top', `${event.pageY - 100}px`)
            .style(
              'left',
              `${
                regionName === 'East'
                  ? window.innerWidth - event.pageX + 10
                  : event.pageX - 10
              }px`
            );

          const body = d3.select('body');

          // Append a <div> element to act as the backdrop
          const backdrop = body
            .append('div')
            .style('position', 'absolute')
            .style('top', '0')
            .style('left', '0')
            .style('width', '100%')
            .style('height', '1000%')
            .on('click', function () {
              tooltip.style('visibility', 'hidden');
              d3.select(this).remove();
            });

          tooltip.select('.close-btn').on('click', function () {
            backdrop.remove();
            tooltip.style('visibility', 'hidden');
          });
          tooltip.select('.print-btn').on('click', function () {
            const printContainer = document.createElement('div');
            printContainer.innerHTML = tooltip
              .select('.tooltip-content')
              .html();

            // Open print dialog with the content of the printContainer
            const printWindow = window.open('', '_blank');
            printWindow.document.write(
              '<html><head><title>Print</title></head><body>'
            );
            printWindow.document.write(printContainer.innerHTML);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
          });
        });

      const tooltipSvg = d3.select(svgRef.current);
      tooltip = tooltipSvg
        .append('div')
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .style('background-color', 'white')
        .style('border', 'solid')
        .style('border-width', '1px')
        .style('border-radius', '5px')
        .style('padding', '10px')
        .style('z-index', '9');

      // Append the SVG to the DOM
      d3.select('#BubbleMap').selectAll('svg').remove();
      d3.select(svgRef.current).append(() => svg.node());

      // Create a legend SVG group
      d3.select('#BubbleMapLegend').selectAll('*').remove();
      const legendSvg = d3.select(legendRef.current);
      const legendWidth = width - 25;
      const legendHeight = 20;

      // Create the gradient for the legend
      legendSvg
        .append('defs')
        .append('linearGradient')
        .attr('id', 'legendGradient')
        .attr('x1', '0%')
        .attr('x2', '100%')
        .selectAll('stop')
        .data(d3.range(0, 1.1, 0.1))
        .enter()
        .append('stop')
        .attr('offset', (d) => d * 100 + '%')
        .attr('stop-color', (d) => colorScale(d * 80));

      // Create the legend rectangle
      legendSvg
        .append('rect')
        .attr('width', legendWidth)
        .attr('height', legendHeight)
        .attr('x', 10)
        .attr('y', 20)
        .style('fill', 'url(#legendGradient)');

      // Create scale for the legend
      const legendScale = d3
        .scaleLinear()
        .domain([0, 1])
        .range([0, legendWidth]);

      // Create and append the legend axis
      const legendAxis = d3.axisBottom(legendScale).ticks(5, '%');
      legendSvg
        .append('g')
        .attr('transform', `translate(10, ${legendHeight + 20})`)
        .call(legendAxis);
      legendSvg
        .append('text')
        .text('Participant population')
        .attr('transform', `translate(100, 10)`)
        .style('text-anchor', 'middle')
        .style('font-size', 15)
        .style('font-weight', 800)
        .style('font-family', 'sans-serif')
        .style('fill', 'black');
      if (person) {
        const personState = person.state.value;
        const { latitude, longitude } =
          usStateCoordinates[stateAcronym[personState]];

        // Select the state circle group
        // Add a rectangle
        svg
          .append('g')
          .selectAll('g')
          .data([{ latitude, longitude }])
          .join('g')
          .attr(
            'transform',
            ({ latitude, longitude }) =>
              `translate(${projection([longitude, latitude]).join(',')})`
          )
          .append('rect')
          .attr('x', -35)
          .attr('y', -50)
          .attr('width', 70)
          .attr('height', 42)
          .attr('fill', 'grey');

        // Add a triangle pointer
        svg
          .append('g')
          .selectAll('g')
          .data([{ latitude, longitude }])
          .join('g')
          .attr(
            'transform',
            ({ latitude, longitude }) =>
              `translate(${projection([longitude, latitude]).join(',')})`
          )
          .append('path')
          .attr('d', 'M 0,0 L 5,-10 L -5,-10 Z')
          .attr('fill', 'grey');

        // Add the text "You" inside the bubble
        svg
          .append('g')
          .selectAll('g')
          .data([{ latitude, longitude }])
          .join('g')
          .attr(
            'transform',
            ({ latitude, longitude }) =>
              `translate(${projection([longitude, latitude]).join(',')})`
          )
          .append('text')
          .text('You')
          .attr('dy', -22)
          .style('text-anchor', 'middle')
          .style('font-size', 20)
          .style('font-weight', 800)
          .style('font-family', 'sans-serif')
          .style('fill', 'black');
      }
    });
  }, [height, regionsPercetange, tooltipFixed, width, person, colorScale]);

  if (isPersonLoading) return <CircularProgress />;
  return (
    <>
      <div id='BubbleMap' onClick={handleTooltipToggle} ref={svgRef}></div>
      <svg id='BubbleMapLegend' width={width} height='70' ref={legendRef} />
      <svg id='tooltip' width='1' height='1' ref={tooltipRef} />
    </>
  );
};

export default BubbleMap;

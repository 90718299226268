const colorMapping = (theme) => ({
  always: theme.palette.nbdfBlue[100],
  often: theme.palette.honeyFlower[100],
  sometimes: theme.palette.tamarillo[100],
  rarely: theme.palette.orient[100],
  never: theme.palette.salmon[100],
});

const labelMapping = () => ({
  always: 'Always',
  often: 'Often',
  sometimes: 'Sometimes',
  rarely: 'Rarely',
  never: 'Never',
});

const orderMapping = () => ({
  always: 0,
  often: 1,
  sometimes: 2,
  rarely: 3,
  never: 4,
});

export const parseData = (
  theme,
  data,
  { bleedingDisorder, howOften } = {}
) => ({
  children: Object.entries(data[bleedingDisorder]?.[howOften] ?? {})
    ?.map?.(([key, value]) => ({
      color: colorMapping(theme)[key],
      name: labelMapping()[key],
      value,
    }))
    ?.sort?.(({ order: lhs }, { order: rhs }) => lhs - rhs),
});

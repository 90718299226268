import {
  useGetParticipantTaskQuery,
  useGetParticipantTasksQuery,
} from 'store/api/nhf/endpoints/participantTasks';
import { useCurrentPersonQuery } from 'store/api/nhf/endpoints/people';
import { TaskTitles } from 'utils/enums';

const useGetBaseline = () => {
  const { data: personData } = useCurrentPersonQuery();
  const personId = personData?.id;
  const { baselineId, isBaselineIdLoading } = useGetParticipantTasksQuery(
    {
      personId,
      query: { title: TaskTitles.BaselineSurvey },
    },
    {
      skip: !personId,
      selectFromResult: ({ data, isFetching }) => ({
        baselineId: data?.[0],
        isBaselineIdLoading: isFetching,
      }),
    },
  );

  const { data, instrument, isBaselineLoading } = useGetParticipantTaskQuery(
    { personId, participantTaskId: baselineId },
    {
      skip: !personId || !baselineId,
      selectFromResult: ({ data, isFetching }) => ({
        data: data?.data?.participant_task_entry?.data,
        instrument: data?.data?.task?.version?.instrument,
        isBaselineLoading: isFetching,
      }),
    },
  );

  const isLoading = isBaselineIdLoading || isBaselineLoading;
  return { data, instrument, isLoading };
};

export default useGetBaseline;

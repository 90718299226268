import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

import Container from 'components/Enrollment/Container';
import Content from './Content';
import Layout from './Layout';
import {
  useCurrentPersonQuery,
  useUpdatePersonMutation,
} from 'store/api/nhf/endpoints/people';
import { PersonStatus, PersonStatusReason, SnackbarError } from 'utils/enums';
import { openSnackbar } from 'store/slices/application';
import { useDispatch } from 'react-redux';
import { useAuth } from 'context/AuthContext';
import { CircularProgress } from '@mui/material';

const ThankYouStep = () => {
  const { width, height } = useWindowSize();
  const dispatch = useDispatch();
  const { isLoading: isAuthLoading, isAuthenticated } = useAuth();
  const { data: person, isFetching: isPersonLoading } = useCurrentPersonQuery(
    {},
    { skip: !isAuthenticated }
  );
  const personId = person?.id;

  const [updatePerson, { isLoading: isUpdatePersonLoading }] =
    useUpdatePersonMutation();

  const handleGoToDashboard = async () => {
    const { error } = await updatePerson({
      personId: personId.toString(),
      attributes: {
        status: PersonStatus.Onboarded,
        statusReason: PersonStatusReason.Onboarded,
      },
    });
    if (error) dispatch(openSnackbar(SnackbarError.TryAgain()));
  };

  const isLoading = isAuthLoading || isPersonLoading || isUpdatePersonLoading;

  if (isLoading) return <CircularProgress />;

  return (
    <Container maxWidth='600px'>
      <Layout>
        <Content onClick={handleGoToDashboard} />
      </Layout>
      <Confetti width={width} height={height} />
    </Container>
  );
};

export default ThankYouStep;

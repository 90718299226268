import { Box } from '@mui/material';

import Container from 'components/Surveys/Container';

const Layout = ({ children }) => (
  <Box m={{ xs: '0', sm: '50px' }}>
    <Container maxWidth='800px' m='50px'>
      {children}
    </Container>
  </Box>
);

export default Layout;

import { useCallback, useRef, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { cloneDeep, isArray, isEqual, isObject, transform } from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ConfirmSubmitModal from 'components/Surveys/ConfirmSubmitModal';
import ThankYou from 'components/Surveys/ThankYou';
import {
  useGetParticipantTaskQuery,
  useUpdateParticipantTaskEntryMutation,
} from 'store/api/nhf/endpoints/participantTasks';
import { useCurrentPersonQuery } from 'store/api/nhf/endpoints/people';
import { useGetPersonAttributesQuery } from 'store/api/nhf/endpoints/personAttributes';
import { openSnackbar } from 'store/slices/application';
import {
  SnackbarError,
  SnackbarInfo,
  SnackbarSuccess,
  TaskStatus,
} from 'utils/enums';
import parseJson from 'utils/parseJson';

import Form from './Form';

const BaselineSurvey = ({ id }) => {
  const navigate = useNavigate();
  const [end, setEnd] = useState(false);
  const [formData, setFormData] = useState(false);
  const dispatch = useDispatch();

  const { data: personData, isFetching: isPersonLoading } =
    useCurrentPersonQuery();
  const personId = personData?.id;

  const {
    isCompleted,
    participantTaskEntryId,
    participantTaskEntry,
    isDefaultValuesLoading,
    instrument,
  } = useGetParticipantTaskQuery(
    { personId, participantTaskId: id },
    {
      skip: !personId || !id,
      selectFromResult: ({ data, isLoading }) => ({
        isCompleted: data?.data?.status === TaskStatus.Completed,
        participantTaskEntryId: data?.data?.participant_task_entry?.id,
        participantTaskEntry: data?.data?.participant_task_entry,
        isDefaultValuesLoading: isLoading,
        instrument: data?.data?.task?.version?.instrument,
      }),
    },
  );

  let defaultValues = transform(
    cloneDeep(participantTaskEntry?.data),
    function (result, value, key) {
      if (isArray(value)) {
        if (value.length === 0) {
          value = {};
        } else {
          value = value.every((data) => isObject(data))
            ? value
            : Object.assign(
                {},
                ...value.map((data, index) => ({ [index]: data })),
              );
        }
      }
      result[key] = value;
    },
    {},
  );

  const { R8, R17, R18, R19, R20, isAttrLoading } = useGetPersonAttributesQuery(
    { personId },
    {
      skip: !personId,
      selectFromResult: ({ data, isFetching }) => ({
        isAttrLoading: isFetching,
        R8: data?.sex_at_birth?.value,
        R17: data?.bd_hist_symptoms?.value,
        R18: data?.bleeding_disorder?.value,
        R19: parseJson(data?.bleeding_disorder_diag?.value),
        R20: data?.vwd_type?.value,
      }),
    },
  );
  const injectedValues = { R8, R17, R18, R19, R20 };

  // This is a combination of all loading flags from all queries and mutations
  const isLoading = isPersonLoading || isAttrLoading || isDefaultValuesLoading;
  const [
    updateParticipantTaskEntry,
    { isLoading: isUpdateParticipantTaskEntryLoading },
  ] = useUpdateParticipantTaskEntryMutation();
  const submittingParticipantTaskEntryRef = useRef(false);
  const savingParticipantTaskEntryRef = useRef(false);

  const handleTrySubmit = useCallback((data) => setFormData(data), []);

  const handleSubmit = async () => {
    if (isCompleted) return;
    if (!submittingParticipantTaskEntryRef.current) {
      submittingParticipantTaskEntryRef.current = true;
      const payload = {
        personId,
        participantTaskId: id,
        data: formData,
        status: TaskStatus.Completed,
        participantTaskEntryId,
      };

      try {
        const { error } = await updateParticipantTaskEntry(payload);
        if (!error) {
          setFormData(false);
          setEnd(true);
        }
      } catch {
        dispatch(openSnackbar(SnackbarError.TryAgain()));
      } finally {
        submittingParticipantTaskEntryRef.current = false;
      }
    }
  };

  const handleSave = useCallback(
    async (data) => {
      if (isCompleted) return;

      if (
        !savingParticipantTaskEntryRef.current &&
        !isEqual(data, defaultValues)
      ) {
        savingParticipantTaskEntryRef.current = true;
        const payload = {
          personId,
          participantTaskId: id,
          data,
          status: TaskStatus.Inprogress,
          participantTaskEntryId,
        };
        try {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          defaultValues = cloneDeep(data);
          dispatch(openSnackbar(SnackbarInfo.FormSent()));
          if (!isCompleted) {
            await updateParticipantTaskEntry({
              ...payload,
              status: TaskStatus.Incomplete,
            });
            await updateParticipantTaskEntry({
              ...payload,
              status: TaskStatus.Inprogress,
            });
            dispatch(openSnackbar(SnackbarSuccess.FormSaved()));
          }
        } catch {
          dispatch(openSnackbar(SnackbarError.TryAgain()));
        } finally {
          savingParticipantTaskEntryRef.current = false;
        }
      }
    },
    [
      dispatch,
      id,
      isCompleted,
      participantTaskEntryId,
      personId,
      updateParticipantTaskEntry,
    ],
  );

  const handleCancel = useCallback(() => navigate('/homepage'), [navigate]);

  if (end) return <ThankYou surveyName='Baseline Survey' />;

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <Form
        injectedValues={injectedValues}
        instrument={instrument}
        defaultValues={defaultValues}
        onSubmit={handleTrySubmit}
        onSave={handleSave}
        onCancel={handleCancel}
        isCompleted={isCompleted}
      />
      <ConfirmSubmitModal
        onSubmit={handleSubmit}
        open={formData}
        setOpen={setFormData}
        isConfirmLoading={isUpdateParticipantTaskEntryLoading}
      />
    </>
  );
};

export default BaselineSurvey;

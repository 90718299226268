import { useEffect, useState } from 'react';

import Html2Pdf from 'js-html2pdf';

const useDownload = (ref, filename) => {
  const [downloading, setDownloading] = useState(false);
  const download = () => setDownloading(true);
  useEffect(() => {
    const handleDownload = async () => {
      if (downloading) {
        const exporter = new Html2Pdf(ref.current, { filename });
        await exporter.getPdf(true);
        setDownloading(false);
      }
    };
    handleDownload();
  }, [downloading, filename, ref]);

  return { download, downloading };
};

export default useDownload;

import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material';
import * as d3 from 'd3';

const piechartColors = (theme) => ({
  hemophiliaA: theme.palette.nbdfBlue[100],
  hemophiliaB: theme.palette.tamarillo[100],
  vonWillebrandDisease: theme.palette.honeyFlower[100],
  ultraRare: theme.palette.chestnutRose[100],
});
const ageGroups = {
  hemophiliaA: { 18: '11%', 33: '16%', 42: '11%', 52: '13%' },
  hemophiliaB: { 18: '5%', 33: '6%', 42: '5%', 52: '4%' },
  vonWillebrandDisease: { 18: '4%', 33: '3%', 42: '4%', 52: '4%' },
  ultraRare: { 18: '4%', 33: '4%', 42: '3%', 52: '3%' },
};
const maleFemaleRatio = {
  hemophiliaA: { name: 'Hemophilia A', male: '68%', female: '32%' },
  hemophiliaB: { name: 'Hemophilia B', male: '77%', female: '23%' },
  vonWillebrandDisease: {
    name: 'Von Willebrand Disease',
    male: '8%',
    female: '92%',
  },
  ultraRare: {
    name: 'Ultra-rare bleeding disorder',
    male: '16%',
    female: '84%',
  },
};
const PieChart = ({ data, width, height, person, isPersonLoading }) => {
  const theme = useTheme();
  const svgRef = useRef(null);

  useEffect(() => {
    const margin = 100;
    // The radius of the pie plot is half the width or half the height (smallest one). Subtract a bit of margin.
    const radius = Math.min(width, height) / 2 - margin;
    d3.select('#PieChart').selectAll('svg').remove();

    // append the SVG object to the div with the ref '
    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Compute the position of each group on the pie
    const pie = d3.pie().value(function (d) {
      return d[1];
    });
    const data_ready = pie(Object.entries(data));

    // shape helper to build arcs
    const arcGenerator = d3.arc().innerRadius(0).outerRadius(radius);
    //Create tooltip according to the graph slice
    const tooltip = d3
      .select(svgRef.current)
      .append('div')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '10px');
    svg
      .selectAll('mySlices')
      .data(data_ready)
      .join('path')
      .attr('d', arcGenerator)
      .attr('fill', function (d) {
        return piechartColors(theme)[d.data[0]];
      })

      .on('mouseover', function (event, d) {
        // Display tooltip on mouseover
        tooltip
          .html(
            `<table style="text-align: center; border-collapse: collapse;">
            <tbody>
               <tr>
                  <th colspan="2"> <svg height="12" width="12">
                  <circle cx="6" cy="6" r="6" style="fill: ${
                    piechartColors(theme)[d.data[0]]
                  };"></circle>
                </svg>  ${maleFemaleRatio[d.data[0]].name}</th>
               </tr>
               <tr style=" border-bottom: 2px solid #D6D6D6;">
                  <th style="padding: 10px;">Male</th>
                  <th style="padding: 10px;">Female</th>
               </tr>
               <tr>
                  <td style="padding: 10px;">${
                    maleFemaleRatio[d.data[0]].male
                  }</td>
                  <td style="padding: 10px;">${
                    maleFemaleRatio[d.data[0]].female
                  } </td>
               </tr>
               <tr style="border-top: 2px solid #D6D6D6; ">
               <th style="padding: 10px; font-weight: 900;">%</th>
               <th style="padding: 10px;">Age</th>
               </tr>
               <tr>
                  <td >${ageGroups[d.data[0]]['18']}</td>
                  <td >18 - 32</td>
               </tr>
               <tr>
                  <td >${ageGroups[d.data[0]]['33']}</td>
                  <td >33 - 41</td>
               </tr>
               <tr>
                  <td >${ageGroups[d.data[0]]['42']}</td>
                  <td >42 - 52</td>
               </tr>
               <tr>
                  <td >${ageGroups[d.data[0]]['52']}</td>
                  <td >53 or older</td>
               </tr>
            </tbody>
         </table>`
          )
          .style('visibility', 'visible')
          .style('top', `${event.pageY}px`)
          .style('left', `${event.pageX}px`);
      })
      .on('mouseout', function () {
        // Hide tooltip on mouseout
        tooltip.style('visibility', 'hidden');
      });
    //add percentage of the population text
    svg
      .selectAll('mySlices')
      .data(data_ready)
      .join('text')
      .text(function (d) {
        return d.data[1] + '%';
      })
      .attr('transform', function (d) {
        return `translate(${arcGenerator.centroid(d)})`;
      })
      .style('text-anchor', 'middle')
      .style('font-size', 20)
      .style('font-weight', 800)
      .style('fill', 'white');

    const youIdentifierData = () => {
      if (person?.bleeding_disorder_diag?.value) {
        const diseases = JSON.parse(person.bleeding_disorder_diag.value);
        var filteredData = [];

        for (const property in diseases) {
          if (property === 'hemophiliaA' && diseases[property] === true) {
            filteredData.push(data_ready[0]);
          } else if (
            property === 'hemophiliaB' &&
            diseases[property] === true
          ) {
            filteredData.push(data_ready[1]);
          } else if (
            property === 'vonWillebrandDisease' &&
            diseases[property] === true
          ) {
            filteredData.push(data_ready[2]);
          } else if (diseases[property] === true) {
            filteredData.push(data_ready[3]);
            break;
          }
        }
        return filteredData;
      }
      return [data_ready[0]];
    };
    //Creates rectangle of the you bubble
    svg
      .selectAll('mySlicesRect')
      .data(youIdentifierData)
      .join('rect')
      .attr('x', function (d) {
        const centroid = arcGenerator.centroid(d);
        return centroid[0] - 25; // Adjust the x-coordinate to position the rectangle
      })
      .attr('y', function (d) {
        const centroid = arcGenerator.centroid(d);
        return centroid[1] - 60; // Adjust the y-coordinate to position the rectangle
      })
      .attr('width', 50)
      .attr('height', 30)
      .attr('fill', 'grey');
    //Creates triangle of the you bubble
    svg
      .selectAll('mySlicesTriangle')
      .data(youIdentifierData)
      .join('path')
      .attr('d', 'M 0,0 L 5,-10 L -5,-10 Z')
      .attr('fill', 'grey')
      .attr('transform', function (d) {
        const centroid = arcGenerator.centroid(d);
        return `translate(${centroid[0]}, ${centroid[1] - 20})`; // Adjust vertical position for the triangle
      });
    //Creates text of the you bubble
    svg
      .selectAll('mySlicesYou')
      .data(youIdentifierData)
      .join('text')
      .text('You')
      .attr('x', function (d) {
        const centroid = arcGenerator.centroid(d);
        return centroid[0]; // Adjust the x-coordinate to position the rectangle
      })
      .attr('y', function (d) {
        const centroid = arcGenerator.centroid(d);
        return centroid[1] - 40; // Adjust the y-coordinate to position the rectangle
      })
      .style('text-anchor', 'middle')
      .style('font-size', 12)
      .style('font-weight', 800)
      .style('font-family', 'sans-serif')
      .style('fill', 'white');
    //Creates Legend circle followed by text
    const legendLocations = [20, 130, 240, 400];
    const legendTexts = [
      'Hemophilia A',
      'Hemophilia B',
      'Von Willebrand Disease',
      'Ultra-rare bleeding disorders',
    ];
    const legendColors = Object.values(piechartColors(theme));
    for (let index = 0; index < legendLocations.length; index++) {
      svg
        .append('circle')
        .attr('cx', -width / 2 + legendLocations[index] - 10)
        .attr('cy', height / 2 - 50)
        .attr('r', 6)
        .style('fill', legendColors[index]);
      svg
        .append('text')
        .attr('x', -width / 2 + legendLocations[index])
        .attr('y', height / 2 - 50)
        .text(legendTexts[index])
        .style('font-size', '12px')
        .style('font-weight', '600')
        .attr('alignment-baseline', 'middle');
    }
  }, [data, width, height, person, isPersonLoading, theme]);

  return (
    <div>
      <div id='PieChart' ref={svgRef}></div>
    </div>
  );
};

export default PieChart;

import nhfApi from 'store/api/nhf';
import { NhfApiVersions, RequestMethods } from 'utils/enums';

const personStudyRoles = nhfApi.injectEndpoints({
  endpoints: (build) => ({
    getPersonStudyRoles: build.query({
      query: ({ personId }) => ({
        url: `/people/${personId}/study-roles`,
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Get,
      }),
      providesTags: ['PersonStudyRoles'],
    }),
    createPersonStudyRole: build.mutation({
      query: ({ personId, studyRoleId }) => ({
        url: `/people/${personId}/study-roles`,
        apiVersion: NhfApiVersions.V3,
        method: RequestMethods.Post,
        body: {
          data: {
            type: 'person-study-roles',
            attributes: {
              personId,
              studyRoleId,
              siteId: 1,
            },
          },
        },
      }),
      invalidatesTags: ['PersonStudyRoles'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetPersonStudyRolesQuery, useCreatePersonStudyRoleMutation } =
  personStudyRoles;

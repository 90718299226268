import usCountiesStates from 'mocks/usCountiesStates';
const personalInformation = {
  display: 'wizard',
  navBar: {
    submitLabel: 'Save',
    saveLabel: 'cancel',
  },
  components: [
    {
      title: 'Page 1',
      key: 'page1',
      type: 'panel',
      input: false,
      tableView: true,
      components: [
        {
          label: 'First Name',
          placeholder: 'Enter Name',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
            maxLength: 100,
            custom:
              'valid = input.match(/^[a-zA-Z]{1,100}([a-zA-Z\'\\-\\s]{1,98}[a-zA-Z])?$/u) ? true : "You can\'t use special characters in the beginning or the end of your name, the limit of characters is 100."',
          },
          key: 'first_name',
          type: 'textfield',
          input: true,
        },
        {
          label: 'Middle Name',
          placeholder: 'Enter Name',
          applyMaskOn: 'change',
          tableView: true,
          key: 'middle_name',
          type: 'textfield',
          validate: {
            maxLength: 100,
            custom:
              'valid = input.match(/^[a-zA-Z]{1,100}([a-zA-Z\'\\-\\s]{1,98}[a-zA-Z])?$/u) || input.length === 0 ? true : "You can\'t use special characters in the beginning or the end of your name, the limit of characters is 100."',
          },
          input: true,
        },
        {
          label: 'Last Name',
          placeholder: 'Enter Name',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
            maxLength: 100,
            custom:
              'valid = input.match(/^[a-zA-Z]{1,100}([a-zA-Z\'\\-\\s]{1,98}[a-zA-Z])?$/u) ? true : "You can\'t use special characters in the beginning or the end of your name, the limit of characters is 100."',
          },
          key: 'last_name',
          type: 'textfield',
          input: true,
        },
        {
          label: 'Email',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          disabled: true,
          tableView: true,
          validate: {
            required: true,
          },
          kickbox: {
            enabled: true,
          },
          key: 'email',
          type: 'email',
          input: true,
        },
        {
          label: 'Alternative Email address',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          tableView: true,
          kickbox: {
            enabled: true,
          },
          key: 'alternative_email',
          type: 'email',
          input: true,
        },
        {
          label: 'Date of Birth',
          format: 'MM/dd/yyyy',
          placeholder: 'Select',
          tableView: false,
          datePicker: {
            disableWeekends: false,
            disableWeekdays: false,
            maxDate: 'moment().subtract(18, "years")',
          },
          refreshOn: 'state',
          enableTime: false,
          validate: {
            required: true,
          },
          enableMinDateInput: false,
          enableMaxDateInput: true,
          key: 'date_of_birth',
          type: 'datetime',
          disabled: true,
          input: true,
          widget: {
            type: 'calendar',
            displayInTimezone: 'viewer',
            locale: 'en',
            useLocaleSettings: false,
            allowInput: true,
            mode: 'single',
            enableTime: false,
            noCalendar: false,
            format: 'MM/dd/yyyy',
            hourIncrement: 1,
            minuteIncrement: 1,
            time_24hr: false,
            minDate: null,
            disableWeekends: false,
            disableWeekdays: false,
            maxDate: 'moment().subtract(18, "years")',
          },
        },
        {
          label: 'Primary phone Number',
          placeholder: 'Enter Phone',
          inputMask: '(999) 999-9999',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
          },
          key: 'phone',
          type: 'textfield',
          input: true,
        },
        {
          label: 'Mobile Phone Number',
          placeholder: 'Enter Phone',
          inputMask: '(999) 999-9999',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: false,
          },
          key: 'home_phone',
          type: 'textfield',
          input: true,
        },
        {
          label: 'Street address',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
          },
          key: 'address',
          type: 'textfield',
          input: true,
        },
        {
          label: 'Apartment Number',
          placeholder: 'Enter',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            maxLength: 255,
          },
          key: 'apt_number',
          type: 'textfield',
          input: true,
        },
        {
          label: 'City',
          placeholder: 'Enter',
          tableView: true,
          validate: {
            required: true,
            maxLength: 255,
            custom: `valid = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/.test(input) ? true : "City is required and City must have no more than 255 characters."`,
          },
          key: 'city',
          type: 'textfield',
          input: true,
        },
        {
          label: 'State or US territory',
          widget: 'choicesjs',
          placeholder: 'Select state',
          tableView: true,
          disabled: true,
          data: {
            values: Object.keys(usCountiesStates).map((key) => ({
              label: key,
              value: key,
            })),
          },
          validate: {
            required: true,
          },
          key: 'state',
          type: 'select',
          input: true,
        },
        {
          label: 'Zip Code',
          placeholder: 'Enter',
          inputMask: '99999',
          applyMaskOn: 'change',
          tableView: true,
          validate: {
            required: true,
          },
          key: 'postal_code',
          type: 'textfield',
          input: true,
        },
        {
          label: 'County',
          widget: 'choicesjs',
          placeholder: 'Select',
          tableView: true,
          dataSrc: 'custom',
          data: {
            custom: `values = (JSON.parse(${JSON.stringify(
              JSON.stringify(usCountiesStates)
            )})[data.state] ?? []).map(county => ({ label: county, value: county }))`,
          },
          valueProperty: 'value',
          refreshOn: 'state',
          clearOnRefresh: true,
          validate: {
            required: true,
          },
          key: 'county',
          conditional: {
            show: false,
            when: 'state',
          },
          type: 'select',
          input: true,
        },
      ],
    },
  ],
};

export default personalInformation;

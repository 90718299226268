const MuiCard = {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      borderRadius: '10px',
      backgroundColor: ownerState.color ?? theme.palette.gray[10],
    }),
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: ({ theme, ownerState }) => ({
        backgroundColor: ownerState.disabled
          ? theme.palette.background.default
          : theme.palette.background.paper,
        border: `1px solid ${ownerState.color ?? theme.palette.gray[10]}`,
      }),
    },
  ],
};

export default MuiCard;

import { Grid } from '@mui/material';

import TaskCard from 'components/TaskCard';
import useShowBaselineSurvey from 'hooks/useShowBaselineSurvey';
import { useGetParticipantTasksQuery } from 'store/api/nhf/endpoints/participantTasks';
import { useCurrentPersonQuery } from 'store/api/nhf/endpoints/people';
import { TaskTitles } from 'utils/enums';

import Layout from './Layout';
import Skeleton from './Skeleton';

const MyTasks = ({ props }) => {
  const { data, isFetching: isPersonLoading } = useCurrentPersonQuery();
  const personId = data?.id;

  const { show, isLoading: isShowLoading } = useShowBaselineSurvey();
  const { data: participantTasks, isLoading: isParticipantTasksLoading } =
    useGetParticipantTasksQuery(
      {
        personId,
        excludeQuery: show ? {} : { title: TaskTitles.BaselineSurvey },
      },
      { skip: !personId || isShowLoading },
    );

  const isLoading =
    isPersonLoading || isParticipantTasksLoading || isShowLoading;

  if (isLoading) return <Skeleton />;

  return (
    <Layout {...props}>
      {participantTasks?.map?.((id) => (
        <Grid key={id} item xs={1}>
          <TaskCard id={id} />
        </Grid>
      ))}
    </Layout>
  );
};

export default MyTasks;

import { createSlice } from '@reduxjs/toolkit';

import { resetAll } from 'store/slices/reset';
import { AlertSeverity } from 'utils/enums';

const intendedRoute = '';
const snackbar = {
  open: false,
  type: AlertSeverity.Success,
  message: '',
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
};

const initialState = {
  intendedRoute,
  snackbar,
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setIntendedRoute: (state, action) => {
      state.intendedRoute = action.payload;
    },
    openSnackbar: (state, action) => {
      state.snackbar.type = action.payload.type;
      state.snackbar.message = action.payload.message;
      state.snackbar.open = true;
      state.snackbar.anchorOrigin = action.payload.anchorOrigin || {
        vertical: 'bottom',
        horizontal: 'right',
      };
    },
    closeSnackbar: (state) => {
      state.snackbar.open = false;
      state.snackbar = snackbar;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(resetAll.type, () => initialState),
});

export const selectSnackbar = () => (state) => state.application.snackbar;

export const selectIntendedRoute = () => (state) =>
  state.application.intendedRoute;

export const { setIntendedRoute, openSnackbar, closeSnackbar } =
  applicationSlice.actions;

export default applicationSlice;

const formSS = {
  title: 'Form SS',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'SS',
  type: 'panel',
  label: 'Form SS',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Social Support</strong>',
      label: 'Content',
      refreshOnChange: false,
      key: 'SS0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'Please rate each of the following statements.',
      tableView: false,
      questions: [
        {
          label: 'I have people who care what happens to me.',
          value: 'SS1',
          tooltip: '',
        },
        {
          label: 'I get love and affection.',
          value: 'SS2',
          tooltip: '',
        },
        {
          label:
            'I get chances to talk to someone about problems at work or with my housework.',
          value: 'SS3',
          tooltip: '',
        },
        {
          label:
            'I get chances to talk to someone I trust about my personal or family problems.',
          value: 'SS4',
          tooltip: '',
        },
        {
          label: 'I get chances to talk about money matters.',
          value: 'SS5',
          tooltip: '',
        },
        {
          label: 'I get invitations to go out and do things with other people.',
          value: 'SS6',
          tooltip: '',
        },
        {
          label: 'I get useful advice about important things in life.',
          value: 'SS7',
          tooltip: '',
        },
        {
          label: 'I get help when I am sick in bed.',
          value: 'SS8',
          tooltip: '',
        },
      ],
      values: [
        {
          label: 'As much as I would like',
          value: '5',
          tooltip: '',
        },
        {
          label: 'Almost as much as I would like',
          value: '4',
          tooltip: '',
        },
        {
          label: 'Some, but would like more',
          value: '3',
          tooltip: '',
        },
        {
          label: 'Less than I would like',
          value: '2',
          tooltip: '',
        },
        {
          label: 'Much less than I would like',
          value: '1',
          tooltip: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'SS1_SS8',
      type: 'survey',
      input: true,
    },
    {
      label:
        'How often do you see or talk to people that you care about and feel close to? (For example: talking to friends on the phone, visiting friends or family, going to church or club meetings)',
      optionsLabelPosition: 'bottom',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Less than once a week',
          value: '1',
          shortcut: '',
        },
        {
          label: '1 or 2 times a week',
          value: '2',
          shortcut: '',
        },
        {
          label: '3 to 5 times a week',
          value: '3',
          shortcut: '',
        },
        {
          label: '5 or more times a week',
          value: '4',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'SS9',
      type: 'radio',
      input: true,
    },
  ],
};

export default formSS;

import { memo } from 'react';
import { isEqual } from 'lodash';

import FormIO from 'components/FormIO';
import Layout from './Layout';

const Form = memo(
  ({
    injectedValues,
    instrument,
    defaultValues,
    onSubmit,
    onSave,
    onCancel,
    isCompleted,
  }) => {
    return (
      <Layout>
        <FormIO
          form={{ injectedValues, ...instrument }}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          onSave={onSave}
          onNextPage={onSave}
          onCancel={onCancel}
          template='enrollment'
          readOnly={isCompleted}
          unlockSubmit={true}
        />
      </Layout>
    );
  },
  (oldProps, newProps) =>
    oldProps.isCompleted === newProps.isCompleted &&
    oldProps.onSubmit === newProps.onSubmit &&
    oldProps.onSave === newProps.onSave &&
    isEqual(oldProps.injectedValues, newProps.injectedValues) &&
    isEqual(oldProps.instrument, oldProps.instrument)
);

export default Form;

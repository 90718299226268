import { Grid, Stack, Typography, useTheme } from '@mui/material';

const ContentText = ({ title, subtitle, sizeDownSubtitle }) => {
  const theme = useTheme();
  return (
    <Grid item xs={12} md={4} mb={2}>
      <Stack>
        <Typography
          color='gray.60'
          sx={{
            fontSize: '0.75rem',
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {title}
        </Typography>
        <Typography
          color='gray.100'
          sx={{
            fontSize: sizeDownSubtitle ? '0.75rem' : '0.85rem',
            fontWeight: theme.typography.fontWeightLight,
          }}
        >
          {subtitle}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default ContentText;

import { Link } from 'react-router-dom';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import { ReactComponent as ArrowDownIcon } from 'images/arrow-down.svg';
import heroBackgroundImage from 'images/SplashPage/backgrounds/hero-background-image.png';

const Hero = ({onClickHandler}) => (
  <Grid container>
    <Grid xs={12} md={6} item bgcolor='background.default'>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        minHeight='100%'
      >
        <Box
          sx={{
            textAlign: { xs: 'center', md: 'left' },
            paddingX: { xs: 2, md: 21 },
            paddingY: { xs: 17.5, md: 0 },
          }}
        >
          <Box>
            <Typography
              variant='h1'
              fontSize='1.875rem'
              fontWeight={700}
              mb={1.75}
            >
              Welcome to Community Voices in Research (CVR)
            </Typography>
            <Typography variant='subtitle1' fontSize='1rem'>
              Where lived experiences lead the future.
            </Typography>
            <Typography mt={3} variant='subtitle1' fontSize='1rem'>
              Answer a few questions to see if you are eligible to join!
            </Typography>
          </Box>
          <Stack direction='row' spacing='10px' mt={3.75}>
            <Link to='/join'>
              <Button>Join</Button>
            </Link>
            <Button variant='outlined' endIcon={<ArrowDownIcon />} onClick={onClickHandler}>
              Learn More
            </Button>
          </Stack>
        </Box>
      </Box>
    </Grid>
    <Grid xs={12} md={6} item>
      <img
        src={heroBackgroundImage}
        width='100%'
        height='100%'
        alt='Hero Banner'
      />
    </Grid>
  </Grid>
);

export default Hero;

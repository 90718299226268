import MuiAvatar from './MuiAvatar';
import MuiButton from './MuiButton';
import MuiCard from './MuiCard';
import MuiChip from './MuiChip';
import MuiContainer from './MuiContainer';
import MuiIconButton from './MuiIconButton';
import MuiLink from './MuiLink';
import MuiMenu from './MuiMenu';
import MuiSelect from './MuiSelect';
import MuiStep from './MuiStep';
import MuiStepper from './MuiStepper';
import MuiSwitch from './MuiSwitch';

const components = {
  MuiAvatar,
  MuiButton,
  MuiCard,
  MuiChip,
  MuiContainer,
  MuiIconButton,
  MuiLink,
  MuiMenu,
  MuiSelect,
  MuiStep,
  MuiStepper,
  MuiSwitch,
};

export default components;

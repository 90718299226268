import { Box, styled } from '@mui/material';

const badgeBigSize = 80;
const badgeSmallSize = 62;

export const BadgeWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    svg: {
      transform: `scale(calc(${badgeSmallSize} / ${badgeBigSize}))`,
    },
  },
}));

export const ChipWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '9px',
  right: '9px',
  [theme.breakpoints.down('sm')]: {
    top: 'unset',
    bottom: '14px',
  },
}));

export const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['color', 'show'].includes(prop),
})(({ theme, show, color }) => ({
  ...(!show && { visibility: 'hidden' }),
  color,
  opacity: 0.5,

  borderRadius: '14px',
  backgroundColor: color,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  position: 'absolute',
  top: '16px',
  left: '16px',
  width: '28px',
  height: '28px',
  svg: {
    width: '15px',
    height: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    top: '5px',
    left: '5px',
    width: '18px',
    height: '18px',

    svg: {
      width: '10px',
      height: '10px',
    },
  },
}));

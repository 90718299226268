const MuiLink = {
  styleOverrides: {
    root: ({ theme, color = theme.palette.primary }) => ({
      color,
      textDecoration: 'none',
      cursor: 'pointer',
      ':hover': {
        color,
        textDecoration: 'underline',
      },
    }),
  },
};

export default MuiLink;

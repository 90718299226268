export const getAuthenticatorDetails = (method) => {
  switch (method) {
    case 'email':
      return {
        mfaTitle: 'Email',
        description: 'Enter the code sent to your email address',
        placeholder: 'Enter code here',
      };
    case 'sms':
      return {
        mfaTitle: 'SMS',
        description: 'Enter code sent to your phone number',
        placeholder: 'Enter code here',
      };
    case 'authenticatorApp':
      return {
        mfaTitle: 'Authenticator app',
        description: `
        <ol style="padding-left: 20px">
          <li>Download and install an app (such as Google Authenticator) on your mobile device.</li>
          <li>Scan the QR code.</li>
          <li>Enter and verify authentication code generated by the app.</li>
        </ol>`,
        placeholder: 'Enter code form 2FA app',
      };
    default:
      return {};
  }
};

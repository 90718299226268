const formPMed = {
  title: 'Form PMed',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'PMed',
  type: 'panel',
  label: 'Form PMed',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Pain Medication</strong>',
      label: 'Content',
      refreshOnChange: false,
      key: 'PMed0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label:
        'In the past 3 months, did you use over the counter medications to treat pain (such as acetaminophen, ibuprofen, naproxen)?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'PMed1',
      type: 'radio',
      input: true,
    },
    {
      label:
        'In the past 3 months, did you take a prescription medication for pain?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'PMed2',
      type: 'radio',
      input: true,
    },
    {
      label: 'What was this prescription for? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        NSAID: false,
        Opioid: false,
        Oth_Presc: false,
        Steroid: false,
        NS: false,
        other: false,
      },
      values: [
        {
          label:
            'Non-steroidal anti-inflammatory drugs (NSAIDs such as Celebrex or Meloxicam)',
          value: 'NSAID',
          shortcut: '',
        },
        {
          label:
            'Opioid medications (such as Vicodin, Norco, tramadol, oxycodone, morphine, etc.)',
          value: 'Opioid',
          shortcut: '',
        },
        {
          label:
            'Other prescription medications (such as gabapentin [Neurontin], pregablin [Lyrica], duloxetine [Cymbalta], amitriptyline [Elavil], etc.)',
          value: 'Oth_Presc',
          shortcut: '',
        },
        {
          label: 'Prednisone',
          value: 'Steroid',
          shortcut: '',
        },
        {
          label: 'I am not sure',
          value: 'NS',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'PMed3',
      conditional: {
        show: true,
        when: 'PMed2',
        eq: 'Yes',
      },
      logic: [
        {
          name: 'None of the above',
          trigger: {
            type: 'javascript',
            javascript: 'result = data[component.key]?.NS;',
          },
          actions: [
            {
              name: 'Unselect',
              type: 'value',
              value:
                "value = Object.keys(data[component.key]).reduce((acc, curr) => ({...acc, [curr]: curr === 'NS'}), {})",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'PMed3_other',
      customConditional: 'show = data.PMed3?.other',
      type: 'textfield',
      input: true,
    },
    {
      label:
        'In the past 3 months, did you run out early of any prescription for pain medication?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'PMed4',
      customConditional: "show = data.PMed2 === 'Yes';",
      type: 'radio',
      input: true,
    },
    {
      label: 'Which medication did you run out of early?',
      optionsLabelPosition: 'right',
      tableView: false,
      defaultValue: {
        sdc: false,
        '': false,
      },
      validate: {
        required: true,
      },
      key: 'PMed5',
      customConditional:
        "show = data.PMed4 == 'Yes' && data.PMed3 && Object.entries(data.PMed3).filter(([key, value]) => value).length > 1 && Object.entries(data.PMed3).some(([key, value]) => key !== 'NS' && value);",
      logic: [
        {
          name: 'PMed3 changed',
          trigger: {
            type: 'javascript',
            javascript: 'result = data.PMed3',
          },
          actions: [
            {
              name: 'Filter',
              type: 'mergeComponentSchema',
              schemaDefinition:
                "const values = utils\n  .getComponent(form.components, 'PMed3').values\n  .filter(({ value }) => data.PMed3?.[value])\n  .map(entrie => entrie.value === 'other' ? { ...entrie, label: data.PMed3_other } : entrie);\nschema = { values }",
            },
          ],
        },
      ],
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
  ],
};

export default formPMed;

const form = `
  {% const { footNotes, hasIntroPage, cancelLabel, closeLabel, previousLabel, startLabel, nextLabel, submitLabel, saveLabel } = form.navBar ?? {} %}
  {% const isIntroPage = hasIntroPage && currentPage === 0 %}
  <div class="wizard-nav" id="{{ wizardKey }}-nav">
    {% if (isIntroPage) { %}
      <div class="spacer"></div>
    {% } %}
    {% if (previousLabel && buttons.previous) { %}
      <button
        class="btn-wizard btn-wizard-backward"
        ref="{{ wizardKey }}-previous"
      >
        {{ previousLabel }}
      </button>
    {% } else if (cancelLabel) { %}
      <button class="btn-wizard btn-wizard-cancel">
        {{ cancelLabel }}
      </button>
    {% } %}
    {% if (!isIntroPage) { %}
      <div class="spacer"></div>
    {% } %}
    {% if (footNotes) { %}
      <div class="wizard-foot-notes">
        {{ footNotes }}
      </div>
    {% } %}
    {% if (!options.readOnly && saveLabel && !isIntroPage) { %}
      <button class="btn-wizard btn-wizard-save">
        {{ saveLabel }}
      </button>
    {% } %}
    {% if (buttons.next) { %}
      <button
        class="btn-wizard btn-wizard-forward btn-wizard-next"
        ref="{{ wizardKey }}-next"
      >
        {{ isIntroPage && startLabel ? startLabel : nextLabel ? nextLabel : 'Next' }}
      </button>
    {% } else if (buttons.submit) { %}
      <button
        class="btn-wizard btn-wizard-forward btn-wizard-submit"
        ref="{{ wizardKey }}-submit"
      >
        {{ submitLabel ? submitLabel : 'Submit' }}
      </button>
    {% } else if (closeLabel) { %}
      <button class="btn-wizard btn-wizard-cancel">
        {{ closeLabel }}
      </button>
    {% } %}
  </div>
`;

export default form;

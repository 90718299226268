import { Button } from '@mui/material';

import Card from 'components/Dashboard/Card';
import { ReactComponent as OpenNew } from 'images/open-new.svg';

const description = (
  <p>
    ClinicalTrials.gov is a place to learn about clinical studies from around
    the world.<br></br>Filter results by entering your location in order to find
    more relevant studies.
  </p>
);

const ExternalResearchOpportunities = (props) => (
  <Card
    title={'MY EXTERNAL RESEARCH OPPORTUNITIES'}
    subtitle={'ClinicalTrials.gov'}
    description={description}
    {...props}
  >
    <a
      href={'https://clinicaltrials.gov/'}
      target='_blank'
      rel='noopener noreferrer'
    >
      <Button
        variant='text'
        endIcon={<OpenNew />}
        sx={{ width: 'fit-content' }}
      >
        Learn More
      </Button>
    </a>
  </Card>
);

export default ExternalResearchOpportunities;

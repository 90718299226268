import { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { Container, IconButton, Stack, Typography } from '@mui/material';

import { ReactComponent as PrinterIcon } from 'images/printer.svg';

const Section = ({ title, description, children, ...rest }) => {
  let ref = useRef(null);
  const [displayWarning, setDisplayWarning] = useState(false);
  return (
    <Container variant='outlined' {...rest}>
      <Stack spacing='24px' p='30px' ref={(el) => (ref = el)}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='w700h16' color='gray.100' noWrap>
            {title}
          </Typography>

          <ReactToPrint
            onAfterPrint={() => {
              setDisplayWarning(false);
            }}
            trigger={() => (
              <IconButton
                size='small'
                onMouseEnter={() => setDisplayWarning(true)}
                onMouseLeave={() => setDisplayWarning(false)}
              >
                <PrinterIcon />
              </IconButton>
            )}
            content={() => ref}
          />
        </Stack>
        <Typography variant='w500h13' color='gray.80' noWrap>
          {description}
        </Typography>
        {children}
        {displayWarning && (
          <footer>
            <Typography variant='w400h16' color='gray.100' textAlign='center'>
              The information contained on this website is for informational
              purposes only. No information on this site is intended to be a
              substitute for professional medical advice, diagnosis, or
              treatment.
            </Typography>
          </footer>
        )}
      </Stack>
    </Container>
  );
};

export default Section;

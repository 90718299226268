import { useState } from 'react';

import { Button } from '@mui/material';

import ContentBox from 'components/MyAccount/ContentBox';
import ChangePasswordModal from 'components/NHFModal/ChangePasswordModal';

const Password = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleSetOpen = () => {
    setOpenModal(true);
  };

  return (
    <>
      <ContentBox title='Password'>
        <Button fullWidth variant='outlined' onClick={handleSetOpen}>
          Change Password
        </Button>
      </ContentBox>
      <ChangePasswordModal open={openModal} setOpen={setOpenModal} />
    </>
  );
};

export default Password;

const impactOfPain = {
  display: 'form',
  components: [
    {
      label: 'Bleeding Disorder',
      tableView: false,
      data: {
        values: [
          {
            label: 'Hemophilia A',
            value: 'hemophiliaA',
          },
          {
            label: 'Hemophilia B',
            value: 'hemophiliaB',
          },
          {
            label: 'Von Willebrand Disease',
            value: 'vonWillebrandDisease',
          },
          {
            label: 'Ultra-rare bleeding disorders',
            value: 'ultraRareBleedingDisorders',
          },
        ],
      },
      key: 'bleedingDisorder',
      widget: 'choicesjs',
      type: 'select',
      input: true,
      inputType: 'checkbox',
      logic: [
        {
          name: 'on clear',
          trigger: {
            type: 'javascript',
            javascript:
              "const content = data[component.key]\nresult = content === '' || content === undefined || content === null",
          },
          actions: [
            {
              name: 'Select first',
              type: 'value',
              value: 'value = component.data.values[0].value',
            },
          ],
        },
      ],
    },
    {
      label:
        'How often did you have the pain so bad in the past 7 days that you...',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'Could not get out of bed',
          value: 'couldNotGetOutOfBed',
          shortcut: '',
        },
        {
          label: 'It was hard to finish what you were doing',
          value: 'itWasHardToFinishWhatYouWereDoing',
          shortcut: '',
        },
        {
          label: 'Could not do anything for a whole day',
          value: 'couldNotDoAnythingForAWholeDay',
          shortcut: '',
        },
        {
          label: 'Had to stop what you were doing',
          value: 'hadToStopWhatYouWereDoing',
          shortcut: '',
        },
      ],
      key: 'howOften',
      type: 'radio',
      input: true,
      logic: [
        {
          name: 'on clear',
          trigger: {
            type: 'javascript',
            javascript:
              "const content = data[component.key]\nresult = content === '' || content === undefined || content === null",
          },
          actions: [
            {
              name: 'Select first',
              type: 'value',
              value: 'value = component.values[0].value',
            },
          ],
        },
      ],
    },
  ],
};

export default impactOfPain;

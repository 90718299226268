import {
  ConditionIntensityAveragePainLabels,
  ConditionNumberOfBleedsLabels,
} from 'utils/enums';

export const parseConditions = (data) => {
  const conditions = [];

  if (data?.PI1_PI2?.PI2) {
    conditions.push(ConditionIntensityAveragePainLabels[data.PI1_PI2.PI2]);
  }

  if (data?.BH3) {
    const numberOfBleeds = data?.BH3?.reduce(
      (acc, curr) => acc + (curr?.numberOfBleeds ?? 0),
      0,
    );
    conditions.push(ConditionNumberOfBleedsLabels(numberOfBleeds));
  }

  return conditions.map((description, id) => ({ id, description }));
};

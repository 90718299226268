import { Card, Stack, useMediaQuery, useTheme } from '@mui/material';

import chartPlaceholder from 'images/chart.png';

const ChartPlaceholder = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Card
      variant={sm ? '' : 'outlined'}
      sx={{
        width: '100%',
        height: { xs: '335px', sm: '324px' },
      }}
    >
      <Stack alignItems='center' justifyContent='center' height='100%'>
        <img src={chartPlaceholder} alt='Chart Placeholder' />
      </Stack>
    </Card>
  );
};
export default ChartPlaceholder;

export const MissionTypes = Object.freeze({
  JoinCVR: Symbol('joinCVR'),
  SurveysCompleted: Symbol('surveysCompleted'),
  BaselineSurvey: Symbol('baselineSurvey'),
  InvitationSent: Symbol('invitationSent'),
  ReadArticle: Symbol('ReadArticle'),
});

export const Languages = Object.freeze({
  english: 'English',
  portuguese: 'Portuguese',
  spanish: 'Spanish',
});

export const NhfApiVersions = Object.freeze({
  V1: 'v1',
  V2: 'v2',
  V3: 'v3',
});

export const RequestMethods = Object.freeze({
  Get: 'GET',
  Patch: 'PATCH',
  Delete: 'DELETE',
  Post: 'POST',
});

export const AlertSeverity = Object.freeze({
  Error: 'error',
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
});
export const SnackbarInfo = Object.freeze({
  FormSent: () => ({
    message: 'Form sent please wait while we are saving it!',
    type: AlertSeverity.Info,
  }),
});
export const SnackbarSuccess = Object.freeze({
  AccountCreated: () => ({
    message: 'Account successfully created!',
    type: AlertSeverity.Success,
  }),
  WelcomeBack: () => ({
    message: 'Welcome back!',
    type: AlertSeverity.Success,
  }),
  FormSaved: () => ({
    message: 'Your answers have been saved successfully',
    type: AlertSeverity.Success,
  }),
  InviteSent: () => ({
    message: 'Thank you! Your invitation has been sent.',
  }),
  PasswordChanged: () => ({
    message:
      'Change password successful. Your password has been changed successfully.',
  }),
  MFASkipped: () => ({
    message: 'You have successfully skipped security setup!',
    type: AlertSeverity.Success,
  }),
  MFADisabled: () => ({
    message: 'You have successfully disabled MFA security!',
    type: AlertSeverity.Success,
  }),
  MFASetup: () => ({
    message: 'You have successfully setup MFA',
    type: AlertSeverity.Success,
  }),
});

export const PersonStatus = Object.freeze({
  Withdrawn: 'withdrawn',
  Consented: 'consented',
  Onboarded: 'onboarded',
  Enrolled: 'enrolled',
});

export const PersonStatusReason = Object.freeze({
  DeclinedConsent: 'Participant declined consent',
  AcceptedConsent: 'Participation accepted the consent',
  Onboarded: 'Participant completed all onboarding process',
});

export const EnrollmentCompletionState = Object.freeze({
  Completed: 'completed',
  Ongoing: 'ongoing',
});

export const TaskStatus = Object.freeze({
  Completed: 'completed',
  Incomplete: 'incomplete',
  Inprogress: 'in_progress',
});
export const TaskTitles = Object.freeze({
  BaselineSurvey: 'Baseline Survey',
});
export const GrantTypes = Object.freeze({
  Password: 'password',
});

export const Auth0Scopes = Object.freeze({
  MFA: 'enroll read:authenticators remove:authenticators',
  DefaultScopes: 'openid offline_access profile email',
});

export const SnackbarError = Object.freeze({
  WrongCurrentPassword: () => ({
    message: 'Current password does not match',
    type: AlertSeverity.Error,
  }),
  EmailAlreadyExists: (email) => ({
    message: `${email} already exists!`,
    type: AlertSeverity.Error,
  }),
  TryAgain: () => ({
    message: 'Something went wrong. Please, try again!',
    type: AlertSeverity.Error,
  }),
  TooManyAttempts: () => ({
    message:
      'Too many wrong code attempts. Your account has been locked for an extended amount of time!',
    type: AlertSeverity.Error,
  }),
  TooManyMFACodeAttempts: () => ({
    message:
      'Too many wrong code attempts. Your account has been locked for an extended amount of time!',
    type: AlertSeverity.Error,
  }),
  InvalidMFACode: () => ({
    message: 'The code you entered is invalid. Please check and try again',
    type: AlertSeverity.Error,
  }),
  WrongEmailPassword: () => ({
    message: 'Wrong email or password!',
    type: AlertSeverity.Error,
  }),
  InvalidMFAPhoneNumber: () => ({
    message:
      'Your phone number is invalid. Please update your number and try again!',
    type: AlertSeverity.Error,
  }),
  TooManySMSAttempts: () => ({
    message: 'Too many SMS attempts. Wait for some minutes before retrying.',
    type: AlertSeverity.Error,
  }),
});

export const ErrorDescriptions = Object.freeze({
  MFARequired: 'mfa_required',
  TooManyAttempts:
    'Too many emails sent by the user. Wait for some minutes before retrying.',
  InvalidMFACode: 'Invalid binding_code.',
  InvalidOTPCode: 'Invalid otp_code.',
  TooManyMFACodeAttempts:
    'Too many failed codes. Wait for some minutes before retrying.',
  InvalidMFAPhoneNumber: 'The phone number is invalid.',
  TooManySMSAttempts:
    'Too many SMS sent by the user. Wait for some minutes before retrying.',
});

export const ErrorStatus = Object.freeze({
  Rejected: 'rejected',
});

export const ErrorCodes = Object.freeze({
  403: '403',
});

export const DiagnosesTypes = Object.freeze({
  HemophiliaA: 'hemophiliaA',
  HemophiliaB: 'hemophiliaB',
  VonWillebrandDisease: 'vonWillebrandDisease',
  PlateletDisorder: 'plateletDisorder',
  FactorIDeficiency: 'factorIDeficiency',
  FactorIiDeficiency: 'factorIiDeficiency',
  FactorVDeficiency: 'factorVDeficiency',
  FactorViiDeficiency: 'factorViiDeficiency',
  FactorXDeficiency: 'factorXDeficiency',
  FactorXiDeficiency: 'factorXiDeficiency',
  FactorXiiiDeficiency: 'factorXiiiDeficiency',
  Pai1Deficiency: 'pai1Deficiency',
  A2AntiPlasminDeficiency: 'a2AntiPlasminDeficiency',
  EhlersDanlosSyndrome: 'ehlersDanlosSyndrome',
  ConnectiveTissueDisorderOtherThanEhlersDanlos:
    'connectiveTissueDisorderOtherThanEhlersDanlos',
  BleedingOfUnknownCause: 'bleedingOfUnknownCause',
  Other: 'other',
});

export const DiagnosesLabels = Object.freeze({
  [DiagnosesTypes.HemophiliaA]:
    'Hemophilia A (Factor VIII or factor 8 deficiency, classic hemophilia)',
  [DiagnosesTypes.HemophiliaB]:
    'Hemophilia B (Factor IX or factor 9 deficiency, Christmas hemophilia)',
  [DiagnosesTypes.VonWillebrandDisease]: 'Von Willebrand Disease (VWD)',
  [DiagnosesTypes.PlateletDisorder]: 'Platelet disorder',
  [DiagnosesTypes.FactorIDeficiency]:
    'Factor I deficiency (1, various fibrinogen deficiencies)',
  [DiagnosesTypes.FactorIiDeficiency]: 'Factor II deficiency (2, prothrombin)',
  [DiagnosesTypes.FactorVDeficiency]: 'Factor V deficiency (5)',
  [DiagnosesTypes.FactorViiDeficiency]: 'Factor VII deficiency (7)',
  [DiagnosesTypes.FactorXDeficiency]: 'Factor X deficiency (10)',
  [DiagnosesTypes.FactorXiDeficiency]:
    'Factor XI deficiency (11 or Hemophilia C)',
  [DiagnosesTypes.FactorXiiiDeficiency]: 'Factor XIII deficiency (13)',
  [DiagnosesTypes.Pai1Deficiency]:
    'PAI-1 deficiency (plasminogen activator inhibitor-1 deficiency)',
  [DiagnosesTypes.A2AntiPlasminDeficiency]:
    'a (alpha) 2 anti-plasmin deficiency (a2-aP)',
  [DiagnosesTypes.EhlersDanlosSyndrome]: 'Ehlers-Danlos Syndrome',
  [DiagnosesTypes.ConnectiveTissueDisorderOtherThanEhlersDanlos]:
    'Connective tissue disorder other than Ehlers-Danlos',
  [DiagnosesTypes.BleedingOfUnknownCause]: 'Bleeding of unknown cause',
});

export const HemophiliaSeverityTypes = Object.freeze({
  Mild: 'mild',
  Moderate: 'moderate',
  Severe: 'severe',
  IAmNotSureAboutMySeverity: 'iAmNotSureAboutMySeverity',
  Other: 'other',
});

export const HemophiliaSeverityLabels = Object.freeze({
  [HemophiliaSeverityTypes.Mild]: 'Mild (greater than 5% but less than 50%)',
  [HemophiliaSeverityTypes.Moderate]: 'Moderate (1%-5%)',
  [HemophiliaSeverityTypes.Severe]: 'Severe (less than 1%)',
  [HemophiliaSeverityTypes.IAmNotSureAboutMySeverity]:
    'I am not sure about my severity',
});

export const PlateletDisorderTypes = Object.freeze({
  GlanzmannThrombasthenia: 'glanzmannThrombasthenia',
  BernardSoulierSyndrome: 'bernardSoulierSyndrome',
  GreyPlateletSyndrome: 'greyPlateletSyndrome',
  HermanskyPudlackSyndrome: 'hermanskyPudlackSyndrome',
  InheritedThrombocytopenia: 'inheritedThrombocytopenia',
  PlateletReleaseDefect: 'plateletReleaseDefect',
  PlateletStoragePoolDefect: 'plateletStoragePoolDefect',
  UnknownByMyDoctor: 'unknownByMyDoctor',
  UnknownByMe: 'unknownByMe',
});

export const PlateletDisorderLabels = Object.freeze({
  [PlateletDisorderTypes.GlanzmannThrombasthenia]:
    'Glanzmann Thrombasthenia (GT)',
  [PlateletDisorderTypes.BernardSoulierSyndrome]: 'Bernard-Soulier Syndrome',
  [PlateletDisorderTypes.GreyPlateletSyndrome]:
    'Grey Platelet Syndrome (Alpha-Storage Pool Deficiency, Alpha-Granule Deficiency)',
  [PlateletDisorderTypes.HermanskyPudlackSyndrome]:
    'Hermansky-Pudlack Syndrome',
  [PlateletDisorderTypes.InheritedThrombocytopenia]:
    'Inherited Thrombocytopenia',
  [PlateletDisorderTypes.PlateletReleaseDefect]: 'Platelet Release Defect',
  [PlateletDisorderTypes.PlateletStoragePoolDefect]:
    'Platelet Storage Pool Defect',
  [PlateletDisorderTypes.UnknownByMyDoctor]: 'Unknown by my doctor',
  [PlateletDisorderTypes.UnknownByMe]: 'Unknown by me',
});

export const VonWillebrandDiseaseTypes = Object.freeze({
  Type1: 'type1',
  Type1C: 'type1C',
  Type2: 'type2',
  Type3: 'type3',
  UnknownByMyDoctor: 'unknownByMyDoctor',
  UnknownByMe: 'unknownByMe',
  Other: 'other',
});

export const VonWillebrandDiseaseLabels = Object.freeze({
  [VonWillebrandDiseaseTypes.Type1]: 'Type 1',
  [VonWillebrandDiseaseTypes.Type1C]: 'Type 1C',
  [VonWillebrandDiseaseTypes.Type2]: 'Type 2',
  [VonWillebrandDiseaseTypes.Type3]: 'Type 3',
  [VonWillebrandDiseaseTypes.UnknownByMyDoctor]: 'Unknown by my doctor',
  [VonWillebrandDiseaseTypes.UnknownByMe]: 'Unknown by me',
});

export const VwdType2Types = Object.freeze({
  _2A: '2A',
  _2B: '2B',
  _2M: '2M',
  _2N: '2N',
  UnknownByMyDoctor: 'unknownByMyDoctor',
  UnknownByMe: 'unknownByMe',
  Other: 'other',
});

export const VwdType2Labels = Object.freeze({
  [VwdType2Types._2A]: '2A',
  [VwdType2Types._2B]: '2B',
  [VwdType2Types._2M]: '2M',
  [VwdType2Types._2N]: '2N',
  [VwdType2Types.UnknownByMyDoctor]: 'Unknown by my doctor',
  [VwdType2Types.UnknownByMe]: 'Unknown by me',
});

export const MedicationsTypes = Object.freeze({
  Advate: 'Advate',
  Adynovate: 'Adynovate',
  Afstyla: 'Afstyla',
  Alpha9: 'Alpha9',
  Alphanate: 'Alphanate',
  Alprolix: 'Alprolix',
  Altuviiio: 'Altuviiio',
  Amicar: 'Amicar',
  Bebulin: 'Bebulin',
  Benefix: 'Benefix',
  Cellcept: 'Cellcept',
  Coagadex: 'Coagadex',
  Corifact: 'Corifact',
  Cryoprecipitate: 'Cryoprecipitate',
  DDAVP_inj: 'DDAVP_inj',
  Eloctate: 'Eloctate',
  Esperoct: 'Esperoct',
  FEIBA: 'FEIBA',
  FF_plasma: 'FF_plasma',
  Fibryga: 'Fibryga',
  Hemlibra: 'Hemlibra',
  Hemofil: 'Hemofil',
  Hum_P: 'Hum_P',
  Idelvion: 'Idelvion',
  Ixinity: 'Ixinity',
  Jivi: 'Jivi',
  Koate: 'Koate',
  Kogenate: 'Kogenate',
  Kovaltry: 'Kovaltry',
  Mononine: 'Mononine',
  NUWIQ: 'NUWIQ',
  Nasal_DDAVP: 'Nasal_DDAVP',
  Novo7_RT: 'Novo7_RT',
  Novoeight: 'Novoeight',
  NSAID: 'NSAID',
  Obizur: 'Obizur',
  Octaplas: 'Octaplas',
  Opioid: 'Opioid',
  Oth_Presc: 'Oth_Presc',
  Platelets: 'Platelets',
  Prednisone: 'Prednisone',
  Profilnine: 'Profilnine',
  Rebinyn: 'Rebinyn',
  Recombinate: 'Recombinate',
  RiaSTAP: 'RiaSTAP',
  Rituxan: 'Rituxan',
  Rixubis: 'Rixubis',
  SevenFACT: 'SevenFACT',
  Steroid: 'Steroid',
  Tretten: 'Tretten',
  Trx_acid: 'Trx_acid',
  Vonvendi: 'Vonvendi',
  Wilate: 'Wilate',
  Xyntha: 'Xyntha',
  Other: 'other',
});

export const MedicationsLabels = Object.freeze({
  [MedicationsTypes.Advate]: 'Advate',
  [MedicationsTypes.Adynovate]: 'Adynovate',
  [MedicationsTypes.Afstyla]: 'Afstyla',
  [MedicationsTypes.Alpha9]: 'AlphaNine SD',
  [MedicationsTypes.Alphanate]: 'Alphanate',
  [MedicationsTypes.Alprolix]: 'Alprolix',
  [MedicationsTypes.Altuviiio]: 'Altuviiio',
  [MedicationsTypes.Amicar]: 'Amicar (aminocaproic acid)',
  [MedicationsTypes.Bebulin]: 'Bebulin',
  [MedicationsTypes.Benefix]: 'Benefix',
  [MedicationsTypes.Cellcept]: 'Cellcept',
  [MedicationsTypes.Coagadex]: 'Coagadex',
  [MedicationsTypes.Corifact]: 'Corifact',
  [MedicationsTypes.Cryoprecipitate]: 'Cryoprecipitate',
  [MedicationsTypes.DDAVP_inj]: 'DDAVP injection (desmopressin acetate)',
  [MedicationsTypes.Eloctate]: 'Eloctate',
  [MedicationsTypes.Esperoct]: 'Esperoct',
  [MedicationsTypes.FEIBA]: 'FEIBA',
  [MedicationsTypes.FF_plasma]: 'Fresh frozen plasma',
  [MedicationsTypes.Fibryga]: 'Fibryga',
  [MedicationsTypes.Hemlibra]: 'Hemlibra (emicizumab)',
  [MedicationsTypes.Hemofil]: 'Hemofil M',
  [MedicationsTypes.Hum_P]: 'Humate-P',
  [MedicationsTypes.Idelvion]: 'Idelvion',
  [MedicationsTypes.Ixinity]: 'Ixinity',
  [MedicationsTypes.Jivi]: 'Jivi',
  [MedicationsTypes.Koate]: 'Koate DVI',
  [MedicationsTypes.Kogenate]: 'Kogenate FS',
  [MedicationsTypes.Kovaltry]: 'Kovaltry',
  [MedicationsTypes.Mononine]: 'Mononine',
  [MedicationsTypes.NUWIQ]: 'NUWIQ',
  [MedicationsTypes.Nasal_DDAVP]: 'Intranasal DDAVP',
  [MedicationsTypes.Novo7_RT]: 'NovoSeven RT',
  [MedicationsTypes.Novoeight]: 'Novoeight',
  [MedicationsTypes.NSAID]:
    'Non-steroidal anti-inflammatory drugs (NSAIDs such as Celebrex or Meloxicam)',
  [MedicationsTypes.Obizur]: 'Obizur',
  [MedicationsTypes.Octaplas]: 'Octaplas',
  [MedicationsTypes.Opioid]:
    'Opioid medications (such as Vicodin, Norco, tramadol, oxycodone, morphine, etc.)',
  [MedicationsTypes.Oth_Presc]:
    'Other prescription medications (such as gabapentin [Neurontin], pregablin [Lyrica], duloxetine [Cymbalta], amitriptyline [Elavil], etc.)',
  [MedicationsTypes.Platelets]: 'Platelets',
  [MedicationsTypes.Prednisone]: 'Prednisone',
  [MedicationsTypes.Profilnine]: 'Profilnine',
  [MedicationsTypes.Rebinyn]: 'Rebinyn',
  [MedicationsTypes.Recombinate]: 'Recombinate',
  [MedicationsTypes.RiaSTAP]: 'RiaSTAP',
  [MedicationsTypes.Rituxan]: 'Rituxan',
  [MedicationsTypes.Rixubis]: 'Rixubis',
  [MedicationsTypes.SevenFACT]: 'SevenFACT',
  [MedicationsTypes.Steroid]: 'Prednisone',
  [MedicationsTypes.Tretten]: 'Tretten',
  [MedicationsTypes.Trx_acid]: 'Tranexamic acid (Lysteda or Cyklokapron)',
  [MedicationsTypes.Vonvendi]: 'Vonvendi',
  [MedicationsTypes.Wilate]: 'Wilate',
  [MedicationsTypes.Xyntha]: 'Xyntha',
});

export const StatesTypes = Object.freeze({
  AL: 'AL',
  AK: 'AK',
  AZ: 'AZ',
  AR: 'AR',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DE: 'DE',
  DC: 'DC',
  FL: 'FL',
  GA: 'GA',
  HI: 'HI',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  IA: 'IA',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  ME: 'ME',
  MD: 'MD',
  MA: 'MA',
  MI: 'MI',
  MN: 'MN',
  MS: 'MS',
  MO: 'MO',
  MT: 'MT',
  NE: 'NE',
  NV: 'NV',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NY: 'NY',
  NC: 'NC',
  ND: 'ND',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PA: 'PA',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VT: 'VT',
  VA: 'VA',
  WA: 'WA',
  WV: 'WV',
  WI: 'WI',
  WY: 'WY',
  AM_Samoa: 'AM_Samoa',
  GU: 'GU',
  N_Mariana: 'N_Mariana',
  PR: 'PR',
  US_VI: 'US_VI',
});

export const StatesLabels = Object.freeze({
  [StatesTypes.AL]: 'Alabama',
  [StatesTypes.AK]: 'Alaska',
  [StatesTypes.AZ]: 'Arizona',
  [StatesTypes.AR]: 'Arkansas',
  [StatesTypes.CA]: 'California',
  [StatesTypes.CO]: 'Colorado',
  [StatesTypes.CT]: 'Connecticut',
  [StatesTypes.DE]: 'Delaware',
  [StatesTypes.DC]: 'District of Columbia',
  [StatesTypes.FL]: 'Florida',
  [StatesTypes.GA]: 'Georgia',
  [StatesTypes.HI]: 'Hawaii',
  [StatesTypes.ID]: 'Idaho',
  [StatesTypes.IL]: 'Illinois',
  [StatesTypes.IN]: 'Indiana',
  [StatesTypes.IA]: 'Iowa',
  [StatesTypes.KS]: 'Kansas',
  [StatesTypes.KY]: 'Kentucky',
  [StatesTypes.LA]: 'Louisiana',
  [StatesTypes.ME]: 'Maine',
  [StatesTypes.MD]: 'Maryland',
  [StatesTypes.MA]: 'Massachusetts',
  [StatesTypes.MI]: 'Michigan',
  [StatesTypes.MN]: 'Minnesota',
  [StatesTypes.MS]: 'Mississippi',
  [StatesTypes.MO]: 'Missouri',
  [StatesTypes.MT]: 'Montana',
  [StatesTypes.NE]: 'Nebraska',
  [StatesTypes.NV]: 'Nevada',
  [StatesTypes.NH]: 'New Hampshire',
  [StatesTypes.NJ]: 'New Jersey',
  [StatesTypes.NM]: 'New Mexico',
  [StatesTypes.NY]: 'New York',
  [StatesTypes.NC]: 'North Carolina',
  [StatesTypes.ND]: 'North Dakota',
  [StatesTypes.OH]: 'Ohio',
  [StatesTypes.OK]: 'Oklahoma',
  [StatesTypes.OR]: 'Oregon',
  [StatesTypes.PA]: 'Pennsylvania',
  [StatesTypes.RI]: 'Rhode Island',
  [StatesTypes.SC]: 'South Carolina',
  [StatesTypes.SD]: 'South Dakota',
  [StatesTypes.TN]: 'Tennessee',
  [StatesTypes.TX]: 'Texas',
  [StatesTypes.UT]: 'Utah',
  [StatesTypes.VT]: 'Vermont',
  [StatesTypes.VA]: 'Virginia',
  [StatesTypes.WA]: 'Washington',
  [StatesTypes.WV]: 'West Virginia',
  [StatesTypes.WI]: 'Wisconsin',
  [StatesTypes.WY]: 'Wyoming',
  [StatesTypes.AM_Samoa]: 'American Samoa',
  [StatesTypes.GU]: 'Guam',
  [StatesTypes.N_Mariana]: 'Northern Mariana Islands',
  [StatesTypes.PR]: 'Puerto Rico',
  [StatesTypes.US_VI]: 'US Virgin Islands',
});

export const PainIntensityTypes = Object.freeze({
  HadNoPain: 1,
  Mild: 2,
  Moderate: 3,
  Severe: 4,
  VerySevere: 5,
});

export const ConditionIntensityAveragePainLabels = Object.freeze({
  [PainIntensityTypes.HadNoPain]: 'In the past 7 days, I had no pain',
  [PainIntensityTypes.Mild]:
    'In the past 7 days, the intensity of my average pain is: Mild',
  [PainIntensityTypes.Moderate]:
    'In the past 7 days, the intensity of my average pain is: Moderate',
  [PainIntensityTypes.Severe]:
    'In the past 7 days, the intensity of my average pain is: Severe',
  [PainIntensityTypes.VerySevere]:
    'In the past 7 days, the intensity of my average pain is: Very severe',
});

export const ConditionNumberOfBleedsLabels = (numberOfBleeds) =>
  numberOfBleeds > 0
    ? `In the past 6 months, the number of bleeds I had was ${numberOfBleeds}`
    : 'In the past 6 months, I did not have any bleeds';

export const HTCData = Object.freeze({
  444: {
    name: "Akron Children's Hospital",
    address1: 'Hemostasis and Thrombosis Center',
    address2: 'One Perkins Square',
    address3: '',
    address4: '',
    city: 'Akron',
    state: 'OH',
    zipcode: '44308-1062',
    phone: '(330) 543-5191',
  },
  938: {
    name: 'Alaska Bleeding Disorder Clinics',
    address1: '4100 Lake Otis Pkwy',
    address2: 'Suite 312',
    address3: '',
    address4: '',
    city: 'Anchorage',
    state: 'AK',
    zipcode: '99508-5231',
    phone: '(907) 917-8235',
  },
  60: {
    name: 'Albany Medical Center',
    address1: '43 New Scotland Avenue',
    address2: 'MC-24',
    address3: '',
    address4: '',
    city: 'Albany',
    state: 'NY',
    zipcode: '12208',
    phone: '(518) 262-5513',
  },
  555: {
    name: "Ann & Robert H. Lurie Children's Hospital of Chicago",
    address1: 'Hematology Box 30',
    address2: '225 E. Chicago Ave.',
    address3: '',
    address4: '',
    city: 'Chicago',
    state: 'IL',
    zipcode: '60611',
    phone: '(312) 227-4000',
  },
  806: {
    name: "Arizona Hemophilia and Thrombosis Center at Phoenix Children's Hospital",
    address1: '1919 E. Thomas Rd',
    address2: 'Bldg. B',
    address3: '',
    address4: '',
    city: 'Phoenix',
    state: 'AZ',
    zipcode: '85016',
    phone: '(602) 933-0920',
  },
  804: {
    name: 'Arizona Hemophilia and Thrombosis Center at the University of Arizona',
    address1: 'University of Arizona Health Sciences Center',
    address2: '1515 North Campbell Avenue',
    address3: 'P.O. Box 245024',
    address4: '',
    city: 'Tucson',
    state: 'AZ',
    zipcode: '85724',
    phone: '(520) 626-4152',
  },
  675: {
    name: 'Arkansas Center for Bleeding Disorders',
    address1: "Arkansas Children's Hospital",
    address2: "1 Children's Way",
    address3: '',
    address4: '',
    city: 'Little Rock',
    state: 'AR',
    zipcode: '72202',
    phone: '(501) 364-1076',
  },
  360: {
    name: "Arnold Palmer Hospital for Children- The Haley Center for Children's Cancer and Blood Disorders",
    address1: '92 West Miller Street',
    address2: 'MP318',
    address3: '',
    address4: '',
    city: 'Orlando',
    state: 'FL',
    zipcode: '32806',
    phone: '(321) 841-8588',
  },
  341: {
    name: 'Augusta University Adult Hemophilia Treatment Center',
    address1: 'Dept of Adult Hematology/Oncology',
    address2: '989 St. Sebastian Way',
    address3: 'EF-100',
    address4: '',
    city: 'Augusta',
    state: 'GA',
    zipcode: '30912-3125',
    phone: '(706) 721-2171',
  },
  357: {
    name: 'Augusta University Pediatric Hemophilia Treatment Center',
    address1: 'Department of Pediatric Hematology/Oncology',
    address2: '1446 Harper Street, BG-2013',
    address3: '',
    address4: '',
    city: 'Augusta',
    state: 'GA',
    zipcode: '30912-3730',
    phone: '(706) 721-7367',
  },
  557: {
    name: 'Bleeding & Clotting Disorders Institute',
    address1: '427 W. Northmoor Rd.',
    address2: '',
    address3: '',
    address4: '',
    city: 'Peoria',
    state: 'IL',
    zipcode: '61614',
    phone: '(309) 692-5337',
  },
  12: {
    name: "Boston Hemophilia Center- Boston Children's Hospital",
    address1: "Children's Hospital Boston",
    address2: 'Boston Hemophilia Center',
    address3: '300 Longwood Avenue Fegan 7',
    address4: '',
    city: 'Boston',
    state: 'MA',
    zipcode: '02115',
    phone: '(617) 355-6101',
  },
  13: {
    name: "Boston Hemophilia Center- Brigham and Women's Faulkner Hospital",
    address1: "Brigham and Women's Faulkner Hospital",
    address2: 'Boston Hemophilia Center',
    address3: '1153 Centre Street Centre Suite 4G',
    address4: '',
    city: 'Jamaica Plain',
    state: 'MA',
    zipcode: '02130',
    phone: '(617) 278-0707',
  },
  427: {
    name: 'Bronson Hemophilia Treatment Center',
    address1: 'Bronson Hemophilia Treatment Center',
    address2: 'Bronson Methodist Hospital',
    address3: '805 John St.',
    address4: '',
    city: 'Kalamazoo',
    state: 'MI',
    zipcode: '49001',
    phone: '(269) 286-7180',
  },
  139: {
    name: 'Cardeza Foundation Hemophilia Center',
    address1: 'Cardeza Foundation Hemophilia Center',
    address2: 'Thomas Jefferson University Hospital',
    address3: '1015 Chestnut St., Suite 1321',
    address4: '',
    city: 'Philadelphia',
    state: 'PA',
    zipcode: '19107',
    phone: '(215) 955-8435',
  },
  422: {
    name: 'Cascade Hemophilia Consortium',
    address1: '2025 Traverwood, Ste A',
    address2: '',
    address3: '',
    address4: '',
    city: 'Ann Arbor',
    state: 'MI',
    zipcode: '48105',
    phone: '(734) 996-3300',
  },
  855: {
    name: 'Center for Comprehensive Care and Diagnosis of Inherited Blood Disorders',
    address1: '701 S. Parker Street',
    address2: 'Suite 1000',
    address3: '',
    address4: '',
    city: 'Orange',
    state: 'CA',
    zipcode: '92868',
    phone: '(714) 221-1200',
  },
  149: {
    name: 'Charleston Area Medical Center',
    address1: '3200 MacConkle Avenue, SE',
    address2: '',
    address3: '',
    address4: '',
    city: 'Charleston',
    state: 'WV',
    zipcode: '25304',
    phone: '(304) 388-8896',
  },
  340: {
    name: "Children's Hospital at Memorial University Medical Center",
    address1: '4750 Waters Avenue',
    address2: 'Provident Building, Suite 103',
    address3: '',
    address4: '',
    city: 'Savannah',
    state: 'GA',
    zipcode: '31404',
    phone: '(912) 350-5646',
  },
  841: {
    name: 'Childrens Hospital Los Angeles',
    address1: 'Hematology/Oncology',
    address2: '4650 Sunset Boulevard, Box #54',
    address3: '',
    address4: '',
    city: 'Los Angeles',
    state: 'CA',
    zipcode: '90027',
    phone: '(323) 361-4624',
  },
  21: {
    name: "Children's Hospital of Michigan",
    address1: 'Hemostasis and Thrombosis Center',
    address2: "3rd Floor Carl's Building",
    address3: '3901 Beaubien Blvd.',
    address4: '',
    city: 'Detroit',
    state: 'MI',
    zipcode: '48201',
    phone: '(313) 745-8432',
  },
  683: {
    name: "Children's Hospital of New Orleans",
    address1: '200 Henry Clay Ave.',
    address2: '',
    address3: '',
    address4: '',
    city: 'New Orleans',
    state: 'LA',
    zipcode: '70118',
    phone: '(504) 899-9511',
  },
  28: {
    name: "Children's Hospital of Philadelphia",
    address1: 'Hemophilia Program',
    address2: '3501 Civic Center Blvd',
    address3: 'Division of Hematology - 11th Floor CTRB',
    address4: '',
    city: 'Philadelphia',
    state: 'PA',
    zipcode: '19104',
    phone: '(215) 590-4493',
  },
  138: {
    name: "Children's Hospital of the King's Daughters",
    address1: 'Bleeding Disorders Center of Hampton Roads',
    address2: 'Division of Hematology/Oncology',
    address3: "Children's Hospital of the King's Daughters",
    address4: "601 Children's Lane",
    city: 'Norfolk',
    state: 'VA',
    zipcode: '23507',
    phone: '(757) 668-7243',
  },
  680: {
    name: "Children's Medical Center Bleeding and Thrombosis Program",
    address1: 'Center for Cancer and Blood Disorders',
    address2: '1935 Medical Center DR',
    address3: '',
    address4: '',
    city: 'Dallas',
    state: 'TX',
    zipcode: '75235',
    phone: '(214) 456-2379',
  },
  558: {
    name: "Children's Minnesota",
    address1: '2525 Chicago Ave., S, Suite CSC 175',
    address2: '',
    address3: '',
    address4: '',
    city: 'Minneapolis',
    state: 'MN',
    zipcode: '55404',
    phone: '(612) 813-5940',
  },
  131: {
    name: "Children's National Medical Center",
    address1: 'Department of Hematology/Oncology',
    address2: '111 Michigan Avenue, NW',
    address3: '',
    address4: '',
    city: 'Washington',
    state: 'DC',
    zipcode: '20010',
    phone: '(202) 476-2140',
  },
  332: {
    name: "Children's Rehabilitation Services - Birmingham Clinic",
    address1: '1600 7th Ave., South',
    address2: 'Suite 422',
    address3: '',
    address4: '',
    city: 'Birmingham',
    state: 'AL',
    zipcode: '35233',
    phone: '(205) 638-5900',
  },
  433: {
    name: "Cincinnati Children's Hospital Medical Center",
    address1: 'Hemophilia Treatment Center',
    address2: 'Mail Location 11009',
    address3: '3333 Burnet Avenue',
    address4: '',
    city: 'Cincinnati',
    state: 'OH',
    zipcode: '45229',
    phone: '(513) 636-4269',
  },
  574: {
    name: 'Comprehensive Center for Bleeding Disorders',
    address1: 'Comprehensive Center for Bleeding Disorders',
    address2: '8733 W. Watertown Plank Road',
    address3: '',
    address4: '',
    city: 'Milwaukee',
    state: 'WI',
    zipcode: '53226',
    phone: '(414) 257-2424',
  },
  15: {
    name: 'Connecticut Bleeding Disorders Center',
    address1: '263 Farmington Avenue',
    address2: 'University Tower',
    address3: '',
    address4: '',
    city: 'Farmington',
    state: 'CT',
    zipcode: '06030-1834',
    phone: '(860) 679-2100',
  },
  10: {
    name: "Connecticut Children's Hemophilia Treatment Centers",
    address1:
      "Connecticut Children's Medical Center, Hematology/Oncology, Suite 5A",
    address2: '282 Washington St. Hartford, CT 06106',
    address3: '',
    address4: '',
    city: 'Hartford',
    state: 'CT',
    zipcode: '06030',
    phone: '(860) 545-9630',
  },
  6: {
    name: 'Dartmouth-Hitchcock Hemophilia Center',
    address1: 'One Medical Center Drive',
    address2: '',
    address3: '',
    address4: '',
    city: 'Lebanon',
    state: 'NH',
    zipcode: '03756',
    phone: '(603) 650-5486',
  },
  439: {
    name: "Dayton Children's Hemostasis and Thrombosis Center",
    address1: "Dayton Children's Hospital",
    address2: "Dayton Children's Hemostasis and Thrombosis Center",
    address3: "One Children's Plaza",
    address4: '',
    city: 'Dayton',
    state: 'OH',
    zipcode: '45404-1815',
    phone: '(937) 641-5877',
  },
  133: {
    name: 'Delaware Hemophilia Treatment Center',
    address1: "Nemours Children's Hospital, Delaware",
    address2: '1600 Rockland Rd.',
    address3: '',
    address4: '',
    city: 'Wilmington',
    state: 'DE',
    zipcode: '19803',
    phone: '(302) 651-5500',
  },
  445: {
    name: 'Detroit Receiving Hospital',
    address1: 'Comprehensive Center for Bleeding Disorders and Thrombosis',
    address2: '4201 St. Antoine Blvd. UHC 7B',
    address3: '',
    address4: '',
    city: 'Detroit',
    state: 'MI',
    zipcode: '48201',
    phone: '(313) 745-7690',
  },
  256: {
    name: 'East Carolina University Health Hemophilia Treatment Center',
    address1: '435 Clinic Drive',
    address2: 'Module F',
    address3: '',
    address4: '',
    city: 'Greenville',
    state: 'NC',
    zipcode: '27834',
    phone: '(252) 744-4676',
  },
  259: {
    name: 'East Tennessee Comprehensive Hemophilia Center',
    address1: 'East Tennessee Comprehensive Hemophilia Center',
    address2: 'University of Tennessee Medical Center',
    address3: 'Medical Office Building B, Suite 214',
    address4: '1928 Alcoa Highway',
    city: 'Knoxville',
    state: 'TN',
    zipcode: '37920-6999',
    phone: '(865) 305-9170',
  },
  429: {
    name: 'Eastern Michigan Hemophilia Treatment Center',
    address1: 'Hurley Medical Center',
    address2: 'One Hurley Plaza',
    address3: '',
    address4: '',
    city: 'Flint',
    state: 'MI',
    zipcode: '48503-5993',
    phone: '(810) 262-9432',
  },
  677: {
    name: 'Fort Worth Comprehensive Hemophilia Center',
    address1: "Cook Children's Medical Center",
    address2: 'Hematology/Oncology Clinics',
    address3: '801 Seventh Avenue, STE 220',
    address4: '',
    city: 'Ft. Worth',
    state: 'TX',
    zipcode: '76104',
    phone: '(682) 885-4007',
  },
  132: {
    name: 'Georgetown University Hospital',
    address1: 'Lombardi Cancer Center, Division of Hem/Onc',
    address2: '3800 Reservoir Road, NW',
    address3: 'Podium B',
    address4: '',
    city: 'Washington',
    state: 'DC',
    zipcode: '20007',
    phone: '(202) 687-0117',
  },
  551: {
    name: 'Great Lakes Hemophilia Foundation',
    address1: '638 N. 18th Street, Suite 108',
    address2: '',
    address3: '',
    address4: '',
    city: 'Milwaukee',
    state: 'WI',
    zipcode: '53233-2121',
    phone: '(414) 257-0200',
  },
  861: {
    name: 'Guam Comprehensive Hemophilia Care Program',
    address1: 'Department of Public Health & Social Services',
    address2: '123 Chalan Kareta',
    address3: '',
    address4: '',
    city: 'Mangilao',
    state: 'GU',
    zipcode: '96913-6304',
    phone: '(671) 735-7168',
  },
  671: {
    name: 'Gulf States Hemophilia and Thrombophilia Center',
    address1: '7000 Fannin St., Suite 700',
    address2: '',
    address3: '',
    address4: '',
    city: 'Houston',
    state: 'TX',
    zipcode: '77030',
    phone: '(713) 500-8360',
  },
  565: {
    name: 'Gundersen Clinic',
    address1: '1900 South Ave. EB2-001',
    address2: '',
    address3: '',
    address4: '',
    city: 'La Crosse',
    state: 'WI',
    zipcode: '54601',
    phone: '(608) 782-7300',
  },
  430: {
    name: "Helen DeVos Children's Hospital",
    address1: "Helen DeVos Children's Hospital",
    address2: 'Coagulation Disorders Program',
    address3: '100 Michigan Street, N.E., MC #249',
    address4: '',
    city: 'Grand Rapids',
    state: 'MI',
    zipcode: '49503',
    phone: '(616) 391-2033',
  },
  801: {
    name: 'Hemophilia and Thrombosis Center at the University of Colorado Anschutz',
    address1: 'University of Colorado Anschutz Medical Campus',
    address2: '13199 E. Montview Blvd., Suite 100',
    address3: '',
    address4: '',
    city: 'Aurora',
    state: 'CO',
    zipcode: '80045-0507',
    phone: '(303) 724-0724',
  },
  143: {
    name: 'Hemophilia and Thrombosis Center of Central Pennsylvania',
    address1: 'The Milton S. Hershey Medical Center',
    address2: '500 University Drive, PO Box 850, H046',
    address3: '',
    address4: '',
    city: 'Hershey',
    state: 'PA',
    zipcode: '17033',
    phone: '(717) 531-7468',
  },
  146: {
    name: 'Hemophilia Center of Western Pennsylvania',
    address1: '3636 Boulevard of the Allies',
    address2: '',
    address3: '',
    address4: '',
    city: 'Pittsburgh',
    state: 'PA',
    zipcode: '15213',
    phone: '(412) 209-7280',
  },
  421: {
    name: 'Hemophilia Foundation of Michigan',
    address1: '1921 W. Michigan Avenue',
    address2: '',
    address3: '',
    address4: '',
    city: 'Ypsilanti',
    state: 'MI',
    zipcode: '48197',
    phone: '(734) 544-0015',
  },
  353: {
    name: 'Hemophilia of Georgia Center for Bleeding and Clotting Disorders of Emory, Adult Division',
    address1: 'Emory Healthcare',
    address2: 'Comprehensive Bleeding Disorder Center',
    address3: 'MOT, 10th Floor - Suite 1075',
    address4: '550 Peachtree Street NE',
    city: 'Atlanta',
    state: 'GA',
    zipcode: '30308',
    phone: '(404) 778-7062',
  },
  359: {
    name: 'Hemophilia of Georgia Center for Bleeding and Clotting Disorders of Emory, Pediatric Division',
    address1: '2015 Uppergate Drive NE',
    address2: '4th Floor Suite 340',
    address3: '',
    address4: '',
    city: 'Atlanta',
    state: 'GA',
    zipcode: '30322',
    phone: '(404) 785-1200',
  },
  331: {
    name: 'Hemophilia of Georgia, Inc.',
    address1: '8607 Roberts Drive',
    address2: 'Suite 150',
    address3: '',
    address4: '',
    city: 'Sandy Springs',
    state: 'GA',
    zipcode: '30350',
    phone: '(770) 518-8272',
  },
  564: {
    name: 'Hemophilia Outreach Center',
    address1: '2060 Bellevue Street',
    address2: '',
    address3: '',
    address4: '',
    city: 'Green Bay',
    state: 'WI',
    zipcode: '54311',
    phone: '(920) 965-0606',
  },
  249: {
    name: "Hemophilia Treatment Center of Levine Cancer Center and Levine Children's Hospital",
    address1: '1021 Morehead Medical Drive',
    address2: 'Building II',
    address3: 'Suite 50100',
    address4: '',
    city: 'Charlotte',
    state: 'NC',
    zipcode: '28204',
    phone: '(980) 442-4363',
  },
  865: {
    name: 'Hemostasis and Thrombosis Center of Nevada (HTCNV)',
    address1: 'Administrative Office',
    address2: '8352 W. Warm Springs Rd.',
    address3: 'Suite 200',
    address4: '',
    city: 'Las Vegas',
    state: 'NV',
    zipcode: '89113',
    phone: '(702) 960-5991',
  },
  424: {
    name: 'Henry Ford Hospital Adult Hemophilia and Thrombosis Treatment Center',
    address1: '2800 West Grand Boulevard',
    address2: 'Suite 3E-204',
    address3: '',
    address4: '',
    city: 'Detroit',
    state: 'MI',
    zipcode: '48202-2610',
    phone: '(313) 916-3790',
  },
  63: {
    name: 'Icahn School of Medicine at Mount Sinai',
    address1: 'Regional Comprehensive Hemophilia Treatment Center',
    address2: '19 East 98th Street, Suite 9D',
    address3: 'Box 1078',
    address4: '',
    city: 'New York',
    state: 'NY',
    zipcode: '10029',
    phone: '(212) 241-8303',
  },
  440: {
    name: 'Indiana Hemophilia and Thrombosis Center',
    address1: '8326 Naab Road',
    address2: '',
    address3: '',
    address4: '',
    city: 'Indianapolis',
    state: 'IN',
    zipcode: '46260',
    phone: '(317) 871-0000',
  },
  358: {
    name: "Johns Hopkins All Children's Hospital",
    address1: "Johns Hopkins All Children's Outpatient Care Center",
    address2: 'Pediatric Cancer and Blood Disorders Center',
    address3: '601 5th Street South Third Floor',
    address4: '',
    city: 'St. Petersburg',
    state: 'FL',
    zipcode: '33701',
    phone: '(727) 767-4176',
  },
  151: {
    name: 'Johns Hopkins University Medical Center',
    address1: '1125 Ross',
    address2: '720 Rutland Avenue',
    address3: '',
    address4: '',
    city: 'Baltimore',
    state: 'MD',
    zipcode: '21205',
    phone: '(410) 614-0834',
  },
  733: {
    name: 'Kansas City Regional Hemophilia Center',
    address1: "The Children's Mercy Hospital",
    address2: '2401 Gillham Road',
    address3: '',
    address4: '',
    city: 'Kansas City',
    state: 'MO',
    zipcode: '64108',
    phone: '(816) 302-6869',
  },
  848: {
    name: "Kapi'olani Medical Center for Women and Children",
    address1: "Kapi'olani Medical Center for Women and Children",
    address2: '1319 Punahou Street',
    address3: 'Pediatric Ambulatory Unit',
    address4: '',
    city: 'Honolulu',
    state: 'HI',
    zipcode: '96826',
    phone: '(808) 983-8551',
  },
  361: {
    name: 'Kidz Medical Services, Inc.',
    address1: '3100 SW 62 Ave.',
    address2: '',
    address3: '',
    address4: '',
    city: 'Miami',
    state: 'FL',
    zipcode: '33155',
    phone: '(305) 662-8360',
  },
  145: {
    name: 'Lehigh Valley Hospital-Muhlenberg',
    address1: 'Hemophilia Treatment Center',
    address2: '2545 Schoenersville Road, Suite 300',
    address3: '',
    address4: '',
    city: 'Bethlehem',
    state: 'PA',
    zipcode: '18017',
    phone: '(484) 884-2080',
  },
  674: {
    name: 'Louisiana Center for Bleeding and Clotting Disorders',
    address1: 'Tulane University Health Science Center',
    address2: 'Hematology/Medical Oncology Campus Mailbox 8410',
    address3: '1430 Tulane Avenue',
    address4: '',
    city: 'New Orleans',
    state: 'LA',
    zipcode: '70112-2699',
    phone: '(504) 988-5433',
  },
  4: {
    name: 'Maine Medical Center',
    address1: 'Maine Hemophilia & Thrombosis Center',
    address2: '100 Campus Drive Suite 107',
    address3: '',
    address4: '',
    city: 'Scarborough',
    state: 'ME',
    zipcode: '04074-9308',
    phone: '(207) 396-7683',
  },
  56: {
    name: 'Mary M. Gooley Hemophilia Center, Inc.',
    address1: '1415 Portland Avenue, Suite 500',
    address2: '',
    address3: '',
    address4: '',
    city: 'Rochester',
    state: 'NY',
    zipcode: '14621',
    phone: '(585) 922-5700',
  },
  14: {
    name: 'Massachusetts General Comprehensive Hemophilia and Thrombosis Treatment Center',
    address1: 'Pediatric Hematology/Oncology',
    address2: 'Yawkey 8B',
    address3: '55 Fruit Street',
    address4: '',
    city: 'Boston',
    state: 'MA',
    zipcode: '02114',
    phone: '(617) 726-2737',
  },
  560: {
    name: 'Mayo Comprehensive Hemophilia Center',
    address1: 'Mayo 10-75E',
    address2: '200 First St. SW',
    address3: '',
    address4: '',
    city: 'Rochester',
    state: 'MN',
    zipcode: '55905',
    phone: '(800) 344-7726',
  },
  442: {
    name: 'Michigan State University Center for Bleeding Disorders & Clotting Disorders',
    address1: '1355 Bogue St.',
    address2: 'B220 Life Sciences',
    address3: '',
    address4: '',
    city: 'East Lansing',
    state: 'MI',
    zipcode: '48824',
    phone: '(517) 353-9385',
  },
  362: {
    name: 'Mississippi Center for Advanced Medicine',
    address1: '7730 Old Canton Rd',
    address2: 'Suite B',
    address3: '',
    address4: '',
    city: 'Madison',
    state: 'MS',
    zipcode: '39110',
    phone: '(601) 499-0935',
  },
  736: {
    name: 'Missouri Hemophilia Treatment Center',
    address1: 'University of Missouri Health Care',
    address2: 'One Hospital Drive - DC058.00',
    address3: '',
    address4: '',
    city: 'Columbia',
    state: 'MO',
    zipcode: '65212',
    phone: '(573) 882-9355',
  },
  64: {
    name: 'Montefiore Medical Center',
    address1: '110 East 210th Street',
    address2: '',
    address3: '',
    address4: '',
    city: 'Bronx',
    state: 'NY',
    zipcode: '10467',
    phone: '(718) 741-2342',
  },
  436: {
    name: "Nationwide Children's Hospital Hemostasis and Thrombosis Center",
    address1: "700 Children's Drive",
    address2: '',
    address3: '',
    address4: '',
    city: 'Columbus',
    state: 'OH',
    zipcode: '43205',
    phone: '(614) 722-3250',
  },
  739: {
    name: 'Nebraska Regional Hemophilia Treatment Center',
    address1: 'University of Nebraska Medical Center',
    address2: '987680 Nebraska Medical Center',
    address3: '',
    address4: '',
    city: 'Omaha',
    state: 'NE',
    zipcode: '68198-7680',
    phone: '(402) 559-4227',
  },
  333: {
    name: "Nemours Children's Health",
    address1: 'Division of Pediatric Hematology/Oncology',
    address2: "807 Children's Way",
    address3: '',
    address4: '',
    city: 'Jacksonville',
    state: 'FL',
    zipcode: '32207',
    phone: '(904) 697-3600',
  },
  1: {
    name: 'New England Hemophilia Center at UMASS Memorial',
    address1: 'New England Hemophilia Center S5-810',
    address2: 'UMass-Memorial Hospital',
    address3: '55 Lake Ave North',
    address4: '',
    city: 'Worcester',
    state: 'MA',
    zipcode: '01655',
    phone: '(774) 441-6316',
  },
  70: {
    name: 'Newark Beth Israel Medical Center',
    address1: 'Comprehensive Hemophilia Treatment Center',
    address2: '201 Lyons Avenue at Osborne Terrace',
    address3: '',
    address4: '',
    city: 'Newark',
    state: 'NJ',
    zipcode: '07112',
    phone: '(973) 926-6511',
  },
  678: {
    name: 'North Texas Comprehensive Hemophilia Center-Adult Program',
    address1: 'University of Texas Southwestern Medical School',
    address2: 'West Campus Building 3',
    address3: '2001 Inwood Road',
    address4: 'Suite 9.604',
    city: 'Dallas',
    state: 'TX',
    zipcode: '75390-8872',
    phone: '(214) 648-1939',
  },
  426: {
    name: 'Northern Regional Bleeding Disorder Center',
    address1: 'Cowell Family Cancer Center',
    address2: '217 S. Madison St',
    address3: '',
    address4: '',
    city: 'Traverse City',
    state: 'MI',
    zipcode: '49684',
    phone: '(231) 935-7227',
  },
  62: {
    name: 'Northwell Hemostasis and Thrombosis Center',
    address1: 'Hemophilia Treatment Center',
    address2: '270-05 76th Avenue',
    address3: 'Oncology Building Suite 358',
    address4: '',
    city: 'New Hyde Park',
    state: 'NY',
    zipcode: '11040',
    phone: '(718) 470-7380',
  },
  437: {
    name: 'Northwest Ohio Hemophilia Treatment Center',
    address1: 'Northwest Ohio Hemophilia Treatment Center',
    address2: "Promedica Russell J. Ebeid Children's Hospital",
    address3: '2109 Hughes Dr., Jobst Tower, Suite 860',
    address4: '',
    city: 'Toledo',
    state: 'OH',
    zipcode: '43606',
    phone: '(419) 291-2210',
  },
  553: {
    name: 'Northwestern Center for Bleeding Disorders',
    address1: 'Arkes Pavillion',
    address2: '676 N. St. Clair Street, Suite 2140',
    address3: '',
    address4: '',
    city: 'Chicago',
    state: 'IL',
    zipcode: '60611',
    phone: '(312) 695-0990',
  },
  432: {
    name: 'Ohio State University Medical Center',
    address1: 'Hemophilia Treatment Center',
    address2: '1210L Lincoln Tower',
    address3: '1800 Cannon Dr',
    address4: '',
    city: 'Columbus',
    state: 'OH',
    zipcode: '43210',
    phone: '(614) 293-9441',
  },
  676: {
    name: 'Oklahoma Center for Bleeding and Clotting Disorders',
    address1: 'Oklahoma Center for Bleeding and Clotting Disorders',
    address2: "1200 N. Children's Avenue, Suite 10000",
    address3: '',
    address4: '',
    city: 'Oklahoma City',
    state: 'OK',
    zipcode: '73104',
    phone: '(405) 271-3661',
  },
  853: {
    name: 'Orthopaedic Institute for Children',
    address1: 'Hemophilia Program',
    address2: '403 West Adams Blvd.',
    address3: '',
    address4: '',
    city: 'Los Angeles',
    state: 'CA',
    zipcode: '90007',
    phone: '(213) 742-1402',
  },
  153: {
    name: 'Penn Comprehensive Hemophilia and Thrombosis Program',
    address1: 'Hospital of the University of Pennsylvania',
    address2: '3400 Spruce Street',
    address3: '3rd floor Dulles Building',
    address4: '',
    city: 'Philadelphia',
    state: 'PA',
    zipcode: '19104',
    phone: '(215) 615-6555',
  },
  250: {
    name: 'Prisma Health - Upstate Hemophilia Treatment Center of South Carolina',
    address1: "BI-LO Charities Children's Cancer Center",
    address2: '900 West Faris Road, 2nd Floor',
    address3: '',
    address4: '',
    city: 'Greenville',
    state: 'SC',
    zipcode: '29605',
    phone: '(864) 455-8898',
  },
  257: {
    name: 'Prisma Health- Midlands Hemophilia Treatment Center of South Carolina',
    address1: 'Hemophilia Center of South Carolina',
    address2:
      "Children's Center for Cancer & Blood Disorders of Prisma Health Children's Hospital- Midlands",
    address3: '7 Richland Medical Park Rd., Suite 7215',
    address4: '',
    city: 'Columbia',
    state: 'SC',
    zipcode: '29203-6872',
    phone: '(803) 434-3533',
  },
  936: {
    name: "Providence Sacred Heart Children's Hospital",
    address1: 'Pediatric Hematology Oncology Department',
    address2: '101 West 8th Avenue',
    address3: '',
    address4: '',
    city: 'Spokane',
    state: 'WA',
    zipcode: '99204-2307',
    phone: '(509) 474-2777',
  },
  53: {
    name: 'Puerto Rico Hemophilia Treatment Center',
    address1: 'University of Puerto Rico School of Medicine',
    address2: 'Puerto Rico Hemophilia Treatment Center',
    address3: 'Department of Pediatrics',
    address4: 'GPO Box 365067',
    city: 'San Juan',
    state: 'PR',
    zipcode: '00936-5067',
    phone: '(787) 754-7410',
  },
  854: {
    name: "Rady Children's Hospital San Diego",
    address1: "3020 Children's Way",
    address2: '',
    address3: '',
    address4: '',
    city: 'San Diego',
    state: 'CA',
    zipcode: '92123',
    phone: '(858) 966-5811',
  },
  7: {
    name: 'RI Hemostasis & Thrombosis Center',
    address1: "Rhode Island Hospital/Hasbro Children's Hospital",
    address2: '593 Eddy St. Potter 159',
    address3: '',
    address4: '',
    city: 'Providence',
    state: 'RI',
    zipcode: '02903',
    phone: '(401) 444-8250',
  },
  569: {
    name: 'Rush University Medical Center',
    address1: 'Rush University Hemophila Thrombophilia Center',
    address2: 'Suite 1591 Jelke',
    address3: '1725 W. Harrison Street',
    address4: '',
    city: 'Chicago',
    state: 'IL',
    zipcode: '60612-3833',
    phone: '(312) 942-3034',
  },
  54: {
    name: 'Rutgers, The State University, Robert Wood Johnson Medical School',
    address1: 'New Jersey Regional Hemophilia Program',
    address2: 'Division of Hematology',
    address3: 'One Robert Wood Johnson Place, Room #378, PO Box 19',
    address4: '',
    city: 'New Brunswick',
    state: 'NJ',
    zipcode: '08903-0019',
    phone: '(732) 235-6542',
  },
  735: {
    name: 'Saint Louis University Center for Bleeding and Thrombotic Disorders',
    address1: 'St. Louis University Hospital',
    address2: 'West Pavilion, 3rd Floor',
    address3: 'Hematology/Oncology',
    address4: '3655 Vista Avenue',
    city: 'St. Louis',
    state: 'MO',
    zipcode: '63110',
    phone: '(314) 268-7029',
  },
  562: {
    name: 'Sanford Health - Center for Bleeding and Clotting Disorder',
    address1: 'Roger Maris Cancer Center',
    address2: '801 Broadway N, Rt 0081',
    address3: '',
    address4: '',
    city: 'Fargo',
    state: 'ND',
    zipcode: '58122',
    phone: '(701) 234-7545',
  },
  930: {
    name: "Seattle Children's Hospital",
    address1: '4800 Sand Point Way NE',
    address2: 'PO Box 5371',
    address3: 'Hematology/Oncology M/S MB.8.501',
    address4: '',
    city: 'Seattle',
    state: 'WA',
    zipcode: '98105',
    phone: '(206) 987-2106',
  },
  573: {
    name: 'South Dakota Center for Blood Disorders',
    address1: "Sanford Children's Specialty Clinic",
    address2: 'Center for Blood Disorders',
    address3: '1600 W. 22nd St',
    address4: 'PO Box 5039',
    city: 'Sioux Falls',
    state: 'SD',
    zipcode: '57117-5039',
    phone: '(605) 312-1000',
  },
  679: {
    name: 'South Texas Comprehensive Hemophilia Center',
    address1: 'UT Health San Antonio',
    address2: '7703 Floyd Curl DR, Mail Stor 7810',
    address3: '',
    address4: '',
    city: 'San Antonio',
    state: 'TX',
    zipcode: '78229',
    phone: '(210) 336-0661',
  },
  150: {
    name: "St. Christopher's Hospital for Children",
    address1: '160 East Erie Avenue',
    address2: '',
    address3: '',
    address4: '',
    city: 'Philadelphia',
    state: 'PA',
    zipcode: '19134',
    phone: '(215) 427-4441',
  },
  348: {
    name: "St. Joseph's Hospital Center for Bleeding and Clotting Disorders",
    address1: "St. Joseph's Children's Hospital",
    address2: '3001 W. Dr. MLK Jr. Blvd.',
    address3: '',
    address4: '',
    city: 'Tampa',
    state: 'FL',
    zipcode: '33607',
    phone: '(813) 554-8294',
  },
  253: {
    name: "St. Jude Affiliate Clinic at Novant Health Hemby Children's Hospital",
    address1: '2711 Randolph Rd., Suite 100',
    address2: '',
    address3: '',
    address4: '',
    city: 'Charlotte',
    state: 'NC',
    zipcode: '28207',
    phone: '(704) 384-1900',
  },
  261: {
    name: "St. Jude Children's Research Hospital",
    address1: '262 Danny Thomas Place',
    address2: 'Mail Stop 800',
    address3: '',
    address4: '',
    city: 'Memphis',
    state: 'TN',
    zipcode: '38105-2794',
    phone: '(901) 595-5700',
  },
  934: {
    name: "St. Luke's Hemophilia Center",
    address1: '100 East Idaho Street',
    address2: '',
    address3: '',
    address4: '',
    city: 'Boise',
    state: 'ID',
    zipcode: '83712-6297',
    phone: '(208) 381-2782',
  },
  55: {
    name: "St. Michael's Medical Center",
    address1: 'Nadeene Brunini Comprehensive Hemophilia Care Center',
    address2: '111 Central Avenue',
    address3: '',
    address4: '',
    city: 'Newark',
    state: 'NJ',
    zipcode: '07102',
    phone: '(973) 877-5340',
  },
  844: {
    name: 'Stanford University Medical Center',
    address1: 'Divsion of Hematology/Oncology',
    address2: '1000 Welch Road, Suite 300',
    address3: 'Mail Code 5798',
    address4: '',
    city: 'Palo Alto',
    state: 'CA',
    zipcode: '94304',
    phone: '(650) 497-8953',
  },
  57: {
    name: 'SUNY Upstate Medical University-Adult Program',
    address1: 'c/o Regional Oncology Center',
    address2: '750 E. Adams Street',
    address3: '',
    address4: '',
    city: 'Syracuse',
    state: 'NY',
    zipcode: '13210',
    phone: '(315) 464-8200',
  },
  58: {
    name: 'SUNY Upstate Medical University-Pediatric Program',
    address1: 'c/o Center for Children with Cancer & Blood Disorder',
    address2: '750 E. Adams Street, Room 5400',
    address3: '',
    address4: '',
    city: 'Syracuse',
    state: 'NY',
    zipcode: '13210',
    phone: '(315) 464-5294',
  },
  802: {
    name: 'Ted R. Montoya Hemophilia Program',
    address1: 'MSC10 5590',
    address2: '1 University of New Mexico',
    address3: 'University of New Mexico/Dept. of Pediatrics',
    address4: '',
    city: 'Albuquerque',
    state: 'NM',
    zipcode: '87121-0001',
    phone: '(505) 272-4461',
  },
  682: {
    name: "Texas Children's Hemophilia and Thrombosis Center",
    address1: 'Clinical Care Center, 14th Floor',
    address2: '6621 Fannin',
    address3: '',
    address4: '',
    city: 'Houston',
    state: 'TX',
    zipcode: '77030',
    phone: '(832) 822-4240',
  },
  684: {
    name: 'The Central Texas Center for Bleeding and Clotting Disorders',
    address1: "Children's Blood and Cancer Center of Central Texas",
    address2: '1301 Barbara Jordan Blvd. Suite 401',
    address3: '',
    address4: '',
    city: 'Austin',
    state: 'TX',
    zipcode: '78723',
    phone: '(512) 628-1900',
  },
  932: {
    name: 'The Hemophilia Center at Oregon Health & Science University',
    address1: 'The Hemophilia Center',
    address2: 'Oregon Health & Science University',
    address3: '707 SW Gaines Street',
    address4: '',
    city: 'Portland',
    state: 'OR',
    zipcode: '97239',
    phone: '(503) 494-8716',
  },
  52: {
    name: 'The Joan & Sanford I Weill Cornell Medical College of Cornell University',
    address1:
      'Regional Comprehensive Hemophilia Diagnostic and Treatment Center',
    address2: '525 E. 68th Street, Room P-695',
    address3: '',
    address4: '',
    city: 'New York',
    state: 'NY',
    zipcode: '10065',
    phone: '(212) 746-3400',
  },
  734: {
    name: 'The John Bouhasin Center for Children with Bleeding Disorders',
    address1: "Cardinal Glennon Children's Medical Center",
    address2: 'Saint Louis Univ. Dept of Pediatrics',
    address3: '1465 South Grand Blvd.',
    address4: '',
    city: 'St. Louis',
    state: 'MO',
    zipcode: '63104',
    phone: '(314) 577-5332',
  },
  857: {
    name: "UCSF Benioff Children's Hospital Oakland",
    address1: 'Division of Hematology/Oncology',
    address2: '747 52nd Street',
    address3: '',
    address4: '',
    city: 'Oakland',
    state: 'CA',
    zipcode: '94610-4131',
    phone: '(510) 428-3286',
  },
  443: {
    name: 'UHHS Cleveland',
    address1: 'University Hospitals Cleveland Medical Center',
    address2: 'Pediatric Hematology Mail Stop 6054',
    address3: '11100 Euclid Avenue',
    address4: '',
    city: 'Cleveland',
    state: 'OH',
    zipcode: '44106',
    phone: '(216) 844-4888',
  },
  346: {
    name: 'University of Alabama at Birmingham',
    address1: '1600 7th Ave., South',
    address2: 'Lowder Building',
    address3: 'Suite 512',
    address4: '',
    city: 'Birmingham',
    state: 'AL',
    zipcode: '35233',
    phone: '(205) 638-2394',
  },
  850: {
    name: 'University of California at Davis',
    address1: 'Hemostasis and Thrombosis Center',
    address2: 'UC Davis Health',
    address3: '2000 Stockton Blvd.,Suite 202',
    address4: '',
    city: 'Sacramento',
    state: 'CA',
    zipcode: '95817',
    phone: '(916) 734-3461',
  },
  852: {
    name: 'University of California San Francisco',
    address1: 'UCSF HTC',
    address2: '513 Parnassus Avenue, Room S561',
    address3: '',
    address4: '',
    city: 'San Francisco',
    state: 'CA',
    zipcode: '94143',
    phone: '(415) 476-4170',
  },
  842: {
    name: 'University of California, San Diego',
    address1: '9333 Genesee Avenue, Suite 310',
    address2: '',
    address3: '',
    address4: '',
    city: 'San Diego',
    state: 'CA',
    zipcode: '92121',
    phone: '(858) 657-6028',
  },
  434: {
    name: 'University of Cincinnati Medical Center',
    address1: 'Hemophilia Treatment Center',
    address2: '3130 Highland Ave.',
    address3: '',
    address4: '',
    city: 'Cincinnati',
    state: 'OH',
    zipcode: '45219',
    phone: '(513) 584-7639',
  },
  336: {
    name: 'University of Florida Hemophilia Treatment Center',
    address1: 'Division of Pediatric Hematology/Oncology',
    address2: '1600 SW Archer Road',
    address3: '',
    address4: '',
    city: 'Gainesville',
    state: 'FL',
    zipcode: '32610',
    phone: '(352) 273-9120',
  },
  731: {
    name: 'University of Iowa Hospitals & Clinics',
    address1: 'Iowa Regional Hemophilia Center',
    address2: 'Department of Pediatrics',
    address3: '200 Hawkins DR, BT 1300-48',
    address4: '',
    city: 'Iowa City',
    state: 'IA',
    zipcode: '52242',
    phone: '(319) 356-4277',
  },
  255: {
    name: 'University of Kentucky Hemophilia Treatment Center',
    address1: 'UK Healthcare',
    address2: 'Department of Pediatrics',
    address3: '800 Rose Street, Suite C400',
    address4: '',
    city: 'Lexington',
    state: 'KY',
    zipcode: '40536-0284',
    phone: '(859) 257-6033',
  },
  254: {
    name: 'University of Louisville Hemophilia Treatment Center',
    address1: 'UL Hemophilia Treatment Center',
    address2: '2401 Terra Crossing Blvd, Suite 202',
    address3: '',
    address4: '',
    city: 'Louisville',
    state: 'KY',
    zipcode: '40245',
    phone: '(502) 210-4301',
  },
  335: {
    name: 'University of Miami Hemophilia Treatment Center Pediatrics and Adults',
    address1: 'University of Miami Miller School of Medicine',
    address2: 'Department of Pediatrics Div of Hematology/Oncology',
    address3: 'Locator Code D820',
    address4: '1601 NW 12th Ave, Room 5019',
    city: 'Miami',
    state: 'FL',
    zipcode: '33136',
    phone: '(305) 243-0834',
  },
  446: {
    name: 'University of Michigan Hemophilia and Coagulation Disorders',
    address1: 'F2480 UH-South/SPC 5235',
    address2: '1500 East Medical Center Drive',
    address3: '',
    address4: '',
    city: 'Ann Arbor',
    state: 'MI',
    zipcode: '48109-5235',
    phone: '(734) 647-8902 ext. opt.1',
  },
  559: {
    name: 'University of Minnesota Health - Center for Bleeding and Clotting Disorders',
    address1:
      'University of Minnesota Health - Center for Bleeding and Clotting Disorders',
    address2: 'Suite 105',
    address3: '2512 South 7th Street',
    address4: '',
    city: 'Minneapolis',
    state: 'MN',
    zipcode: '55454',
    phone: '(612) 273-5005',
  },
  262: {
    name: 'University of North Carolina at Chapel Hill',
    address1: 'UNC Hemophilia and Thrombosis Center',
    address2: 'University of NC at Chapel Hill',
    address3: 'Campus Box # 7016',
    address4: '',
    city: 'Chapel Hill',
    state: 'NC',
    zipcode: '27599-7016',
    phone: '(919) 966-4736',
  },
  334: {
    name: 'University of South Florida - Adult',
    address1: '13220 USF Laurel Drive',
    address2: 'FOB, 4th floor',
    address3: 'Hematology Dept.',
    address4: '',
    city: 'Tampa',
    state: 'FL',
    zipcode: '33612',
    phone: '(813) 974-3725',
  },
  135: {
    name: 'University of Virginia Hospital',
    address1: '1204 Main Street',
    address2: 'Peds Hematology',
    address3: 'Rm 5121 Battle Building',
    address4: '',
    city: 'Charlottesville',
    state: 'VA',
    zipcode: '22903',
    phone: '(434) 924-8499',
  },
  807: {
    name: "Utah Center for Bleeding & Clotting Disorders at Primary Children's Hospital and University of Utah",
    address1: '100 N. Mario Capecchi Drive',
    address2: '',
    address3: '',
    address4: '',
    city: 'Salt Lake City',
    state: 'UT',
    zipcode: '84113',
    phone: '(801) 662-4700',
  },
  567: {
    name: 'UWHC Comprehensive Program for Bleeding Disorders',
    address1: 'University of Wisconsin Hospital and Clinics',
    address2: 'UWHC Comprehensive Program for Bleeding Disorders',
    address3: '600 Highland Ave.',
    address4: '',
    city: 'Madison',
    state: 'WI',
    zipcode: '53792-9749',
    phone: '(608) 890-9493',
  },
  862: {
    name: "Valley Children's Hospital",
    address1: 'Hematology/Oncology',
    address2: "9300 Valley Children's Place",
    address3: '',
    address4: '',
    city: 'Madera',
    state: 'CA',
    zipcode: '93636',
    phone: '(559) 353-5460',
  },
  260: {
    name: 'Vanderbilt University Medical Center',
    address1: 'Hemostasis Clinic',
    address2: '1500 21st Avenue South, Suite 2500',
    address3: '',
    address4: '',
    city: 'Nashville',
    state: 'TN',
    zipcode: '37212',
    phone: '(615) 936-1765',
  },
  8: {
    name: 'Vermont Regional Hemophilia Center',
    address1: 'University of Vermont Medical Center',
    address2: '111 Colchester Ave',
    address3: 'Hematology and Oncology',
    address4: '',
    city: 'Burlington',
    state: 'VT',
    zipcode: '05401',
    phone: '(802) 847-8400',
  },
  137: {
    name: 'Virginia Commonwealth University',
    address1: 'VMI Building',
    address2: '1000 E. Marshall St.',
    address3: '4th Floor, Room 422C',
    address4: 'P.O. Box 980461',
    city: 'Richmond',
    state: 'VA',
    zipcode: '23298-0461',
    phone: '(804) 827-3306',
  },
  251: {
    name: 'Wake Forest University School of Medicine',
    address1: 'Wake Forest University Health Sciences',
    address2: 'Department of Pediatrics',
    address3: 'Medical Center Boulevard',
    address4: '',
    city: 'Winston-Salem',
    state: 'NC',
    zipcode: '27157-1081',
    phone: '(336) 716-4324',
  },
  931: {
    name: 'Washington Center for Bleeding Disorders at Washington Institute for Coagulation',
    address1: 'Washington Center for Bleeding Disorders',
    address2: 'Washington Institute for Coagulation',
    address3: '701 Pike Street, Suite 1900',
    address4: '',
    city: 'Seattle',
    state: 'WA',
    zipcode: '98101',
    phone: '(206) 614-1200',
  },
  737: {
    name: 'Washington University Center for Bleeding and Blood Clotting Disorders-Adult Program',
    address1: '660 South Euclid, Campus Box 8125',
    address2: '',
    address3: '',
    address4: '',
    city: 'St. Louis',
    state: 'MO',
    zipcode: '63110',
    phone: '(314) 362-7216',
  },
  738: {
    name: 'Washington University Center for Bleeding and Blood Clotting Disorders-Pediatric Progra',
    address1: "One Children's Place",
    address2: '',
    address3: '',
    address4: '',
    city: 'St. Louis',
    state: 'MO',
    zipcode: '63110',
    phone: '(314) 454-6018',
  },
  147: {
    name: 'West Virginia University Medical Center',
    address1: 'WVUHealthcare',
    address2: 'Mary Babb Randolph Cancer Center',
    address3: 'One Medical Center Drive',
    address4: 'PO Box 8110',
    city: 'Morgantown',
    state: 'WV',
    zipcode: '26506-8110',
    phone: '(304) 598-4500',
  },
  59: {
    name: 'Western New York BloodCare',
    address1: '1010 Main Street, Suite 300',
    address2: '',
    address3: '',
    address4: '',
    city: 'Buffalo',
    state: 'NY',
    zipcode: '14202',
    phone: '(716) 896-2470',
  },
  2: {
    name: 'Yale HTC',
    address1: 'Yale Hemophilia Treatment Center',
    address2: 'Department of Pediatrics, LMP 4087',
    address3: '333 Cedar Street',
    address4: '',
    city: 'New Haven',
    state: 'CT',
    zipcode: '06510',
    phone: '(203) 785-4640',
  },
});

const formBC = {
  title: 'Form BC',
  theme: '',
  breadcrumbClickable: true,
  buttonSettings: {
    previous: true,
    cancel: true,
    next: true,
  },
  navigateOnEnter: false,
  saveOnEnter: false,
  scrollToTop: false,
  collapsible: false,
  key: 'BC',
  type: 'panel',
  label: 'Form BC',
  input: false,
  tableView: false,
  components: [
    {
      html: '<strong>Bleeding Disorder Camp</strong>',
      label: 'Content',
      refreshOnChange: false,
      key: 'BC0',
      type: 'content',
      input: false,
      tableView: false,
    },
    {
      label: 'Have you ever attended a bleeding disorder camp?',
      optionsLabelPosition: 'right',
      inline: true,
      tableView: false,
      values: [
        {
          label: 'Yes',
          value: 'Yes',
          shortcut: '',
        },
        {
          label: 'No',
          value: 'No',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      key: 'BC1',
      type: 'radio',
      input: true,
    },
    {
      label: 'How old were you when you first attended?',
      placeholder: 'Please enter age when you first attended camp',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        required: true,
        min: 1,
        max: 999,
      },
      key: 'BC2',
      conditional: {
        show: true,
        when: 'BC1',
        eq: 'Yes',
      },
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'How many times have you attended a bleeding disorders camp?',
      placeholder: 'Please enter number of times you attended camp',
      applyMaskOn: 'change',
      mask: false,
      tableView: false,
      delimiter: false,
      requireDecimal: false,
      inputFormat: 'plain',
      truncateMultipleSpaces: false,
      validate: {
        required: true,
        min: 1,
        max: 999,
      },
      key: 'BC3',
      conditional: {
        show: true,
        when: 'BC1',
        eq: 'Yes',
      },
      type: 'number',
      input: true,
      decimalLimit: 0,
    },
    {
      label: 'What was your role at camp? Choose all that apply.',
      optionsLabelPosition: 'right',
      tableView: false,
      values: [
        {
          label: 'Camper',
          value: 'Camper',
          shortcut: '',
        },
        {
          label: 'Volunteer',
          value: 'Volunteer',
          shortcut: '',
        },
        {
          label: 'Organizer',
          value: 'Organizer',
          shortcut: '',
        },
        {
          label: 'Other',
          value: 'other',
          shortcut: '',
        },
      ],
      validate: {
        required: true,
      },
      conditional: {
        show: true,
        when: 'BC1',
        eq: 'Yes',
      },
      key: 'BC4',
      type: 'selectboxes',
      input: true,
      inputType: 'checkbox',
    },
    {
      label: 'Other',
      placeholder: 'Other...',
      applyMaskOn: 'change',
      hideLabel: true,
      tableView: true,
      validate: {
        required: true,
        maxLength: 250,
      },
      key: 'BC4_other',
      customConditional: "show = data[component.key.split('_')[0]]?.other;",
      type: 'textfield',
      input: true,
    },
  ],
};

export default formBC;
